import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizeConversion'
})
export class SizeConversionPipe implements PipeTransform {

  transform(value: number | string, args: string): string {
    if (isNaN(Number(value))) {
      return '';
    }

    const units = args.split(',');
    const fromUnit = units[0].trim();
    const toUnit = units[1].trim();

    const conversionFactors: { [key: string]: number } = {
      B: 1,
      KB: 1024,
      MB: 1024 * 1024,
      GB: 1024 * 1024 * 1024
      // Add more units and conversion factors as needed
    };

    const fromFactor = conversionFactors[fromUnit.toUpperCase()];
    const toFactor = conversionFactors[toUnit.toUpperCase()];

    if (!fromFactor || !toFactor) {
      return '';
    }

    const result = (Number(value) * fromFactor / toFactor).toFixed(2);
    return `${result} ${toUnit}`;
  }

}
