<div *ngFor="let item of items" class="item">

    <div class="item-row">
        <label class="item-label">Time: </label>
        <div class="item-value">{{item.dateTime | date:'HH:mm'}}</div>
        <label class="item-label">Price: </label>
        <div class="item-value">{{item.totalAmount | currency: "EUR" }}</div>

    </div>

    <div class="item-row">
        <label class="item-label">Reason:</label>
        <div class="item-value2">{{item.reason}}</div>

    </div>

    <div class="item-row">
        <label class="item-label">Drinks:</label>
        <div *ngFor="let dis of item.drinks" class="item-drink">
            {{names[dis]}}
        </div>
    </div>







</div>
