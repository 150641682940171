import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Bar } from "src/app/Classes/bar";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-create-bar",
  templateUrl: "./create-bar.component.html",
  styleUrls: ["./create-bar.component.css"],
})
export class CreateBarComponent implements OnInit {
  bar: Bar = new Bar();
  staff: any[] = [];
  staffUids: string[] = [];
  manager: string = "";
  page: number = -1;
  existingBars: any[] = [];
  constructor(
    public db: StaffAppDatabase,
    public loading: LoadingService,
    public alert: AlertsService,
    public bi: BarInfo,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.getData();

    //dirty fill
  }

  async getData() {
    this.loading.show();
    this.staff = (await this.db.getStaffAsync())
      .filter((a: any) => {
        return (
          a.access > 0 &&
          a.lastName &&
          a.bar != "Pertempto" &&
          a.bar != "Old Staff"
        );
      })
      .sort((a: any, b: any) => {
        if (a.access != b.access) {
          return b.access - a.access;
        }

        if (!a.lastName) {
          return -1;
        }

        if (!b.lastName) {
          return 1;
        }
        return a.lastName.localeCompare(b.lastName);
      });

    this.staffUids = this.staff.map((a) => a.firstName[0] + ". " + a.lastName);
    console.log(this.staff);

    this.existingBars = await this.db.getBarsAsync();
    console.log(this.existingBars);
    this.loading.hide();
  }

  managerChanged(staffName: string) {
    console.log(staffName);
    this.manager = staffName;
    const staff = this.staff.find((a) => {
      return staffName == a.firstName[0] + ". " + a.lastName;
    });
    if (!staff) {
      this.alert.nextEmit(AlertsService.error("Staff Not Found"));
      this.manager = "";
      this.bar.manager = "";
      return;
    }
    this.bar.manager = staff.uid;
  }

  clear() {
    window.confirm("Are you sure you want to clear all data?") &&
      (this.bar = new Bar());
  }

  verify(): boolean {
    //make sure the bar has atleast a name
    var errors = this.bar.verify();
    if (errors.length) {
      this.alert.nextEmit(AlertsService.error(errors.join("\n")));
      return false;
    }

    console.log(this.existingBars, "searching: " + this.bar.name);
    if (this.existingBars.find((a) => a == this.bar.name)) {
      this.alert.nextEmit(AlertsService.error("Bar Name Already Exists"));
      return false;
    }

    this.alert.nextEmit(AlertsService.success("Bar Verified"));
    return true;
  }

  save() {
    if (!this.verify()) return;
    //get ready to save the bar
    this.loading.show();
    console.log("saving", this.bar);
    console.log("fixing variables");
    this.bar.fixVariables();
    console.log("fixed variables: ", this.bar);

    this.db
      .addBar(this.bar)
      .then(() => {
        this.alert.nextEmit(AlertsService.success("Bar Saved"));
        this.bi.setBar(this.bar.name);
        this.router.navigate([""]);
        this.loading.hide();
      })
      .catch((err) => {
        this.alert.nextEmit(AlertsService.error(err));
        this.loading.hide();
      });
  }
}
