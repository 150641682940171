<div class="flex-row">
  <input
    type="date"
    name="Start Date"
    [ngModel]="dateStart | date : 'yyyy-MM-dd'"
    (change)="dateChanged(true, $event)"
  />
  <button
    (click)="toggleLive()"
    class="reactiveButtonTitle"
    [ngClass]="{ green: isLive, red: !isLive }"
  >
    Live
  </button>
  <button (click)="getData()">Get</button>
</div>

<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col" sortable="date" (sort)="onSort($event)">Date</th>
      <th scope="col" sortable="amount" (sort)="onSort($event)">Amount</th>
      <th scope="col" sortable="status" (sort)="onSort($event)">Status</th>
      <th scope="col" sortable="deviceId" (sort)="onSort($event)">Device</th>
      <th scope="col">...</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let payment of filteredPayments"
      [class]="payment.pinStatus == 'success' ? 'green' : 'red'"
    >
      <th scope="row">{{ payment.date | date : "yyyy-MM-dd HH:mm:ss" }}</th>
      <td>{{ payment.amount | currency : "EUR" }}</td>
      <td>{{ payment.status | capitalize }}</td>
      <td>{{ payment.deviceId }}</td>
      <td (click)="openPayment(payment)"><i class="fas fa-ellipsis-h"></i></td>
    </tr>
  </tbody>
</table>

<div class="d-flex justify-content-between p-2">
  <ngb-pagination
    [collectionSize]="payments.length"
    [(page)]="page"
    [pageSize]="pageSize"
    (pageChange)="sort()"
  >
  </ngb-pagination>
</div>
