import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { Auth } from 'src/app/auth/auth';

@Component({
  selector: 'app-set-schedule',
  templateUrl: './set-schedule.component.html',
  styleUrls: ['./set-schedule.component.css'],

})
export class SetScheduleComponent implements OnInit {

  user: any;
  list = [];
  selected: any;

  schedule = {};
  hours: any = {};
  showReason = false;
  staffNames: any = {};
  daysPerWeek = 3;
  target = 4;

  //CalendarVariables
  date: Date;
  table: any[][];
  countdown: number = 0;

  cleaning: any[] = [];
  working: any[] = [];

  reasonText = "";
  reason: any;

  trafficDays: any = {};

  request: any = {};


  readonly today = new Date();
  start = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate() - ((this.today.getDay() + 6) % 7) + 14,
    0,
    0,
    0,
    0
  );

  end = new Date(
    this.start.getFullYear(),
    this.start.getMonth() + 1,
    0,
    0,
    0,
    0,
    0
  );
  nextSunday = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    0,
    0,
    0,
    0
  );

  nextWeek = new Date(this.start);

  constructor(
    private db: StaffAppDatabase,
    private auth: Auth,
    private router: Router,
    private renderer: Renderer2,
    private ngModal: NgbModal
  ) {
    this.nextSunday.setDate(
      this.nextSunday.getDate() + 7 - this.nextSunday.getDay()
    );


    this.date = new Date();
    this.fillCalendar(this.start, this.end);

    this.initData();
  }

  testButton($event) {
    alert('hi mitchell');
    $event.stopPropagation();
  }

  setDate(event) {
    this.start = new Date(event);

    this.end = new Date(
      this.start.getFullYear(),
      this.start.getMonth() + 1,
      0,
      0,
      0,
      0,
      0
    );

    this.fillCalendar(this.start, this.end);
    this.getStaffDays();
    this.getHours();
    this.getSchedule();
  }
  async initData() {
    this.user = await this.auth.getDbUser();
    console.log('got user: ' + this.user)
    if (this.user) {

      if (Number.isInteger(this.user.target)) {

        this.target = this.user.target;
      }

      this.getStaffNames();
      this.update(this.start);

      this.countDownTimer();
    }
  }

  selectDay(day: any = null) {
    if (!day) {
      this.cleaning = [];
      this.working = [];
    }
    console.log(day)
    this.selected = day;
  }

  countDownTimer() {
    const time = this.nextSunday.getTime() - new Date().getTime();
    this.countdown = time;
    setTimeout(() => {
      this.countDownTimer();
    }, 1000);
  }

  getHours() {
    if (this.hours[this.start.getMonth() + ""]) {
      this.insertList(this.hours[this.start.getMonth() + ""], "allHours");
      this.insertList(
        this.hours[this.start.getMonth() + ""].filter(
          (b) => b.data[this.user.uid]
        ),
        "hours"
      );
    } else {
      ////console.log('getting hours', this.start.getMonth())
      this.db
        .getHoursForMonth(
          this.user.bar,
          this.user.uid,
          this.start.getFullYear(),
          this.start.getMonth()
        )
        .then((a: any[]) => {
          this.hours[this.start.getMonth() + ""] = a;
          this.insertList(a, "allHours");
          this.insertList(
            a.filter((a) => a.data[this.user.uid]),
            "hours"
          );
        });
    }
  }

  tableClicked(event: any) {
    console.log(event);
    alert('table clicked')
    var targetElement = document.elementFromPoint(event.clientX, event.clientY);
    if (targetElement.classList.contains("day")) {
      event.preventDefault();
      console.log('day clicked');
      var element = targetElement as HTMLDivElement
      element.click();
    } else {
      console.log('no element found');
    }
  }

  getStaffNames() {
    //console.log("getting staff names");
    const sub = this.db.getStaffNames(this.user.bar).subscribe((a) => {
      if (a) {
        sub.unsubscribe();
        a.forEach((a: any) => {
          this.staffNames[a.uid] = a.firstName + " " + a.lastName;
        });
      }
    });
  }

  getStaffDays() {
    this.db
      .getStaffDays(this.user.uid, this.start, this.user.bar)
      .then((a: any) => {
        this.insertList(a, "request");

        this.daysPerWeek =
          this.user.target === undefined || this.user.target === null
            ? 3
            : this.user.target;
        for (const day of a) {

          if (this.sameDay(new Date(day.date), this.nextWeek)) {

            if (
              day.data &&
              day.data.target != null &&
              day.data.target != undefined
            ) {
              this.daysPerWeek = day.data.target;
              break;
            }
          }
        }
        this.trafficDays[
          new Date(
            this.start.getFullYear(),
            this.start.getMonth(),
            1,
            0,
            0,
            0,
            0
          ).getTime() + ""
        ] = a;

      });
  }

  getSchedule() {
    ////console.log('getting schedule')
    let sub = this.db
      .getWorkingDays(
        this.user.bar,
        null,
        this.start.getFullYear(),
        this.start.getMonth()
      )
      .subscribe((events) => {
        console.log(events)
        this.schedule[this.start.getMonth() + ""] = events.map((a: any) => {

          const times = (a.times as any[] || []).reduce((prev, curr, index) => { prev[curr.key] = curr.startTime; return prev }, {});
          const data = (a.staff as any[] || []).map(a => {
            if (times[a]) {
              return { key: a, time: times[a] };
            } else {
              return { key: a, time: 8 };
            }
          })


          return { date: a.date, data: data };
        });
        console.log(this.schedule[this.start.getMonth() + ""])
        this.insertList(this.schedule[this.start.getMonth() + ""], "schedule");
        console.log(this.schedule)
        sub.unsubscribe();
      });
  }

  sameDay(date, today): boolean {
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      today.getDate() === date.getDate()
    );
  }

  isToday(time) {
    const date = new Date(time);
    return this.sameDay(date, this.today);
  }

  sameMonth(time) {
    const date = new Date(time);
    return (
      date.getMonth() === this.start.getMonth() &&
      date.getFullYear() === this.start.getFullYear()
    );
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.list.forEach((a) => {
      a.unsubscribe();
    });
  }

  /*
  requestCleaning(date,from,till){
    ////console.log(date,from.value,till.value);
    const day = new Date(date.value);
    const timeStart = new Date(date.value);
    const timeEnd = new Date(date.value);

    timeStart.setHours(from.value.split(":")[0]);
    timeStart.setMinutes(from.value.split(":")[1]);

    timeEnd.setHours(till.value.split(":")[0]);
    timeEnd.setMinutes(till.value.split(":")[1]);

    ////console.log(day,timeStart,timeEnd);
    if(timeStart >= timeEnd){
      alert('Invalid time range!');
      return;
    }
    this.db.requestCleaning(this.uid.uid, this.uid.bar,day,timeStart,timeEnd,this.uid.firstName);
  }*/

  nextMonth() {
    this.start.setMonth(this.start.getMonth() + 1);
    this.start = new Date(this.start);
    this.end = new Date(
      this.start.getFullYear(),
      this.start.getMonth() + 1,
      0,
      0,
      0,
      0,
      0
    );

    this.fillCalendar(this.start, this.end);
    this.getStaffDays();
    this.getHours();
    this.getSchedule();
  }

  previousMonth() {
    this.start.setMonth(this.start.getMonth() - 1);
    this.start = new Date(this.start);
    this.end = new Date(
      this.start.getFullYear(),
      this.start.getMonth() + 1,
      0,
      0,
      0,
      0,
      0
    );

    this.fillCalendar(this.start, this.end);
    this.getStaffDays();
    this.getHours();
    this.getSchedule();
  }

  /*Calendar Functions*/
  fillCalendar(start, end) {
    ////console.log("filling calendar", start, end);
    var firstDay = new Date(start.getFullYear(), start.getMonth(), 1);
    var lastDay = new Date(end.getFullYear(), end.getMonth() + 1, 0);
    var day = new Date(start.getFullYear(), start.getMonth(), 1);
    var table = new Array(6);

    //get first day of week
    for (var i = 0; i < 6; i++) {
      if (day > lastDay) {
        table[i] = new Array(7);
        for (var j = 0; j < 7; j++) {
          table[i][j] = {
            date: 0,
            weekType: 0,
            request: { request: -1 },
          };
        }

        break;
      }
      table[i] = new Array(7);
      for (var j = 0; j < 7; j++) {
        if (i == 0 && j < (firstDay.getDay() + 6) % 7) {
          const monthBefore = new Date(firstDay);
          monthBefore.setDate(
            monthBefore.getDate() - ((firstDay.getDay() + 6) % 7) + j
          );
          ////console.log(monthBefore)
          table[i][j] = {
            date: monthBefore.getTime(),
            weekType: this.weekType(monthBefore),
            request: { request: -1 },
          };

          continue;
        }

        table[i][j] = {
          date: day.getTime(),
          weekType: this.weekType(day),
          request: { request: -1 },
        };
        day.setDate(day.getDate() + 1);
      }
    }

    this.table = [];
    this.table = table;

  }

  isWorking(list) {
    return list.filter((a) => a === this.user.uid).length > 0;
  }

  sameWeek(date: Date, comp: Date) {
    const day1 = new Date(date);
    day1.setDate(day1.getDate() - ((day1.getDay() + 6) % 7));

    const day2 = new Date(comp);
    day2.setDate(day2.getDate() - ((day2.getDay() + 6) % 7));

    return this.sameDay(day1, day2);
  }

  getWeek(date: Date) {
    const day1 = new Date(date);
    day1.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    return day1;
  }

  dayClicked($event, day) {

    try {
      $event.stopPropagation();
    } catch (e) {
      console.log('coulndt  stop propagation')
    }


    console.log('day clicked: ', day, $event)
    if (!day || !day.date) {
      console.log('day not a day: ', day, $event)
      return;
    }
    if (day.weekType.indexOf("past") == -1) {
      if (!this.sameWeek(this.nextWeek, new Date(day.date))) {

        this.nextWeek = this.getWeek(new Date(day.date));

        this.update(this.nextWeek);
      } else if (
        !this.sameMonth(day.date) ||
        !this.sameWeek(new Date(day.date), this.nextWeek)
      ) {
        this.start = new Date(day.date);
        this.update(this.start);
      } else {

        var newDay = 0;
        if (
          !day.request ||
          day.request.request == undefined ||
          day.request.request == null
        ) {
          newDay = 0;
        } else {
          newDay = (day.request.request + 1) % 3;
        }

        day.request.request = newDay;

        const d = new Date(day.date);
        this.setTrafficDay(d, newDay);


        if (this.isBlockedWeek(new Date(this.nextWeek))) {
          this.openReason();
        } else {
          this.db.setStaffDays(
            this.user.uid,
            new Date(day.date),
            this.user.bar,
            newDay
          );
        }
      }
    } else {


      if (day !== undefined) {
        this.selected = {};
        console.log(day)
        Object.assign(this.selected, day);

      }

    }
  }

  setTrafficDay(day: Date, target: number) {

    var month =
      this.trafficDays[
      new Date(day.getFullYear(), day.getMonth(), 1, 0, 0, 0, 0).getTime() +
      ""
      ];
    if (month) {

      const index = month.findIndex((a) => this.sameDay(a.date, day));
      if (index < 0 || index === undefined) {
        month.push({ date: new Date(day), data: { request: target } });
        month = month.sort((a: any, b: any) => a.date - b.date);
      } else {
        month[index].data = { request: target };
      }
    } else {

    }
  }

  weekType(time) {
    //past, last past, next, future
    const endOfWeek = new Date(this.today);

    endOfWeek.setDate(endOfWeek.getDate() + (7 - endOfWeek.getDay()) % 7);

    if (time < endOfWeek) {
      return "past";
    }

    endOfWeek.setDate(endOfWeek.getDate() + 7);
    // console.log('endofweek2: ' + endOfWeek.toISOString())
    if (time < endOfWeek) {
      return "past last";
    }

    endOfWeek.setDate(endOfWeek.getDate() + 7);
    // console.log('endofweek3: ' + endOfWeek.toISOString())
    if (this.sameWeek(time, this.nextWeek)) {
      return "next";
    }

    return "";
  }

  insertObject(date, objectKey, object) {
    this.table.forEach((week) => {
      week.forEach((day) => {
        if (day.date) {
          const d = new Date(day.date);
          if (this.sameDay(d, date)) {
            day[objectKey] = object;
            return;
          }
        }
      });
    });
  }

  insertRange(start, end, objectKey, object) {
    var day = new Date(start);
    while (day <= end && day.getMonth() <= this.date.getMonth()) {
      this.insertObject(day, objectKey, object);
      day.setDate(day.getDate() + 1);
    }
  }

  //list and string key to add to
  insertList(list, objectKey) {

    list.forEach((day) => {
      const d = new Date(day.date);

      this.insertObject(d, objectKey, day.data);
    });
  }

  changeDaysPerWeek(number) {
    if (!this.sameMonth(this.nextWeek)) {
      this.update(new Date(this.nextWeek));
    }
    if (this.daysPerWeek + number >= 0 && this.daysPerWeek + number <= 7) {
      this.daysPerWeek += number;

      if (this.daysPerWeek === 0 && this.target != 0) {
        for (var i = 0; i < 7; i++) {
          const day = new Date(this.nextWeek);
          day.setDate(day.getDate() + i);
          const indexes = this.getIndexes(day);
          this.table[indexes[0]][indexes[1]].request.request = 0;
          this.table[indexes[0]][indexes[1]].request.target = this.daysPerWeek;
        }
        this.openReason();
      } else {
        const day = new Date(this.nextWeek);
        const indexes = this.getIndexes(day);
        this.table[indexes[0]][indexes[1]].request.target = this.daysPerWeek;
        this.db.setTarget(
          this.user.bar,
          this.user.uid,
          this.nextWeek,
          this.daysPerWeek
        );
      }
    }
  }

  openReason() {
    this.showReason = true;
    this.db
      .getRequestDay(this.user.bar, this.user.uid, this.nextWeek)
      .then((a: any) => {
        ////console.log(a)
        if (a) {
          this.reasonText = a;
        } else {
          this.reasonText = "";
        }
        this.reason = { date: new Date(this.nextWeek), reason: "" };
      });
  }

  cancelReason() {
    this.showReason = false;
    this.reason = null;
    this.update(this.nextWeek);
  }

  setReason() {
    if (this.reasonText != "") {
      for (var i = 0; i < 7; i++) {
        const day = new Date(this.nextWeek);
        day.setDate(day.getDate() + i);
        const indexes = this.getIndexes(day);
        this.db.setStaffDays(
          this.user.uid,
          new Date(day),
          this.user.bar,
          this.table[indexes[0]][indexes[1]].request.request
        );
      }
      this.db.setTarget(
        this.user.bar,
        this.user.uid,
        this.nextWeek,
        this.daysPerWeek
      );
      this.db.requestDay(
        this.user.bar,
        this.user.uid,
        this.nextWeek,
        this.reasonText
      );
      this.reasonText = "";
      this.reason = null;
      this.showReason = false;
    }
  }

  isBlockedWeek(d: Date) {
    ////console.log('checkig for blocked week')
    var days: any[] = [];
    Object.entries(this.trafficDays).map((e: any) => {
      e[1].forEach((d) => {
        days.push(d);
      });
    });
    ////console.log('got all days as map', days);
    //get first index of this week in the list
    const index = days.findIndex((a: any) =>
      this.sameWeek(a.date, this.nextWeek)
    );
    const startDate = new Date(this.nextWeek);
    //console.log("index of start of week", index, startDate);
    if (index >= 0) {
      for (var i = index; i < index + 7; i++) {
        //console.log(days[i]);
        if (
          days[i] === undefined ||
          days[i].data === undefined ||
          days[i].data == null ||
          days[i].data.request != 0
        ) {
          //console.log(days[i], "week not blocked off", this.trafficDays);
          return false;
        }
        if (!this.sameWeek(this.nextWeek, days[i].date)) {
          return false;
        }
        startDate.setDate(startDate.getDate() + 1);
      }
    } else if (index === -1) {
      return false;
    }

    return true;
  }

  scheduleHas(key) {
    console.log(key)
    if (key && key != '' && this.selected && this.selected.schedule)
      return this.selected.schedule.filter(a => a.key == key).length >= 1;
    return false
  }
  getIndexes(d: Date) {
    for (var i = 0; i < this.table.length; i++) {
      for (var j = 0; j < this.table[i].length; j++) {
        if (this.sameDay(d, new Date(this.table[i][j].date))) {
          return [i, j];
        }
      }
    }
  }

  update(start) {
    this.start = new Date(start);
    this.end = new Date(
      this.start.getFullYear(),
      this.start.getMonth() + 1,
      0,
      0,
      0,
      0,
      0
    );
    this.fillCalendar(this.start, this.end);
    this.getStaffDays();
    this.getHours();
    this.getSchedule();
  }
}
