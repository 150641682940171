<div class="message" [style.border-color]="message.user.uid | toColor">

  <div class="message-body">
    <div class="message-content">{{message.message}}</div>
  </div>
  <div class="message-footer">
    <div class="message-user">{{message.user.displayName}}</div>
    <div class="message-date">{{message.date | date:'short'}}</div>
  </div>
</div>
