export class Song {
  name: string = "";
  index: string = "";
  key: string = "";
  artist: string = "";
  count: number = 0;
  genre: string[] = [];
  image: string = "";
  info: string = "";
  length: string = "";
  mbid: string = "";
  year: string = "";
  editedBy: string = "";
  location: string = "";
  sung: number = 0;
  sungd: any;
  reviewed: string = "";
  modifier: number = 1.2;
  isDutch: boolean = false;
  language: string = ""; // e.g. 'gb' 'gb, nl' for a song in both languages with the English version first
  review() {
    if (
      this.name &&
      !isNaN(Number(this.index)) &&
      this.artist &&
      this.info.length
    ) {
      this.reviewed = "reviewed";
    } else {
      console.log(this.artist + " - " + this.name);
      this.reviewed = "";
    }
  }
}
