import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-list',
  templateUrl: './overview-list.component.html',
  styleUrls: ['./overview-list.component.css']
})
export class OverviewListComponent implements OnInit {

  @Input('keys') keys: Map<string,string>; //map<key,displayName>
  @Input('columns') columns: Map<string,string>; //map<key,displayName>
  @Input('header') header: Map<string,string>; //map<key,displayName>
  @Input('data') data: any = {};
 @Input('titleClass') titleClass
  @Input('isHeader') isHeader: boolean = false;
  @Input('title') title: string = '';

  @Input('list') list: any[];


  constructor() { }

  ngOnInit(): void {

  }


}
