<form>
  <div class="mb-3 row">
    <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
    <div class="col-xs-3 col-sm-auto">
      <input id="table-filtering-search" class="form-control" type="text" [formControl]="filter" />
    </div>
  </div>

  <div style=" display: flex; align-items: center; justify-content: space-evenly;">
    <button style="width: 150px; flex-shrink: 0;" (click)="setProgress(true,0); cleanup()" title=" Click me before using and finding songs on page when you start a new song buying process, it merges duplicates,
    and other cleanup operations which should give you a more accurate account">Clean Up</button>

    <app-input type="checkbox" name="Show Dutch Songs" [model]="showDutch"
               (changed)="showDutch = $event; refresh()"></app-input>
    <app-input type="checkbox" name="Show Blacklisted Songs" [model]="showBlacklisted"
               (changed)="showBlacklisted = $event; refresh()"></app-input>
    <app-input type="checkbox" name="Show Liked Songs" [model]="showWished"
               (changed)="showWished = $event; refresh()"></app-input>
    <app-input type="checkbox" name="Show Already Owned Songs" [model]="showBought"
               (changed)="showBought = $event; refresh()"></app-input>
    <app-input type="checkbox" name="Show Other Songs" [model]="showOther"
               (changed)="showOther = $event; refresh()"></app-input>
  </div>



</form>

<table class="table table-striped" style="display:table; width: 100%;">
  <thead style="display:block; width: 100%">
    <tr>
      <th scope="col">#</th>
      <th scope="col" sortable="name" style="width: 250px" (sort)="onSort($event)">Name</th>
      <th scope="col" sortable="artist" style="width: 250px" (sort)="onSort($event)">Artist</th>
      <th style="width: 80px;" scope="col" sortable="count" (sort)="onSort($event)">Count</th>
      <th style="width: 80px;" scope="col">Link</th>
      <th style="width: 80px;" scope="col">Remove</th>
      <th style="width: 80px;" scope="col" sortable="wished" (sort)="onSort($event)">Liked</th>
      <th style="width: 80px;" scope="col" sortable="bought" (sort)="onSort($event)">Owned</th>
      <th style="width: 80px;" scope="col" sortable="dutch" (sort)="onSort($event)">Dutch</th>


    </tr>
  </thead>
  <tbody style="height: 500px; overflow: auto; display:block; width: 100%">
    <tr *ngFor="let country of results | async; index as i">
      <th scope="row">{{ i + 1 }}</th>
      <td style="width: 250px">
        <ngb-highlight [result]="country.name" [term]="filter.value"></ngb-highlight>
      </td>
      <td style="width: 250px">
        <ngb-highlight [result]="country.artist" [term]="filter.value"></ngb-highlight>
      </td>

      <td style="width: 80px;">
        {{ country.count }}
      </td>

      <td style="width: 80px;">
        <a [href]="country.url" target="_blank">Link</a>
      </td>
      <th style="width: 80px;"><button class="icon-button" [style.border-color]="country.blacklisted ? 'gold' : 'black'"
                (click)="removeSongRequest(country)"><i class="fas fa-thumbs-down" style="color: red;"></i></button>
      </th>
      <th style="width: 80px;"><button [style.border-color]="country.wished ? 'gold' : 'black'" class="icon-button"
                (click)="markWished(country)"><i class="fas fa-thumbs-up" style="color: green;"></i></button></th>
      <th style="width:80px;"><button [style.border-color]="country.bought ? 'gold' : 'black'" class="icon-button"
                (click)="addToCatalogue(country)"><i class="fas fa-plus" style="color: blue;"></i></button></th>
      <th style="width: 80px;"><button [style.border-color]="country.dutch ? 'gold' : 'black'" class="icon-button"
                (click)="markDutch(country)"><i class="fas fa-flag" style="color: orange;"></i></button></th>
    </tr>
  </tbody>
</table>

<div class="d-flex justify-content-between p-2" style="max-width: 90%; overflow: hidden;">
  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refresh()"
                  [boundaryLinks]="true" [maxSize]="5">
  </ngb-pagination>

  <select class="form-select" style="width: auto; height: 2em;" [(ngModel)]="pageSize" (ngModelChange)="refresh()">
    <option [ngValue]="10">10 items per page</option>
    <option [ngValue]="20">20 items per page</option>
    <option [ngValue]="40">40 items per page</option>
  </select>
</div>


<div *ngIf="showCleanup"
     style="color: white; position: absolute; z-index: 5; width: 80%; height: 80%; left: 10%; padding: 10px; border-radius: 10px; background-color: #535353; border: 2px solid black; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; overflow: auto;">
  <div class="button-bar">
    <button class="red" (click)="showCleanup = false">Cancel</button>
    <button class="green" (click)="saveCleanup()">Save</button>
  </div>
  <h2>To Remove</h2>
  <div class="list" style="max-height: 400px; overflow: auto; width: 90%;">
    <div class="list-item" *ngFor="let remove of cleanups.toRemove ">
      <app-input type="checkbox" [name]="remove.reason + ' -> ' + remove.request.name + ' by ' + remove.request.artist"
                 [model]="remove.request.blacklisted" (changed)="remove.request.blacklisted = $event"></app-input>
    </div>

  </div>


  <h2>Marked Dutch</h2>
  <div class="list" style="max-height: 400px; overflow: auto; width: 90%;">
    <div class="list-item" *ngFor="let remove of cleanups.markedDutch ">
      <app-input type="checkbox" [name]=" remove.name + ' by ' + remove.artist" [model]="remove.dutch"
                 (changed)="remove.dutch = $event"></app-input>
    </div>

  </div>


  <h2>Maybe Owned Already</h2>
  <div class="list" style="max-height: 400px; overflow: auto; width: 90%;">
    <div class="list-item" *ngFor="let remove of cleanups.possibleOwned ">
      <app-input type="checkbox"
                 [name]=" remove.request.name + ' by ' + remove.request.artist + '  clashes with already owned ' + remove.song.name + ' by ' + remove.song.artist "
                 [model]="remove.request.bought" (changed)="remove.request.bought = $event"></app-input>
    </div>

  </div>

</div>
