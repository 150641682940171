import { Component, Input, OnInit } from "@angular/core";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { AlertsService } from "../alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-edit-stock",
  templateUrl: "./edit-stock.component.html",
  styleUrls: ["./edit-stock.component.css"],
})
export class EditStockComponent implements OnInit {
  @Input("drink") drink: any;
  @Input("barName") barName: string;
  @Input("tab") tab = 0;
  invoiceCategories = [
    "frisdrank",
    "bieren",
    "bieren alcoholvrij",
    "gedestilleerd",
    "wijnen",
    "schoonmaak",
    "restaurant artikelen",
    "glaswerk",
    "vel",
  ];
  tillCategories = [
    { id: "Hardlopers", name: "Hardlopers" },
    { id: "Frisdrank", name: "Frisdrank" },
    { id: "Bieren", name: "Bieren" },
    { id: "Gedestilleerd", name: "Gedestilleerd" },
    { id: "Wijnen", name: "Wijnen" },
    { id: "Shotjes", name: "Shotjes" },
    { id: "Diversen", name: "Diversen" },
    { id: "Carnaval", name: "Carnaval" },
  ];
  emballageTypes = ["", "Krat Fris", "Krat Bier", "Gas", "Wijnen"];
  categories = ["crates", "glasses", "wine", "kegs", "liqueur", "other"];
  variable: any = null;

  tillCategoryChanged($event) {
    console.log($event);
  }
  constructor(
    public db: StaffAppDatabase,
    public alert: AlertsService,
    public loading: LoadingService,
    public activeModal: NgbActiveModal,
    public auth: Auth
  ) {}

  ngOnInit(): void {}

  dismiss() {
    this.activeModal.dismiss();
  }

  delete(drink, cat) {
    if (
      !confirm(
        "Are you sure you want to delete " + drink.name + " from " + cat + "?"
      )
    ) {
      return;
    }
    this.db.deleteDrink(drink, cat, this.barName);
    this.dismiss();
  }

  async save(drink, cat, doNext = false) {
    console.log(drink, cat);
    let temp: any = {};
    if (cat) {
      temp.category = cat;
      if (isNaN(drink.offset)) {
        temp.offset = 0;
      } else {
        temp.offset = Number(drink.offset);
      }
      if (drink.saleAreaIds) {
        //untill variant originally

        temp.serverId = String(drink.id);

        try {
          temp.price = Number(drink.prices[0].amount);
        } catch (e) {
          temp.price = drink.price || 0;
        }
        //console.log(Number(drink.prices[0].amount),drink.prices[0].amount)
        //todo is 0 a number, on 0 vat should be allowed
        try {
          temp.vat =
            Number(drink.prices && drink.prices[0].vat) ||
            Number(drink.vat) ||
            0;
        } catch (e) {
          console.error(e);
          temp.vat = drink.vat || 0;
        }

        console.log("id:" + drink.id);
      } else {
        temp = drink;
        temp.price = Number(drink.price);
        temp.pricePerUnit = Number(drink.pricePerUnit) || 0;
        temp.vat = Number(drink.vat);
        temp.id = Number(drink.id);
      }

      temp.units = Number(drink.units) || 1;
      temp.defaultFridgeStock = Number(drink.defaultFridgeStock) | 0;
      temp.defaultVIPRoom = Number(drink.defaultVIPRoom) | 0;
      temp.crateSize = Number(drink.crateSize) || 1;
      temp.defaultQuantity = Number(drink.defaultQuantity);
      temp.pricePerUnit = Number(drink.pricePerUnit) || 0;
      temp.name = drink.name;
      temp.embellage = Number(drink.embellage) || 0;
      temp.singlePrice = Number(drink.singlePrice) || 0;
      temp.invoiceCategory = drink.invoiceCategory;
      temp.description = drink.description;
      temp.emballageType = drink.emballageType;
      temp.serverId = String(drink.serverId);

      temp.sellingRatio = Number(drink.sellingRatio) || 0;
      //console.log(temp);
      temp.tillQuantity = Number(drink.tillQuantity) || 0;
      if (!temp.price) {
        temp.price = 0;
      }
      console.log(temp);
      temp.saleAreaId = null;
      const res = await this.db
        .addDrink(temp, cat, this.barName)
        .then((a) => null)
        .catch((e) => e);
      if (res) {
        this.alert.nextEmit(AlertsService.warning("Failed to update", res));
        return;
      }

      this.alert.nextEmit(AlertsService.good("Updated " + temp.name));
      if (doNext) {
        this.activeModal.close({ command: "next", tab: this.tab });
        return;
      }
      this.dismiss();
    } else {
      alert("no category");
    }
  }

  async saveVariable(cat, variable) {
    console.log(cat, variable);
    var vari = variable.drink[variable.key];

    var res = await this.db
      .addDrinkVariable(
        this.barName,
        cat,
        variable.drink.serverId,
        variable.key,
        vari,
        variable.type
      )
      .then((a) => a)
      .catch((e) => e);
    if (res) {
      this.alert.nextEmit(
        AlertsService.warning(
          variable.drink.name + " failed to update",
          res.toString()
        )
      );
    } else {
      this.alert.nextEmit(
        AlertsService.good(
          variable.drink.name + " update",
          "Desc: " +
            variable.name +
            ", key: " +
            variable.key +
            ", set to " +
            (variable.type ? `(${variable.type}) ` : "") +
            vari
        )
      );
    }
  }

  editVariable(cat, variable) {
    if (variable.drink.serverId) {
      console.log(cat, variable);
      //this.drink.category = cat;
      this.variable = Object.assign({}, variable);
      this.drink = null;
    }
  }

  unitChanged(drink, $event) {
    if (isNaN($event) || $event < 0 || $event == null || $event == undefined) {
      this.alert.nextEmit(
        AlertsService.error(
          "Invalid number",
          "Please enter a valid number for units"
        )
      );
      return;
    }

    if (!drink.pricePerUnit || isNaN(drink.pricePerUnit)) {
      this.alert.nextEmit(
        AlertsService.error(
          "Invalid number",
          "Please enter a valid number for the Delivery Price"
        )
      );
      return;
    }

    drink.units = $event;
    drink.singlePrice = Math.round((drink.pricePerUnit / $event) * 100) / 100;
  }
  async saveOffset() {
    console.log(this.drink.offset);
    const res = await this.db
      .updateOffset(this.drink.serverId, this.drink.offset, this.barName)
      .then((a) => null)
      .catch((e) => e);
    if (!res) {
      this.alert.nextEmit(
        AlertsService.good(
          "Updated Offset for " + this.drink.name + " to " + this.drink.offset
        )
      );
    } else {
      this.alert.nextEmit(AlertsService.error("Failed to update offset", res));
    }
  }
}
