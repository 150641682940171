import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Auth } from "./auth";

import { MatSnackBar } from "@angular/material/snack-bar";
import { BarInfo } from "../Helpers/functions/BarInfo";
import { isNumber } from "lodash";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public auth: Auth,
    private router: Router,
    private snackbar: MatSnackBar,
    private barInfo: BarInfo
  ) {}

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let roleLevel = route.data.roleLevel as number;
    let count = 0;

    while (this.auth.accessUser.access === 0 && !this.auth.user) {
      await this.delay(500);
      count++;
      if (count >= 4) {
        return false;
      }
    }

    const currentUser = this.auth.accessUser;
    var access = 0;
    if (currentUser && currentUser.access > 0) {
      access = currentUser.access;
    } else {
      access = await this.auth.getAccess().then((a) => Number(a) || 0);
    }

    if (isNumber(access)) {
      if (access < 0) {
        this.router.navigate(["login"]);
        this.barInfo.nextError("You are not authenticated, please log in");
        return false;
      }
      if (access >= roleLevel) {
        return true;
      } else {
        if(route.data.accessEmail && route.data.accessEmail.includes(currentUser.email)){
          return true;
        }

        this.barInfo.nextError("You are not allowed here");
        //console.log('no access',currentUser.access + "/" + roleLevel)
        return false;
      }
    }

    //console.log(currentUser);
    this.router.navigate([""]);
    return false;
  }
}
