import { Component, OnInit } from '@angular/core';
import { Console } from 'console';
import { CalendarManager } from 'src/app/Classes/my-calendar/calendar-manager';

import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { MyDate } from 'src/app/Helpers/functions/MyDate';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-till',
  templateUrl: './till.component.html',
  styleUrls: ['./till.component.css']
})
export class TillComponent implements OnInit {
  barSub = null;
  sub = null;
  calSub = null;
  drinksSub = null;
  staffSub = null;
  expected: any = { internet: -1, pin: -1, cash: -1, staffDrinks: -1, other: -1 };
  date = new Date();
  barName;
  values = [];
  selectedDate = null;
  start = true;
  startValues: any = null; endValues: any = null;
  worked = null;

  drinks = [];
  constructor(private calManager: CalendarManager, private db: StaffAppDatabase, private bI: BarInfo, private progress: LoadingService, private alert: AlertsService) {
    this.calManager.destroy();
    this.sub = this.db.tillCountedObserver.subscribe((values: any[]) => {

      if (values) {
        //console.log(values)
        this.values = values;

        values.forEach(element => {
          let day = element.day;
          let event: any = {};
          let d = new Date();
          let splits = day.split("-");
          d.setMonth(splits[1]);
          d.setFullYear(splits[0]);
          d.setDate(splits[2]);
          event.allDay = false;
          event.start = d;
          event.end = d;

          event.meta = {
            incrementsBadgeTotal: false,
          };
          if (element.start) {
            event.title = "Start Counted";
            event.color = this.calManager.colors.blue;
            this.calManager.nextEvent(event);
          }


          let event2: any = {};
          event2.allDay = false;
          event2.start = d;
          event2.end = d;

          event2.meta = {
            incrementsBadgeTotal: false,
          };
          if (element.end) {
            event2.title = "End Counted";
            event2.color = this.calManager.colors.yellow;
            this.calManager.nextEvent(event2);
          }
        });
      }
      if (this.selectedDate) {
        this.dateSelected(this.selectedDate);
      }
    })

    this.barSub = this.bI.barObserver.subscribe(values => {
      if (values) {
        if (this.barSub) {
          this.barSub.unsubscribe();
        }

        this.barName = values;
        this.drinksSub = (this.db.getAllStock(this.barName).subscribe(then => {
          const allDrinks = then.map(a => {
            const data = a.payload.val();
            return Object.values(data);
          });

          this.drinks = ([].concat.apply([], allDrinks)).reduce((map, obj) => {
            map[obj.serverId] = { price: obj.price, name: obj.name };
            return map;
          }, {})



        }));
        this.db.getTill(this.date.getFullYear(), this.barName);
      }

    })




  }



  ngOnDestroy() {
    if (this.barSub) {
      this.barSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.calSub) {
      this.calSub.unsubscribe();
    }
    if (this.staffSub) {
      this.staffSub.unsubscribe();
    }
    if (this.drinksSub) {
      this.drinksSub.unsubscribe();
    }
  }

  dateSelected(date) {

    if (date) {
      this.selectedDate = date;
    } else {
      return;
    }
    this.startValues = null;
    this.endValues = null;
    this.expected = { internet: 0, pin: 0, cash: 0, staffDrinks: 0, other: 0 };
    this.values.forEach(val => {

      if (this.sameDay(val.day, this.selectedDate)) {



        if (val.start) {
          ////console.log("adding start", val);
          this.startValues = val.start;
        }


        if (val.end) {
          this.endValues = val.end;
        }



        if (val.internet) {
          this.expected.internet = Number(val.internet);

        }

        if (val.other) {
          this.expected.other = Number(val.other);

        }
        if (val.expectedCash) {
          this.expected.cash = Number(val.expectedCash);

        }
        if (val.expectedPin) {
          this.expected.pin = Number(val.expectedPin);

        }
        if (val.staffDrinks) {
          try {
            console.log(val.staffDrinks);
            const results = [].concat.apply([], val.staffDrinks.map(a => {

              const items = [].concat.apply([], a.map(b => {
                return [].concat.apply([], b.items.map(c => {
                  var str = [];

                    console.log({id:c.articleId, quantity:c.quantity, price: c.singlePrice, discount: c.discount});
                    str.push({id:c.articleId, quantity:c.quantity, price: c.singlePrice, discount: c.discount});

                  return str;
                }));
              }));


              return items.map(a => a.quantity * a.price);
            }));

            this.expected.staffDrinks = results.reduce((a, b) => a + b, 0);

          } catch (e) {

            this.expected.staffDrinks = 0;
          }


        }

      }
    })

  }

  ngOnInit(): void {

    this.calSub = this.calManager.daysObserver.subscribe((values: Date) => {
      this.worked = null;
      if (values) {
        this.getStaff(values);
        ////console.log("get count for date: " + values);
        this.selectedDate = values;
        if (values && this.date.getFullYear() != values.getFullYear() && this.barName) {
          this.db.getTill(values.getFullYear(), this.barName);
        }
        this.date = values;
        this.dateSelected(this.selectedDate)
      }

    })

    this.staffSub = this.db.staffWorkedObserver.subscribe(values => {
      if (values) {
        this.worked = values;
      } else {
        this.worked = null;
      }

    })
  }

  getDay(serverDate) {
    let splits = serverDate.split("-");
    return Number(splits[2])
  }

  sameDay(day, date) {
    const splits = day.split("-");

    if (date.getFullYear() === Number(splits[0]) && date.getMonth() === Number(splits[1]) && date.getDate() === Number(splits[2])) {
      return true;
    }
    return false;
  }

  getStaff(date: Date) {

    this.db.getHoursForDate(this.barName, date);
  }

  getTill() {
    this.progress.nextEmit('on')
    ////console.log(new Date(this.selectedDate).toISOString(),this.selectedDate,this.barName);
    this.db.getTillForDate(new Date(this.selectedDate).toLocaleString(), this.barName).then(a => {
      console.log(a);
      this.alert.nextEmit(AlertsService.good("Getting Till", "Success"));
      this.progress.nextEmit('null')
      this.db.getTill(this.date.getFullYear(), this.barName);
    }).catch(e => {
      console.error(e)
      this.alert.nextEmit(AlertsService.error("Error Getting Till", JSON.stringify(e, null, 2)));
      this.progress.nextEmit('null')
    });
  }



}
