import { Injectable } from '@angular/core';
import { Book } from '../Classes/book';
import { Page } from '../Classes/page';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BarInfo } from '../Helpers/functions/BarInfo';
import { finalize, map, take } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  books: Book[] = [];
  constructor(  private staffAppBase: AngularFireDatabase, public bi: BarInfo,private storage: AngularFireStorage,) { }

  async downloadBooks(){
    console.log('downloading books');
    return await this.staffAppBase.list('theendtv/bars/' + this.bi.bar + '/books').valueChanges().pipe(take(1), map(a => {
      //make sure the data is in the format of a book
      return a.map((b) => {
        var book = new Book('', '', []);
        book = Object.assign(book, b);
        book.pages = book.pages.map((p) => {
          var page = new Page('');
          page = Object.assign(page, p);
          return page;
        });
        return book;
      })

    })).toPromise().then((res) => {
      this.books = res as Book[];
      return this.books;
    })
  }

  async saveBook(book: Book){
    if(!book) {
      return;
    }

    if(!book.id) {
      book.id = Math.random().toString(36).substring(7);
    }

    //remove dataUrl from images
    book.pages.forEach((page) => {
      page.data.images.forEach((image) => {
        image.dataUrl = '';
      })
    })

    return await this.staffAppBase.object('theendtv/bars/' + this.bi.bar + '/books/' + book.id).set(book).then((res) => null).catch((err) => err);
  }

  deleteBook(){

  }

  nextChapter(number) {

  }

  async uploadImage(book: Book, page: number, image: number, file: File) {
    if (!file) return;

    const filePath = `theendtv/${book.id}/page-${page}/image-${image}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(url => {
          console.log('File available at', url);
        });
      })
    ).subscribe();
  }

  async downloadImage(path) {
    return await this.storage.ref(path).getDownloadURL().toPromise();
  }


}
