import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-lafmodal',
  templateUrl: './lafmodal.component.html',
  styleUrls: ['./lafmodal.component.css']
})
export class LafmodalComponent implements OnInit {
  @Input() item: any;
  mainPhoto$;
  controlPhoto$;
  constructor(public db: StaffAppDatabase, public modal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.item)
    this.mainPhoto$ = this.getPhotos(this.item,0);
      this.controlPhoto$ = this.getPhotos(this.item,1);
  }

  async getPhotos(task,index){

    const url = await this.db.getLostAndFoundPhotos(task.key,index);
    return url;
}
dismiss(){
  this.modal.dismiss();
}


}
