import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { clone } from 'lodash';
import { Observable } from 'rxjs';
import { refCount, take } from 'rxjs/operators';
import { Auth } from 'src/app/auth/auth';
import { Giftable } from 'src/app/Classes/giftable';
import { ComponentCanDeactivateGuard } from 'src/app/Guards/component-can-deactivate.guard';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';

import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { NamePipe } from 'src/app/Pipes/name.pipe';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

import { Survey } from './Survey';
import { SurveyQuestion } from './SurveyQuestion';
import { SurveyStatus } from './SurveyStatus';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit, ComponentCanDeactivateGuard {
  surveys: Survey[] = [];
  survey: Survey;
  questionIndex: number = 0;
  question: SurveyQuestion;
  edited = false;
  staff: any[] = [];
  staffNames: any[] = [];
  selectedBar = '';
  staffKeys = {};

  selectBar(bar) {
    if (this.selectedBar == bar) {
      this.selectedBar = '';
    } else {
      this.selectedBar = bar;
    }
  }

  constructor(private db: StaffAppDatabase, public bi: BarInfo, private loading: LoadingService, private alert: AlertsService, private namePipe: NamePipe, public auth: Auth) {
  }

  @HostListener('window:beforeunload')
  canDeactivate(component: ReviewsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    console.log('checking if component edited')
    if (!component.edited) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.loading.nextEmit('on');
    if (!this.staff || !this.staff.length) {
      this.staff = await this.db.getStaff().pipe(take(1)).toPromise().then(a => a as any[]).catch(e => []);
      this.staffKeys = this.staff.reduce((prev, curr) => {
        prev[curr.uid] = new NamePipe().transform(curr);
        return prev;
      }, {})
      this.barChanged();
    }
    this.surveys = await this.db.getSurveys().then(a => { return a.map(b => { return Object.assign(new Survey(), b) }) }).catch(e => { this.alert.nextEmit(AlertsService.warning('Get Surveys Failed', e)); return null })
    this.loading.nextEmit('');
  }

  async barChanged() {
    const bar = this.survey ? this.survey.bar : null;
    if (!bar) {
      this.staffNames = this.staff.map(a => { return { id: a.uid, name: this.namePipe.transform(a), selected: this.survey && this.survey.users.includes(a.uid) ? true : false } }).filter(a => a)
    } else {
      this.staffNames = this.staff.filter(a => a.bar == bar).map(a => {
        return {
          id: a.uid, name: this.namePipe.transform(a), selected: this.survey && this.survey.users.filter(b => b.id == a.uid && b.selected).length ? true : false
        }
      }).filter(a => a)
    }
  }

  newSurvey() {
    if (!this.check()) return;
    this.question = null;
    const survey = new Survey();
    this.surveys = [survey].concat(this.surveys);
    this.edited = true;
    this.survey = survey;

    if (this.auth.accessUser.access <= 3) {
      this.survey.bar = this.bi.bar;
    }
    this.barChanged();

    console.log(this.surveys)
  }



  setUsers($event: any[]) {
    console.log($event)
    this.survey.users = $event.filter(a => a.selected)
  }
  cancel() {

    this.survey = null;
    this.getData();
  }

  check(): boolean {
    if (this.survey && this.edited) {
      this.alert.nextEmit(AlertsService.warning("Save Or Cancel", "There are active changes that haven't been saved"))
      return false;
    }
    return true;
  }

  async surveySelected(sur) {
    if (!this.check()) return;
    this.question = null;
    this.edited = false;
    this.questionIndex = 0;

    sur.answers = {};

    const answers = await this.db.getSurveyAnswers(sur.key).then(a => a as any[]).catch(e => null);
    if (answers) {
      Object.entries(answers).forEach((user: [string, any]) => {
        console.log(user[1])
        if (user[1].value.done) {
          Object.entries(user[1].value.answers).forEach(([userAnswer, ans]: [string, any]) => {
            if (!sur.answers[userAnswer]) {
              sur.answers[userAnswer] = [];
            }

            sur.answers[userAnswer].push({ bar: user[1].value.bar, val: ans, user: user[1].key });
          })
        }

      })
    }
    this.questionIndex = 0;
    this.survey = sur;
    if (this.survey.questions.length) {
      this.question = this.survey.questions[0]
    }
    this.barChanged();
    console.log(answers, this.survey)
  }

  addQuestion() {
    this.edited = true;
    this.question = new SurveyQuestion();
    this.survey.questions.push(this.question)
    this.questionIndex = this.survey.questions.length - 1;
  }

  nextQuestion(numb: number) {
    this.questionIndex += numb;
    this.questionIndex = Math.min(this.survey.questions.length - 1 || 0, Math.max(0, this.questionIndex));
    this.question = this.survey.questions[this.questionIndex];


  }

  setType(type: string, defaultValue: any) {
    console.log(this.question, type, defaultValue);
    this.question.type = type;
    this.question.answer = defaultValue;
    this.edited = true;
  }

  deleteQuestion() {
    this.survey.questions.splice(this.questionIndex, 1);
    this.nextQuestion(-1)
  }

  async deleteSurvey() {
    console.log('deleting survey', this.survey);
    await this.db.deleteSurvey(this.survey).then().catch(e => e);
    this.survey = null;
    this.getData();
  }

  async saveSurvey() {
    console.log('saving survey', this.survey);
    await this.db.saveSurvey(this.survey)
    this.edited = false;
  }

  async issue() {
    this.survey.status = SurveyStatus.statuses[1];
    await this.db.saveSurvey(this.survey);
    this.remind();
  }

  remind() {
    this.db.notifySurvey(this.survey).then(a => {
      console.log(a);
    }).catch(e => {
      console.log(e);
    })
  }

  log(ev) {
    console.log(ev)
    console.log(this.survey)
  }
  async complete() {
    this.loading.nextEmit('on');
    this.survey.status = SurveyStatus.statuses[2];


    if (this.survey.reward > 0) {
      var users = {};
      for (var survey of Object.values(this.survey.answers)) {
        console.log(survey);
        for (var user of (survey as any[])) {
          users[user.user] = 1;
        }

      }
      console.log(users)
      for (var i of Object.keys(users)) {
        const gift = new Giftable();
        gift.from = 'Survey';
        gift.to = i;
        gift.reason = 'Completed survey: ' + this.survey.title;
        gift.type = 'Credit';
        gift.total = Number(this.survey.reward);
        console.log(gift)
        await this.db.rewardStaff(gift)
      }

    }
    await this.db.saveSurvey(this.survey);
    this.loading.nextEmit(null);
    this.survey = null;
  }

  async copy() {
    const survey = new Survey();
    survey.title = this.survey.title + " (copy)";
    survey.questions = clone(this.survey.questions);
    await this.db.saveSurvey(survey);
    await this.getData();
  }

  moved(direction: number, question: SurveyQuestion, index: number) {
    console.log(question, "moved: " + direction)
    this.swap(this.survey.questions, index, direction == 0 ? index - 1 : index + 1);
  }

  swap(arr, a: number, b: number) {
    if (a < 0 || a >= arr.length || b < 0 || b >= arr.length) {
      return
    }

    const temp = arr[a];
    arr[a] = arr[b];
    arr[b] = temp;
  }

}






