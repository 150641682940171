import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-multiselect",
  templateUrl: "./multiselect.component.html",
  styleUrls: ["./multiselect.component.css"],
})
export class MultiselectComponent implements OnInit {
  @Input("list") list: MultiSelectOption[] = [];
  @Output("changed") changed = new EventEmitter();
  @Input("listHideAll") listHideAll = false;
  @Input("hint") hint = "Select None For All to be Reviewed";
  dropdown: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {}

  selectedLength() {
    return this.list.filter((a) => a.selected).length;
  }

  change() {
    this.changed.emit(this.list);
  }
}

export class MultiSelectOption {
  id: string;
  name: string;
  selected: boolean = false;
}
