import { Component, OnInit, ViewChild } from "@angular/core";
import { ChartConfiguration, ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";

import { Firebase } from "src/app/Services/firebase";

@Component({
  selector: "app-song-credit-simulation",
  templateUrl: "./song-credit-simulation.component.html",
  styleUrls: ["./song-credit-simulation.component.css"],
})
export class SongCreditSimulationComponent implements OnInit {
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  bars = ["Groningen", "Amsterdam", "Tilburg"];
  startDate: string = "2023-09-02";
  bar = "Amsterdam";
  useOpenTime = false;
  normalize = true;
  simulate = false;
  speed: number = 0.4;
  defaultPrice: number = 7;
  minimumPrice: number = 3.2;

  equationTypes = [
    "Unweighted Averages",
    "Weighted Averages (Prefer Longer Periods)",
    "Weighted Averages (Prefer Shorter Periods)",
    "Custom Weights",
    "4 songs every 15 minutes exactly",
    "Random Up Or Down",
    "Bell Curve",
  ];
  equationType = this.equationTypes[1];
  customWeights = {
    last5Minutes: 1,
    middle5Minutes: 1,
    first5Minutes: 1,
    lastHour: 5,
    last15Minutes: 3,
  };

  bellCurveOpts: any = {
    height: 15,
    peak: 23,
    stdev: 200,
  };

  maxUp = 1.5;
  maxDown = 1;

  readonly lables = [
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
    "00:00",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    "02:00",
    "02:15",
    "02:30",
    "02:45",
    "03:00",
    "03:15",
    "03:30",
    "03:45",
    "04:00",
  ];
  public lineChartData: ChartConfiguration<"line">["data"] = {
    labels: this.lables,

    datasets: [
      {
        data: [],
        label: "Simulation Price",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(250,250,250,0.8)",
      },
      {
        data: [],
        label: "euros Drinks Sold (last 15 minutes) ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(255,0,0,0.5)",
      },

      {
        data: [],
        label: "euros Drinks Sold (last hour)",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(0,255,0,0.5)",
      },
      {
        data: [],
        label: "euros Drinks Sold (last 5 minutes) ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(0,0,255,0.3)",
      },
      {
        data: [],
        label: "euros Drinks Sold (middle 5 minutes) ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(0,0,120,0.3)",
      },

      {
        data: [],
        label: "euros Drinks Sold (start 5 minutes) ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(0,120,0,0.3)",
      },
      {
        data: [],
        label: "Current Price ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(53,53,53,0.2)",
      },
    ],
  };

  public lineChartOptions: ChartOptions<"line"> = {
    responsive: false,
  };
  public lineChartLegend = true;
  constructor(public cdb: Firebase) {}

  ngOnInit(): void {}

  ngAfterViewInit() {}

  dateChanged(event) {
    this.startDate = event;
    this.getCurrentPrice();
  }

  async getCurrentPrice() {
    this.defaultPrice = await this.cdb
      .getCreditCost(this.bar, new Date(this.startDate).getDay())
      .then((a) => {
        return Number(a);
      });
  }

  async getJsonData(bar, startDate: Date): Promise<any> {
    // Use a relative path to the JSON file in your assets folder
    console.log(
      "Getting JSON Data for: " + bar + " for " + startDate.toUTCString()
    );
    return new Promise((res, rej) => {
      try {
        fetch(
          "./assets/records/" +
            bar.toLowerCase() +
            "/" +
            startDate.getFullYear() +
            "/" +
            startDate.getMonth() +
            "/" +
            startDate.getDate() +
            "/turnover.json"
        ).then((d) => {
          if (d.status == 200) {
            return res(d.json());
          } else {
            return res(null);
          }
        });
      } catch (e) {
        console.error(e);
        rej(e);
      }
    });
  }

  async prepareSimulation() {
    const data = await this.getJsonData(this.bar, new Date(this.startDate));

    if (!data) {
      alert("cant emulate days dont have data for");
      return;
    }
    await this.getCurrentPrice();

    this.prepareGraph(Object.values(data));
  }

  async prepareGraph(
    data: {
      balance: number;
      closeTime: number;
      items: { name: number; price: number; quantity: number; total: number }[];
      openTime: number;
    }[]
  ) {
    console.log(
      "using equation type: " +
        this.equationType +
        " with weights: " +
        JSON.stringify(this.customWeights, null, 2)
    );
    console.log(data);
    this.lineChartData.datasets[0].data = [];
    this.lineChartData.datasets[1].data = [];
    this.lineChartData.datasets[2].data = [];
    this.lineChartData.datasets[3].data = [];
    this.lineChartData.datasets[4].data = [];
    this.lineChartData.datasets[5].data = [];

    var startPrice = this.minimumPrice;
    var currentPrice = startPrice;
    for (var time of this.lables) {
      var index = this.lables.indexOf(time);
      var split = time.split(":");
      if (split.length == 1) {
        time += ":00";
      }
      const endTime = new Date(this.startDate);
      endTime.setHours(parseInt(time.split(":")[0]));
      endTime.setMinutes(parseInt(time.split(":")[1]) || 0);
      endTime.setSeconds(0);

      if (Number(time.split(":")[0]) <= 12) {
        endTime.setDate(endTime.getDate() + 1);
      }

      //need last hour, last 15 minutes and the 3 5 minute segments
      const lastHourStart = new Date(endTime);
      lastHourStart.setHours(lastHourStart.getHours() - 1);

      const last15MinutesStart = new Date(endTime);
      last15MinutesStart.setMinutes(last15MinutesStart.getMinutes() - 15);

      const last5MinutesStart = new Date(endTime);
      last5MinutesStart.setMinutes(last5MinutesStart.getMinutes() - 5);

      const middle5MinutesStart = new Date(endTime);
      middle5MinutesStart.setMinutes(middle5MinutesStart.getMinutes() - 10);

      const first5MinutesStart = new Date(endTime);
      first5MinutesStart.setMinutes(first5MinutesStart.getMinutes() - 15);

      const lastHourData = data.filter((d) => {
        const date = new Date(this.useOpenTime ? d.openTime : d.closeTime);
        return date >= lastHourStart && date <= endTime;
      });

      const last15MinutesData = data.filter((d) => {
        const date = new Date(this.useOpenTime ? d.openTime : d.closeTime);
        return date >= last15MinutesStart && date <= endTime;
      });

      const last5MinutesData = data.filter((d) => {
        const date = new Date(this.useOpenTime ? d.openTime : d.closeTime);
        return date >= last5MinutesStart && date <= endTime;
      });

      const middle5MinutesData = data.filter((d) => {
        const date = new Date(this.useOpenTime ? d.openTime : d.closeTime);
        return date >= middle5MinutesStart && date <= last5MinutesStart;
      });

      const first5MinutesData = data.filter((d) => {
        const date = new Date(this.useOpenTime ? d.openTime : d.closeTime);
        return date >= first5MinutesStart && date <= middle5MinutesStart;
      });

      var lastHourTotal = lastHourData.reduce((a, b) => {
        return a + b.balance;
      }, 0);

      var last15MinutesTotal = last15MinutesData.reduce((a, b) => {
        return a + b.balance;
      }, 0);

      var last5MinutesTotal = last5MinutesData.reduce((a, b) => {
        return a + b.balance;
      }, 0);

      var middle5MinutesTotal = middle5MinutesData.reduce((a, b) => {
        return a + b.balance;
      }, 0);

      var first5MinutesTotal = first5MinutesData.reduce((a, b) => {
        return a + b.balance;
      }, 0);

      if (this.normalize) {
        lastHourTotal = lastHourTotal / 60;
        last15MinutesTotal = last15MinutesTotal / 15;
        last5MinutesTotal = last5MinutesTotal / 5;
        middle5MinutesTotal = middle5MinutesTotal / 5;
        first5MinutesTotal = first5MinutesTotal / 5;
      }

      this.lineChartData.datasets[1].data.push(last15MinutesTotal);
      this.lineChartData.datasets[2].data.push(lastHourTotal);
      this.lineChartData.datasets[3].data.push(last5MinutesTotal);
      this.lineChartData.datasets[4].data.push(middle5MinutesTotal);
      this.lineChartData.datasets[5].data.push(first5MinutesTotal);
      this.lineChartData.datasets[6].data.push(this.defaultPrice);

      var nextAverage = 0;
      if (this.equationType == this.equationTypes[0]) {
        nextAverage =
          (last5MinutesTotal +
            middle5MinutesTotal +
            first5MinutesTotal +
            lastHourTotal +
            last15MinutesTotal) /
          5;
      } else if (this.equationType == this.equationTypes[1]) {
        nextAverage =
          (last5MinutesTotal * 2 +
            middle5MinutesTotal +
            first5MinutesTotal +
            lastHourTotal * 5 +
            last15MinutesTotal * 3) /
          14;
      } else if (this.equationType == this.equationTypes[2]) {
        nextAverage =
          (last5MinutesTotal * 5 +
            middle5MinutesTotal * 3 +
            first5MinutesTotal * 2 +
            lastHourTotal +
            last15MinutesTotal) /
          12;
      } else if (this.equationType == this.equationTypes[3]) {
        console.log(
          Number(this.customWeights.last5Minutes),
          Number(this.customWeights.middle5Minutes),
          Number(this.customWeights.first5Minutes),
          Number(this.customWeights.lastHour),
          Number(this.customWeights.last15Minutes)
        );
        nextAverage =
          (last5MinutesTotal * Number(this.customWeights.last5Minutes) +
            middle5MinutesTotal * Number(this.customWeights.middle5Minutes) +
            first5MinutesTotal * Number(this.customWeights.first5Minutes) +
            lastHourTotal * Number(this.customWeights.lastHour) +
            last15MinutesTotal * Number(this.customWeights.last15Minutes)) /
          (Number(this.customWeights.last5Minutes) +
            Number(this.customWeights.middle5Minutes) +
            Number(this.customWeights.first5Minutes) +
            Number(this.customWeights.lastHour) +
            Number(this.customWeights.last15Minutes));
        console.log(nextAverage, last5MinutesTotal);
      } else if (this.equationType == this.equationTypes[4]) {
        nextAverage = (last15MinutesTotal * (this.normalize ? 15 : 1)) / 4;
        console.log(nextAverage);
      } else if (this.equationType == this.equationTypes[5]) {
        //random between Number(maxUp) and negative Number(maxDown)
        nextAverage =
          currentPrice +
          this.randomNumberBetween(Number(this.maxDown), Number(this.maxUp));
        console.log(nextAverage);
      } else if (this.equationType == this.equationTypes[6]) {
        console.log(15, this.lables.indexOf("23:15"), 2, index);
        nextAverage = this.bellCurve(
          Number(this.bellCurveOpts.height),
          Number(this.bellCurveOpts.peak),
          Number(this.bellCurveOpts.stdev),
          index
        );
      }

      nextAverage = Math.max(this.minimumPrice, nextAverage);
      var difference = nextAverage - currentPrice;
      if (difference > this.maxUp) {
        //dont want jumps to be bigger than 2 euro
        console.log(
          "wanting to jump up at " +
            time +
            " from : " +
            currentPrice +
            " to: " +
            nextAverage +
            " greater than max " +
            this.maxUp
        );
        nextAverage = Number(currentPrice) + Number(this.maxUp);
        console.log("resulting in: " + nextAverage);
      } else if (difference < -this.maxDown) {
        //dont want jumps to be bigger than 2 euro
        console.log(
          "wanting to jump down at " +
            time +
            " from : " +
            currentPrice +
            " to: " +
            nextAverage
        );
        nextAverage = Number(currentPrice) - Number(this.maxDown);
      }

      currentPrice = Math.max(this.minimumPrice, nextAverage);

      this.lineChartData.datasets[0].data.push(currentPrice);
      this.chart.update();

      if (this.simulate) {
        await this.simpleWait(this.speed * 1000);
      }
    }

    console.log(this.lineChartData);
  }

  async simpleWait(ms: number) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(ms);
      }, ms);
    });
  }

  randomNumberBetween(min: number, max: number) {
    //should be negative or positive
    var isPositive = Math.random() > 0.5;
    if (isPositive) {
      return Math.random() * max;
    } else {
      return -Math.random() * min;
    }
  }

  bellCurve(height: number, peak: number, stdev: number, x: number) {
    console.log(height, peak, stdev, x);
    var y = height * Math.exp(-((x - peak) ** 2) / stdev);
    return y;
  }
}
