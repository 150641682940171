import { Pipe, PipeTransform } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {

  transform(value: any, ...args: string[]): unknown {

    if(value && ((value.firstName && value.lastName))) {
      var firstName = '';
      try {
        firstName = value.firstName.split(' ')[0];
      } catch(e) {
        firstName = '';
      }

      firstName = this.capitalizeFirst(firstName);
      var lastName = this.capitalizeFirst(value.lastName);

      if(args[0]) {
        switch(args[0]){
          case 'full': return firstName + " " + lastName;
          case 'short': return firstName.substring(0,10) + " " + lastName[0] + ".";
        }
      }




         return firstName.substring(0,8) + (firstName.length > 8 ? ". " : ' ') + lastName

    }

    if(value && value.displayName) {
      return value.displayName
    }

    if(value && value.email) {
      return value.email.split('@')[0];
    }
    return null;
  }

  capitalizeFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}
