<div class="pricing-header">
    <div class="pricing-header-left">
        <div class="pricing-button-row">
            <button (click)="showOld = !showOld">Toggle Old View</button>
            <button (click)="editModel()">Edit Model</button>

        </div>
        <div class="pricing-button-row">
            <app-input type="checkbox" name="Get Live Updates" [model]="getLive" (changed)="getLiveChanged($event)"></app-input>
            <button (click)="simulate()">Simulate</button>
            <app-input name="Date" type="date" [model]="date | date:'yyyy-MM-dd'" (changed)="changeDate($event)"></app-input>
        </div>
    </div>
    <div class="pricing-header-right">
        <div class="model-view">
            <div class="model-label">Last 5</div>
            <div class="model-value">{{model.model.customWeights.last5Minutes}}</div>
        </div>

        <div class="model-view">
            <div class="model-label">Middle 5</div>
            <div class="model-value">{{model.model.customWeights.middle5Minutes}}</div>
        </div>

        <div class="model-view">
            <div class="model-label">First 5</div>
            <div class="model-value">{{model.model.customWeights.first5Minutes}}</div>
        </div>

        <div class="model-view">
            <div class="model-label">Last 15</div>
            <div class="model-value">{{model.model.customWeights.last15Minutes}}</div>
        </div>

        <div class="model-view">
            <div class="model-label">Last Hour</div>
            <div class="model-value">{{model.model.customWeights.lastHour}}</div>
        </div>

        <div class="model-view">
            <div class="model-label">Normalise</div>
            <div class="model-value">{{model.model.normalise}}</div>

        </div>

        <div class="model-view">
            <div class="model-label">Minimum Price</div>
            <div class="model-value">{{model.model.minimumPrice}}</div>
        </div>

        <div class="model-view">
            <div class="model-label">Max Up</div>
            <div class="model-value">{{model.model.maxUp}}</div>
        </div>

        <div class="model-view">
            <div class="model-label">Max Down</div>
            <div class="model-value">{{model.model.maxDown}}</div>
        </div>
    </div>
</div>


<div *ngIf="showOld">
    <div style="width: 100%" class="flex column center">
        <h2>Song Prices</h2>
        <div>
            <div class="row">
                <div class="column">
                    <div class="table-item weekday header2">Day</div>
                    <div *ngFor="let weekday of weekdays" class="table-item weekday">
                        {{ weekday.index | weekday : 3 }}
                    </div>
                </div>

                <div class="column">
                    <div class="table-item header2">Euro Cost</div>
                    <input *ngFor="let weekday of weekdays" class="table-item" type="number" [(ngModel)]="weekday.euros" (ngModelChange)="edited = true" step="0.25" />
                </div>

                <div class="column">
                    <div class="table-item cost header2">Credit Cost</div>
                    <div *ngFor="let weekday of weekdays" class="table-item cost">
                        {{ creditF(weekday.euros) }}
                    </div>
                </div>
            </div>
            <div>
                <button [disabled]="!edited" (click)="edited = false; getCost(bar)">
          Cancel
        </button>
                <button [disabled]="!edited" (click)="edited = false; saveCosts(bar)">
          Save
        </button>
            </div>
        </div>
    </div>

    <div style="width: 100%; margin-top: 20px" class="flex column center">
        <h2>Graph</h2>
        <ngx-charts-line-chart [view]="view" [scheme]="colorScheme" [legend]="legend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline"
            [results]="multi">
        </ngx-charts-line-chart>
    </div>
</div>


<div *ngIf="!showOld" style="width: 100%; display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start; flex-wrap: wrap;">

    <div style="width: 200px;">


    </div>




</div>
<div style="display: block; position: relative;" [style.display]='showOld ? "none" : "block"'>
    <div class="chart-info">
        <h3>{{isSimulation ? 'Simulation' : 'Real Data'}}</h3>
        <div>Model</div>
        <ng-container *ngIf="isSimulation">
            <div class="info-label-value">
                <div>Last 5 Min:</div>
                <div> {{simulateModel.model.customWeights.last5Minutes}}</div>
            </div>
            <div class="info-label-value">
                <div>Middle 5 Min:</div>
                <div> {{simulateModel.model.customWeights.middle5Minutes}}</div>
            </div>
            <div class="info-label-value">
                <div>First 5 Min:</div>
                <div> {{simulateModel.model.customWeights.first5Minutes}}</div>
            </div>
            <div class="info-label-value">
                <div>Last 15 Min:</div>
                <div> {{simulateModel.model.customWeights.last15Minutes}}</div>
            </div>
            <div class="info-label-value">
                <div>Last Hour:</div>
                <div> {{simulateModel.model.customWeights.lastHour}}</div>
            </div>
            <div class="info-label-value">
                <div>Normalise:</div>
                <div> {{simulateModel.model.normalise}}</div>
            </div>
            <div class="info-label-value">
                <div>Min Price:</div>
                <div> {{simulateModel.model.minimumPrice | currency:'EUR'}}</div>
            </div>
            <div class="info-label-value">
                <div>Max Up:</div>
                <div> {{simulateModel.model.maxUp | currency:'EUR'}}</div>
            </div>
            <div class="info-label-value">
                <div>Max Down:</div>
                <div> {{simulateModel.model.maxDown | currency:'EUR'}}</div>
            </div>

        </ng-container>

        <ng-container *ngIf="!isSimulation">
            <div class="info-label-value">
                <div>Last 5 Min:</div>
                <div> {{model.model.customWeights.last5Minutes}}</div>
            </div>
            <div class="info-label-value">
                <div>Middle 5 Min:</div>
                <div> {{model.model.customWeights.middle5Minutes}}</div>
            </div>
            <div class="info-label-value">
                <div>First 5 Min:</div>
                <div> {{model.model.customWeights.first5Minutes}}</div>
            </div>
            <div class="info-label-value">
                <div>Last 15 Min:</div>
                <div> {{model.model.customWeights.last15Minutes}}</div>
            </div>
            <div class="info-label-value">
                <div>Last Hour:</div>
                <div> {{model.model.customWeights.lastHour}}</div>
            </div>
            <div class="info-label-value">
                <div>Normalise:</div>
                <div> {{model.model.normalise}}</div>
            </div>
            <div class="info-label-value">
                <div>Min Price:</div>
                <div> {{model.model.minimumPrice | currency:'EUR'}}</div>
            </div>
            <div class="info-label-value">
                <div>Max Up:</div>
                <div> {{model.model.maxUp | currency:'EUR'}}</div>
            </div>
            <div class="info-label-value">
                <div>Max Down:</div>
                <div> {{model.model.maxDown | currency:'EUR'}}</div>
            </div>
        </ng-container>



    </div>
    <canvas #baseChart baseChart width="1000" height="700" [type]="'line'" [data]="lineChartData" [options]="lineChartOptions" [legend]="lineChartLegend">
</canvas>
</div>
