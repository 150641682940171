<div class="flex-columns" style="width: 320px">
  <button (click)="newSurvey()" class="datchev" style="width: 100%; margin-bottom: 5px">
    New Survey
  </button>
  <div *ngFor="let sur of surveys" (click)="surveySelected(sur)" class="review-box" [ngClass]="{
      red: sur.status.index == 0,
      orange: sur.status.index == 1,
      green: sur.status.index == 2
    }">
    <text style="font-weight: bold">{{ sur.title }}</text>
    <div class="row" style="justify-content: space-between; width: 100%">
      <div class="column">
        <div class="label">Created</div>
        <div>{{ sur.date | date : "EEE dd LLL yyyy" }}</div>
      </div>

      <div class="column">
        <div class="label">Status</div>
        <div>{{ sur.status.status }}</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="survey" style="width: 400px" class="flex-columns">
  <app-input type="text" [editable]="survey.status.canEdit" name="Title"
             (changed)="survey.title = $event; edited = true" [model]="survey.title"></app-input>

  <app-input type="number" [editable]="survey.status.canEdit && auth.accessUser.access > 3"
             name="Reward  (Wallet Credits €)" (changed)="survey.reward = $event; edited = true" [model]="survey.reward"
             placeholder="€0,€3,€420">
  </app-input>

  <app-input type="checkbox" [editable]="survey.status.canEdit" name="Anonomous Answers"
             (changed)="survey.anonomous = $event; edited = true" [model]="survey.anonomous">
  </app-input>
  <app-input type="multiline" [editable]="survey.status.canEdit" [name]="'Description'"
             (changed)="survey.description = $event; edited = true" [model]="survey.description"
             placeholder="Description Of Survey"></app-input>

  <app-input type="select" [editable]="survey.status.canEdit && auth.accessUser.access > 3" name="Select Bar:"
             (changed)="
      survey.bar = $event; edited = true; log($event); barChanged($event)
    " [model]="survey.bar" [list]="bi.bars">
  </app-input>

  <app-input type="multiselect" [editable]="survey.status.canEdit && auth.accessUser.access > 3" name="Select User(s)"
             (changed)="setUsers($event); edited = true; log($event)" [model]="survey.users"
             [list]="staffNames"></app-input>

  <div class="button-row">
    <app-input [editable]="false" name="Status" [model]="survey.status.status"></app-input>
    <app-input [editable]="false" name="Date" [model]="survey.date | date : 'EEE dd LLL yyyy'"></app-input>
    <app-input [editable]="false" name="Questions" [model]="survey.questions.length"></app-input>
  </div>

  <div class="button-row">
    <button *ngIf="edited" (click)="cancel()" class="option-button orange">
      Cancel
    </button>
    <button *ngIf="survey.status.index == 0" (click)="saveSurvey()" class="option-button green">
      Save
    </button>

    <button class="option-button red" (click)="deleteSurvey()">Delete</button>
    <button class="option-button green" *ngIf="!edited" (click)="copy()">
      Copy
    </button>
    <button class="option-button" *ngIf="!edited && survey.status.index == 0" (click)="issue()">
      Issue
    </button>
    <button class="option-button" (click)="remind()" *ngIf="!edited && survey.status.index == 1" (clcik)="remind()">
      Remind
    </button>
    <button class="option-button" *ngIf="!edited && survey.status.index == 1" (click)="complete()">
      Completed
    </button>
  </div>

  <div class="divider"></div>
  <div class="button-row" style="
      overflow: auto;
      align-items: flex-start !important;
      justify-content: flex-start;
      height: 4rem;
    " *ngIf="!survey.status.canEdit">
    <button *ngFor="let bar of bi.bars" class="option-button" style="height: 2.5em; width: 150px"
            (click)="selectBar(bar)" [ngClass]="{ green: selectedBar == bar }">
      {{ bar }}
    </button>
  </div>
  <div class="button-row" style="overflow: auto" *ngIf="survey.status.canEdit">
    <button class="option-button green" (click)="addQuestion()">
      Add Question
    </button>
    <button class="option-button red" (click)="deleteQuestion()">
      Delete Question
    </button>
  </div>
  <div class="divider"></div>
  <div *ngIf="question" style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    ">
    <app-input type="text" [editable]="survey.status.canEdit" [name]="'Question ' + (questionIndex + 1)"
               titleClass="editDesc-Larger" (changed)="question.title = $event; edited = true" [model]="question.title"
               placeholder="Why is the sky blue?"></app-input>

    <app-input type="multiline" [editable]="survey.status.canEdit" [name]="'Description'"
               (changed)="question.description = $event; edited = true" [model]="question.description"
               placeholder="Your answer should explain your feelings about the sky being blue?"></app-input>

    <app-input type="text" [editable]="false" name="Example Answer">
      <app-review-answer [question]="question" style="margin-bottom: 5px"></app-review-answer>
    </app-input>

    <div *ngIf="survey.status.canEdit" class="button-row scroll" style="margin-bottom: 10px">
      <button (click)="setType('section', '')" class="option-button" [ngClass]="{ green: question.type == 'section' }">
        New Section
      </button>
      <button (click)="setType('scale', 5)" class="option-button" [ngClass]="{ green: question.type == 'scale' }">
        Scale (1-10)
      </button>
      <button (click)="setType('text', '')" class="option-button" [ngClass]="{ green: question.type == 'text' }">
        Text
      </button>
      <button (click)="setType('agreement', 2)" class="option-button"
              [ngClass]="{ green: question.type == 'agreement' }">
        Level of Agreement
      </button>
      <button (click)="setType('yesno', 1)" class="option-button" [ngClass]="{ green: question.type == 'yesno' }">
        Yes / No
      </button>
      <button (click)="setType('timely', 0)" class="option-button" [ngClass]="{ green: question.type == 'timely' }">
        Timely
      </button>
    </div>
    <div class="divider"></div>
  </div>

  <div class="button-row">
    <button [disabled]="questionIndex <= 0" (click)="nextQuestion(-1)" class="option-button">
      <i class="fas fa-chevron-left"></i>
      <div>Prev</div>
    </button>
    <button [disabled]="!survey.questions[questionIndex + 1]" (click)="nextQuestion(1)" class="option-button">
      <div>Next</div>
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>

<div class="flex-columns" style="max-width: 400px !important" *ngIf="survey && question && !survey.status.canEdit">
  <h2>Answers</h2>
  <div *ngFor="let answer of survey.answers[questionIndex] | keyvalue" style="width: 100%; margin-bottom: 4px">
    <app-input type="text" [editable]="false" [name]="
        answer.value.bar +
        (survey.anonomous ? '' : ' - ' + staffKeys[answer.value.user])
      " *ngIf="answer.value.bar == selectedBar || !selectedBar">
      <app-review-answer [question]="question" [value]="answer.value.val" style="margin-bottom: 5px">
      </app-review-answer>
    </app-input>
  </div>
</div>

<div class="flex-columns" *ngIf="survey && survey.status.canEdit">
  <h2>Question Overview</h2>
  <div *ngFor="let question of survey.questions; let i = index" style="
      width: 100%;
      margin-bottom: 4px;

      padding: 4px 2px;
    " class="button-row">
    <div class="review-shrunk" style="height: 60px">
      <app-divider style="width: 100%; height: 1px; background-color: darkgray"
                   *ngIf="i != 0 && question.type == 'section'"></app-divider>
      <span style="z-index: 4" [style.font-size]="question.type == 'section' ? 'normal' : 'small'">{{ question.type ==
        "section" ? "&nbsp; &nbsp;" : "" }}
        {{ question.title }}</span>
      <br />
      <div class="shrink">
        <app-review-answer style="margin-bottom: 5px"></app-review-answer>
      </div>
    </div>

    <app-up-down style="z-index: 5" [length]="survey.questions.length" [index]="i"
                 (moved)="moved($event, question, i)"></app-up-down>
  </div>
</div>
