import { Component, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { Firebase } from "src/app/Services/firebase";

@Component({
  selector: "app-can-dutch",
  templateUrl: "./can-dutch.component.html",
  styleUrls: ["./can-dutch.component.css"],
})
export class CanDutchComponent implements OnInit {
  bars: any[] = [];
  subs: any[] = [];
  daysOfWeek = {1:{
    name: "Monday",
    value: 1,
    canDutch : false
  },
  2:{
    name: "Tuesday",
    value: 2,
    canDutch : false
  },
  3:{
    name: "Wednesday",
    value: 3,
    canDutch : false
  },
  4:{
    name: "Thursday",
    value: 4,
    canDutch : false
  },
  5:{
    name: "Friday",
    value: 5,
    canDutch : false
  },
  6:{
    name: "Saturday",
    value: 6,
    canDutch : false
  },
  0:{
    name: "Sunday",
    value: 0,
    canDutch : false

  }}
  constructor(
    private db: Firebase,
    private bi: BarInfo,
    public alert: AlertsService
  ) {}

  ngOnInit(): void {
    this.bars = this.bi.bars.map((a) => {
      return {
        name: a,
        canDutch: false,
      };
    }).filter(a => {
      //remove Daga Beheer, Pertemto, undefined, null and Old Staff
      return a.name !== "Daga Beheer" && a.name !== "Pertempto" && a.name !== undefined && a.name !== null && a.name !== "Old Staff";
    })
    this.getData();
  }

  ngOnDestroy(){
    this.subs.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        //not a sub
        }
    });
  }

  async getData() {
    this.bars.forEach(async (bar2) => {
      var bar = bar2;
      bar.schedule = Object.assign({}, this.daysOfWeek);
      this.subs.push(
        this.db.getCanDutchSub(bar.name).subscribe((data) => {
          bar.canDutch = data;
        })
      );

      this.subs.push(
        this.db.getCanDutchSchedule(bar.name).subscribe((data) => {
          console.log('SUB:   ' + bar.name)

          bar.schedule = JSON.parse(JSON.stringify(this.daysOfWeek));
          Object.keys(data).forEach(key => {
            bar.schedule[key].canDutch = data[key].canDutch;
          });

        })
      );

    });
  }

  async toggle(bar, candutch) {
    console.log(bar, candutch);
    const err = await this.db
      .setCanDutch(bar.name, candutch)
      .then((a) => null)
      .catch((e) => e);
    if (err) {
      this.alert.nextEmit(AlertsService.error("Error", err));
    } else {
      this.alert.nextEmit(
        AlertsService.success(
          "Success",
          "Updated " + bar.name + " can dutch? " + candutch
        )
      );
    }
  }

  async toggleSchedule(bar, day) {

    console.log(bar, day);
    const err = await this.db
      .setCanDutchSchedule(bar.name, day, !bar.schedule[day].canDutch)
      .then((a) => null)
      .catch((e) => e);
    if (err) {

      this.alert.nextEmit(AlertsService.error("Error", err));
    } else {
      this.alert.nextEmit(
        AlertsService.success(
          "Success",
          "Updated " + bar.name + " can dutch? " + day
        )
      );
    }
  }
}
