import { ElementRef, Inject, ViewChild } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import { ScheduledTask } from "src/app/Classes/scheduled-task";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { MultiSelectOption } from "src/app/Packages/multiselect/multiselect.component";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-tasks",
  templateUrl: "./tasks.component.html",
  styleUrls: ["./tasks.component.css"],
})
export class TasksComponent implements OnInit {
  name = "";
  barSub;
  sub;
  tasks;
  scheduledTasks: ScheduledTask[] = [];
  scheduledTask: ScheduledTask;
  task;
  managerSub;
  manager;
  staff = [];
  staffSub;
  selectedImage = 1;
  showChat = false;
  nextMessage = "";
  @ViewChild("user") user: ElementRef;
  @ViewChild("status") status: ElementRef;

  viewScheduled = false;
  pictures = [];
  extraPictures = [];
  constructor(
    private barInfo: BarInfo,
    private db: StaffAppDatabase,
    private storage: AngularFireStorage,
    public auth: Auth,
    private alert: AlertsService,
    private loading: LoadingService
  ) {
    this.sub = this.db.tasksObserver.subscribe((values) => {
      if (values) {
        this.tasks = values
          .filter((a) => a.status != "removed")
          .sort((a: any, b: any) => {
            var bPos = 0;
            var aPos = 0;

            switch (a.status) {
              case "archived":
                aPos = 2;
                break;
              case "progress":
                aPos = 1;
                break;
              case "archived":
              case "removed":
                aPos = 3;
                break;
              default:
                aPos = 0;
                break;
            }

            switch (b.status) {
              case "archived":
                bPos = 2;
                break;
              case "progress":
                bPos = 1;
                break;
              case "archived":
              case "removed":
                bPos = 3;
                break;
              default:
                bPos = 0;
                break;
            }

            if (aPos == bPos) {
              return Number(b.date) - Number(a.date);
            }

            return aPos - bPos;
          });

        if (this.task) {
          try {
            this.task = this.tasks.filter((a) => a.ref == this.task.ref)[0];
          } catch (e) {
            this.task = null;
          }
        }
      }
    });
    this.barSub = this.barInfo.barObserver.subscribe((name) => {
      this.bars = this.barInfo.bars.map((a) => {
        return { id: a, name: a, selected: false };
      });

      if (name) {
        this.name = name;
        this.db.getTasks(name);
        this.db.getStaffInfo(name);
      }
    });

    this.managerSub = this.db.managerObserver.subscribe((value) => {
      if (value) {
        this.manager = this.staff.find((a) => a.key == value);
      }
    });
    this.staffSub = this.db.staffInfoObserver.subscribe((values) => {
      if (values) {
        this.staff = values;
        this.db.getManager(this.name);
      }
    });
  }

  switchStatusColor(status) {
    var str = "";
    switch (status) {
      case "requested":
        return "red";
      case "progress":
        return "orange";
      case "archived":
        return "green";
      case "removed":
        return "green";
    }
    return str;
  }

  switchStatus(status) {
    var str = "";
    switch (status) {
      case "requested":
        return "Requested";
      case "progress":
        return "In Progress";
      case "archived":
        return "Completed";
      case "removed":
        return "Archived";
    }
    return str;
  }

  getUserName(ref): string {
    if (ref == "manager") {
      return this.manager ? this.manager.firstName : "manager";
    } else {
      return "management";
    }
  }
  ngOnInit(): void {
    this.cancel();
  }

  sendMessage() {
    if (this.nextMessage) {
      this.db.sendTaskMessage(this.barInfo.bar, this.task, {
        message: this.nextMessage,
        displayName:
          this.auth.accessUser.firstName[0] +
          ". " +
          this.auth.accessUser.lastName,
        date: Date.now() + "",
      });
      this.nextMessage = "";
    }
  }

  ngOnDestroy() {
    if (this.barSub) {
      this.barSub.unsubscribe();
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.managerSub) {
      this.managerSub.unsubscribe();
    }

    if (this.staffSub) {
      this.staffSub.unsubscribe();
    }
  }

  openTask(task) {
    this.scheduledTask = null;
    console.log(task);
    this.task = task;
    if (this.task.showUntil < 1000) {
      this.task.showUntil = new Date().getTime();
    }

    this.pictures = [];
    this.getInitialImages(task.date);
  }

  getInitialImages(pictureRef) {
    for (let i = 0; i < 3; i++) {
      let ref = this.storage.ref("/tasks/" + pictureRef + "_" + i);
      ref.getDownloadURL().subscribe((values) => {
        this.pictures.push(values);
      });
    }
  }

  getExtraImages(pictureRef, commentRef) {
    let ref = this.storage.ref("/tasks/" + pictureRef + "_" + commentRef);
    ref.getDownloadURL().subscribe((values) => {
      this.extraPictures.push(values);
    });
  }

  async getScheduledImages(task: ScheduledTask) {
    this.extraPictures = [];
    for (let i = 0; i < this.bars.length; i++) {
      const completedOnIndex = task.lastCompletedOn.findIndex(
        (a) => a.bar == this.bars[i].name
      );
      if (completedOnIndex == -1) {
        this.extraPictures.push("");
      } else {
        try {
          let ref = this.storage.ref(
            "/scheduledtasks/" +
              task.lastCompletedOn[completedOnIndex].bar +
              "/" +
              task.key +
              "/" +
              task.lastCompletedOn[completedOnIndex].date +
              ".jpg"
          );
          console.log(
            "/scheduledtasks/" +
              task.lastCompletedOn[completedOnIndex].bar +
              "/" +
              task.key +
              "/" +
              task.lastCompletedOn[completedOnIndex].date +
              ".jpg"
          );
          const image = await ref.getDownloadURL().toPromise();
          console.log(image);
          this.extraPictures.push(image || "");
        } catch (e) {
          console.log("couldnt download image", e);
          this.extraPictures.push("");
        }
      }
    }
  }

  download(a) {
    const link = document.createElement("a");
    link.href = a;
    if (!link.href.includes("null")) {
      link.download = name + ".jpeg";
      link.target = "_blank";
      link.click();
    }
  }

  cancel() {
    this.task = null;
    this.isEdit = false;
    this.scheduledTask = null;
    this.db.getTasks(this.name);
    this.getScheduledTasks();
  }

  save() {
    this.task.user = this.user.nativeElement.value;

    if (
      (this.task.status != "archived" &&
        this.status.nativeElement.value == "archived") ||
      this.status.nativeElement.value == "removed"
    ) {
      this.task.completedOn = Date.now();
    }
    this.task.status = this.status.nativeElement.value;
    try {
      this.db.changedRecordOld(
        this.barInfo.bar,
        this.auth.user.uid,
        this.task,
        "onderhoud",
        this.task.ref,
        "Task Saved"
      );
    } catch (e) {}
    this.db.updateTask(this.name, this.task);
    this.cancel();
  }

  showUntilChanged(event) {
    console.log(event);
    this.task.showUntil = new Date(event).getTime();
  }

  /* ---------- SCHEDULED TASKS ---------- */

  async getScheduledTasks() {
    this.scheduledTasks = await this.db.getScheduledTasks();
    //console.log(this.scheduledTasks);
  }

  isEdit = false;
  WEEK = 1000 * 60 * 60 * 24 * 7;
  frequency = [
    { id: "week", name: "1 Week", value: this.WEEK * 1 },
    { id: "week2", name: "2 Weeks", value: this.WEEK * 2 },
    { id: "month", name: "1 Month", value: this.WEEK * 4 },
    { id: "month2", name: "2 Months", value: this.WEEK * 8 },
    { id: "month3", name: "3 Months", value: this.WEEK * 12 },
    { id: "month6", name: "6 Months", value: this.WEEK * 6 },
    { id: "year", name: "1 Year", value: this.WEEK * 52 },
  ];
  frequencySimplified = [
    "1 Week",
    "2 Weeks",
    "1 Month",
    "2 Months",
    "3 Months",
    "6 Months",
    "1 Year",
  ];
  bars: any[] = [];

  async scheduledTaskClicked(scheduledTask) {
    this.scheduledTask = scheduledTask;
    this.task = null;
    await this.getScheduledImages(scheduledTask);
  }
  async createScheduledTask() {
    this.isEdit = true;
    this.scheduledTask = new ScheduledTask();
    this.scheduledTask.bars = this.barInfo.bars;
    this.scheduledTask.frequency = this.frequency[0].value;
    this.scheduledTask.frequencyType = this.frequency[0].name;
  }

  async deleteScheduledTask() {
    if (
      window.confirm("Are you sure you want to proceed deleting this task?")
    ) {
      // User confirmed, perform the desired action
      this.loading.nextEmit("on");
      try {
        await this.db.deleteScheduledTask(this.scheduledTask);
      } catch (error) {
        this.alert.nextEmit(
          AlertsService.error(
            "Error",
            "There was an error deleting the scheduled task"
          )
        );
        console.log(error);
        this.loading.nextEmit(null);
        return;
      }

      try {
        this.db.changedRecordOld(
          this.barInfo.bar,
          this.auth.user.uid,
          this.scheduledTask,
          "onderhoud",
          this.scheduledTask.key,
          "Scheduled Task Removed"
        );
      } catch (e) {
        console.error(e);
      }

      this.loading.nextEmit(null);
      this.alert.nextEmit(
        AlertsService.success("Success", "Scheduled task deleted")
      );
      this.cancel();
    } else {
      // User cancelled, handle accordingly
    }
  }

  async saveScheduledTask() {
    if (!this.isEdit) {
      this.isEdit = true;
      return;
    }
    console.log(this.scheduledTask);
    this.loading.nextEmit("on");
    try {
      if (this.scheduledTask.key) {
        await this.db.setScheduledTask(this.scheduledTask);
      } else {
        await this.db.newScheduledTask(this.scheduledTask);
      }
    } catch (error) {
      this.alert.nextEmit(
        AlertsService.error(
          "Error",
          "There was an error saving the scheduled task"
        )
      );
      console.log(error);
      this.loading.nextEmit(null);
      return;
    }

    try {
      this.db.changedRecordOld(
        this.barInfo.bar,
        this.auth.user.uid,
        this.scheduledTask,
        "onderhoud",
        this.scheduledTask.key,
        "Scheduled Task Saved"
      );
    } catch (e) {
      console.error(e);
    }

    this.loading.nextEmit(null);
    this.alert.nextEmit(
      AlertsService.success("Success", "Scheduled task saved")
    );
    this.cancel();
  }

  barsChange(event) {
    console.log(event);

    if (event.filter((a) => a.selected).length == 0) {
      this.scheduledTask.bars = event.map((a) => a.name);
    } else {
      this.scheduledTask.bars = event
        .filter((a) => a.selected)
        .map((a) => a.name);
    }
    console.log(this.scheduledTask.bars);
  }

  frequencyChange(event) {
    this.scheduledTask.frequencyType = event;
    this.scheduledTask.frequency = this.frequency.find(
      (a) => a.name == event
    ).value;
  }
}
