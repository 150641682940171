import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ar } from 'date-fns/locale';
import { Subscriber, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Artist } from '../Classes/artist';
import { Firebase } from '../Services/firebase';



@Pipe({
  name: 'song'
})
export class SongPipe implements PipeTransform {



  constructor(private db: Firebase) {

  }

  async transform(artist,song): Promise<any> {



    console.log(artist,song)

    if(!artist || !song) {
      return ''
    }


    const so = await this.db.getSong(artist, song).valueChanges().pipe(take(1)).toPromise().then(a => a as Artist).catch(e => {
      console.error(e);
      return null;
    })
    console.log(so)


    return so;





  }

}
