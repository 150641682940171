import { merge } from "lodash";

export class OneOffExpense {
  key: string = '';
  name: string ='';
  price: number = 0;
  date: number = (new Date()).getTime();


  constructor(e = {}) {
    merge(this,e);
  }

  isValid(){
    console.log(this)
    if(this.name && !isNaN(this.price) && !isNaN(this.date)) {
      return true;
    }
    return false;
  }
}
