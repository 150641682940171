import { Component, OnInit } from "@angular/core";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-tools",
  templateUrl: "./tools.component.html",
  styleUrls: ["./tools.component.css"],
})
export class ToolsComponent implements OnInit {
  constructor(public auth: Auth) {}

  ngOnInit(): void {}
}
