import {
  concat,
  isArray,
  isBoolean,
  isNumber,
  isObject,
  isString,
  merge,
  mergeWith,
} from "lodash";
import { Discount } from "./discount";
import { VerbruikType } from "./verbruik-type";

export class Verbruik {
  discounts: Discount[] = [];

  verbruik: VerbruikType = new VerbruikType();
  spill: VerbruikType = new VerbruikType();
  wisselen: VerbruikType = new VerbruikType();
  qr: VerbruikType = new VerbruikType();
  total = 0;
  color = "green";

  setValues(discounts) {
    this.discounts = discounts;
    this.discounts.forEach((disc) => {
      disc = new Discount(disc);
      this.total += disc.totalAmount;
      if (disc.reason.includes("Medewerker")) {
        this.verbruik.addItem(disc);
        return;
      }

      if (disc.reason.includes("Wisselen") || disc.reason.includes("Einde")) {
        this.wisselen.addItem(disc);
        return;
      }

      if (disc.reason.includes("QR") || disc.reason.includes("STAFF")) {
        this.qr.addItem(disc);
        return;
      }

      this.spill.addItem(disc);
    });
  }

  merge(th: Verbruik, ver: Verbruik) {
    mergeWith(th, ver, (obj, src) => {
      if (isNumber(obj)) {
        return obj + src;
      }
      if (isBoolean(obj)) {
        return obj && src;
      }

      if (isArray(obj)) {
        return concat(src, obj);
      }

      if (isString(obj)) {
        if (obj === "red" || src === "red") {
          src = "red";
          return src;
        }
        if (obj === "yellow" || src === "yellow") {
          src = "yellow";
          return src;
        }
        src = "green";
        return src;
      }

      if (isObject(obj)) {
        return mergeWith(obj, src, (obj2, src2) => {
          if (isNumber(obj2)) {
            return obj2 + src2;
          }
          if (isBoolean(obj2)) {
            return obj2 && src2;
          }

          if (isArray(obj2)) {
            return concat(src2, obj2);
          }
        });
      }
    });
    merge(this, th);
  }
  calculateColors(worked: any[], stock: any[]) {
    try {
      const work = worked.filter((a) => !a.cleaning);

      if (this.verbruik.quantity > work.length * 5) {
        this.verbruik.color = "yellow";
      } else {
        this.verbruik.color = "green";
      }

      this.verbruik.items.forEach((a) => {
        a.drinks.forEach((drink) => {
          try {
            const item = stock.filter((a) => a.serverId == drink)[0];

            if (item.vat > 10 || item.price > 4.5) {
              this.verbruik.color = "red";
            }
          } catch (e) {}
        });
      });
    } catch (e) {
      console.log(e);
    }

    try {
      this.spill.color =
        Math.abs(this.spill.total) >= 10
          ? "red"
          : Math.abs(this.spill.total) < 2
          ? "green"
          : "yellow";
    } catch (e) {
      console.log(e);
    }

    try {
      this.wisselen.color =
        Math.abs(this.wisselen.total) >= 10
          ? "red"
          : Math.abs(this.wisselen.total) < 2
          ? "green"
          : "yellow";
    } catch (e) {
      console.log(e);
    }

    const colors = [this.verbruik.color, this.spill.color, this.wisselen.color]
      .map((a) => {
        switch (a) {
          case "green":
            return 0;
          case "yellow":
            return 1;
          case "red":
            return 2;
        }
        return 1;
      })
      .sort((a: number, b: number) => b - a);

    this.color = this.calcColor(colors);
  }

  private calcColor(colors: number[]) {
    switch (colors.toString()) {
      case [2, 2, 2].toString():
      case [2, 2, 1].toString():
      case [2, 2, 0].toString():
      case [2, 1, 1].toString():
        return "red";
      case [2, 1, 0].toString():
      case [2, 0, 0].toString():
      case [1, 1, 1].toString():
      case [1, 1, 0].toString():
        return "yellow";
      case [1, 0, 0].toString():
      case [0, 0, 0].toString():
        return "green";
    }
    return "yellow";
  }
}
