import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Song } from "src/app/Classes/song";

import { Firebase } from "src/app/Services/firebase";

import { LoadingService } from "src/app/Services/loading.service";
import { AlertsService } from "../alerts/alerts.service";
import { ConfirmationDialogService } from "../confirmation-dialog/confirmation-dialog.service";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-edit-song",
  templateUrl: "./edit-song.component.html",
  styleUrls: ["./edit-song.component.css"],
})
export class EditSongComponent implements OnInit {
  artistForm: FormGroup;
  genres: string[] = [];
  data: Song;
  downloadUrl;
  selectedImage: string;
  languages = ['gb'];
  @Input("song") set song(song: Song) {
    this.artistForm.patchValue({
      name: song.name,
      genre: song.genre,
      info: song.info,
      length: song.length,
      year: song.year,
      location: song.location,
      modifier: Number(song.modifier),
      isDutch: song.isDutch,
      language: song.language ,
    });
    this.languages = song.language.split(',').map(a => a.trim());
    this.data = song;
    this.genres = song.genre.sort();
    this.api.getPhoto(song.key).then((a) => {
      this.downloadUrl = a;
    });
  }

  languageChanged() {
    //asset is a comma separated list of languages
    var pattern = /^[a-z]{2}(,[a-z]{2})*$/;
    if (!pattern.test(this.artistForm.controls['language'].value)) {
      this.artistForm.controls['language'].setErrors({ 'incorrect': true });
    }
    this.languages = this.artistForm.controls['language'].value.split(',').map(a => a.trim());
  }

  constructor(
    private loading: LoadingService,
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    public api: Firebase,
    private confirmation: ConfirmationDialogService,
    private alert: AlertsService,
    public db: StaffAppDatabase,
    public bi: BarInfo,
    public auth: Auth
  ) {
    this.artistForm = this.fb.group({
      name: [
        "",
        [Validators.required, Validators.maxLength(200), Validators.min(1)],
      ],
      genre: [],
      info: "",
      length: "",
      year: "",
      location: "",
      modifier: 1.2,
      isDutch: false,
      language: "en",
    });
  }

  ngOnInit(): void {}

  dismiss(reason: string) {
    this.modal.dismiss(reason);
  }

  async close(reason: string) {
    if (reason === "save") {
      await this.save();
    } else if (reason === "delete") {
      await this.delete();
    }
  }

  async save() {
    this.loading.nextEmit("on");
    console.log(
      "saving " + this.data.name + " to " + this.artistForm.get("name").value
    );
    Object.assign(this.data, this.artistForm.getRawValue());
    this.data.genre = this.genres;

    var val;
    if (this.selectedFiles) {
      val = await this.api
        .updateSongPhoto(this.data, this.selectedFiles)
        .then((a) => null)
        .catch((e) => e);
      if (val) {
        this.alert.nextEmit(AlertsService.error("Upload Photo Error", val));
        await this.api.init();
        this.loading.nextEmit("");
        return;
      }
    }
    this.selectedFiles = null;
    this.selectedImage = null;

    val = await this.api
      .addSong(this.data)
      .then((a) => null)
      .catch((e) => e);

    if (val) {
      this.alert.nextEmit(AlertsService.error("Save Error", val));
      console.error(val);
      await this.api.init();
      this.loading.nextEmit("");
      return;
    }

    try {
      this.db.changedRecord(
        this.bi.bar,
        this.auth.user.uid,
        this.data,
        "catalogue",
        this.data.key,
        "edit",
        "theendadmin.nl/catalogue",
        "Song Updated: " ,
        this.db.getDifferences(this.data, this.artistForm.getRawValue())
      );
    } catch (e) {
      console.error(e);
    }

    this.loading.nextEmit("");
    this.modal.dismiss();
  }

  async delete() {
    this.selectedFiles = null;
    this.selectedImage = null;
    console.log("deleting " + this.data.name);
    this.confirmation
      .confirm(
        "Delete: " + this.data.name,
        "Are you sure you want to delete this song? ",
        "Delete",
        "Cancel",
        "lg"
      )
      .then(
        async (ful) => {
          if (ful) {
            const val = await this.api
              .removeSong(this.data)
              .then((a) => null)
              .catch((e) => e);
            if (val) {
              this.alert.nextEmit(AlertsService.error("Delete Error", val));
              await this.api.init();
            }

            this.modal.dismiss();
          }
        },
        (rej) => {}
      );
  }

  isValid(name: string) {
    return this.artistForm.get(name).valid;
  }

  deleteGenre(index) {
    try {
      this.genres.splice(index, 1);
    } catch (e) {
      this.alert.nextEmit(AlertsService.warning("Failed to remove genre", e));
    }
  }

  selectedFiles: any;
  percentage: number;

  addGenre(genre: string) {
    this.genres.push(genre);
    this.genres = [...new Set(this.genres)].sort();
  }

  selectFile(event): void {
    this.selectedFiles = event.target.files[0];
    this.selectedImage = URL.createObjectURL(this.selectedFiles);
  }

  uploadPhoto() {}
}
