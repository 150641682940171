import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PDFViewerComponent } from "duncans-pdfviewer";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { Observable } from "rxjs";
import { Auth } from "src/app/auth/auth";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { InvoiceSplitterPreviewComponent } from "../../Modals/invoice-splitter-preview/invoice-splitter-preview.component";

@Component({
  selector: "app-invoice-splitter",
  templateUrl: "./invoice-splitter.component.html",
  styleUrls: ["./invoice-splitter.component.css"],
})
export class InvoiceSplitterComponent implements OnInit {
  pdfOptions = {
    width: "600px",
    height: "900px",
  };
  selectedFiles: InvoiceData[] = [];

  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  @ViewChild("pdfDecider") private pdfDecider: ElementRef<HTMLDivElement>;
  currentPDF: InvoiceData;

  page: number = 1;
  pageSize: number = 1;
  collectionSize: number = 0;

  bars: String[] = [];
  constructor(
    public cdf: ChangeDetectorRef,
    private http: HttpClient,
    public modal: NgbModal,
    public alert: AlertsService,
    public loading: LoadingService,
    public auth: Auth,
    public bi: BarInfo
  ) {}

  ngOnInit(): void {
    this.bars = this.bi.filteredBars
      .concat("*Northern Programming")
      .concat("*Arnhem")
      .concat("*Zwolle")
      .concat("*Duncan")
      .concat("N/A");
  }

  ngAfterViewInit() {
    //get page size

    var width = this.pdfDecider.nativeElement.offsetWidth;
    var a4AspectRatio = 1.414;
    if (width < 600) {
      this.pdfOptions.width = width + "px";
      this.pdfOptions.height = width * a4AspectRatio + "px";
    } else {
      this.pdfOptions.width =
        this.pdfDecider.nativeElement.offsetWidth / 2 + "px";
      this.pdfOptions.height =
        (this.pdfDecider.nativeElement.offsetWidth / 2) * a4AspectRatio + "px";
    }

    this.cdf.detectChanges();
  }

  sendEmail() {}

  clear() {
    this.selectedFiles = [];
    this.currentPDF = null;
    this.collectionSize = 0;
    this.cdf.detectChanges();
  }

  onFilesSelected(event: Event): void {
    this.clear();
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement?.files || inputElement.files.length <= 0) {
      return;
    }

    for (let i = 0; i < inputElement.files.length; i++) {
      const file = inputElement.files[i];
      const invoiceData = new InvoiceData();
      invoiceData.pdfData = file;
      invoiceData.sender =
        this.auth.accessUser.firstName + " " + this.auth.accessUser.lastName;
      this.selectedFiles.push(invoiceData);
    }
    this.collectionSize = this.selectedFiles.length;

    this.cdf.detectChanges();

    this.previewFiles();
  }

  pageChanged(page) {
    this.page = page;
    this.previewFiles(page);
  }

  async previewFiles(number = 0) {
    this.page = number;

    this.currentPDF = this.selectedFiles[number];
    this.openPDF(this.selectedFiles[number].pdfData);
  }

  async openPDF(file: File) {
    var reader = new FileReader();
    reader.onload = async (p) => {
      this.currentPDF.pdfUrl = p.target.result;
    };
    reader.readAsArrayBuffer(file);
  }

  barClicked(bar) {
    this.currentPDF.assignedBar = bar;

    if (bar[0] == "*") {
      var b = bar.substring(1);
      this.currentPDF.assignedBar = b;
      switch (b) {
        case "Northern Programming":
          this.currentPDF.email = "npfacturen@daga-beheer.nl";
          break;
        case "Arnhem":
          this.currentPDF.email = "arnhemfacturen@daga-beheer.nl";
          break;
        case "Zwolle":
          this.currentPDF.email = "zwollefacturen@daga-beheer.nl";
          break;
        case "Duncan":
          this.currentPDF.email = "duncansaunders1997@gmail.com";
          break;
      }
    } else {
      try {
        this.currentPDF.email = this.bi.companyInfo[bar].facturenemail || "n/a";
      } catch (e) {
        this.currentPDF.email = "n/a";
      }
    }

    console.log(this.currentPDF.email);
  }

  checkViews() {
    //do all files have an assigned bar?
    if (!this.selectedFiles || this.selectedFiles.length <= 0) {
      return false;
    }
    for (let i = 0; i < this.selectedFiles.length; i++) {
      if (!this.selectedFiles[i].assignedBar) {
        return false;
      }
    }

    return true;
  }
  search(stringToSearch: string) {
    this.pdfComponent.eventBus.dispatch("find", {
      query: stringToSearch,
      type: "again",
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
    });
  }

  reviewAndSend() {
    var instance = this.modal.open(InvoiceSplitterPreviewComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    instance.componentInstance.files = this.selectedFiles;
    instance.result.then((result) => {});
  }
}

export class InvoiceData {
  pdfData: any;
  assignedBar: string;
  pdfUrl: any;
  email: string;
  status: string = "";
  sender: string;
  type: string = "invoice";
}
