<h2>Choose Month</h2>

<input [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="date = $event" type="date" name="date" (ngModelChange)="setMonth()" />

<button (click)="getReport()">Get</button>

<div>
    May take up to 5 minutes
</div>

<div *ngIf="days.length && !report" style="display: flex; flex-direction: row; align-items: flex-start; justify-content:flex-start; flex-wrap: wrap">
    <div *ngFor="let day of days; let i = index" [class]="day.status == 0 ? 'red' : day.status == 1 ? 'orange' : 'green'" style="margin: 5px; width: 30px; height: 30px; border-radius: 50px; display: flex; align-items: center; justify-content: center;">
        {{i + 1}}
    </div>
</div>

<div *ngIf="report">
    <a class="btn btn-clear" title="Download JSON" [href]="downloadJsonHref" download="download.json">Download JSON</a>
    <button>Download as XML</button>
</div>

<div *ngIf="summary">
    <h2>Total</h2>
    <div>Cash: {{summary.cash | currency:'EUR'}} from {{summary.cashQ}} transactions</div>
    <div>Pin: {{summary.pin | currency:'EUR'}} from {{summary.pinQ}} transactions</div>
    <div>Other (staff, breuk etc...): {{summary.other | currency:'EUR'}} from {{summary.otherQ}} transactions</div>
    <div>Internet: {{summary.internet | currency:'EUR'}} from {{summary.internetQ}} transactions</div>
    <div>Total: {{summary.total | currency:'EUR'}} from {{summary.totalQ}} transactions</div>

</div>