import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { LoadingService } from "src/app/Services/loading.service";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import merge from "lodash-es/merge";
import { isNumber } from "lodash";
import { OverviewService } from "src/app/Packages/overview.service";
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WorkedRecordsComponent } from "src/app/Packages/worked-records/worked-records.component";
import { GenericMessage } from "src/app/Classes/generic-message";
import { Auth } from "src/app/auth/auth";
import { CreateMessageComponent } from "src/app/Packages/create-message/create-message.component";
import { MissingTellingDatesComponent } from "../missing-telling-dates/missing-telling-dates.component";

@Component({
  selector: "app-telling",
  templateUrl: "./telling.component.html",
  styleUrls: ["./telling.component.css"],
})
export class TellingComponent implements OnInit {
  data: any = {
    differences: {},
    differencesAbs: {},
  };
  subs: Subscription[] = [];
  bar = "";
  writeMessage = false;

  constructor(
    private bI: BarInfo,
    private db: StaffAppDatabase,
    private loading: LoadingService,
    private alert: AlertsService,
    public overview: OverviewService,
    private modal: NgbModal,
    public auth: Auth
  ) {
    this.loading.nextEmit("on");
  }

  openWorkedRecords() {
    const modal = this.modal.open(WorkedRecordsComponent, { size: "xl" });
    modal.componentInstance.records = this.overview.selection.workedRecords;
    modal.result.then(
      (a) => {},
      (e) => {}
    );
  }

  ngOnInit(): void {
    this.subs.push(
      this.bI.barObserver.subscribe((val) => {
        this.bar = val;
        this.build();
      })
    );
  }

  async addDate(date: Date, add: number = 0) {

    if (date) {
      await this.overview.setDate(date);
    } else {
      this.overview.removeMissing(this.overview.start);
      await this.overview.addDate(add);
    }
    await this.getData();
  }

  async build() {
    await this.overview.getRange(this.bar, false);
    await this.getData();
  }

  missingDates(){
    this.modal.open(MissingTellingDatesComponent, { size: "xl" });
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }

  async getData() {
    await this.overview.buildSelection();
    this.loading.nextEmit("on");

    await this.getTill(this.overview.date);
    await this.getCount(this.overview.date);
    const inout = await this.db.getInOut(this.bar, this.overview.date);
    //console.log(inout, this.overview.selection.inout)

    this.data.count = this.data.count
      .filter((a) => a.difference != 0)
      .map((a) => {
        try {
          a.price =
            this.overview.stock.filter((b: any) => a.key === b.serverId)[0]
              .price || 0;
        } catch (e) {
          a.price = 0;
          console.error(e);
          console.log(this.overview.stock);
          console.log(a);
        }
        return a;
      });

    this.data.flesjes = this.data.count.reduce(
      (pas, acc) => pas - acc.price * acc.difference,
      0
    );

    this.data.bonnen = {
      list: this.overview.selection.inout,
      till: this.overview.selection.inout.reduce((pre, cur) => {
        return pre + cur.amount;
      }, 0),
    };
    this.calculateDifferences();
    this.loading.nextEmit();
  }

  async getTill(date: Date) {
    const og = {
      expectedPin: 0,
      expectedCash: 0,
      internet: 0,
      teller: 0,
      final: {
        pin: null,
        cash: null,
        internet: null,
        flesjes: null,
        teller: null,
        total: null,
        bonnen: 0,
        bonnenVAT: 0,
        checks: {
          total: false,
          pin: false,
          cash: false,
          internet: false,
          flesjes: false,
          bonnen: false,
          teller: false,
        },
      },
      end: {
        realPin: 0,
        realCash: 0,
      },
    };
    const newData = await this.db
      .getTillForDate2(this.bar, date)
      .then((a: any) => {
        if (!a) {
          a = {
            end: {
              realPin: 0,
              realCash: 0,
            },
          };
        }
        if (a && !a.end) {
          a.end = {
            realPin: 0,
            realCash: 0,
          };
        } else {
          a.end.realPin = Number(a.end.realPin);
          a.end.realCash = Number(a.end.realCash);
        }
        return a;
      })
      .catch((e) => {
        console.error(e);
        return {};
      });
    //console.log(og, newData);
    this.data.till = merge(og, newData);

    //console.log(og);
    this.data.till = og;

    console.log(this.data);
  }

  async getCount(date: Date) {
    this.data.count = await this.db
      .getCountForDate2(this.bar, date)
      .then((a) => a)
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  calculateDifferences() {
    var pinDifference = -(this.data.till.expectedPin || 0);
    if (this.data.till.final.checks && this.data.till.final.checks.pin) {
      pinDifference += this.data.till.final.pin || 0;
    } else {
      pinDifference += this.data.till.final.pin || this.data.till.end.realPin;
    }

    var cashDifference = -(this.data.till.expectedCash || 0);
    if (this.data.till.final.checks && this.data.till.final.checks.cash) {
      cashDifference += this.data.till.final.cash || 0;
    } else {
      cashDifference +=
        this.data.till.final.cash || this.data.till.end.realCash;
    }

    var internetDifference =
      (-this.data.till.internet || 0) + (this.data.till.final.internet || 0);

    var bonnenDifference =
      (this.data.bonnen.till || 0) - (this.data.till.final.bonnen || 0);

    var flesjesDifference = 0;
    if (this.data.till.final.checks && this.data.till.final.checks.flesjes) {
      flesjesDifference += this.data.till.final.flesjes;
    } else {
      flesjesDifference += this.data.flesjes;
    }

    const tellerDifference = this.data.till.final.teller || 0;
    const totalDifference =
      pinDifference +
      cashDifference +
      internetDifference +
      tellerDifference +
      flesjesDifference +
      bonnenDifference;

    var toFixedNumber = function (base, digits) {
      var pow = 100;
      return Math.round(base * pow) / pow;
    };
    this.data.differences = {
      pin: toFixedNumber(pinDifference, 2),
      cash: toFixedNumber(cashDifference, 2),
      internet: toFixedNumber(internetDifference, 2),
      flesjes: toFixedNumber(flesjesDifference, 2),
      teller: toFixedNumber(tellerDifference, 2),
      total: toFixedNumber(totalDifference, 2),
      bonnen: toFixedNumber(bonnenDifference, 2),
    };
    console.log(this.data.differences);
    this.data.differencesAbs = {
      pin: Math.abs(pinDifference),
      cash: Math.abs(cashDifference),
      internet: Math.abs(internetDifference),
      flesjes: Math.abs(flesjesDifference),
      teller: Math.abs(tellerDifference),
      total: Math.abs(totalDifference),
      bonnen: Math.abs(bonnenDifference),
    };

    this.data.till.final.total =
      this.data.till.final.pin +
      this.data.till.final.cash +
      this.data.till.final.internet +
      this.data.till.final.teller +
      this.data.till.final.flesjes +
      this.data.till.final.bonnen;
  }

  async updateFinal(name: string = null) {
    //console.log("updating final", this.data.till.final,name)
    if (!isNaN(this.data.till.final[name])) {
      if (name === "internet") {
        await this.updateInternet()
          .then((a) => a)
          .catch((e) => console.error(e));
      }

      if (name == "bonnen") {
        res = await this.db
          .updateBonnenVAT(
            this.bar,
            this.overview.date,
            this.data.till.final["bonnenVAT"],
            "bonnenVAT"
          )
          .then((a) => null)
          .catch((e) => e);
        if (res) {
          console.error(res);
          this.alert.nextEmit(
            AlertsService.error("Failed to save", "Refreshing page")
          );
          this.getData();
          return;
        }
      }
      var res = await this.db
        .updateTellingFinal(
          this.bar,
          this.overview.date,
          this.data.till.final[name],
          name,
          this.data.till.final.checks[name]
        )
        .then((a) => null)
        .catch((e) => e);
      if (res) {
        console.error(res);
        this.alert.nextEmit(
          AlertsService.error("Failed to save", "Refreshing page")
        );
        this.getData();
        return;
      }
      res = await this.db
        .updateTellingFinal(
          this.bar,
          this.overview.date,
          this.data.till.final["total"],
          "total",
          this.data.till.final.checks.total
        )
        .then((a) => null)
        .catch((e) => e);
      if (res) {
        console.error(res);
        this.alert.nextEmit(
          AlertsService.error("Failed to save", "Refreshing page")
        );
        this.getData();
        return;
      }

      res = await this.db
        .updateTellingDifferences(
          this.bar,
          this.overview.date,
          name,
          this.data.differences[name]
        )
        .then((a) => null)
        .catch((e) => e);
      if (res) {
        console.error(res);
        this.alert.nextEmit(
          AlertsService.error("Failed to save", "Refreshing page")
        );
        this.getData();
        return;
      }

      res = await this.db
        .updateTellingDifferences(
          this.bar,
          this.overview.date,
          "total",
          this.data.differences["total"]
        )
        .then((a) => null)
        .catch((e) => e);
      if (res) {
        console.error(res);
        this.alert.nextEmit(
          AlertsService.error("Failed to save", "Refreshing page")
        );
        this.getData();
        return;
      }
    } else {
      this.alert.nextEmit(
        AlertsService.warning("invalid property", name + " is invalid")
      );
    }
  }

  async updateInternet() {
    const res = await this.db
      .updateTellingInternet(
        this.bar,
        this.overview.date,
        this.data.till.internet
      )
      .then((a) => null)
      .catch((e) => e);
    if (res) {
      this.alert.nextEmit(
        AlertsService.error("Failed to save", "Refreshing page")
      );
      this.getData();
    }
  }

  setInternet(event) {
    this.data.till.internet = Number(event);
  }

  check(name, event) {
    //console.log(name, event);
    this.data.till.final.checks[name] = event;
    //console.log(this.data.till.final);
    const unchecked = Object.entries(this.data.till.final.checks).filter(
      (k, v) => {
        //console.log(k, v);
        return !k[1];
      }
    );
    if (unchecked.length <= 0) {
      this.updateFinal(name);
    }
    //console.log(unchecked);
    if (unchecked.length == 1) {
      //console.log(unchecked);
      const unch = unchecked[0];
      if (unch[0] == "total") {
        this.data.till.final.checks.total = true;
      } else {
        this.data.till.final.checks.total = false;
      }
    }

    if (unchecked.length > 1) {
      this.data.till.final.checks.total = false;
    }

    this.updateFinal(name);
  }

  isNaN(num) {
    return isNaN(num);
  }

  refreshTill() {
    this.loading.nextEmit("on");
    ////console.log(new Date(this.selectedDate).toISOString(),this.selectedDate,this.barName);
    this.db
      .getTillForDate(new Date(this.overview.date).toLocaleString(), this.bar)
      .then((a) => {
        console.log(a);
        this.alert.nextEmit(AlertsService.good("Getting Till", "Success"));
        this.loading.nextEmit();
        this.getTill(new Date(this.overview.date));
      })
      .catch((e) => {
        console.error(e);
        this.alert.nextEmit(
          AlertsService.error("Error Getting Till", JSON.stringify(e, null, 2))
        );
        this.loading.nextEmit();
      });
  }

  async sendMessage() {
    const user: any = await this.auth.getDbUser();
    var message = new GenericMessage(
      "message",
      "",
      {
        uid: this.auth.user.uid,
        email: this.auth.user.email,
        displayName: user.firstName + " " + user.lastName,
      },
      "telling",
      "telling",
      this.overview.start,
      this.bar
    );

    var modal = this.modal.open(CreateMessageComponent, { size: "xl" });
    modal.componentInstance.message = message;
    modal.componentInstance.messages = this.overview.shiftMessages;
    modal.result.then(
      (a) => {
        if (a) {
          this.db.sendGenericMessage(a).then(() => {
            this.overview.getMessages();
          });
        }
      },
      (e) => {}
    );
  }
}
