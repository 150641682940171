import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "priority",
})
export class PriorityPipe implements PipeTransform {
  transform(value: number): unknown {
    if (isNaN(value)) return "n/a";
    if (value < 0) return "Very Low";
    if (value < 1) return "Low";
    if (value < 2) return "Medium";
    if (value < 3) return "High";

    return null;
  }
}
