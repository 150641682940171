import { CompanyInfo } from "./company-info";
import { TillInformation } from "./till-information";
import { VipInformation } from "./vip-information";

export class Bar {
  companyInfo: CompanyInfo = new CompanyInfo();
  manager: string = "";
  name: string = "";
  tillInformation: TillInformation = new TillInformation();
  type: string = "bar";
  vipInformation: VipInformation = new VipInformation();

  constructor() {
    this.companyInfo = new CompanyInfo();
    this.manager = "";
    this.name = "";
    this.tillInformation = new TillInformation();
    this.vipInformation = new VipInformation();
  }

  verify(): string[] {
    var errors: string[] = [];
    if (!this.name) {
      errors.push("Name is required");
    }

    if (!this.companyInfo.verify()) {
      errors = errors.concat("Company Info is required");
    }

    if (!this.manager) {
      errors.push("Manager is required");
    }

    if (!this.type) {
      errors.push("Type is required");
    }

    if (!this.tillInformation.verify()) {
      errors.push("Till Information is required");
    }

    if (!this.vipInformation.verify()) {
      errors.push("VIP Information is required");
    }

    return errors;
  }

  fixVariables() {
    // inputs are strings, cast to number if needed

    this.companyInfo.fixVariables();
    this.tillInformation.fixVariables();
    this.vipInformation.fixVariables();

    //check name, type and manager are strings
  }
}
