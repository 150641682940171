import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import * as xml2js from "xml2js";

@Component({
  selector: 'app-booking-api',
  templateUrl: './booking-api.component.html',
  styleUrls: ['./booking-api.component.css']
})
export class BookingAPIComponent implements OnInit {

  bar: string = "";
  subs: any[] = [];
  appointments: any[] = [];
  agenda: any = null;
  constructor(
    private mi: BarInfo,
    private db: StaffAppDatabase,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    console.log(this.mi.bar);
    this.subs.push(
      this.mi.barObserver.subscribe((v) => {
        if (v) {
          this.bar = v;
          this.db
            .getVipInformation(v).pipe(take(1)).toPromise()
            .then((a) => {
             this.getData(a);
             // this.getAgendas(a);
            })
            .catch((e) => {
              console.error(e);
            });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }

  async getData(a) {
    await this.getAgendas(a);
    this.getAppointments(a);
  }

  //StartDate YYYY-MM-DD

  async getAppointments(info) {
    var start = new Date();
    var end = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() + 6
    );
    const AgendaId = info.agendaId || this.agenda;
    const STARTDATE = start.toISOString().split("T")[0];
    const ENDDATE = end.toISOString().split("T")[0];
    const salt = new Date().getTime().toFixed(0);
    const r = `AgendaId${AgendaId}EndDate${ENDDATE}StartDate${STARTDATE}methodgetAppointments${info.secret}${salt}`;
    const signature = Array.from(
      new Uint8Array(
        await window.crypto.subtle.digest("SHA-1", new TextEncoder().encode(r))
      )
    )
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    const url = `https://agenda.onlineafspraken.nl/APIREST?method=getAppointments&AgendaId=${AgendaId}&EndDate=${ENDDATE}&StartDate=${STARTDATE}&api_key=${info.key}&api_signature=${signature}&api_salt=${salt}`;
    await this.db
      .intercept(url)
      .then((a) => {

        xml2js
          .parseStringPromise(a)
          .then((b) => {

            this.appointments = this.parseAppointments(b);
          })
          .catch((e) => console.error(e));
      })
      .catch((e) => {
        console.error(e);
      });
  }

  async getAgendas(info) {
    var start = new Date();
    var end = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() + 6
    );

    const salt = (new Date().getTime() / 1000 - 50).toFixed(0);
    const r = `methodgetAgendas` + info.secret + salt;
    const signature = Array.from(
      new Uint8Array(
        await window.crypto.subtle.digest("SHA-1", new TextEncoder().encode(r))
      )
    )
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    const url = `https://agenda.onlineafspraken.nl/APIREST?method=getAgendas&api_key=${info.key}&api_signature=${signature}&api_salt=${salt}`;

    await this.db.intercept(url).then((a) => {
      xml2js
          .parseStringPromise(a)
          .then((b) => {

            this.parseAgendas(b);
          })
          .catch((e) => console.error(e));
    }).catch(e => {
      console.error(e);
    })

  }

  parseAppointments(obj) {
    try {
      var apps = obj.Response.Objects[0].Appointment;
      apps = apps.map((app) => {
        var a: any = {};
        Object.entries(app).forEach((val) => {
          a[val[0]] = val[1][0];
        });
        return a;
      });

      console.log(apps);
      return apps;
    } catch (e) {}
    return [];
  }

  parseAgendas(obj) {
    try {
      var agenda = obj;
      console.log(agenda);
      this.agenda = obj.Response.Objects[0].Agenda[0].Id[0];
    } catch(e) {
      console.error(e);
    }
  }

}
