<div [class]="titleClass ? titleClass : 'normal-box'">
    {{title}}</div>
<div *ngFor="let first of list" [class]="first.class + (isHeader? ' header-item ' : ' ') + ((first.type && first.type =='color') ? (first.data | colorRange:first.range) : '')">

    <div *ngIf="first.type && first.type == 'currency'">{{first.data | currency:'EUR'}}</div>
    <div *ngIf="first.type && first.type == 'percent'">{{((first.data || 0) * 100 | number : '1.2-2') + '%'}}</div>


    <div *ngIf="!first.type">
        {{first.data}}
    </div>

</div>