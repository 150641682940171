<div class="title">
  <h2>Change Records</h2>
</div>

<div class="toolbar">
  <button (click)="handle()">
    {{ showAll ? "Show Differences" : "Show All" }}
  </button>
</div>

<div class="content">
  <div style="margin-left: 10px" class="left">
    <h3>Previous</h3>
    <ngx-json-viewer
      [expanded]="false"
      [json]="showAll ? records[0] : changes[0]"
    ></ngx-json-viewer>
  </div>

  <div class="right">
    <h3 style="margin-left: 10px">Change</h3>
    <ngx-json-viewer
      [expanded]="false"
      [json]="showAll ? records[1] : changes[1]"
    ></ngx-json-viewer>
  </div>
</div>
