import { Discount } from "./discount";

export class VerbruikType {
  quantity=0;
  color = 'green';
  total = 0;
  items: Discount[] = []

  addItem(item: Discount) {
    this.items.push(item);
    this.total += item.totalAmount;
    this.quantity += item.quantity
  }
}
