<div class="buttons-row">
    <app-input type="date" [model]="date | date:'yyyy-MM-dd'" name="Start Date" (changed)="dateChanged(true,$event)"></app-input>
    <app-input type="date" [model]="endDate | date:'yyyy-MM-dd'" name="End Date" (changed)="dateChanged(false,$event)"></app-input>
    <button (click)="getData()" style="padding: 0px 20px">Get</button>
</div>

<div class="staff-row-header">
    <div class="staff-name">
        Date

    </div>

    <div class="staff-email">
        Have Shirts

    </div>

    <div class="staff-telephone">
        Arrived On Time

    </div>

    <div class="staff-eoc">Comments </div>



</div>


<div class="staff-table">
    <div *ngFor="let s of shifts" class="staff-row" [ngbTooltip]="s.comment" [ngClass]="{
      'green-border': s.arriveOnTime && s.shirts,
      'red-border': !s.arriveOnTime || !s.shirts



    }">
        <div class="staff-name">
            {{ s.date | date:' EEE dd LLL yyyy' }}
        </div>

        <div class="staff-email" [ngClass]="{
          'green': s.shirts,
          'red': !s.shirts
        }">
            &nbsp;
        </div>

        <div class="staff-telephone" [ngClass]="{
          'green': s.arriveOnTime,
          'red': !s.arriveOnTime

        }">
            &nbsp;
        </div>

        <div class="staff-eoc">{{s.comment}}</div>
    </div>
</div>

<div class="staff-footer">
    <div class="staff-row">
        <div class="staff-name">
            Totals:
        </div>

        <div class="staff-email">
            {{ total.shirts }} / {{ total.shifts }}
        </div>

        <div class="staff-telephone">
            {{ total.onTime}} / {{ total.shifts }}
        </div>

        <div class="staff-eoc"></div>
    </div>
</div>
