import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-string-entry-modal",
  templateUrl: "./string-entry-modal.component.html",
  styleUrls: ["./string-entry-modal.component.css"],
})
export class StringEntryModalComponent implements OnInit {
  @Input("title") title: string;
  @Input("placeholder") placeholder: string;
  value: string = "";
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  close() {
    console.log("close");
    this.activeModal.close(this.value);
  }

  dismiss() {
    console.log("dismiss");
    this.activeModal.dismiss();
  }
}
