<div *ngFor="let da of dates | keyvalue; let index = i" style="border-radius: 1px solid black;" (click)="setDay(da)">
    {{ da.key }}
</div>

<div *ngIf="day">
    <div *ngIf="i != 0">
        <div class="descval">
            <div class="desc"></div>
            <div class="val"></div>
        </div>

        <div class="descval">
            <div class="desc">Date:</div>
            <div class="val">
                {{ day.key }}
            </div>
        </div>
        <div class="descval">
            <div class="desc">New User Scans:</div>
            <div class="val">
                {{ day.value.newUsers }}
            </div>
        </div>
        <div class="descval">
            <div class="desc">Total Scans:</div>
            <div class="val">
                {{ day.value.total }}
            </div>
        </div>
        <div class="descval">
            <div class="desc">Android/IOS:</div>
            <div class="val">{{ day.value.android }}/{{ day.value.ios }}</div>
        </div>
        <div style="max-height: 600px;">
            <div *ngFor="let user of day.value.users " class="user">
                <div class="descval">
                    <div class="desc">Key:</div>
                    <div class="val">
                        {{ user.uid }}
                    </div>
                </div>
                <div class="descval">
                    <div class="desc">Is New:</div>
                    <div class="val">
                        {{ user.newUser }}
                    </div>
                </div>
                <div class="descval">
                    <div class="desc">Scans:</div>
                    <div class="val">
                        {{ user.quantity }}
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>