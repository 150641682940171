/**
 * API
 * # Introduction  Welcome to the TPAPI-POS API! unTill Server provides a REST interface for 3rd party applications which may be used for remotely working with POS: reading configuration, making orders, etc.  You can view code samples in the dark area to the right.  In case you might have any questions about our API, please create a new support [**ticket**](http://integrations.untill.com/support/tickets/new). If you don't have an account yet but already signed an [**NDA**](https://untill.com/nda), please send an email to [**systemintegration@untill.com**](mailto:systemintegration@untill.com). Please do not use this email address for support questions.  # User permissions  Each API function should be invoked with appropriate user permission. Please see function description. The following permissions can be assigned to a user in unTill Backoffice - Backoffice view - Backoffice modify - Ordering - Ordering: override price - Apply discounts - Payment - Client management - Reporting - Reservations - Misc
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Article } from '../model/article';
import { ArticleShort } from '../model/articleShort';
import { BreakReason } from '../model/breakReason';
import { Category } from '../model/category';
import { Country } from '../model/country';
import { Course } from '../model/course';
import { DepartmentInfo } from '../model/departmentInfo';
import { DiscountGroup } from '../model/discountGroup';
import { DiscountReason } from '../model/discountReason';
import { GroupInfo } from '../model/groupInfo';
import { OptionInfo } from '../model/optionInfo';
import { PaymentInfo } from '../model/paymentInfo';
import { PriceInfo } from '../model/priceInfo';
import { Printer } from '../model/printer';
import { SalesAreaInfo } from '../model/salesAreaInfo';
import { SizeModifier } from '../model/sizeModifier';
import { SmartCard } from '../model/smartCard';
import { SmartCardGroup } from '../model/smartCardGroup';
import { Supplier } from '../model/supplier';
import { UserInfo } from '../model/userInfo';
import { VoidReason } from '../model/voidReason';
import { Voucher } from '../model/voucher';

import { BASE_PATH } from 'src/variables';
import { Configuration }                                     from '../configuration';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';




@Injectable()
export class BackofficeViewService {

    protected basePath = 'http://82.73.157.201:{port}/api/v1/{dbname}';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration,
                private db: StaffAppDatabase) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * List available articles
     * List available articles. Required user permission: Backoffice view
     * @param salesAreaId Search articles of a certain sales area only, if absent all available articles will return
     * @param active Search articles with specified state. When param absent then both active and inactive articles returns
     * @param plu Searches articles by PLU, the PLU list can either be a single PLU or a comma-separated list
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articleGet(salesAreaId?: number, active?: boolean, plu?: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public articleGet(salesAreaId?: number, active?: boolean, plu?: number, observe?: 'response', reportProgress?: boolean): Observable<string>;
    public articleGet(salesAreaId?: number, active?: boolean, plu?: number, observe?: 'events', reportProgress?: boolean): Observable<string>;
    public articleGet(salesAreaId?: number, active?: boolean, plu?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesAreaId !== undefined && salesAreaId !== null) {
            queryParameters = queryParameters.set('salesAreaId', salesAreaId as any);
        }
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', active as any);
        }
        if (plu !== undefined && plu !== null) {
            queryParameters = queryParameters.set('plu', plu as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        console.log(this.basePath);

        return this.db.httpsCall({
          method: 'get',
          path: `${this.basePath}/article`,
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: {Authorization: headers.get('Authorization'), Accept: headers.get('Accept'), AppToken: this.configuration.accessToken} ,
          observe,
          reportProgress
        });
    }

    /**
     * Find article by ID
     * Find article by ID. Required user permission: Backoffice view
     * @param id Specify to find article by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articleIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public articleIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public articleIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public articleIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling articleIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Article>('get', `${this.basePath}/article/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List available short articles
     * List available short articles. Required user permission: Backoffice view
     * @param salesAreaId Specify to request articles of a certain sales area only, if absent all available articles will return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articleShortGet(salesAreaId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ArticleShort>>;
    public articleShortGet(salesAreaId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ArticleShort>>>;
    public articleShortGet(salesAreaId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ArticleShort>>>;
    public articleShortGet(salesAreaId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesAreaId !== undefined && salesAreaId !== null) {
            queryParameters = queryParameters.set('salesAreaId', salesAreaId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ArticleShort>>('get', `${this.basePath}/article-short`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active break reasons
     * List active break reasons. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public breakReasonGet(observe?: 'body', reportProgress?: boolean): Observable<Array<BreakReason>>;
    public breakReasonGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BreakReason>>>;
    public breakReasonGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BreakReason>>>;
    public breakReasonGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BreakReason>>('get', `${this.basePath}/break-reason`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find active break reason by ID
     * Find active break reason by ID. Required user permission: Backoffice view
     * @param id Specify to get break reason by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public breakReasonIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<BreakReason>;
    public breakReasonIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BreakReason>>;
    public breakReasonIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BreakReason>>;
    public breakReasonIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling breakReasonIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BreakReason>('get', `${this.basePath}/break-reason/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active categories
     * List active categories. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoryGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Category>>;
    public categoryGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Category>>>;
    public categoryGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Category>>>;
    public categoryGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Category>>('get', `${this.basePath}/category`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List countries
     * List countries. Required user permission: Backoffice view
     * @param active Search countries by active parameter. 1 - Active only, 0 - Inactive only, if absent then all countries.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countryGet(active?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Country>>;
    public countryGet(active?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Country>>>;
    public countryGet(active?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Country>>>;
    public countryGet(active?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', active as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Country>>('get', `${this.basePath}/country`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find country by ID
     * Find country by ID. Required user permission: Backoffice view
     * @param id Specify to get country by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countryIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Country>;
    public countryIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Country>>;
    public countryIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Country>>;
    public countryIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling countryIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Country>('get', `${this.basePath}/country/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List courses
     * List courses. Required user permission: Backoffice view
     * @param active Search courses with specified state. When param absent then both active and inactive courses returns
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public courseGet(active?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Course>>;
    public courseGet(active?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Course>>>;
    public courseGet(active?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Course>>>;
    public courseGet(active?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', active as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Course>>('get', `${this.basePath}/course`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find course by ID
     * Find course by ID. Required user permission: Backoffice view
     * @param id Specify to get course by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public courseIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Course>;
    public courseIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Course>>;
    public courseIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Course>>;
    public courseIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling courseIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Course>('get', `${this.basePath}/course/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active departments
     * List active departments. Required user permission: Backoffice view
     * @param salesAreaId Specify to request departments of a certain sales area only, if absent all available departments will return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public departmentInfoGet(salesAreaId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DepartmentInfo>>;
    public departmentInfoGet(salesAreaId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DepartmentInfo>>>;
    public departmentInfoGet(salesAreaId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DepartmentInfo>>>;
    public departmentInfoGet(salesAreaId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesAreaId !== undefined && salesAreaId !== null) {
            queryParameters = queryParameters.set('salesAreaId', salesAreaId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DepartmentInfo>>('get', `${this.basePath}/department-info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find active department by ID
     * Find active department by ID. Required user permission: Backoffice view
     * @param id Specify to get department by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public departmentInfoIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<DepartmentInfo>;
    public departmentInfoIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DepartmentInfo>>;
    public departmentInfoIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DepartmentInfo>>;
    public departmentInfoIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling departmentInfoIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DepartmentInfo>('get', `${this.basePath}/department-info/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List discount groups
     * List discount groups. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discountGroupGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DiscountGroup>>;
    public discountGroupGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DiscountGroup>>>;
    public discountGroupGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DiscountGroup>>>;
    public discountGroupGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DiscountGroup>>('get', `${this.basePath}/discount-group`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active discount reasons
     * List active discount reasons. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discountReasonGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DiscountReason>>;
    public discountReasonGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DiscountReason>>>;
    public discountReasonGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DiscountReason>>>;
    public discountReasonGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DiscountReason>>('get', `${this.basePath}/discount-reason`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active groups
     * List active groups. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupInfoGet(observe?: 'body', reportProgress?: boolean): Observable<Array<GroupInfo>>;
    public groupInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GroupInfo>>>;
    public groupInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GroupInfo>>>;
    public groupInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GroupInfo>>('get', `${this.basePath}/group-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active options
     * List active options. Required user permission: Backoffice view
     * @param salesAreaId Specify to request options of a certain sales area only, if absent all available options will return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public optionInfoGet(salesAreaId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<OptionInfo>>;
    public optionInfoGet(salesAreaId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OptionInfo>>>;
    public optionInfoGet(salesAreaId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OptionInfo>>>;
    public optionInfoGet(salesAreaId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesAreaId !== undefined && salesAreaId !== null) {
            queryParameters = queryParameters.set('salesAreaId', salesAreaId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OptionInfo>>('get', `${this.basePath}/option-info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find active option by ID
     * Find active option by ID. Required user permission: Backoffice view
     * @param id Specify to get option by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public optionInfoIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<OptionInfo>;
    public optionInfoIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OptionInfo>>;
    public optionInfoIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OptionInfo>>;
    public optionInfoIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling optionInfoIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OptionInfo>('get', `${this.basePath}/option-info/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active payments
     * List active payments. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentInfoGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentInfo>>;
    public paymentInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentInfo>>>;
    public paymentInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentInfo>>>;
    public paymentInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PaymentInfo>>('get', `${this.basePath}/payment-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active prices
     * List active prices. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceInfoGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PriceInfo>>;
    public priceInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PriceInfo>>>;
    public priceInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PriceInfo>>>;
    public priceInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PriceInfo>>('get', `${this.basePath}/price-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active printers
     * List active printers. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public printerGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Printer>>;
    public printerGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Printer>>>;
    public printerGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Printer>>>;
    public printerGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Printer>>('get', `${this.basePath}/printer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active sales areas
     * List active sales areas. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salesAreaInfoGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SalesAreaInfo>>;
    public salesAreaInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesAreaInfo>>>;
    public salesAreaInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesAreaInfo>>>;
    public salesAreaInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SalesAreaInfo>>('get', `${this.basePath}/sales-area-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List size modifiers
     * List size modifiers. Required user permission: Backoffice view
     * @param active Search size modifiers with specified state. When param absent then both active and inactive size modifiers returns
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sizeModifierGet(active?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<SizeModifier>>;
    public sizeModifierGet(active?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SizeModifier>>>;
    public sizeModifierGet(active?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SizeModifier>>>;
    public sizeModifierGet(active?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', active as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SizeModifier>>('get', `${this.basePath}/size-modifier`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List smart cards
     * List smart cards. Required user permission: Backoffice view
     * @param uid Specify to get smart cards by UID
     * @param smartCardGroupId Specify to get smart cards by smart card group ID
     * @param active Search smart card with specified state. When param absent then both active and inactive smart card returns
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public smartCardGet(uid?: string, smartCardGroupId?: number, active?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<SmartCard>>;
    public smartCardGet(uid?: string, smartCardGroupId?: number, active?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SmartCard>>>;
    public smartCardGet(uid?: string, smartCardGroupId?: number, active?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SmartCard>>>;
    public smartCardGet(uid?: string, smartCardGroupId?: number, active?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (uid !== undefined && uid !== null) {
            queryParameters = queryParameters.set('uid', uid as any);
        }
        if (smartCardGroupId !== undefined && smartCardGroupId !== null) {
            queryParameters = queryParameters.set('smartCardGroupId', smartCardGroupId as any);
        }
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', active as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SmartCard>>('get', `${this.basePath}/smart-card`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List smart card groups
     * List smart card groups. Required user permission: Backoffice view
     * @param active Search smart card group with specified state. When param absent then both active and inactive smart card groups returns
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public smartCardGroupGet(active?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<SmartCardGroup>>;
    public smartCardGroupGet(active?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SmartCardGroup>>>;
    public smartCardGroupGet(active?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SmartCardGroup>>>;
    public smartCardGroupGet(active?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', active as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SmartCardGroup>>('get', `${this.basePath}/smart-card-group`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find smart card group by ID
     * Find smart card group by ID. Required user permission: Backoffice view
     * @param id Specify to get smart card group by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public smartCardGroupIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<SmartCardGroup>;
    public smartCardGroupIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartCardGroup>>;
    public smartCardGroupIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartCardGroup>>;
    public smartCardGroupIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling smartCardGroupIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartCardGroup>('get', `${this.basePath}/smart-card-group/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find smart card by ID
     * Find smart card by ID. Required user permission: Backoffice view
     * @param id Specify to get smart card by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public smartCardIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<SmartCard>;
    public smartCardIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmartCard>>;
    public smartCardIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmartCard>>;
    public smartCardIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling smartCardIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmartCard>('get', `${this.basePath}/smart-card/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List suppliers
     * List suppliers. Required user permission: Backoffice view
     * @param active Search suppliers with specified state. When param absent then both active and inactive suppliers returns
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public supplierGet(active?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Supplier>;
    public supplierGet(active?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Supplier>>;
    public supplierGet(active?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Supplier>>;
    public supplierGet(active?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', active as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Supplier>('get', `${this.basePath}/supplier`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List active users
     * List active users. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userInfoGet(observe?: 'body', reportProgress?: boolean): Observable<Array<UserInfo>>;
    public userInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserInfo>>>;
    public userInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserInfo>>>;
    public userInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserInfo>>('get', `${this.basePath}/user-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find active user by ID
     * Find active user by ID. Required user permission: Backoffice view
     * @param id Specify to get user by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userInfoIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<UserInfo>;
    public userInfoIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInfo>>;
    public userInfoIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInfo>>;
    public userInfoIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling userInfoIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserInfo>('get', `${this.basePath}/user-info/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List void reasons
     * List void reasons. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voidReasonGet(observe?: 'body', reportProgress?: boolean): Observable<Array<VoidReason>>;
    public voidReasonGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VoidReason>>>;
    public voidReasonGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VoidReason>>>;
    public voidReasonGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VoidReason>>('get', `${this.basePath}/void-reason`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find voucher by barcode
     * Find voucher by barcode. Required user permission: Backoffice view
     * @param barcode Specify to get voucher by barcode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voucherBarcodeBarcodeGet(barcode: string, observe?: 'body', reportProgress?: boolean): Observable<Voucher>;
    public voucherBarcodeBarcodeGet(barcode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Voucher>>;
    public voucherBarcodeBarcodeGet(barcode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Voucher>>;
    public voucherBarcodeBarcodeGet(barcode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (barcode === null || barcode === undefined) {
            throw new Error('Required parameter barcode was null or undefined when calling voucherBarcodeBarcodeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Voucher>('get', `${this.basePath}/voucher/barcode/${encodeURIComponent(String(barcode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * List vouchers
     * List vouchers. Required user permission: Backoffice view
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voucherGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Voucher>>;
    public voucherGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Voucher>>>;
    public voucherGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Voucher>>>;
    public voucherGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Voucher>>('get', `${this.basePath}/voucher`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Find voucher by ID
     * Find voucher by ID. Required user permission: Backoffice view
     * @param id Specify to get voucher by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voucherIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Voucher>;
    public voucherIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Voucher>>;
    public voucherIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Voucher>>;
    public voucherIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling voucherIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys.AppToken) {
            headers = headers.set('AppToken', this.configuration.apiKeys.AppToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Voucher>('get', `${this.basePath}/voucher/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
