import { Component, OnInit } from '@angular/core';
import { HttpRequest, HttpClient } from '@angular/common/http';

import { DataSnapshot } from '@angular/fire/compat/database/interfaces';


import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { Auth } from 'src/app/auth/auth';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements OnInit {



  constructor(private firebase: StaffAppDatabase, public auth: Auth, public bI: BarInfo) {

  }

  ngOnInit(){



  }

  myFilter(list: string[]) {
    return list.filter((value) => {
      return !this.bI.onlyDuncan.get(value) || this.auth.accessUser.access >= 7;
    });
  }





}
