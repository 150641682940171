<ng-template #confirmation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="color: black">
      Actions for week {{ selectedWeek[0].date | date : "LLLL dd" }} to
      {{ selectedWeek[6].date | date : "LLLL dd" }}
    </h4>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('savenotify')"
    >
      Save, Send Notification and Quit
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('save')"
    >
      Save and Quit
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('quit')"
    >
      Quit
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('cancel')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #BlockedConfirmation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="color: black">
      Are you sure you want to block {{ selectedStaff | name : "full" }} from
      using their wallet to drink?
    </h4>
  </div>

  <div class="modal-body">
    <label style="color: black">Reason:</label>
    <input [(ngModel)]="blockingReason" type="text" />
  </div>

  <div class="modal-footer">
    <button
      [disabled]="!blockingReason"
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('block')"
    >
      Yes (Block)
    </button>

    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('cancel')"
    >
      Cancel (Dont Block)
    </button>
  </div>
</ng-template>

<ng-template #staffModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="color: black">
      Staff {{ selectedStaff | name : "full" }}
    </h4>
  </div>

  <div class="modal-content">
    <label style="color: black">Target Days Per Week:</label>
    <input
      [(ngModel)]="selectedStaff.target"
      type="number"
      min="0"
      max="7"
      step="1"
    />

    <br />
    <label style="color: black">Can Use Wallet To Drink:</label>
    <app-input
      [model]="
        selectedStaff.isBlockedFromWallet == undefined ||
        !selectedStaff.isBlockedFromWallet
          ? true
          : false
      "
      type="checkbox"
      style="color: black"
      [titleClass]="'edit-desc-hidden'"
      (changed)="toggleWalletBlock(BlockedConfirmation)"
    ></app-input>
    <span style="color: black" *ngIf="selectedStaff.isBlockedFromWallet">
      Current Reason: {{ selectedStaff.blockedReason }}
    </span>

    <br />
    <label style="color: black">Role:</label>
    <select [(ngModel)]="selectedStaff.role" style="color: black">
      <option *ngFor="let r of roles; let i = index" [value]="i">
        {{ r }}
      </option>
    </select>

    <label style="color: black">Custom User Color:</label>
    <input [(ngModel)]="selectedStaff.userColor" type="color" />
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('save')"
    >
      Save and Quit
    </button>

    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('cancel')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-body">
    <h2 style="color: black">{{ reason.name | name : "full" }}</h2>
    <div style="color: black">{{ reason.reason }}</div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Close
    </button>
  </div>
</ng-template>

<div class="flex-rows">
  <div class="flex-columns" style="width: 250px">
    <h2>Staff</h2>
    <div
      *ngFor="let s of staff"
      (click)="openStaffModal(staffModal, s)"
      style="
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        align-items: center;
        margin: 5px 0px;
        justify-content: space-between;
        width: 100%;
      "
    >
      <div
        style="width: 30px; height: 30px; border-radius: 50px"
        [style.background-color]="s.userColor"
      ></div>
      <div style="width: 10px"></div>
      {{ s | name }}
      <div style="flex-grow: 3"></div>
    </div>
  </div>

  <div class="flex-columns">
    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
      "
    >
      <button (click)="onPreviousMonth(confirmation)" style="width: 100px">
        Previous
      </button>
      <div>
        {{ calendarCreator.getMonthName(monthNumber) }} &nbsp; {{ year }}
      </div>
      <button (click)="onNextMonth(confirmation)" style="width: 100px">
        Next
      </button>
    </div>

    <br />

    <div class="calendar">
      <div>
        <div *ngFor="let weekDay of weekDaysName" class="day">
          {{ weekDay }}
        </div>
      </div>
      <div [ngClass]="{ 'selected-week': isSelectedDay(monthDays[0]) }">
        <div
          *ngFor="let day of monthDays.slice(0, 7); let i = index"
          class="day"
          (click)="day.number && dayClicked(confirmation, day)"
        >
          <div>
            {{ day.number }}
          </div>
          <div>
            <div
              *ngFor="
                let staff of getScheduled(day.number, day.monthIndex, day.year)
              "
              style="width: 14px; height: 14px; border-radius: 50%"
              [style.background-color]="staff.userColor"
            ></div>
          </div>
        </div>
      </div>
      <div [ngClass]="{ 'selected-week': isSelectedDay(monthDays[7]) }">
        <div
          *ngFor="let day of monthDays.slice(7, 14); let i = index"
          class="day"
          (click)="dayClicked(confirmation, day)"
        >
          <div>
            {{ day.number }}
          </div>
          <div>
            <div
              *ngFor="
                let staff of getScheduled(day.number, day.monthIndex, day.year)
              "
              style="width: 14px; height: 14px; border-radius: 50%"
              [style.background-color]="staff.userColor"
            ></div>
          </div>
        </div>
      </div>
      <div [ngClass]="{ 'selected-week': isSelectedDay(monthDays[14]) }">
        <div
          *ngFor="let day of monthDays.slice(14, 21); let i = index"
          class="day"
          (click)="dayClicked(confirmation, day)"
        >
          <div>
            {{ day.number }}
          </div>
          <div>
            <div
              *ngFor="
                let staff of getScheduled(day.number, day.monthIndex, day.year)
              "
              style="width: 14px; height: 14px; border-radius: 50%"
              [style.background-color]="staff.userColor"
            ></div>
          </div>
        </div>
      </div>
      <div [ngClass]="{ 'selected-week': isSelectedDay(monthDays[21]) }">
        <div
          *ngFor="let day of monthDays.slice(21, 28); let i = index"
          class="day"
          (click)="dayClicked(confirmation, day)"
        >
          <div>
            {{ day.number }}
          </div>
          <div>
            <div
              *ngFor="
                let staff of getScheduled(day.number, day.monthIndex, day.year)
              "
              style="width: 14px; height: 14px; border-radius: 50%"
              [style.background-color]="staff.userColor"
            ></div>
          </div>
        </div>
      </div>

      <div [ngClass]="{ 'selected-week': isSelectedDay(monthDays[28]) }">
        <div
          *ngFor="let day of monthDays.slice(28, 35); let i = index"
          class="day"
          (click)="dayClicked(confirmation, day)"
        >
          <div>
            {{ day.number }}
          </div>
          <div>
            <div
              *ngFor="
                let staff of getScheduled(day.number, day.monthIndex, day.year)
              "
              style="width: 14px; height: 14px; border-radius: 50%"
              [style.background-color]="staff.userColor"
            ></div>
          </div>
        </div>
      </div>

      <div [ngClass]="{ 'selected-week': isSelectedDay(monthDays[35]) }">
        <div
          *ngFor="let day of monthDays.slice(35, 42); let i = index"
          class="day"
          (click)="dayClicked(confirmation, day)"
        >
          <div>
            {{ day.number }}
          </div>
          <div>
            <div
              *ngFor="
                let staff of getScheduled(day.number, day.monthIndex, day.year)
              "
              style="width: 14px; height: 14px; border-radius: 50%"
              [style.background-color]="staff.userColor"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-columns" *ngIf="selectedWeek">
    <button (click)="selectedWeek = null" *ngIf="!edits">X</button>
    <button (click)="cancel(confirmation)" *ngIf="edits">
      Save and/or Quit
    </button>
    {{ selectedWeek[0].date | date : "LLLL" }}

    <div class="week-requests">
      <div class="week-staff">
        <div class="weekname" style="width: 260px">
          <div style="font-size: x-small; width: 140px">Name</div>
          <div style="font-size: x-small">Target</div>
          <div style="font-size: x-small">Reason</div>
        </div>

        <div
          *ngFor="let request of selectedWeek; let i = index"
          [ngClass]="{ dayofweek: i === selectedDay }"
          class="weekday"
        >
          {{ request.date | date : "EEEE" }} <br />
          {{ request.date | date : "dd" }}
        </div>
      </div>
      <div class="week-staff" *ngFor="let st of staff">
        <div class="weekname" style="width: 260px">
          <div
            style="
              white-space: nowrap;
              word-wrap: unset;
              overflow: hidden;
              width: 140px;
            "
          >
            {{ st | name : "short" }}
          </div>
          <div>
            {{ selectedWeek[0].requests[st.uid].target }} /
            {{ selectedWeek[0].requests[st.uid].mainTarget }}
          </div>

          <div *ngIf="selectedWeek[0].requests[st.uid].reason == ''"></div>
          <button
            *ngIf="selectedWeek[0].requests[st.uid].reason != ''"
            (click)="
              openReason(
                content1,
                st.uid,
                selectedWeek[0].requests[st.uid].reason
              )
            "
          >
            >
          </button>
          <div></div>
        </div>

        <div
          *ngFor="let d of WEEKDAYS; let i = index"
          [ngClass]="{ dayofweek: i === selectedDay }"
          class="weekday"
        >
          <div
            *ngIf="selectedWeek[i].requests && selectedWeek[i].requests[st.uid]"
            class="day-request"
            [ngClass]="{
              red: selectedWeek[i].requests[st.uid].request === 0,
              orange: selectedWeek[i].requests[st.uid].request === 1,
              green: selectedWeek[i].requests[st.uid].request === 2,
              gray: selectedWeek[i].requests[st.uid].request === -1,
              today:
                selectedWeek[i].requests[st.uid].request === 2 &&
                i === selectedDay
            }"
            (click)="staffRequestClicked(st, selectedWeek[i])"
          >
            <div
              *ngIf="contains(selectedWeek[i].schedule.staff, st.uid)"
              class="working"
            >
              w
            </div>
          </div>
          <div *ngIf="contains(selectedWeek[i].schedule.staff, st.uid)">
            <select
              [disabled]="selectedWeek[i].future"
              [ngModel]="getStartTime(selectedWeek[i].schedule, st.uid)"
              (change)="timeChanged(selectedWeek[i].schedule, st.uid, $event)"
            >
              <option value="1">1pm</option>
              <option value="2">2pm</option>
              <option value="3">3pm</option>
              <option value="4">4pm</option>
              <option value="5">5pm</option>
              <option value="6">6pm</option>
              <option value="7">7pm</option>
              <option value="8">8pm</option>
              <option value="9">9pm</option>
              <option value="10">10pm</option>
              <option value="11">11pm</option>
              <option value="12">12am</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row">
      <div class="legend">
        <div>
          <div class="day-request red"></div>
          <div><b>Cannot </b> &nbsp;work this day</div>
        </div>

        <div>
          <div class="day-request orange"></div>
          <div><b>Maybe </b>&nbsp; a good day</div>
        </div>
        <div>
          <div class="day-request green"></div>
          <div><b>Perfect </b> &nbsp;day to work</div>
        </div>

        <div>
          <div class="day-request gray"></div>
          <div>No preference given</div>
        </div>
        <div>
          <div class="day-request gray">
            <div class="working">w</div>
          </div>
          <div>Scheduled to work (clickable)</div>
        </div>
      </div>

      <div class="legend changes-box" style="">
        <div *ngFor="let change of changes">
          {{ change.userName }} - {{ change.dateString }}
        </div>
      </div>
    </div>
  </div>
</div>
