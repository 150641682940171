import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-id-circle",
  templateUrl: "./id-circle.component.html",
  styleUrls: ["./id-circle.component.scss"],
})
export class IdCircleComponent implements OnInit {
  @Input("id") id: string;
  @Input("selected") selected: boolean = true;
  backgroundColor = "red";
  color: string = "white";
  constructor() {}

  ngOnInit(): void {
    this.backgroundColor = this.idToColor(this.id);
    this.color = this.reverseColor(this.backgroundColor);
  }

  idToColor(id: string) {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }

  reverseColor(color: string) {
    //from a hex value to black or white whichever has more contrast
    let r = parseInt(color.substr(1, 2), 16);
    let g = parseInt(color.substr(3, 2), 16);
    let b = parseInt(color.substr(5, 2), 16);
    let yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  }
}
