export class VipInformation {
  key: string = "";
  password: string = "";
  secret: string = "";
  username: string = "";
  website: string = "";
  customerId: string = "";
  agendaId: string = "";
  appointmentType: string = "";

  constructor() {}

  verify() {
    return true;
  }

  fixVariables() {
    // inputs are strings, cast to number if needed
    // this.number = Number(this.number);
  }
}
