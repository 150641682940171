import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "src/app/auth/auth";
import { CalendarCreator } from "src/app/Classes/mycal/calendarCreator.service";
import { CalendarCreatorDay } from "src/app/Classes/mycal/CalendarCreatorDay";
import { Roles } from "src/app/Enums/roles.enum";

import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import {
  ChangeRecord,
  StaffAppDatabase,
} from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-schedule2",
  templateUrl: "./schedule2.component.html",
  styleUrls: ["./schedule2.component.css"],
})
export class Schedule2Component implements OnInit {
  schedule: any = {};
  requests: any = {};
  subs = [];
  staff = [];
  bar;
  selectedStaff;
  now = Date.now();
  today = new Date();
  addDays;
  WEEKDAYS = ["Sun", "Mon", "Tue", "Wen", "Thu", "Fri", "Sat"];
  changes: ChangeRecord[] = [];

  roles = new Roles().roles;

  selectedDay: number;
  selectedWeek: any[];

  public monthDays: CalendarCreatorDay[];

  public monthNumber: number = this.today.getMonth();
  public year: number = this.today.getFullYear();

  public weekDaysName = [];

  edits = false;

  reason: any;

  scheduleSub;
  planSub;
  currentDate: Date = new Date();
  constructor(
    private db: StaffAppDatabase,
    private bi: BarInfo,
    public calendarCreator: CalendarCreator,
    public modal: NgbModal,
    public auth: Auth
  ) {}

  openReason(content, uid, reason) {
    this.reason = {
      name: this.staff.filter((a) => a.uid === uid)[0],
      reason: reason,
    };
    //console.log(this.reason)

    this.modal
      .open(content, { size: "lg" })
      .result.then((a) => {
        this.reason = null;
      })
      .catch((e) => {
        this.reason = null;
      });
  }
  blockingReason: string = "";
  async toggleWalletBlock(model) {
    var st = this.selectedStaff;
    this.blockingReason = "";
    if (st.isBlockedFromWallet) {
      //unblocking
      var e = await this.db
        .setStaffVariable("isBlockedFromWallet", st, false)
        .then((a) => null)
        .catch((e) => {
          console.error(e);
          return e;
        });
      await this.db
        .setStaffVariable("blockedReason", st, "")
        .then((a) => null)
        .catch((e) => {
          console.error(e);
          return e;
        });
      await this.db.setWalletBlocked(
        this.bar,
        this.selectedStaff,
        this.auth.accessUser,
        false,
        "unblocking"
      );
      if (e) {
        alert("failed to change wallet block: " + e);
        this.selectedStaff.isBlockedFromWallet = true;
      } else {
        this.selectedStaff.isBlockedFromWallet = false;
      }
    } else {
      this.modal
        .open(model, {
          size: "lg",
          fullscreen: true,
          backdrop: "static",
          backdropClass: "backdrop",
          windowClass: "second-modal",
        })
        .result.then(
          async (a) => {
            //block
            var e = await this.db
              .setStaffVariable("isBlockedFromWallet", st, true)
              .then((a) => null)
              .catch((e) => {
                console.error(e);
                return e;
              });
            await this.db
              .setStaffVariable("blockedReason", st, this.blockingReason)
              .then((a) => null)
              .catch((e) => {
                console.error(e);
                return e;
              });
            await this.db.setWalletBlocked(
              this.bar,
              this.selectedStaff,
              this.auth.accessUser,
              true,
              this.blockingReason
            );
            if (e) {
              if (e) {
                alert("failed to change wallet block: " + e);
                this.selectedStaff.isBlockedFromWallet = false;
              } else {
                this.staff.filter(
                  (a) => a.uid === st.uid
                )[0].isBlockedFromWallet = false;
              }
            } else {
              this.selectedStaff.isBlockedFromWallet = true;
            }
          },
          (b) => {
            //unblock
          }
        );
    }
  }
  ngOnInit(): void {
    this.createCalendar();

    this.subs.push(
      this.bi.barObserver.subscribe((value) => {
        if (value) {
          this.bar = value;
          this.getStaff(value);
        }
      })
    );
  }

  getStaff(value: string) {
    this.subs.push(
      this.db.getStaffAtBar(value).subscribe(async (values) => {
        var staff = [];
        values.forEach((s: any) => {
          if (this.selectedStaff && this.selectedStaff.uid === s.uid) {
            this.selectedStaff = s;
          }

          if (!s.userColor) {
            s.userColor = this.uidToColour(s.uid);
            console.log("default: " + s.userColor);
          } else {
            console.log("custom: " + s.userColor);
          }

          if (!Number.isInteger(s.target)) {
            s.target = 3;
          }

          if (!Number.isInteger(s.role)) {
            s.role = 0;
          }
          staff.push(s);
        });
        staff = staff.sort((a: any, b: any) => {
          if (a.target > b.target) {
            return -1;
          }

          if (a.target < b.target) {
            return 1;
          }

          return -a.role + b.role;
        });
        //console.log(staff);
        this.staff = [];
        this.staff = staff;
        await this.getSchedule();
        await this.getStaffSchedules();
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => {
      a.unsubscribe();
    });

    if (this.scheduleSub) {
      this.scheduleSub.unsubscribe();
    }

    if (this.planSub) {
      this.planSub.unsubscribe();
    }
  }

  /* --------------- Database ------------------- */

  async getSchedule() {
    if (!this.currentDate) {
      this.currentDate = new Date();
    }

    const previousMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() - 1,
      1,
      1,
      0,
      0,
      0
    );
    const nextMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() + 1,
      1,
      1,
      0,
      0,
      0
    );

    var values = await this.db.getSchedule(this.bar, this.currentDate);
    console.log(values);
    values = values.concat(await this.db.getSchedule(this.bar, previousMonth));
    console.log(values);

    values = values.concat(await this.db.getSchedule(this.bar, nextMonth));
    console.log("schedule", values);
    this.schedule = {};
    values.forEach((val: any) => {
      try {
        var staff = [];
        let d = new Date(val.date);
        //console.log(d)
        d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 1, 0, 0, 0);

        if (val.staff) {
          val.staff.forEach((key) => {
            staff.push({
              name: this.getName(key),
              uid: key,
              userColor: this.getColor(key),
            });
          });

          this.schedule[d.getTime() + ""] = {
            date: d.getTime(),
            staff: staff,
            times: val.times ? val.times : [],
          };
        }
      } catch (e) {}
    });
  }

  async getStaffSchedules() {
    if (!this.currentDate) {
      this.currentDate = new Date();
    }
    console.log("updating plan");
    const previousMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() - 1,
      1,
      1,
      0,
      0,
      0
    );
    const nextMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() + 1,
      1,
      1,
      0,
      0,
      0
    );
    this.requests = {};
    var values = (await this.db.getPlan(this.bar, this.currentDate)).map(
      (staff) => {
        const month = staff.payload.val();
        for (const [key, value] of Object.entries(month)) {
          if (!this.requests[staff.key]) this.requests[staff.key] = {};
          this.requests[staff.key][
            new Date(
              this.currentDate.getFullYear(),
              this.currentDate.getMonth(),
              Number(key),
              1,
              0,
              0,
              0
            ).getTime() + ""
          ] = value;
        }
      }
    );
    values = values.concat(
      (await this.db.getPlan(this.bar, previousMonth)).map((staff) => {
        const month = staff.payload.val();
        for (const [key, value] of Object.entries(month)) {
          if (!this.requests[staff.key]) this.requests[staff.key] = {};
          this.requests[staff.key][
            new Date(
              previousMonth.getFullYear(),
              previousMonth.getMonth(),
              Number(key),
              1,
              0,
              0,
              0
            ).getTime() + ""
          ] = value;
        }
      })
    );
    values = values.concat(
      (await this.db.getPlan(this.bar, nextMonth)).map((staff) => {
        const month = staff.payload.val();
        for (const [key, value] of Object.entries(month)) {
          if (!this.requests[staff.key]) this.requests[staff.key] = {};
          this.requests[staff.key][
            new Date(
              nextMonth.getFullYear(),
              nextMonth.getMonth(),
              Number(key),
              1,
              0,
              0,
              0
            ).getTime() + ""
          ] = value;
        }
      })
    );

    console.log("got requests", this.requests);
  }

  /* ---------- Listeners ------------------ */
  dayClicked(content, day) {
    if (this.selectedWeek) {
      this.deselectWeek(content).then((a) => {
        this.setSelectedWeek(day);
      });
    } else {
      this.setSelectedWeek(day);
    }
  }

  async setSelectedWeek(day) {
    this.selectedDay = (day.weekDayNumber + 6) % 7;

    const start2 = new Date(
      day.year,
      day.monthIndex,
      day.number - ((day.weekDayNumber + 6) % 7),
      1,
      0,
      0,
      0
    );

    const cutOffDate = new Date();
    const now = new Date();
    cutOffDate.setMonth(cutOffDate.getMonth() - 1);
    cutOffDate.setDate(20);

    if (start2.getTime() < cutOffDate.getTime()) {
      alert("You can't edit past schedules");
      return;
    }

    const looper2 = new Date(start2);
    this.selectedWeek = [{}, {}, {}];
    for (var i = 0; i < 7; i++) {
      var schedule: any = { staff: [], date: looper2.getTime() };
      if (this.schedule[looper2.getTime() + ""]) {
        schedule = this.schedule[looper2.getTime() + ""];
      }
      this.selectedWeek[i] = {
        index: i,
        date: looper2.getTime(),
        requests: {},
        schedule: Object.assign({}, schedule),
      };
      looper2.setDate(looper2.getDate() + 1);
    }

    for (var s of this.staff) {
      var target =
        s.target != undefined && s.target != null && s.target >= -1
          ? s.target
          : 3;
      const start = new Date(
        day.year,
        day.monthIndex,
        day.number - ((day.weekDayNumber + 6) % 7),
        1,
        0,
        0,
        0
      );

      const looper = new Date(start);
      const k = s.uid;
      for (var i = 0; i < 7; i++) {
        var requests: any = {
          mainTarget: target,
          target: target,
          request: -1,
          reason: "",
        };

        try {
          if (this.requests[k][looper.getTime() + ""]) {
            Object.assign(requests, this.requests[k][looper.getTime() + ""]);
          }
        } catch (e) {}

        this.selectedWeek[i].requests[k] = requests;
        looper.setDate(looper.getDate() + 1);
      }
    }

    this.changes = (
      await this.db.getShortRecords(
        this.bar,
        "schedule",
        this.selectedWeek[0].date
      )
    ).reverse();
  }

//possible needs or a.key
  contains(list: any[], item: any) {
    try {
      if (list.filter((a) => a.uid === item || a.key === item ).length) {
        return true;
      }
    } catch (e) {
      return false;
    }

    return false;
  }

  /* --------------- Schedule ------------------- */

  getName(key) {
    let temp = this.staff.filter((a) => a.uid === key);
    if (temp && temp.length === 1) {
      return temp[0].firstName + " " + temp[0].lastName;
    } else {
      return key;
    }
  }

  getColor(key) {
    let temp = this.staff.filter((a) => a.uid === key);
    if (temp && temp.length === 1) {
      return temp[0].userColor;
    } else {
      return this.uidToColour(key);
    }
  }

  /* -------------------- Calendar Stuff ---------------------- */

  getScheduled(day: number, month: number, year: number) {
    const d = new Date(year, month, day, 1, 0, 0, 0);
    if (
      this.schedule[d.getTime() + ""] &&
      this.schedule[d.getTime() + ""].staff
    ) {
      return this.schedule[d.getTime() + ""].staff;
    } else {
      return [];
    }
  }

  createCalendar() {
    this.setMonthDays(this.calendarCreator.getCurrentMonth());

    this.weekDaysName.push("Mo");
    this.weekDaysName.push("Tu");
    this.weekDaysName.push("We");
    this.weekDaysName.push("Th");
    this.weekDaysName.push("Fr");
    this.weekDaysName.push("Sa");
    this.weekDaysName.push("Su");
  }

  onNextMonth(content): void {
    if (this.selectedWeek) {
      this.deselectWeek(content)
        .then((a) => {
          this.monthNumber++;
          this.selectedWeek = null;
          if (this.monthNumber == 12) {
            this.monthNumber = 0;
            this.year++;
          }

          this.setMonth();
        })
        .catch((e) => {});
    } else {
      this.monthNumber++;

      if (this.monthNumber == 12) {
        this.monthNumber = 0;
        this.year++;
      }
      this.setMonth();
    }
  }

  onPreviousMonth(content): void {
    if (this.selectedWeek) {
      this.deselectWeek(content)
        .then((a) => {
          this.selectedWeek = null;
          this.monthNumber--;

          if (this.monthNumber < 0) {
            this.monthNumber = 11;
            this.year--;
          }

          this.setMonth();
        })
        .catch((e) => {});
    } else {
      this.monthNumber--;

      if (this.monthNumber < 0) {
        this.monthNumber = 11;
        this.year--;
      }

      this.setMonth();
    }
  }

  async setMonth() {
    this.currentDate = new Date(this.year, this.monthNumber, 1, 0, 0, 0, 0);

    await this.getSchedule();
    await this.getStaffSchedules();
    this.setMonthDays(
      this.calendarCreator.getMonth(this.monthNumber, this.year)
    );
  }

  private setMonthDays(days: CalendarCreatorDay[]): void {
    this.monthDays = days;
  }

  uidToColour(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }

  async deselectWeek(content) {
    return new Promise((res, rej) => {
      try {
        if (!this.edits) {
          return res("done");
        }
        this.modal.open(content, {}).result.then(
          (result) => {
            if (result === "save" || result === "savenotify") {
              alert(
                "Saving" + (result === "savenotify" ? " and notifying" : "")
              );
              this.saveWeek(result === "savenotify" ? true : false)
                .then((a) => {
                  this.selectedWeek = null;
                  this.selectedDay = null;
                  this.setMonth();
                  return res("done");
                })
                .catch((a) => {
                  this.selectedWeek = null;
                  this.selectedDay = null;
                  this.setMonth();
                  console.error(a);
                  alert("failed to save week!");
                  return rej(a);
                });
            } else {
              this.selectedWeek = null;
              this.selectedDay = null;
              return res("done");
            }
          },
          (reason) => {
            return rej(reason);
          }
        );
      } catch (e) {
        return rej(e);
      }
    });
  }

  click() {}

  cancel(content) {
    this.deselectWeek(content)
      .then((a) => {})
      .catch((e) => {});
  }

  staffRequestClicked(staff, day) {
    this.edits = true;

    this.toggleSchedule(staff, day.index);
  }

  toggleSchedule(s, index) {
    if (
      this.selectedWeek[index].schedule.staff.filter((a) => a.uid == s.uid)
        .length == 0
    ) {
      this.selectedWeek[index].schedule.staff.push({
        uid: s.uid,
        name: s.firstName + " " + s.lastName,
      });
      if (!this.selectedWeek[index].schedule.times) {
        console.log("creating times for " + s.uid);
        this.selectedWeek[index].schedule.times = [];
      }
      if (!this.contains(this.selectedWeek[index].schedule.times, s.uid)) {
        console.log("adding time for " + s.uid);
        this.selectedWeek[index].schedule.times.push({
          key: s.uid,
          startTime: 8,
        });
      } else {
        console.log("already contains");
      }

    } else {
      this.selectedWeek[index].schedule.staff = this.selectedWeek[
        index
      ].schedule.staff.filter((a) => a.uid != s.uid);
      this.selectedWeek[index].schedule.times = this.selectedWeek[index].schedule.times.filter(
        (a) => a.key != s.uid
      );
    }
  }

  getStartTime(week: any, staff) {
    // //console.log(week, staff);
    if (week && staff && week.times) {
      const index = week.times.findIndex((a) => a.key === staff);
      ////console.log(week, index, staff)
      if (index >= 0) {
        return week.times[index].startTime;
      }
    }
    return "";
  }

  timeChanged(week: any, staff, event) {
    //console.log(event, event.target.value);
    if (week && staff && event) {
      const index = week.times.findIndex((a) => a.key === staff);
      //console.log(index, staff, event.target.value)
      if (index >= 0) {
        this.edits = true;
        week.times[index].startTime = Number(event.target.value);
        //console.log(week.times)
      }
    }
  }

  async saveWeek(notify = false) {
    console.log("saving week", notify);
    if (notify) {
      const list = [
        ...new Set(
          [].concat.apply(
            [],
            this.selectedWeek.map((a) => a.schedule.staff.map((a) => a.uid))
          )
        ),
      ];

      const da2 = new Date(this.selectedWeek[0].date);
      //console.log(list,this.bar,da2.getTime());

      await this.db.triggerScheduleNotification(this.bar, da2.getTime(), list);
    }

    for (var i = 0; i < 7; i++) {
      const date = new Date(this.selectedWeek[i].date);
      console.log("saving date:" + date);

      try {
        //console.log('saving', date, this.selectedWeek[i].schedule.staff, this.selectedWeek[i].schedule.staff.map((a) => {return {key: a.uid, startTime: a.startTime}}));

        await this.db.saveSchedule(
          this.bar,
          {
            date: new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              1,
              0,
              0,
              0
            ).getTime(),

            staff: Object.values(
              this.selectedWeek[i].schedule.staff.map((a) => a.uid)
            ),

            times: this.selectedWeek[i].schedule.times,
          },
          this.auth.accessUser.uid
        );
      } catch (e) {
        console.error(e);
        alert(
          "couldnt save: " +
            date.getDate() +
            "-" +
            this.WEEKDAYS[i] +
            "\n" +
            e.toString()
        );
      }
    }

    try {
      await this.db.changedRecordOld(
        this.db.bar,
        this.auth.accessUser.uid,
        this.selectedWeek,
        "schedule",
        this.selectedWeek[0].date
      );
    } catch (e) {
      console.error(e);
    }
    this.setMonth();
  }

  isSelectedDay(day) {
    if (!this.selectedWeek) {
      return false;
    }

    for (var i = 0; i < 7; i++) {
      try {
        const date = new Date(
          day.year,
          day.monthIndex,
          day.number + i,
          0,
          0,
          0,
          0
        );
        if (
          this.selectedWeek[i] &&
          this.sameDay(new Date(this.selectedWeek[i].date), date)
        ) {
          return true;
        }
      } catch (e) {}
    }

    return false;
  }

  sameMonth(a: any, b: any) {
    return new Date(a).getMonth() === new Date(b).getMonth();
  }

  sameDay(a: Date, b: Date) {
    return (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    );
  }

  openStaffModal(staffmodal, s) {
    this.selectedStaff = Object.assign({}, s);
    this.modal.open(staffmodal, { size: "lg" }).result.then(
      (suc) => {
        if (
          !Number.isInteger(this.selectedStaff.target) &&
          !Number.isInteger(this.selectedStaff.role)
        ) {
          this.selectedStaff = {};
          return;
        }

        try {
          const target = Number(this.selectedStaff.target);

          if (
            Number.isInteger(target) &&
            target != (Number.isInteger(s.target) ? s.target : -1)
          ) {
            //console.log("saving target", Number(target));
            this.db
              .setStaffTarget(this.selectedStaff, Number(target))
              .then()
              .catch((e) => {
                alert("Failed to save target: " + e);
              });
            //save Target
          }
        } catch (e) {}

        try {
          const role = Number(this.selectedStaff.role);

          if (
            Number.isInteger(role) &&
            role != (Number.isInteger(s.role) ? s.role : -1)
          ) {
            //console.log("saving role", Number(role));
            this.db
              .setStaffRole(this.selectedStaff, Number(role))
              .then()
              .catch((e) => {
                alert("Failed to save target: " + e);
              });
            //save Role
          }
        } catch (e) {}

        try {
          const color = this.selectedStaff.userColor;
          if (color) {
            this.db.setStaffVariable("userColor", this.selectedStaff, color);
          }
        } catch (e) {}

        this.selectedStaff = {};
      },
      (err) => {
        this.selectedStaff = {};
      }
    );
  }
}
