<form *ngIf="catalogue && catalogue.length">
  <div class="label-input-row">
    <label
      for="table-complete-search"
      class="col-xs-3 col-sm-auto col-form-label"
      >Search:</label
    >
    <div class="col-xs-3 col-sm-auto">
      <input
        id="table-complete-search"
        type="text"
        class="plain-input"
        name="searchTerm"
        [(ngModel)]="service.searchTerm"
      />
    </div>
  </div>
  <div class="label-input-row" style="margin-bottom: 2px">
    <input
      class="datchev"
      type="date"
      [ngModel]="date | date : 'yyyy-MM-dd'"
      min="2023-01-01"
      (ngModelChange)="dateChanged($event)"
      [ngModelOptions]="{ standalone: true }"
    />

    <app-input
      class="datchev"
      type="select"
      [list]="barnames"
      listHideAll="true"
      [model]="bar"
      (changed)="this.bar = $event; this.getData()"
    ></app-input>

    <button
      class="datchev"
      [ngClass]="{ selected: searchType.type === 'all' }"
      (click)="searchType.type = 'all'"
    >
      All
    </button>
    <button
      class="datchev"
      [ngClass]="{ selected: searchType.type === 'year' }"
      (click)="searchType.type = 'year'"
    >
      {{ searchType.type != "year" ? "Year" : searchType.year }}
    </button>

    <button
      class="datchev"
      [ngClass]="{ selected: searchType.type === 'month' }"
      (click)="searchType.type = 'month'"
    >
      {{ searchType.type != "month" ? "Month" : (searchType.month | month) }}
    </button>
    <button class="datchev green" (click)="search()">Go</button>
    <button class="datchev green" (click)="getStats()">Graph</button>

    <span class="col col-form-label" *ngIf="service.loading$ | async"
      >Loading...</span
    >
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" sortable="name" (sort)="onSort($event)">Name</th>
        <th scope="col" sortable="artist" (sort)="onSort($event)">Artist</th>
        <th scope="col" sortable="sungSearch" (sort)="onSort($event)">Sung</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let country of service.countries$ | async">
        <td class="has-details">
          <ngb-highlight
            [result]="country.name"
            [term]="service.searchTerm"
          ></ngb-highlight>
          <span class="details">
            <div>Key: {{ country.key }}</div>
            <div>
              <div *ngFor="let year of country.sungd | keyvalue">
                <div>Year: {{ year.key }}</div>
                <div *ngFor="let month of year.value; let i = index">
                  &nbsp; {{ i | month }}: &nbsp; {{ month || 0 }}
                </div>
              </div>
            </div>
          </span>
        </td>
        <td>
          <ngb-highlight
            [result]="country.artist"
            [term]="service.searchTerm"
          ></ngb-highlight>
        </td>

        <td>
          <ngb-highlight
            [result]="country.sungSearch | number"
            [term]="service.searchTerm"
          ></ngb-highlight>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination
      [collectionSize]="(service.total$ | async)!"
      [(page)]="service.page"
      [pageSize]="service.pageSize"
      [boundaryLinks]="true"
      [maxSize]="10"
    >
    </ngb-pagination>
  </div>
</form>

<div *ngIf="showGraph" class="stat-graph">
  <div
    class="label-input-row"
    style="position: absolute; right: 20px; top: 20px"
  >
    <button
      class="datchev"
      (click)="getStats(true, 'artists')"
      [ngClass]="{ selected: graphType === 'artists' }"
    >
      Artists
    </button>
    <button
      class="datchev"
      (click)="getStats(true, 'songs')"
      [ngClass]="{ selected: graphType === 'songs' }"
    >
      Songs
    </button>

    <button (click)="showGraph = false" class="close-button">X</button>
  </div>
  <ngx-charts-bar-vertical-stacked
    [view]="view"
    [scheme]="colorScheme"
    [legend]="legend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="timeline"
    [results]="multi"
  >
  </ngx-charts-bar-vertical-stacked>
</div>
