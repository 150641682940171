import { Component, OnInit } from "@angular/core";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { InvoiceData } from "../../Instellingen/invoice-splitter/invoice-splitter.component";

@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.css"],
})
export class EmailComponent implements OnInit {
  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public db: StaffAppDatabase
  ) {}

  ngOnInit(): void {}

  async sendEmail() {
    this.loading.show();
    var i = new InvoiceData();
    i.email = "duncansaunders1997@gmail.com";
    i.type = "receipt";

    await this.db
      .sendEmail(i)
      .then(([value, error]) => {
        if (value) {
          console.log(value);
          this.loading.hide();
          this.alert.nextEmit(AlertsService.good("Email sent"));
        } else {
          this.loading.hide();
          this.alert.nextEmit(AlertsService.error("Email failed to send"));
          console.log(error);
        }
      })
      .catch((e) => {
        this.loading.hide();
        this.alert.nextEmit(AlertsService.error("Email failed to send"));
        console.log(e);
      });
  }
}
