<!--<div class="flex-columns" *ngIf="barData">
  <app-input
    [editable]="false"
    type="checkbox"
    (changed)="getLiveChanged()"
    [model]="getLive"
    name="Get live data every 15 minutes"
  ></app-input>
  <app-input
    type="select"
    name="Select Bar:"
    (changed)="currentBar = $event"
    [model]="currentBar"
    [list]="bars"
    listHideAll="true"
  >
  </app-input>

  <button *ngIf="currentBar" (click)="get()">Get</button>

  <div
    *ngIf="
      currentBar &&
      currentDayData &&
      currentDate == currentDayData.date &&
      currentDayData.bar == currentBar
    "
  >
    <div>
      <label>Current Score</label>
    </div>
    <div>
      <app-input
        type="number"
        name="Last Night"
        (changed)="saveValue('yesterday', $event)"
        [model]="currentDayData.yesterday"
      ></app-input>
    </div>
    <div>
      <app-input
        type="number"
        name="Totaal Beers"
        (changed)="saveValue('total', $event)"
        [model]="currentDayData.total"
      ></app-input>
    </div>
    <div>
      <app-input
        type="number"
        name="Score"
        (changed)="saveValue('score', $event)"
        [model]="currentDayData.score"
      ></app-input>
    </div>
    <div>
      <app-input
        type="number"
        name="Euros"
        (changed)="saveValue('euros', $event)"
        [model]="currentDayData.euros"
      ></app-input>
    </div>

    <div>
      <app-input
        type="number"
        name="Drink Percentage (0-100)"
        min="0"
        max="100"
        step="0.1"
        (changed)="saveValue('shotPercentage', $event)"
        [model]="currentDayData.shotPercentage"
      ></app-input>
    </div>

    <div>
      <app-input
        type="number"
        name="Omzet Percentage (0-100)"
        (changed)="saveValue('omzetPercentage', $event)"
        [model]="currentDayData.omzetPercentage"
      ></app-input>
    </div>
    <button (click)="save()">Save</button>
  </div>
</div>-->

<table class="flex-columns" #resultsTable>
  <button (click)="toExcel()">EXCEL</button>
  <tr class="date-row">
    <th></th>
    <th>Amsterdam</th>
    <th>Groningen</th>
    <th>Tilburg</th>
  </tr>

  <tr class="date-row">
    <td>Totaal</td>
    <td>{{ barData.Amsterdam.total }}</td>
    <td>{{ barData.Groningen.total }}</td>
    <td>{{ barData.Tilburg.total }}</td>
  </tr>

  <tr class="date-row">
    <td>Live</td>
    <td>{{ liveShots.Amsterdam }}</td>
    <td>{{ liveShots.Groningen }}</td>
    <td>{{ liveShots.Tilburg }}</td>
  </tr>

  <tr class="date-row">
    <td>Totaal Euros</td>
    <td>{{ barData.Amsterdam.totalEuros | currency : "EUR" }}</td>
    <td>{{ barData.Groningen.totalEuros | currency : "EUR" }}</td>
    <td>{{ barData.Tilburg.totalEuros | currency : "EUR" }}</td>
  </tr>

  <tr
    class="date-row"
    style="border-bottom: 1px solid black; margin-bottom: 5px"
  >
    <td>Date</td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tbody class="table-container">
    <tr *ngFor="let date of dates; let i = index" class="date-row">
      <td>{{ date | date : "EEE, MMM d y" }}</td>
      <td [tooltipClass]="'custom-tooltip'">
        {{ barData.Amsterdam.days[i].shots }}
      </td>
      <td [tooltipClass]="'custom-tooltip'">
        {{ barData.Groningen.days[i].shots }}
      </td>
      <td [tooltipClass]="'custom-tooltip'">
        {{ barData.Tilburg.days[i].shots }}
      </td>
    </tr>
  </tbody>
  <tr style="border-top: 1px solid black; margin-top: 5px" class="date-row">
    <td>Total</td>
    <td>
      {{ barData.Amsterdam.shots }}
    </td>
    <td>
      {{ barData.Groningen.shots }}
    </td>
    <td>
      {{ barData.Tilburg.shots }}
    </td>
  </tr>
</table>
