import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "frequency",
})
export class FrequencyPipe implements PipeTransform {
  transform(value: number): unknown {
    if (isNaN(value)) return "n/a";
    switch (value) {
      case 0:
        return "1 Week";
      case 1:
        return "2 Weeks";
      case 2:
        return "3 Weeks";
      case 3:
        return "1 Month";
      case 4:
        return "2 Months";
      case 5:
        return "6 Months";
      default:
        return "n/a";
    }
  }
}
