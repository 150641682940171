export class CreditInformation {
    months : number[];
    rate: number;
    targetRate: number;
    targetHours: number;
    newStaff: number;

    constructor(){
        this.rate = 0;
        this.targetRate = 0;
        this.targetHours = 0;
        this.newStaff = 0;
    }
}
