import { merge, values } from "lodash";

export class Discount {
  dateTime = 0;
  drinks: number[];
  id: string = '';
  reason: string = ''
  totalAmount: number = 0;
  quantity: number = 1;

  constructor(disc) {
    merge(this,disc);
  }



}
