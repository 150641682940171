import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleaning-modal',
  templateUrl: './cleaning-modal.component.html',
  styleUrls: ['./cleaning-modal.component.css']
})
export class CleaningModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
