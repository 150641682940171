import { Flag } from "./flag";

export class UserFlag {
  // flags is a object with keys as the date of the flag

  flags: { [key: string]: Flag } = {};
  lastFlag: number = 0;
  user: any = {};
  key: string = "";
  lastFlagObj: Flag = new Flag();
  getLastFlag(): Flag {
    console.log(this.flags, this.lastFlag);
    return Object.values(this.flags).find((flag) => flag.date == this.lastFlag);
  }

  flagLength(): number {
    return Object.keys(this.flags).length;
  }
}
