<div *ngIf="question" style="display: flex; align-items: center; justify-content: center">
    <div *ngIf="question.type === 'text'" style="max-height: 120px; overflow: auto">
        <div style="max-lines: 3">
            {{ value || question.answer || "sky is blue because physics" }}
        </div>
    </div>
    <div *ngIf="question.type === 'scale'" class="scale">
        <button *ngFor="let level of levels" [class]="
        value == level || (!(value | isNumber) && question.answer == level)
          ? 'selectedVar'
          : ''
      ">
      {{ level }}
    </button>
    </div>

    <div *ngIf="question.type === 'agreement'" class="agreement">
        <button *ngFor="let level of agreements" style="font-size: smaller" [class]="
        value == level.index ||
        (!(value | isNumber) && question.answer == level.index)
          ? 'selectedVar'
          : ''
      ">
      {{ level.title }}
    </button>
    </div>

    <div *ngIf="question.type === 'timely'" class="agreement">
        <button *ngFor="let level of timely" style="font-size: smaller" [class]="
        value == level.index ||
        (!(value | isNumber) && question.answer == level.index)
          ? 'selectedVar'
          : ''
      ">
      {{ level.title }}
    </button>
    </div>

    <div *ngIf="question.type === 'yesno'" class="agreement">
        <button *ngFor="let level of yesno" style="font-size: smaller" [class]="
        value == level.index ||
        (!(value | isNumber) && question.answer == level.index)
          ? 'selectedVar'
          : ''
      ">
      {{ level.title }}
    </button>
    </div>
</div>
