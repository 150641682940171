import { tr } from "date-fns/locale";
import { isBoolean, isNull, isNumber, isObject, merge, mergeWith } from "lodash";

export class Omzet {
  differences = {
    pin: 0,
    cash: 0,
    teller: 0,
    internet: 0,
    flesjes: 0,
    total: 0,
  };

  checks = {
    pin: false,
    cash: false,
    teller: false,
    internet: false,
    flesjes: false,
    total: false,
  };

  pin = 0;
  cash = 0;
  teller = 0;
  internet = 0;
  flesjes = 0;
  total = 0;

  constructor(forceCheck = false){
    if(forceCheck) {
      this.checks.pin = true;
      this.checks.cash= true;
      this.checks.teller = true;
      this.checks.internet = true;
      this.checks.flesjes = true;
      this.checks.total = true;
    }
  }
    merge(th: Omzet, om: Omzet) {

      mergeWith(th,om, (obj, src) => {

        if(isNumber(obj)) {

          return obj + src
        }
        if(isBoolean(obj)) {
          return obj && src
        }
      });
      merge(this, th);

    }
}
