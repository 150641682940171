import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Firebase } from 'src/app/Services/firebase';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  sub;
  constructor(private firebase: StaffAppDatabase) {

   }

   private chatSubject = new BehaviorSubject<any>(null);
   chatObserver = this.chatSubject.asObservable();

   getChat(myUid, theirUid){
     console.log(myUid, theirUid)
    this.sub = this.firebase.getChat( myUid > theirUid ? myUid + theirUid : theirUid + myUid).subscribe(values => {
      console.log(values);
      let list = values.map((d: any) => {
        let temp: any = {};
        temp.messages = d.payload.val();
        temp.key = d.key;
        return temp;
      })
      console.log(list);
      this.chatSubject.next(list);
    })
   }

   clearChat(){
    this.chatSubject.next(null);
    if(this.sub){
      this.sub.unsubscribe();
    }
   }

   sendMessage(myUid, theirUid, message){
     console.log(message);
     this.firebase.notifyUser(theirUid, myUid);
    this.firebase.addChat( myUid > theirUid ? myUid + theirUid : theirUid + myUid, message )
   }





}
