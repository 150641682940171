import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {

  constructor(public alert: AlertsService, public db: StaffAppDatabase, public loading: LoadingService) { }

  ngOnInit(): void {
  }

  testCheckContracts() {
    this.db.testCheckContractsFunction().then((msg) => {
      console.log('Test Check Contracts Function Done',msg);
    }).catch((error) => {
      console.log('Test Check Contracts Function Error', error);
    });
  }
}
