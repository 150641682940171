export class CompanyInfo {
  address: string;
  btw: string;
  city: string;
  country: string;
  email: string;
  facturenemail: string;
  iban: string;
  kvk: string;
  name: string;
  number: string;
  zip: string;

  verify() {
    return true;
  }

  fixVariables() {
    // inputs are strings, cast to number if needed
    // this.number = Number(this.number);
  }
}
