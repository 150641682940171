<app-loading style="
    z-index: 50;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none !important;
  "></app-loading>
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Song Update</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')">
    <i class="fas fa-close"></i>
  </button>
</div>
<div class="modal-body">
    <form [formGroup]="artistForm">
        <div class="edit-row">
            <div class="edit-desc">Name:</div>
            <input class="form-control" class="edit" id="name" #name formControlName="name" />
            <div *ngIf="!isValid('name')">
                Name is required between 1 and 200 characters
            </div>
        </div>

        <div class="edit-row">
            <div class="edit-desc">Path:</div>
            <input class="form-control" class="edit" id="location" #location disabled="true" formControlName="location" />
        </div>

        <div class="edit-row">
            <div class="edit-desc">isDutch:</div>
            <input class="form-control" class="edit" id="isDutch" type="checkbox" #isDutch disabled="true" formControlName="isDutch" />
        </div>

        <div class="edit-row">
            <div ngbTooltip="Use ISO 3611-1 Code (i.e. gb for English, nl for Dutch), First language is the primary one" class="edit-desc">Language <a style="color: blue;" href="https://en.wikipedia.org/wiki/ISO_3166-1" target="_blank">Codes</a></div>
            <input class="form-control" class="edit" id="language" type="text" #language formControlName="language" (ngModelChange)="languageChanged()" />
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start;">
                <span *ngFor="let lang of languages" [class]="'fi fi-' + lang"></span>
            </div>
        </div>

        <div class="edit-row">
            <div class="edit-desc">Info:</div>
            <textarea class="form-control" style="word-break: break-word; white-space: normal" class="edit" id="info" #info formControlName="info"></textarea>
        </div>

        <div class="edit-row">
            <div class="edit-desc">Length:</div>
            <input class="form-control" class="edit" id="length" #length formControlName="length" />
        </div>

        <div class="edit-row">
            <div class="edit-desc">
                Price Modifier (either 1, 1.1 or 1.2):
                <button type="button" class="btn btn-primary" ngbTooltip="Normal Song (e.g. 10cc - I'm not in love )= 1.2, upbeat well known popular and good for atmosphere (e.g. barbie girl, mr brightside)= 1.0, ballads (e.g. bohemian, paradise...)= 1.1. Time/Length of song should not affect this modifier that is seperate">
          <i class="fas fa-info"></i>
        </button>
            </div>
            <input class="form-control" type="number" step="0.1" min="1" max="1.2" class="edit" id="modifier" #modifier formControlName="modifier" />
        </div>

        <div class="edit-row">
            <div class="edit-desc">Year:</div>
            <input class="form-control" class="edit" id="year" #year formControlName="year" />
        </div>

        <div class="edit-row">
            <div class="edit-desc">Genre(s)</div>

            <div class="genre-row">
                <div class="genre">
                    <input type="text" #gen />
                    <button (click)="addGenre(gen.value); gen.value = ''" [style.background-color]="
              gen.value.trim() != '' ? 'rgb(75, 240, 75)' : 'rgb(245, 44, 44)'
            ">
            Add
          </button>
                </div>
                <div *ngFor="let g of genres; index as i" class="genre">
                    <div>{{ g }}</div>
                    <button (click)="deleteGenre(i)">X</button>
                </div>
            </div>
        </div>

        <div class="edit-row" *ngIf="!selectedImage && downloadUrl | async; let url">
            <div class="edit-desc">Photo:</div>
            <div class="flex center"><img [src]="url" /></div>
        </div>

        <div class="edit-row">
            <div class="edit-desc">Upload Photo:</div>
            <input class="form-control" type="file" accept="image/*" (change)="selectFile($event)" />
            <div class="flex center" *ngIf="selectedImage" style="margin-top: 5px">
                <img [src]="selectedImage | sanitizerUrl" />
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark warning" (click)="close('delete')">
    Delete
  </button>
    <button type="button" class="btn btn-outline-dark" (click)="close('save')">
    Save
  </button>
</div>