export class InvoiceProduct {
    description: string;
    vatType: number = 0;
    amount: number;
    total: number;
    vat: number;
    recalculate(){
    
        this.total = Number(this.amount) + Number(this.amount * this.vatType / 100);
        this.vat = this.total - this.amount;
    }
}
