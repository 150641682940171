<div class="swapflex" style="align-items: center; justify-content: flex-start; width: 100%;">
  <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-evenly;">
    <h2 style="margin:0" *ngIf="start.getFullYear() == today.getFullYear()">
      {{ start | date: "LLLL" }}</h2>
    <h2 style="margin:0" *ngIf="start.getFullYear() != today.getFullYear()">{{
      start | date: "LLL yy"
      }}</h2>
    <input class="date-select" type="date" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="setDate($event)">

  </div>

  <div style="display: flex; width: 100%; flex-direction: row; align-items: center; justify-content: space-evenly;">
    <div>Target Days a Week</div>
    <div style="font-weight: bold; font-size: large;">{{ daysPerWeek }}</div>
    <div>
      <div (click)="changeDaysPerWeek(1)">
        <i class="fas fa-chevron-up"></i>
      </div>
      <div (click)="changeDaysPerWeek(-1)">
        <i class="fas fa-chevron-down chev"></i>
      </div>
    </div>
  </div>
</div>

<div class="face front" *ngIf="!showReason" id="sh7" (click)="tableClicked($event)">
  <div class="cal">
    <div class="countdown" id="sh4">
      {{ countdown | countdown }}
    </div>
    <div style="width: 100%; height: 100%">
      <div class="header" style="height: 80px">
        <div>Mo</div>
        <div>Tu</div>
        <div>We</div>
        <div>Th</div>
        <div>Fr</div>
        <div>Sa</div>
        <div>Su</div>
      </div>
      <div class="month" id="sh3">
        <div *ngFor="let week of table; let i = index" [class]="'week ' + week[0].weekType"
             [style.display]="!week[0].date ? 'none' : 'flex'">

          <!--
                 <div *ngIf="week[0] && week[0].weekType === 'next'" class="arrow left">
            <i class="fas fa-chevron-right"></i>
          </div>
             -->




          <button class="day-button" *ngFor="let day of week" style="cursor: pointer;"
                  (click)="dayClicked($event, day)">
            <div *ngIf="day && day.date" class="day day-past" [ngClass]="{
              'hours': day.schedule &&(user.uid | checkArray:day.schedule),
              'red':  day.weekType != 'past' && day.weekType != 'past last' && day.request.request === 0,
              'requested':day.weekType != 'past' && day.weekType != 'past last'&& day.request.request === 1,
              'approved': day.weekType != 'past'&& day.weekType != 'past last' &&day.request.request === 2,
              'border-green': day.request.request === 2,
              'border-orange': day.request.request === 1,
              'border-red': day.request.request === 0
            }">
              <div *ngIf="sameMonth(day.date)">
                {{ day.date | date: "d" }}
              </div>
            </div>
          </button>

          <!--
   <div *ngIf="week[0] && week[0].weekType === 'next'" class="arrow right">
            <i class="fas fa-chevron-left"></i>
          </div>
-->

        </div>
      </div>
    </div>
  </div>
</div>

<div class="face back" *ngIf="showReason">

  <div class="cal" style="background-color: rgba(247, 152, 51, 0.8)" *ngIf="selected">


    <h3>Worked</h3>
    <div *ngFor="let h of selected.allHours | keyvalue">
      {{ staffNames[h.key] }} {{ h.value.startTime | time }} - {{ h.value.endTime | time }}
    </div>

    <h3>Scheduled</h3>
    <div *ngFor="let h of selected.schedule">
      {{ staffNames[h.key] }} {{h.time + (h.time >= 12 ? 'AM':'PM')}}
    </div>

    <button class="close-day" (click)="selected = null">X</button>
  </div>


  <div class="reason" style="color: white" *ngIf="reason" id="sh6">
    <h3 style="margin: 5px 0px">Reason for less shifts</h3>
    <span style="margin: 5px 0px">Week Starting: {{ nextWeek | date: "dd LLL &#180;yy" }}</span>
    <textarea style="width: 100%; flex-grow: 1; font-size: large" [(ngModel)]="reasonText"></textarea>
    <button style="
      height: 40px;
      width: 150px;
      background-color: #5b5b5b;
      border-radius: 20px;
      color: white;
      font-size: larger;
      margin: 5px 0px;
    " (click)="setReason()">
      Save
    </button>
    <button class="close-day" (click)="cancelReason()">X</button>
  </div>
</div>
