import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";

import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { PDFViewerComponent } from "duncans-pdfviewer";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.css"],
})
export class DocumentsComponent implements OnInit {
  payslips: any[] = [];
  selected: any;

  contracts: any[] = [];
  subs: Subscription[] = [];
  constructor(
    public auth: Auth,
    public loading: LoadingService,
    public alert: AlertsService,
    public api: StaffAppDatabase,
    public modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.auth.afAuth.user.subscribe(async (a) => {
        this.selected = a;
        if (a) {
          this.staffSelected(a.uid);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => {
      try {
        a.unsubscribe();
      } catch (e) {}
    });
  }

  async open(doc: any, type, date) {
    const url = await this.api.getDownloadURL(doc.location);
    this.openPDF(doc.location, url, type, date);
  }

  async openPDF(location, p, type, date) {
    console.log(p);

    var username = await this.auth.getFullNameAsync();
    var m = this.modal.open(PDFViewerComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    m.componentInstance.pdfData = {
      data: p,
      ref: location,
      type: type,
      date: date,
      name: username + "_" + this.auth.user.uid,
    };
    console.log(m.componentInstance.pdfData);
    m.componentInstance.init();
    m.componentInstance.followClose().subscribe((a) => {
      m.close();
    });
  }

  async staffSelected(s) {
    this.payslips = [];
    this.contracts = [];

    if (this.selected && this.selected.uid) {
      this.loading.nextEmit("on");
      const payslips = (await this.api.getDocuments(s, "payslips"))[
        "_delegate"
      ].items
        .map((a) => a["_location"]["path_"])
        .map((a) => {
          return { location: a, date: a.split("/").pop() };
        });
      const contracts = (await this.api.getDocuments(s, "contract"))[
        "_delegate"
      ].items
        .map((a) => a["_location"]["path_"])
        .map((a) => {
          return { location: a, date: a.split("/").pop() };
        });
      this.payslips = payslips;
      this.contracts = contracts;
      console.log(payslips, contracts);
      this.loading.nextEmit(null);
    }
  }
}
