<div class="generic-table-header generic-cols-6">
    <div class="generic-table-item short">
        ID
    </div>

    <div class="generic-table-item" style="text-decoration: underline;">
        Date
    </div>

    <div class="generic-table-item">
        Category
    </div>

    <div class="generic-table-item">
        Title
    </div>

    <div class="generic-table-item">
        Description
    </div>
</div>



<div class="generic-table">
    <div *ngFor="let s of data" class="generic-table-row generic-cols-6" [ngbTooltip]="s.title + ' - ' + s.description">
        <div class="generic-table-item short">
            {{s.token}}
        </div>

        <div class="generic-table-item">
            {{s.date | date:'EEE dd LLL yyyy'}}
        </div>

        <div class="generic-table-item">
            {{s.garment || s.item}}
        </div>

        <div class="generic-table-item">
            {{s.title}}
        </div>

        <div class="generic-table-item">{{s.description}}</div>

        <div class="generic-table-icons "><i (click)="openItem(s)" class="generic-icon fas fa-ellipsis-h green"></i><i (click)="deleteItem(s)" class="generic-icon fas fa-trash red"></i></div>
    </div>
</div>
<!--
<div class="flex-rows">
    <div class="flex-columns">
        <div *ngFor="let d of data" (click)="itemSelected(d)" style="border: 1px solid black; background-color: orange; margin: 5px; width: 200px; border-radius: 5px;">
            {{d.title}} <br> {{d.date | date:'dd/MM/yyyy'}}
        </div>
    </div>
    <div class="flex-columns" *ngIf="item">
        <div class="even-row" style="width: 300px;">
            <h1>{{item.title}}</h1>
            <h1>{{item.token}}</h1>
        </div>

        <div>{{item.date | dateth:'time'}}</div>

        <h3>Public Information</h3>

        <label>Description:</label>
        <div>
            {{item.description}}
        </div>
        <label>Type</label>
        <div>
            {{item.garment ? item.garment : item.item}}
        </div>



        <h3>Control Information</h3>
        <label>Question:</label>
        <div>
            {{item.controlQuestion}}
        </div>
        <label>Expected Answer:</label>
        <div>
            {{item.controlAnswer}}
        </div>



        <div class="even-row" style="width: 300px;">
            <button (click)="deleteItem(item)">Delete</button>
            <button (click)="itemSelected(null)">Close</button>
        </div>



    </div>

    <div class="flex-columns" *ngIf="item">
        <h3>Public Photo</h3>
        <img class="photo" [src]="mainPhoto$ | async">
        <h3>Control Photo</h3>
        <img class="photo" [src]="controlPhoto$ | async">
    </div>
</div>


-->
