import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ja } from "date-fns/locale";
import { LoadingService } from "src/app/Services/loading.service";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import test from "./test.json";
@Component({
  selector: "app-detailed-turnover",
  templateUrl: "./detailed-turnover.component.html",
  styleUrls: ["./detailed-turnover.component.css"],
})
export class DetailedTurnoverComponent implements OnInit {
  date = new Date();
  report: any;
  days = [];
  summary: any;
  downloadJsonHref;
  constructor(
    private db: StaffAppDatabase,
    private loading: LoadingService,
    private alert: AlertsService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.setMonth();
  }

  setMonth() {
    this.days = [];
    var start = new Date(this.date);
    start.setDate(1);

    var end = new Date(this.date);
    end.setMonth(start.getMonth() + 1);
    end.setDate(0);

    while (start <= end) {
      this.days.push({ date: start.getTime(), status: 0, data: [] });
      start.setDate(start.getDate() + 1);
    }
  }
  async getReport() {
    this.summary = null;
    for (var i = 0; i < this.days.length; i++) {
      this.loading.nextEmit("on");
      this.days[i].status = 1;

      /*await this.db
        .getTillForDate(new Date(this.days[i].date), this.db.bar)
        .then((a) => {
          this.days[i].data = a;
        })
        .catch((e) => {
          this.alert.nextEmit(AlertsService.error("Failed", e));
          return e;
        });*/
      await this.db
        .getDetailedTurnover(new Date(this.days[i].date))
        .then((a) => {
          this.days[i].data = a;
        })
        .catch((e) => {
          this.alert.nextEmit(AlertsService.error("Failed", e));
          return e;
        });
      this.days[i].status = 2;
    }

    var temp = this.days
      .reduce((prev, curr) => {
        return prev.concat(curr.data[0]);
      }, [])
      .filter((a) => a);

    const sum = {
      pin: 0,
      cash: 0,
      internet: 0,
      other: 0,
      pinQ: 0,
      cashQ: 0,
      internetQ: 0,
      otherQ: 0,
      total: 0,
      totalQ: 0,
    };

    temp = temp.map((rep) => {
      const t = {
        time: rep.closeDateTime,
        amount: 0,
        type: "",
        id: "",
      };

      if (rep.bills && rep.bills[0]) {
        var type = "";
        var amount = 0;
        var quantity = 0;
        rep.bills.forEach((b) => {
          b.payments.forEach((c) => {
            type = c.bookkeepingAccountName;
            amount += c.amount;
            if (c.amount > 0) {
              quantity++;
            } else if (c.amount < 0) {
              quantity--;
            }
          });
        });

        switch (type) {
          case "Kontant":
            sum.cash += amount;
            sum.cashQ += quantity;
            break;
          case "Pin":
            sum.pin += amount;
            sum.pinQ += quantity;
            break;
          case "Internet betaling":
            sum.internet += amount;
            sum.internetQ += quantity;
            break;
          default:
            console.log(type);
            type = "Other";
            sum.other += amount;
            sum.otherQ += quantity;
        }

        sum.total += amount;
        sum.totalQ += quantity;

        t.amount = amount;
        t.type = type;
        t.id = rep.id;
      }

      return t;
    });

    this.summary = sum;

    console.log(temp);
    this.report = temp;
    this.generateDownloadJsonUri();
    this.loading.nextEmit("");
  }

  generateDownloadJsonUri() {
    var theJSON = JSON.stringify(this.report);
    var uri = this.sanitizer.bypassSecurityTrustUrl(
      "data:text/json;charset=UTF-8," + encodeURIComponent(theJSON)
    );
    this.downloadJsonHref = uri;
  }
}
