import { CalendarCreatorDay } from "./CalendarCreatorDay";


export class CalendarCreator {
  private currentYear: number;
  private currentMonthIndex: number;

  constructor() {
    let date = new Date();
    this.currentYear = date.getFullYear();
    this.currentMonthIndex = date.getMonth();
  }

  public getCurrentMonth(): CalendarCreatorDay[] {
    return this.getMonth(this.currentMonthIndex, this.currentYear);
  }

  public getMonth(monthIndex: number, year: number): CalendarCreatorDay[] {
    let days = [];

    let firstday = this.createDay(1, monthIndex, year);

    //create empty days
    console.log(firstday)
    let i = 1;
    var nextMonth = new Date(year, monthIndex, 0);
    nextMonth.setDate(nextMonth.getDate() - (firstday.weekDayNumber != 0 ? firstday.weekDayNumber : 7) + 2);
    for (; i < (firstday.weekDayNumber != 0 ? firstday.weekDayNumber : 7); i++) {
      days.push({

        weekDayNumber: nextMonth.getDay(),
        year: nextMonth.getFullYear(),
        month: this.getMonthName(nextMonth.getMonth()),
        monthIndex: nextMonth.getMonth(),
        weekDayName: this.getWeekDayName(nextMonth.getDay()),
        number: nextMonth.getDate(),
        future: true

      } as CalendarCreatorDay);
      nextMonth.setDate(nextMonth.getDate() + 1);
    }
    days.push(firstday);
    //

    let countDaysInMonth = new Date(year, monthIndex + 1, 0).getDate();
    i = 2;
    for (; i < countDaysInMonth + 1; i++) {
      days.push(this.createDay(i, monthIndex, year));
    }
    console.log(i, ((firstday.weekDayNumber != 0 ? firstday.weekDayNumber : 7)))

    //fill remainder days
    nextMonth = new Date(year, monthIndex + 1, 1);
    while ((i - 2 + ((firstday.weekDayNumber != 0 ? firstday.weekDayNumber : 7))) % 7 != 0) {

      days.push({
        weekDayNumber: nextMonth.getDay(),
        year: nextMonth.getFullYear(),
        month: this.getMonthName(nextMonth.getMonth()),
        monthIndex: nextMonth.getMonth(),
        weekDayName: this.getWeekDayName(nextMonth.getDay()),
        number: nextMonth.getDate(),
        future: true

      } as CalendarCreatorDay);
      nextMonth.setDate(nextMonth.getDate() + 1);
      i++;
    }

    console.log('finished on i: ' + i)
    console.log(days)
    return days;
  }

  public getMonthName(monthIndex: number): string {
    switch (monthIndex) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";

      default:
        return "";
    }
  }

  public getWeekDayName(weekDay: number): string {
    switch (weekDay) {
      case 0:
        return "Su"; // Sunday
      case 1:
        return "Mo"; // Monday
      case 2:
        return "Tu"; // Tuesday
      case 3:
        return "We"; // Wednesday
      case 4:
        return "Th"; // Thursday
      case 5:
        return "Fr"; // Friday
      case 6:
        return "Sa"; // Saturday

      default:
        return "";
    }
  }

  private createDay(dayNumber: number, monthIndex: number, year: number) {
    let day = new CalendarCreatorDay();

    day.monthIndex = monthIndex;
    day.month = this.getMonthName(monthIndex);

    day.number = dayNumber;
    day.year = year;


    day.weekDayNumber = new Date(year, monthIndex, dayNumber).getDay();
    day.weekDayName = this.getWeekDayName(day.weekDayNumber);

    return day;
  }
}
