<div class="flex-rows">
    <div class="flex-columns" style="min-width: 400px !important">
        <h1>Items</h1>
        <button class="button-row" mat-raised-button (click)="newDrink()">
      New Item
    </button>
        <br />
        <button *ngFor="let item of drinks" class="nd-button" (click)="drinkSelected(item)">
      <div style="width: 50%; overflow: hidden; word-wrap: none">
        {{ item.name }}
      </div>
      <div style="width: 20%">
        {{ item.defaultQuantity }}
      </div>

      <div style="flex-grow: 1"></div>

      <button
        class="sort-arrow"
        (click)="$event.stopPropagation(); sortUp(item)"
        [disabled]="item.position <= 0"
      >
        <i class="fas fa-chevron-up"></i>
      </button>

        <button class="sort-arrow" [disabled]="item.position >= drinks.length - 1" (click)="$event.stopPropagation(); sortDown(item)">
        <i class="fas fa-chevron-down"></i>
      </button>
        </button>
    </div>

    <div class="flex-columns" style="min-width: 400px !important">
        <h1>Edit/Add</h1>

        <div *ngIf="selectedDrink">
            <div class="button-row">
                <button mat-raised-button color="primary" (click)="saveDrink(selectedDrink)">
          Save
        </button>
                <button mat-raised-button color="warn" (click)="cancel()">
          Cancel
        </button>
                <button mat-raised-button color="warn" (click)="deleteDrink()">
          Delete
        </button>
            </div>
            <div class="edit-row">
                <div class="edit-desc">Name:</div>
                <input class="edit" [(ngModel)]="selectedDrink.name" name="name" />
            </div>
            <div class="edit-row">
                <div class="edit-desc">Default Quantity:</div>
                <input class="edit" type="number" [(ngModel)]="selectedDrink.defaultQuantity" name="name" />
            </div>

            <div class="edit-row">
                <div class="edit-desc">VAT:</div>
                <input class="edit" type="number" [(ngModel)]="selectedDrink.vat" name="name" />
            </div>

            <div class="edit-row">
                <div class="edit-desc">Price Per Unit :</div>
                <input class="edit" type="number" [(ngModel)]="selectedDrink.price" name="name" />
            </div>

            <div class="edit-row">
                <div class="edit-desc">Description:</div>
                <input class="edit" type="text" [(ngModel)]="selectedDrink.description" name="name" />
            </div>

            <div class="edit-row">
                <div class="edit-desc">Invoice Category:</div>
                <select [(ngModel)]="selectedDrink.invoiceCategory">
          <option *ngFor="let c of invoiceCategories" [ngValue]="c">
            {{ c }}
          </option>
        </select>
            </div>
        </div>
    </div>
</div>