import { CurrentPrice } from "./current-price";

export class PriceStatistics {
  barname: string;
  change: CurrentPrice;
  end: number;
  first5Minutes: number;
  middle5Minutes: number;
  last5Minutes: number;
  last15Minutes: number;
  lastHour: number;
  start: number;
}
