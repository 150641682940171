<ng-container *ngIf="voucher">
  <qrcode
    *ngIf="voucher.qrCode"
    [qrdata]="voucher.qrCode"
    [width]="qrOptions.width"
    [colorDark]="'#000000'"
    [colorLight]="'#FFFFFF'"
    [errorCorrectionLevel]="'M'"
    (click)="refresh()"
  ></qrcode>

  <h3>{{ voucher.name }}</h3>
</ng-container>
