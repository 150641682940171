import { OutingRecord } from "./outing-record";

export class OutingBudget {
  id: string = Math.random().toString(36).substr(2, 9);
  year: number = new Date().getFullYear();
  createdOn: number = new Date().getTime();
  bar: string = "";
  currentBalance: number = 0;
  records: OutingRecord[] = [];
  comments: string = "";
}
