import { Component, OnInit } from "@angular/core";
import { UserFlag } from "src/app/Classes/user-flag";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { Firebase } from "src/app/Services/firebase";
import { LoadingService } from "src/app/Services/loading.service";

@Component({
  selector: "app-flags",
  templateUrl: "./flags.component.html",
  styleUrls: ["./flags.component.css"],
})
export class FlagsComponent implements OnInit {
  userFlags: UserFlag[] = [];
  users: any = {};

  sortDirection: number = 1;
  sortingOn: string = "name";
  dateTime = Date.now();

  selectedUser: string = "";
  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public db: Firebase
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.loading.show();

    var temp: UserFlag[] = await this.db.getUserFlags();
    this.users = (
      await this.db.getUsersFromList(
        temp.reduce((acc, flag) => {
          return acc.concat(flag.key);
        }, [])
      )
    ).reduce((acc, user) => {
      acc[user.key] = user;
      return acc;
    }, {});

    console.log(temp, this.users);
    //attach user data to flags
    this.userFlags = temp.map((flag) => {
      flag.user = this.users[flag.key];
      flag.lastFlagObj = flag.getLastFlag();
      return flag;
    });
    this.sortBy("date");
    this.loading.hide();
  }

  sortBy(str) {
    if (this.sortingOn == str) {
      this.sortDirection = this.sortDirection * -1;
    }
    this.sortingOn = str;
    switch (str) {
      case "name":
        this.userFlags.sort((a, b) => {
          return a.user.userName.localeCompare(b.user.userName);
        });
        break;
      case "date":
        this.userFlags.sort((a, b) => {
          return a.lastFlag - b.lastFlag;
        });
        break;

      case "reason":
        this.userFlags.sort((a, b) => {
          return a.lastFlagObj.flagType.localeCompare(b.lastFlagObj.flagType);
        });
        break;
    }

    if (this.sortDirection == -1) {
      this.userFlags = this.userFlags.reverse();
    }
  }

  userSelected(user) {
    if (this.selectedUser == user.key) {
      this.selectedUser = "";
      return;
    }
    console.log(user);
    this.selectedUser = user.key;
  }
}
