import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { Auth } from 'src/app/auth/auth';

@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.css']
})
export class HoursComponent implements OnInit {
  hours: any[] = [];
  selected: any;
  subs: Subscription[] = [];
  date = new Date();
  calendarDays: any[] = [];
  total = 0;
  constructor(public auth: Auth, public loading: LoadingService, public alert: AlertsService, public api: StaffAppDatabase, public bi: BarInfo) { }

  ngOnInit(): void {
    this.loading.nextEmit('on')
    this.subs.push(this.auth.afAuth.user.subscribe(async a => {
      this.selected = a;
      if (a) {
        this.staffSelected(a.uid)
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(a => {
      try {
        a.unsubscribe();
      } catch (e) {
      }
    });
  }



  async staffSelected(s) {
    this.loading.nextEmit(null);
    this.dateChanged(this.date);
  }

  dateChanged(dateEvent: any) {
    this.loading.nextEmit('on');
    const date = new Date(dateEvent);
    this.calendarDays = this.getCalendarDays(date);
    this.date = date;
    console.log(date)
    const firstCalendarDay = this.calendarDays[0].date;
    const lastCalendarDay = this.calendarDays[this.calendarDays.length - 1].date;
    this.api.getHoursForStaff(this.bi.bar, firstCalendarDay, lastCalendarDay, this.selected.uid).then(a => {
      console.log(a);
      this.hours = a;
      this.hours.forEach(a => {
        const hourDate = new Date(a.year, a.month, a.day);
        this.calendarDays.forEach(b => {
          if (b.date.getFullYear() == hourDate.getFullYear() && b.date.getMonth() == hourDate.getMonth() && b.date.getDate() == hourDate.getDate()) {
            b.hours = a.total;
          }
        });
      })
      this.total = this.hours.reduce((a, b) => {
        const bDate = new Date(b.year, b.month, b.day);
        if (bDate.getMonth() == this.date.getMonth() && bDate.getFullYear() == this.date.getFullYear()) {
          return a + b.total;
        }
        return a;
      }, 0);
      console.log(this.calendarDays);
      this.loading.nextEmit(null);
    });
  }
  getCalendarDays(date: Date): any[] {
    const calendarDays: any[] = [];

    // Determine the first day of the current month
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    // Determine the last day of the previous month
    const lastDayOfPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);

    // Calculate the number of days to display from the previous month
    const startOffset = (firstDayOfMonth.getDay() + 6) % 7; // 0 = Monday, 1 = Tuesday, ...

    // Calculate the number of days in the current month
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    // Calculate the number of days to display from the next month
    const endOffset = (7 - new Date(date.getFullYear(), date.getMonth(), daysInMonth).getDay()) % 7;

    // Generate dates for the previous month
    for (let i = startOffset - 1; i >= 0; i--) {
      const day = new Date(lastDayOfPrevMonth.getFullYear(), lastDayOfPrevMonth.getMonth(), lastDayOfPrevMonth.getDate() - i);
      calendarDays.push({ date: day, isCurrentMonth: false, isToday: false, hours: 0 });
    }

    // Generate dates for the current month
    for (let i = 1; i <= daysInMonth; i++) {
      const day = new Date(date.getFullYear(), date.getMonth(), i);
      calendarDays.push({ date: day, isCurrentMonth: true, isToday: (i === new Date().getDate() && date.getMonth() === new Date().getMonth()), hours: 0 });
    }

    // Generate dates for the next month
    for (let i = 1; i <= endOffset; i++) {
      const day = new Date(date.getFullYear(), date.getMonth() + 1, i);
      calendarDays.push({ date: day, isCurrentMonth: false, isToday: false, hours: 0 });
    }

    return calendarDays;
  }



}
