import { Component, OnInit } from '@angular/core';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-vip-rooms',
  templateUrl: './vip-rooms.component.html',
  styleUrls: ['./vip-rooms.component.css']
})
export class VipRoomsComponent implements OnInit {
  bar;
  crates;
  vipRooms;
  subs = [];
  selectedRoom;

  vipSub;
  cratesSub;
  changed = false;

  constructor(private db: StaffAppDatabase, private bi: BarInfo) {
    this.subs.push(this.bi.barObserver.subscribe(bar => {
      if (this.vipSub) this.vipSub.unsubscribe();
      if (this.cratesSub) this.cratesSub.unsubscribe();
      if (bar) {
        this.bar = bar;

        this.vipSub = this.db.getVipRooms(this.bar).subscribe(values => {
          this.vipRooms = values;
          if (this.cratesSub) this.cratesSub.unsubscribe();
          this.cratesSub = this.db.getCrates(this.bar).subscribe(values => {
            values.forEach((drink: any) => {
              if (!drink.vipRooms) {
                drink.vipRooms = {};
              }
              this.vipRooms.forEach(room => {
                if (!drink.vipRooms[room.key]) {
                  drink.vipRooms[room.key] = { defaultQuantity: 0, currentQuantity: 0 };
                }
              })


            })
            this.crates = values.sort((a: any, b: any) => a.id - b.id);
            console.log(this.crates)
          })
        })

      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(a => a.unsubscribe());
  }
  ngOnInit(): void {
  }

  saveChanges() {
    this.db.saveStock(this.bar, this.crates, 'crates');
    this.changed = false;
  }

  toNumber(a) {
    return Number(a);
  }

}
