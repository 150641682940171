<div class="staff-info-title">
    <div class="action-side"></div>
    <h3 style="margin: 5px 0px 2px 0px">Reorder Stock</h3>
    <button class="action-side close-button red" (click)="activeModal.dismiss()">
  <i class="fas fa-times"></i>
</button>
</div>

<div class="actions actions-header">
    <button *ngFor=" let cat of categories " class="reactive-button-title " (click)="categoryChanged(cat) " [ngClass]="selectedCategory==cat ? 'green' : '' ">
    {{cat | capitalize}}
  </button>




</div>

<div class="stock-table-header ">
    <div class="stock-item-large ">
        <div>Name</div>
    </div>
    <div class="stock-item-imp ">
        <div>Old Index</div>
    </div>

    <div class="stock-item-imp ">
        <div>New Index</div>
    </div>

    <button style="opacity: 0; "><i class="fas fa-chevron-up "></i></button>
    <button style="opacity: 0; "><i class="fas fa-chevron-down "></i></button>
</div>

<div class="stock-table ">
    <div *ngFor="let s of list; let i=index " class="stock-item ">
        <div class="stock-item-large ">{{s.name}}</div>
        <div class="stock-item-imp ">{{s.id}}</div>
        <div class="stock-item-imp ">{{i}}</div>
        <button (click)="$event.stopPropagation();moveUp(i) "><i class="fas fa-chevron-up "></i></button>
        <button (click)="$event.stopPropagation();moveDown(i) "><i class="fas fa-chevron-down "></i></button>

    </div>
</div>

<div class="actions actions-header" style="margin-bottom: 0px;">
    <button class="reactive-button-title green" (click)="savePositioning(this.selectedCategory) ">
  Save
  </button>
    <button class="reactive-button-title red" (click)="cancel() ">
    Cancel
  </button>




</div>
