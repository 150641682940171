<h3>Past Budgets</h3>

<div>
  <button *ngFor="let n of budgets" (click)="selectBudget(n)">
    {{ n.year }}
  </button>
</div>

<button (click)="createBudget()" [disabled]="auth.user.access <= 3">
  Create Budget ({{ date | date : "yyyy" }})
</button>
