import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "paginate",
})
export class PaginatePipe implements PipeTransform {
  transform(value: any[], ...args: unknown[]): unknown {
    console.log(value);
    console.log(args);
    if (args.length < 2) return value;

    const page = args[1] as number;
    const pageSize = args[0] as number;

    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    console.log;
    return value.slice(start, end);
  }
}
