<div style="height: 55px; width: 100%" class="flex-even">
    <input type="date" [ngModel]="overview.date | date: 'yyyy-MM-dd'" class="hidden-date" (ngModelChange)="overview.setDate($event)" />
    <div style="flex-grow: 1"></div>
    <div style="font-size: xx-large; color: black">
        {{ overview.selection.omzet.checks && overview.selection.omzet.checks.total ? "Checked" : "Preview" }}
    </div>
</div>

<div style="height: 55px; width: 100%" class="flex-even">
    <button (click)="overview.viewType = 0; overview.viewScale = 1; overview.buildSelection()" class="datchev" [ngClass]="{ selected: overview.viewType === 0 }" style="margin-right: 10px !important">
    Day
  </button>
    <button (click)="overview.viewType = 1; overview.viewScale = 7; overview.buildSelection()" class="datchev" [ngClass]="{ selected: overview.viewType === 1 }" style="margin-right: 10px !important">
    Week
  </button>
    <button (click)="overview.viewType = 2; overview.viewScale = 30; overview.buildSelection()" class="datchev" [ngClass]="{ selected: overview.viewType === 2 }" style="margin-right: 10px !important">
    Month
  </button>
    <button (click)="overview.viewType = 3; overview.viewScale = 365; overview.buildSelection()" class="datchev" [ngClass]="{ selected: overview.viewType === 3 }" style="margin-right: 10px !important">
    Year
  </button>

    <div style="flex-grow: 1"></div>
    <button (click)="overview.addDate(-1)" class="datchev" style="margin-right: 10px !important">
    <i class="fas fa-chevron-left"></i>
  </button>
    <button (click)="overview.addDate(1)" class="datchev">
    <i class="fas fa-chevron-right"></i>
  </button>
</div>

<div class="page-overview" style="height: calc(100% - 110px) !important">
    <div class="page-box-out" style="width: 100%; flex-direction: column">
        <div class="bonus-form omzet-kosten">
            <div class="bonus-form-inner bonus-omzet">
                <h2>Omzet</h2>
                <div class="bonus-row">
                    <div class="bonus-name">Name</div>
                    <div class="bonus-value"></div>
                    <div class="bonus-value"></div>
                    <div class="bonus-value">Total</div>
                </div>

                <div style="flex-grow: 1; overflow: auto">

                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]="'Pin'" [list]="[
                { class: 'bonus-value' },
                {
                  class: 'bonus-value'

                },
                {
                  class: 'bonus-value',
                  data: overview.selection.omzet.pin,
                  type: 'currency'
                }
              ]">
                    </app-overview-list>

                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]="'Cash'" [list]="[
                { class: 'bonus-value' },
                {
                  class: 'bonus-value'

                },
                {
                  class: 'bonus-value',
                  data: overview.selection.omzet.cash,
                  type: 'currency'
                }
              ]">
                    </app-overview-list>

                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]="'Internet'" [list]="[
                { class: 'bonus-value' },
                {
                  class: 'bonus-value'
                },
                {
                  class: 'bonus-value',
                  data: overview.selection.omzet.internet,
                  type: 'currency'
                }
              ]">
                    </app-overview-list>
                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]="'Flesjes'" [list]="[
                { class: 'bonus-value' },
                {
                  class: 'bonus-value'
                },
                {
                  class: 'bonus-value',
                  data: overview.selection.omzet.flesjes,
                  type: 'currency'
                }
              ]">
                    </app-overview-list>
                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]="'Teller'" [list]="[
                { class: 'bonus-value' },
                {
                  class: 'bonus-value'
                },
                {
                  class: 'bonus-value',
                  data: overview.selection.omzet.teller,
                  type: 'currency'
                }
              ]">
                    </app-overview-list>
                    <div style="flex-grow:1;"></div>



                </div>

                <app-overview-list class="bonus-row bonus-total" titleClass="bonus-right" [title]="'Total'" [list]="[
                { class: 'bonus-value' },
                {
                  class: 'bonus-value'
                },
                {
                  class: 'bonus-value',
                  data: overview.selection.omzet.total,
                  type: 'currency'
                }
              ]">
                </app-overview-list>

            </div>

            <div class="bonus-form-inner bonus-kosten">
                <h2>Kosten</h2>
                <div class="bonus-row">
                    <div class="bonus-name">Name</div>
                    <div class="bonus-value"></div>
                    <div class="bonus-value"></div>
                    <div class="bonus-value">Total</div>
                </div>

                <div style="flex-grow: 1; overflow: auto">

                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]=" 'Vaste Lasten' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data: overview.selection.kosten.vasteLasten, type:'currency' } ] ">
                    </app-overview-list>

                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]=" 'One Off' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data: overview.selection.kosten.oneoff, type:'currency' } ] ">
                    </app-overview-list>

                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]=" 'Bonnen' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data: overview.selection.kosten.inout, type:'currency' } ] ">
                    </app-overview-list>
                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]=" 'Inkoop' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data: overview.selection.omzet.inkoop, type:'currency' } ] ">
                    </app-overview-list>
                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]=" 'Verbruik' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data: overview.selection.verbruik.verbruik.total, type:'currency' } ] ">
                    </app-overview-list>
                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]=" 'Spill/Breuk' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data: overview.selection.verbruik.spill.total, type:'currency' } ] ">
                    </app-overview-list>
                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]=" 'Wisselen Fles' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data: overview.selection.verbruik.wisselen.total, type:'currency' } ] ">
                    </app-overview-list>
                    <app-overview-list class="bonus-row" titleClass="bonus-name" [title]=" 'Personeel' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data:overview.selection.personel.totalCost, type:'currency' } ] ">
                    </app-overview-list>
                </div>


                <app-overview-list class="bonus-row bonus-total" titleClass="bonus-right" [title]=" 'Total' " [list]="[ { class: 'bonus-value' }, { class: 'bonus-value' }, { class: 'bonus-value', data: overview.selection.totalKosten + overview.selection.personel.totalCost || 0, type:'currency' } ] ">
                </app-overview-list>
            </div>
        </div>

        <div class="bonus-form staff-total ">
            <div class="bonus-form-inner bonus-results ">
                <div class="bonus-results-row">
                    <div>Name</div>
                    <div></div>
                    <div>Hours</div>
                    <div>Price</div>
                    <div>Real</div>
                    <div>%</div>
                    <div>1:1</div>
                </div>
                <div *ngFor="let hour of overview.selection.worked" class="bonus-results-row">
                    <div>{{hour.name.substring(0,15)}}</div>
                    <div></div>
                    <div>{{hour.total}}</div>
                    <div> {{hour.price * hour.total | currency:'EUR'}}</div>
                    <div> {{hour.actualCost | currency:'EUR'}}</div>
                    <div> {{hour.percent}}</div>
                    <div> {{hour.ratio}}</div>


                </div>


            </div>
            <div class="bonus-form-inner" style="justify-content: space-evenly; align-items: flex-end;">
                <div class="bonus-row" style="justify-content: flex-end">
                    <div class="net-box-name">
                        Net:
                    </div>
                    <div class="net-box-val">
                        {{overview.selection.bonus.net | currency:'EUR'}}

                    </div>


                    <div class="net-box-icon">

                    </div>
                </div>

                <div class="bonus-row" style="justify-content: flex-end">
                    <div class="net-box-name">
                        Total Bonus:
                    </div>
                    <div class="net-box-val">

                        <input *ngIf="overview.selection.bonus.changedTotal > 0" type="number" [ngModel]="overview.selection.bonus.changedTotal" (ngModelChange)="overview.selection.bonus.changedTotal = $event; overview.recalculateBonus()">
                        <input type="number" *ngIf="!overview.selection.bonus.changedTotal" [ngModel]=" overview.selection.bonus.total" (ngModelChange)="overview.selection.bonus.changedTotal = $event; overview.recalculateBonus()">
                    </div>

                    <div class="net-box-icon">
                        <i class="fas fa-rotate-left" (click)="overview.selection.bonus.changedTotal = null; overview.recalculateBonus()"></i>
                    </div>
                </div>

                <div class="bonus-row" style="justify-content: flex-end">
                    <div class="net-box-name">
                        After %:
                    </div>
                    <div class="net-box-val">
                        {{overview.selection.bonus.afterPercent | currency:'EUR'}}
                    </div>
                    <div class="net-box-icon">

                    </div>
                </div>

                <div class="bonus-row" style="justify-content: flex-end">
                    <div class="net-box-name">
                        Per Hour:
                    </div>
                    <div class="net-box-val">
                        {{overview.selection.bonus.perHour | currency:'EUR'}}
                    </div>
                    <div class="net-box-icon">

                    </div>
                </div>
            </div>
        </div>

        <div class="bonus-form bonus-results ">
            <div class="bonus-results-row">
                <div>Name</div>
                <div>Hours</div>

                <div>%</div>
                <div>From %</div>

                <div>1:1</div>
                <div>From 1:1 </div>
                <div>Total</div>
            </div>
            <div style="flex-grow:1; overflow: auto">
                <div *ngFor="let hour of overview.selection.worked" class="bonus-results-row">
                    <div>{{hour.name.substring(0,15)}}</div>
                    <div>{{hour.total}}</div>
                    <div>{{hour.percent}}</div>
                    <div>{{hour.percentTotal | currency:'EUR'}}</div>
                    <div>{{hour.ratio}}</div>
                    <div>{{hour.ratioTotal | currency:'EUR'}}</div>
                    <div>{{hour.bonusTotal | currency:'EUR'}}</div>

                </div>
            </div>


        </div>
    </div>
</div>
