import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Firebase } from 'src/app/Services/firebase';
import { LoadingService } from 'src/app/Services/loading.service';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  searchKey = '';
  users: Observable<any[]>;
  user: any;
  searchType='email'

  constructor(private alert: AlertsService, private db: Firebase, private loading: LoadingService, private modal: NgbModal) {

  }

  ngOnInit(): void {

  }

  open(content) {
    this.modal.open(content).result.then((ful) => {
      this.db.saveUser(this.user).then(a => {
        this.alert.nextEmit(AlertsService.good('User Edit Success',this.user.userName + ' has ' + this.user.credits + ' credits'))
        this.load();

      }).catch(e => {
        this.alert.nextEmit(AlertsService.warning('User Edit Failed',e + ''))
      })
    }, (rej) => [

    ])
  }

  load(){
    console.log(this.searchKey, this.searchType)
    if(this.searchKey.length <=3) {
      return;
    }
    this.users = this.db.getUsers(this.searchKey, this.searchType);
    this.users.subscribe(val => {
      console.log(val)
    })
  }

}
