import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Tip } from "src/app/Classes/tip";

@Component({
  selector: "app-tip-modal",
  templateUrl: "./tip-modal.component.html",
  styleUrls: ["./tip-modal.component.css"],
})
export class TipModalComponent implements OnInit {
  @Input() tip: Tip = new Tip();
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  close() {
    this.modal.close(this.tip);
  }
}
