<ng-template #content let-modal>
    <div class="modal-header" style="color: black">
        <h4 class="modal-title" id="modal-basic-title">{{ modalContent.title }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
      ">
      <i class="fas fa-close"></i>
    </button>
    </div>
    <div class="modal-body">
        <app-verbruik-popover [items]="modalContent.list" [stock]="stock"></app-verbruik-popover>
    </div>
</ng-template>

<ng-template #content2 let-modal>
    <div class="modal-header" style="color: black">
        <h4 class="modal-title" id="modal-basic-title">{{ modalContent.title }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
      ">
      <i class="fas fa-close"></i>
    </button>
    </div>
    <div class="modal-body">
        <app-expenses-pop-over [items]="modalContent.list"></app-expenses-pop-over>
    </div>
</ng-template>

<div style="height: 55px; width: 100%" class="flex-even">
    <!--
<input type="date" [ngModel]="overview.date | date: 'yyyy-MM-dd'" class="hidden-date" (ngModelChange)="overview.setDate($event)" />

    -->

    <button (click)="overview.dateVisible = true"><i class="fas fa-calendar"></i></button>
    <ngb-datepicker *ngIf="overview.dateVisible" style="z-index: 10; background-color: whitesmoke; color:black" #d="ngbDatepicker" name="dp" [footerTemplate]="footerTemplate" (dateSelect)="overview.onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"
        [autoClose]="'outside'">
    </ngb-datepicker>

    <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="overview.focused" [class.range]="overview.isRange(date)" [class.faded]="overview.isHovered(date) || overview.isInside(date)" (mouseenter)="overview.hoveredDate = date" (mouseleave)="overview.hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>

    <ng-template #footerTemplate>
        <hr class="my-0">
        <button class="btn btn-secondary btn-sm m-2 float-end" (click)="overview.customRange(); overview.dateVisible = false">Close</button>
        <button class="btn btn-primary btn-sm m-2 float-end" (click)="overview.customRange(); overview.dateVisible = false; overview.buildSelection()">Go</button>
    </ng-template>

    {{(overview.start | date:'EE dd MMM `yy')}}{{overview.viewType == 0? '' : (' t/m ' + (overview.end | date:'EE dd MMM `yy'))}}
    <button (click)=" overview.buildSelection() ">Go</button>
    <div style="flex-grow: 1 "></div>
    <div style="font-size: xx-large; color: black ">
        {{ overview.selection.omzet.checks && overview.selection.omzet.checks.total ? "Checked " : "Preview " }}
    </div>
</div>

<div style="height: 55px; width: 100% " class="flex-even ">
    <button (click)="overview.viewType=0 ; overview.viewScale=1 ; overview.setDateRange() " class="datchev " [ngClass]="{ selected: overview.viewType===0 } " style="margin-right: 10px !important ">
    Day
  </button>
    <button (click)="overview.viewType=1 ; overview.viewScale=7 ; overview.setDateRange() " class="datchev " [ngClass]="{ selected: overview.viewType===1 } " style="margin-right: 10px !important ">
    Week
  </button>
    <button (click)="overview.viewType=2 ; overview.viewScale=30; overview.setDateRange() " class="datchev " [ngClass]="{ selected: overview.viewType===2 } " style="margin-right: 10px !important ">
    Month
  </button>
    <button (click)="overview.viewType=3 ; overview.viewScale=365; overview.setDateRange() " class="datchev " [ngClass]="{ selected: overview.viewType===3 } " style="margin-right: 10px !important ">
    Year
  </button>

    <button (click)="overview.viewType=4 ; overview.customRange(null) " class="datchev " [ngClass]="{ selected: overview.viewType===4 } " style="margin-right: 10px !important ">
    Custom
  </button>

    <div style="flex-grow: 1 "></div>
    <button (click)="overview.addDate(-1) " [disabled]="overview.viewType == 4" class="datchev " style="margin-right: 10px !important ">
    <i class="fas fa-chevron-left "></i>
  </button>
    <button (click)="overview.addDate(1) " [disabled]="overview.viewType == 4" class="datchev ">
    <i class="fas fa-chevron-right "></i>
  </button>
</div>

<div class="page-overview " style="height: calc(100% - 110px) !important ">
    <div class="page-box-out ">
        <div class="page-box-sub " *ngIf="overview.selection.omzet.differences ">
            <app-overview-list (click)="viewBox=='graph' ? viewBox='' : viewBox='graph' " class="box-row box-header " [title]=" 'Omzet' " [list]="[ { class: 'grow' }, { class: 'small-box circle', data: overview.selection.omzet.differences.total, type:
            'color', range: 1 * viewScale }, { class: 'grow' }, { class: 'normal-box input-box header-input', data: overview.selection.omzet.total, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'Pin' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: overview.selection.omzet.pin / overview.selection.omzet.total, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data:
            overview.selection.omzet.pin, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'Cash' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: overview.selection.omzet.cash / overview.selection.omzet.total, type: 'percent' }, { class: 'grow' }, { class: 'normal-box',
            data: overview.selection.omzet.cash, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'Internet' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: overview.selection.omzet.internet / overview.selection.omzet.total, type: 'percent' }, { class: 'grow' }, { class:
            'normal-box', data: overview.selection.omzet.internet, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'Flesjes' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: overview.selection.omzet.flesjes / overview.selection.omzet.total, type: 'percent' }, { class: 'grow' }, { class:
            'normal-box', data: overview.selection.omzet.flesjes, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'Teller' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: overview.selection.omzet.teller / overview.selection.omzet.total, type: 'percent' }, { class: 'grow' }, { class:
            'normal-box', data: overview.selection.omzet.teller, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'Verschil' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: overview.selection.omzet.differences.total / overview.selection.omzet.total, type: 'percent' }, { class: 'grow' }, { class:
            'normal-box', data: overview.selection.omzet.differences.total, type: 'currency' } ] "></app-overview-list>
        </div>
        <div class="page-box-sub " style="flex-grow: 1 ">
            <div *ngIf="viewBox==='graph' ">
                <app-pin-cash-internet-chart [data]="overview.charts " [viewType]="viewType "></app-pin-cash-internet-chart>

            </div>
        </div>
    </div>

    <div class="page-box-out ">
        <div class="page-box-sub ">
            <app-overview-list class="box-row box-header " [title]=" 'Kosten' " [list]="[ { class: 'grow' }, { class: 'small-box circle', data: -overview.selection.kosten.total - overview.selection.verbruik.total + overview.selection.omzet.total, type:
            'color', range: 'aboveZero' }, { class: 'grow' }, { class: 'normal-box input-box header-input', data: overview.selection.kosten.total + overview.selection.verbruik.total, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'Vaste Lasten' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: overview.selection.kosten.vasteLasten, type:
            'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'One Off' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: overview.selection.kosten.oneoff, type: 'currency'
            } ] "></app-overview-list>

            <app-overview-list class="box-row " [title]=" 'Bonnen' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: overview.selection.kosten.inout, type: 'currency' }
            ] "></app-overview-list>

            <app-overview-list class="box-row " [title]=" 'Inkoop' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: overview.selection.omzet.inkoop, type: 'currency' }
            ] "></app-overview-list>

            <app-overview-list class="box-row " [title]=" 'Verbruik' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: overview.selection.verbruik.verbruik.total, type:
            'currency' } ] "></app-overview-list>

            <app-overview-list class="box-row " [title]=" 'Spill/Breuk' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: overview.selection.verbruik.spill.total, type:
            'currency' } ] "></app-overview-list>

            <app-overview-list class="box-row " [title]=" 'Wisselen Fles' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: overview.selection.verbruik.wisselen.total, type:
            'currency' } ] "></app-overview-list>

            <app-overview-list class="box-row " [title]=" 'Personeel' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: 0, type: 'currency' } ] "></app-overview-list>

            <app-overview-list class="box-row " [title]=" 'Bonus Personeel' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: 0, type: 'currency' } ] "></app-overview-list>
        </div>
        <div class="page-box-sub ">
            <app-overview-list class="box-row box-header " [title]=" 'Winst' " [list]="[ { class: 'grow' }, { class: 'small-box circle', data: 0, type: 'color', range: 'aboveZero' }, { class: 'grow' }, { class: 'normal-box input-box header-input', data:
            0, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" 'Prognose' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: 0, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" '2021' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: 0, type: 'currency' } ] "></app-overview-list>
            <app-overview-list class="box-row " [title]=" '2020' " [list]="[ { class: 'grow' }, { class: 'normal-box', data: 0, type: 'percent' }, { class: 'grow' }, { class: 'normal-box', data: 0, type: 'currency' } ] "></app-overview-list>
        </div>
    </div>
</div>
