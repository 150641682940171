<ng-template #content2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Song Update</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="fas fa-close"></i>
    </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="mb-3"></div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
    </div>
</ng-template>

<form style="max-height: 900px">
    <div class="mb-3 row" style="height: 2rem">
        <label style="height: 2rem" for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Search:</label
    >
    <div class="col-xs-3 col-sm-auto" style="height: 2rem">
      <input
        id="table-complete-search"
        type="text"
        class="form-control"
        name="searchTerm"
        style="height: 2rem"
        [(ngModel)]="service.searchTerm"
      />
    </div>
    <div
      style="
        width: 1px;
        border: solid rgba(0, 0, 0, 0.286) 1px;
        height: 2rem;
        margin: 0px 5px;
      "
    ></div>
    <div class="mb-3 row" style="height: 2rem">
      <label
        for="table-complete-reviewed"
        class="col-xs-3 col-sm-auto col-form-label"
        style="height: 2rem"
        >Only Incomplete:</label
      >
      <div class="col-xs-3 col-sm-auto center" style="height: 2rem">
        <input
          id="table-complete-reviewed"
          type="checkbox"
          class="form-control"
          name="reviewedCheck"
          style="
            width: 50px;
            height: 2rem;
            border-radius: 50%;
            background-color: white;
          "
          [(ngModel)]="service.reviewedCheck"
        />
      </div>
    </div>
    <div class="mb-3 row" style="height: 2rem">
      <label
        for="table-complete-reviewed"
        class="col-xs-3 col-sm-auto col-form-label"
        style="height: 2rem"
        >Possible Duplicates:</label
      >
      <div class="col-xs-3 col-sm-auto center" style="height: 2rem">
        <input
          id="table-complete-reviewed"
          type="checkbox"
          class="form-control"
          name="duplicates"
          style="
            width: 50px;
            height: 2rem;
            border-radius: 50%;
            background-color: white;
          "
          [(ngModel)]="service.duplicates"
        />
      </div>
    </div>
    <div class="mb-3 row" style="height: 2rem">
      <label
        for="table-complete-reviewed"
        class="col-xs-3 col-sm-auto col-form-label"
        style="height: 2rem"
        >Bad Times:</label
      >
      <div class="col-xs-3 col-sm-auto center" style="height: 2rem">
        <input
          id="table-complete-reviewed"
          type="checkbox"
          class="form-control"
          name="duplicates"
          style="
            width: 50px;
            height: 2rem;
            border-radius: 50%;
            background-color: white;
          "
          [(ngModel)]="service.badtimes"
        />
      </div>
    </div>
    <div
      style="
        width: 1px;
        border: solid rgba(0, 0, 0, 0.286) 1px;
        height: 2rem;
        margin: 0px 5px;
      "
    ></div>
    <span class="col col-form-label" *ngIf="service.loading$ | async"
      >Loading...</span
    >
  </div>

  <div class="mb-3 row" style="height: 2rem">
    <div class="col-xs-3 col-sm-auto" style="height: 2rem">
      <button id="add-artist" (click)="addArtist()">Add Artist</button>
    </div>
  </div>

  <table
    class="table table-striped table-hover table-borderless"
    data-detail-view="true"
  >
    <thead>
      <tr>
        <th scope="col" style="width: 20%">#</th>
        <th
          scope="col"
          style="width: 45%"
          sortable="name"
          (sort)="onSort($event)"
        >
          Name
        </th>
        <th
          scope="col"
          style="width: 15%"
          sortable="songs"
          (sort)="onSort($event)"
        >
          Songs
        </th>
        <th scope="col" style="width: 10%"></th>
        <th scope="col" style="width: 10%"></th>
      </tr>
    </thead>
    <tbody style="width: 100%">
      <ng-container
        *ngFor="let country of countries$ | async"
        style="all: inherit"
      >
        <tr
          style="height: 50px"
          [style.border-bottom]="lastClicked == country.key ? '0px' : '1px'"
          (click)="lastClicked = lastClicked == country.key ? '' : country.key"
        >
          <th
            scope="row"
            style="width: 20%; overflow: hidden; font-size: small"
          >
            {{ country.key }}
          </th>

          <td style="width: 45%">
            <ngb-highlight
              [result]="country.name"
              [term]="service.searchTerm"
            ></ngb-highlight>
          </td>
          <td style="width: 15%">
            <ngb-highlight
              [result]="country.songs.length | number"
              [term]="service.searchTerm"
            ></ngb-highlight>
          </td>
          <td style="width: 10">
            <button
              class="btn btn-primary btn-block"
              style="height: 50px"
              type="button"
              (click)="$event.stopPropagation(); edit(country)"
            >
              Edit
            </button>
          </td>

          <td style="width: 10">
            <button
              class="btn btn-primary btn-block"
              style="height: 50px"
              type="button"
              (click)="$event.stopPropagation(); addSong(country)"
            >
              Add
            </button>
          </td>
        </tr>

        <tr *ngIf="lastClicked === country.key">
          <td></td>

          <div class="table-wrapper">
            <div class="table-scroll">
              <table>
                <thead></thead>
                <tbody>
                  <tr
                    *ngFor="let song of country.songs; let i = index"
                    [style.background-color]="
                      song.reviewed == 'reviewed' ? 'inherit' : 'green'
                    "
                  >
                    <ng-container *ngIf="song">
                      <td style="width: 65%">
                        <ngb-highlight
                          [result]="song.name"
                          [term]="service.searchTerm"
                        ></ngb-highlight>
                      </td>
                    </ng-container>
                    <td style="width: 35">
                      <button
                        class="btn btn-primary btn-block"
                        style="height: 50px"
                        type="button"
                        (click)="
                          $event.stopPropagation();
                          editSong(content2, country, song)
                        "
                      >
                        Edit
                      </button>
                    </td>
                    <td style="width: 35" *ngIf="!song.toDelete">
                      <button
                        class="btn btn-primary btn-block"
                        style="height: 50px"
                        type="button"
                        (click)="
                          $event.stopPropagation();
                          moveSong(content2, country, song)
                        "
                      >
                        Move
                      </button>
                    </td>

                    <td style="width: 35" *ngIf="song.toDelete">moved</td>
                    <div *ngIf="!song">{{ index }} is there but no song</div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <td></td>
          <td></td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination
      [collectionSize]="(total$ | async)!"
      [(page)]="service.page"
      [pageSize]="service.pageSize"
      [boundaryLinks]="true"
      [maxSize]="10"
      [ellipses]="false"
      size="sm"
    >
    </ngb-pagination>

    <select
      class="form-select"
      style="width: auto"
      name="pageSize"
      [(ngModel)]="service.pageSize"
    >
      <option [ngValue]="5">5 items per page</option>
      <option [ngValue]="10">10 items per page</option>
      <option [ngValue]="15">15 items per page</option>
    </select>
  </div>
</form>

<div
  style="position: absolute; right: 10px; top: 10px; width: 300px; height: auto"
>
  <button (click)="showSongsToAdd = !showSongsToAdd">Songs To Add</button>
  <div *ngIf="showSongsToAdd" style="background-color: #535353">
    <div
      *ngFor="let song of songsToAdd"
      style="border: 1px solid black; display: flex; flex-direction: column"
    >
      <div style="color: white">
        {{ song.name }} <br />
        {{ song.artist }}
      </div>
      <div>
        <button (click)="added(song)">Added</button>
      </div>
    </div>
  </div>
</div>