<div class="actions staff-info-header">
  <button
    (click)="page = -1"
    [class]="
      page == -1 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    General
  </button>
  <button
    (click)="page = 0"
    [class]="
      page == 0 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    Company Info
  </button>
  <button
    (click)="page = 1"
    [class]="
      page == 1 ? 'green  reactive-button-title' : 'reactive-button-title'
    "
  >
    Till Information
  </button>

  <button
    (click)="page = 2"
    [class]="
      page == 2 ? 'green  reactive-button-title' : 'reactive-button-title'
    "
  >
    Calendar Information
  </button>
</div>

<!--Editing-->
<div class="staff-info-content" *ngIf="page == -1">
  <app-input
    type="text"
    name="Bar Name"
    [model]="bar.name"
    (changed)="bar.name = $event"
  ></app-input>
  <app-input
    type="text"
    name="Type"
    [editable]="false"
    [model]="bar.type"
    (changed)="bar.type = $event"
  ></app-input>

  <app-input
    type="text"
    name="Manager"
    type="select"
    [listHideAll]="true"
    [model]="manager"
    [list]="staffUids"
    (changed)="managerChanged($event)"
  ></app-input>
</div>

<div class="staff-info-content" *ngIf="page == 0">
  <app-input
    type="text"
    name="Company Name (e.g. Daga Groningen)"
    [model]="bar.companyInfo.name"
    (changed)="bar.companyInfo.name = $event"
  >
  </app-input>

  <app-input
    type="text"
    name="Street Name"
    [model]="bar.companyInfo.address"
    (changed)="bar.companyInfo.address = $event"
  ></app-input>

  <app-input
    type="text"
    name="House Number"
    [model]="bar.companyInfo.number"
    (changed)="bar.companyInfo.number = $event"
  ></app-input>

  <app-input
    type="text"
    name="Company City"
    [model]="bar.companyInfo.city"
    (changed)="bar.companyInfo.city = $event"
  ></app-input>

  <app-input
    type="text"
    name="Company Postal Code"
    [model]="bar.companyInfo.zip"
    (changed)="bar.companyInfo.zip = $event"
  ></app-input>

  <app-input
    type="text"
    name="Company Country"
    [model]="bar.companyInfo.country"
    (changed)="bar.companyInfo.country = $event"
  ></app-input>

  <app-input
    type="text"
    name="Company Email"
    [model]="bar.companyInfo.email"
    (changed)="bar.companyInfo.email = $event"
  ></app-input>

  <app-input
    type="text"
    name="Facturen Email"
    [model]="bar.companyInfo.facturenemail"
    (changed)="bar.companyInfo.facturenemail = $event"
  ></app-input>

  <app-input
    type="text"
    name="IBAN"
    [model]="bar.companyInfo.iban"
    (changed)="bar.companyInfo.iban = $event"
  ></app-input>

  <app-input
    type="text"
    name="KVK"
    [model]="bar.companyInfo.kvk"
    (changed)="bar.companyInfo.kvk = $event"
  ></app-input>

  <app-input
    type="text"
    name="BTW"
    [model]="bar.companyInfo.btw"
    (changed)="bar.companyInfo.btw = $event"
  ></app-input>
</div>

<div class="staff-info-content" *ngIf="page == 1">
  <!-- Till Information from tillInformation.ts class -->
  <!-- password, url, usrename, token, path, database, salesAreaId, cashReference, internetReference, pinReference, staffReference, vipHalf, vipHour, internetQRReference, missingReference, postCode, privateBookings (map), staffDiscountReason-->
  <app-input
    type="text"
    name="Password"
    [model]="bar.tillInformation.password"
    (changed)="bar.tillInformation.password = $event"
  ></app-input>

  <app-input
    type="text"
    name="URL"
    [model]="bar.tillInformation.url"
    (changed)="bar.tillInformation.url = $event"
  ></app-input>

  <app-input
    type="text"
    name="Username"
    [model]="bar.tillInformation.username"
    (changed)="bar.tillInformation.username = $event"
  ></app-input>

  <app-input
    type="text"
    name="Token"
    [model]="bar.tillInformation.token"
    (changed)="bar.tillInformation.token = $event"
  ></app-input>

  <app-input
    type="text"
    name="Path"
    [model]="bar.tillInformation.path"
    (changed)="bar.tillInformation.path = $event"
  ></app-input>

  <app-input
    type="text"
    name="Database"
    [model]="bar.tillInformation.database"
    (changed)="bar.tillInformation.database = $event"
  ></app-input>

  <app-input
    type="text"
    name="Sales Area Id"
    [model]="bar.tillInformation.salesAreaId"
    (changed)="bar.tillInformation.salesAreaId = $event"
  ></app-input>

  <app-input
    type="text"
    name="Cash Reference"
    [model]="bar.tillInformation.cashReference"
    (changed)="bar.tillInformation.cashReference = $event"
  ></app-input>

  <app-input
    type="text"
    name="Internet Reference"
    [model]="bar.tillInformation.internetReference"
    (changed)="bar.tillInformation.internetReference = $event"
  ></app-input>

  <app-input
    type="text"
    name="Pin Reference"
    [model]="bar.tillInformation.pinReference"
    (changed)="bar.tillInformation.pinReference = $event"
  ></app-input>

  <app-input
    type="text"
    name="Staff Reference"
    [model]="bar.tillInformation.staffReference"
    (changed)="bar.tillInformation.staffReference = $event"
  ></app-input>

  <app-input
    type="text"
    name="VIP Half"
    [model]="bar.tillInformation.vipHalf"
    (changed)="bar.tillInformation.vipHalf = $event"
  ></app-input>

  <app-input
    type="text"
    name="VIP Hour"
    [model]="bar.tillInformation.vipHour"
    (changed)="bar.tillInformation.vipHour = $event"
  ></app-input>

  <app-input
    type="text"
    name="Internet QR Reference"
    [model]="bar.tillInformation.internetQRReference"
    (changed)="bar.tillInformation.internetQRReference = $event"
  ></app-input>

  <app-input
    type="text"
    name="Missing Reference"
    [model]="bar.tillInformation.missingReference"
    (changed)="bar.tillInformation.missingReference = $event"
  ></app-input>

  <app-input
    type="text"
    name="Post Code"
    [model]="bar.tillInformation.postCode"
    (changed)="bar.tillInformation.postCode = $event"
  ></app-input>

  <app-input
    type="text"
    name="Staff Discount Reason"
    [model]="bar.tillInformation.staffDiscountReason"
    (changed)="bar.tillInformation.staffDiscountReason = $event"
  ></app-input>
</div>

<div class="staff-info-content" *ngIf="page == 2">
  <!-- Calendar Info From VIPInformation.ts-->
  <!-- key, password, secret, username, website, customerId, agendaId, appointmentType-->
  <app-input
    type="text"
    name="Key"
    [model]="bar.vipInformation.key"
    (changed)="bar.vipInformation.key = $event"
  ></app-input>

  <app-input
    type="text"
    name="Password"
    [model]="bar.vipInformation.password"
    (changed)="bar.vipInformation.password = $event"
  ></app-input>

  <app-input
    type="text"
    name="Secret"
    [model]="bar.vipInformation.secret"
    (changed)="bar.vipInformation.secret = $event"
  ></app-input>

  <app-input
    type="text"
    name="Username"
    [model]="bar.vipInformation.username"
    (changed)="bar.vipInformation.username = $event"
  ></app-input>

  <app-input
    type="text"
    name="Website"
    [model]="bar.vipInformation.website"
    (changed)="bar.vipInformation.website = $event"
  ></app-input>

  <app-input
    type="text"
    name="Customer Id"
    [model]="bar.vipInformation.customerId"
    (changed)="bar.vipInformation.customerId = $event"
  ></app-input>

  <app-input
    type="text"
    name="Agenda Id"
    [model]="bar.vipInformation.agendaId"
    (changed)="bar.vipInformation.agendaId = $event"
  ></app-input>

  <app-input
    type="text"
    name="Appointment Type"
    [model]="bar.vipInformation.appointmentType"
    (changed)="bar.vipInformation.appointmentType = $event"
  ></app-input>
</div>

<div class="divider"></div>

<div class="actions">
  <button class="reactive-button-title red" (click)="clear()">Clear</button
  ><button
    class="reactive-button-title green"
    ngbTooltip="Currently Only Needs A Bar Name"
    (click)="verify()"
  >
    Verify</button
  ><button class="reactive-button-title green" (click)="save()">Save</button>
</div>
<p>
  Note: If this is done by accident or a mistake is made contact your system
  admin, this is a one time thing, any editing of the bar has to be done in its
  respective places elsewhere
</p>
