import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CleaningTask } from "src/app/Classes/cleaning-task";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { CleaningService } from "src/app/Services/cleaning.service";
import { LoadingService } from "src/app/Services/loading.service";
import { CleaningModalComponent } from "../cleaning-modal/cleaning-modal.component";

@Component({
  selector: "app-cleaning",
  templateUrl: "./cleaning.component.html",
  styleUrls: ["./cleaning.component.css"],
})
export class CleaningComponent implements OnInit {
  cleaningTasks: CleaningTask[] = [];
  sortBy = {
    key: "name",
    reverse: false,
  };
  selected: CleaningTask;
  viewRecords: CleaningTask;
  page = 1;
  frequencies = [
    {
      id: 0,
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    {
      id: 1,
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    {
      id: 2,
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    {
      id: 3,
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    {
      id: 4,
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    {
      id: 5,
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  ];

  staffSelected: any = null;
  weekly = [
    { name: "Monday", value: 1, frequencies: [] },
    { name: "Tuesday", value: 2, frequencies: [] },
    { name: "Wednesday", value: 3, frequencies: [] },
    { name: "Thursday", value: 4, frequencies: [] },
    { name: "Friday", value: 5, frequencies: [] },
    { name: "Saturday", value: 6, frequencies: [] },
    { name: "Sunday", value: 0, frequencies: [] },
  ];

  staff: any[] = [];

  completionDownloadURLS = [];
  staffCleaning: any;
  constructor(
    public cleaning: CleaningService,
    public loading: LoadingService,
    public bi: BarInfo,
    public alert: AlertsService,
    public modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    this.loading.show();
    await this.getCleaningTasks();
    this.staff = await this.cleaning.getStaff();
    this.loading.hide();
  }

  async calculateStaffCleaning() {
    if (this.staffCleaning) {
      return;
    }

    this.loading.show();

    // go over each cleaning task and get the download urls

    // go back 30 days
    var date2 = new Date();
    date2.setDate(date2.getDate() - 30);

    var today = new Date();

    // go over each day
    var promises = [];
    while (date2 < today) {
      var promise = new Promise(async (resolve, reject) => {
        const date = new Date(date2);
        var day = {
          date: new Date(date),
          hours: [],
          tasks: [],
          staff: {},
        };
        //get shift date 2025/0/1
        day.hours = await this.cleaning.getStaffWorking(
          this.bi.bar,
          new Date(date),
          new Date(date),
          true
        );

        // do any of the download urls match this date?

        for (var url of this.completionDownloadURLS) {
          for (var item of url.items) {
            if (
              new Date(item.date).toDateString() ==
              new Date(date).toDateString()
            ) {
              day.tasks.push({ key: url.key, item: item.date });
            }
          }
        }

        for (var s of this.staff) {
          for (var task of day.tasks) {
            //check if the staff memeber worked when the task was completed
            var hourOfCleaning = new Date(task.item).getHours() * 100;
            if (hourOfCleaning < 600) {
              hourOfCleaning += 2400;
            }
            var workRecord = day.hours.find((a) => a.key == s.uid);
            if (!workRecord) {
              day.staff[s.uid] = {};
              continue;
            }
            var startTime = workRecord.startTime;
            var endTime = workRecord.endTime;

            if (startTime < 600) {
              startTime += 2400;
            }

            if (endTime < 600) {
              endTime += 2400;
            }

            if (hourOfCleaning >= startTime && hourOfCleaning <= endTime) {
              if (!day.staff[s.uid]) {
                day.staff[s.uid] = {};
              }
              day.staff[s.uid][task.key] = true;
            }
          }
        }
        console.log(Object.keys(day.staff).length);
        resolve(day);
      });
      promises.push(promise);
      date2.setDate(date2.getDate() + 1);
    }

    this.staffCleaning = await Promise.all(promises);

    console.log(this.staffCleaning);
    this.loading.hide();
  }

  async getCleaningTasks() {
    try {
      this.cleaningTasks = await this.cleaning.getCleaningTasks();
      this.setSortBy("title", false);
      console.log(this.cleaningTasks);
      // sort into frequencies
      for (let i = 0; i < this.cleaningTasks.length; i++) {
        if (
          !this.cleaningTasks[i].days ||
          isNaN(this.cleaningTasks[i].frequency) ||
          this.cleaningTasks[i].frequency < 0
        ) {
          continue;
        }
        if (this.cleaningTasks[i].days.includes("0")) {
          this.frequencies[this.cleaningTasks[i].frequency].sunday.push(
            this.cleaningTasks[i]
          );
        }
        if (this.cleaningTasks[i].days.includes("1")) {
          this.frequencies[this.cleaningTasks[i].frequency].monday.push(
            this.cleaningTasks[i]
          );
        }
        if (this.cleaningTasks[i].days.includes("2")) {
          this.frequencies[this.cleaningTasks[i].frequency].tuesday.push(
            this.cleaningTasks[i]
          );
        }
        if (this.cleaningTasks[i].days.includes("3")) {
          this.frequencies[this.cleaningTasks[i].frequency].wednesday.push(
            this.cleaningTasks[i]
          );
        }
        if (this.cleaningTasks[i].days.includes("4")) {
          this.frequencies[this.cleaningTasks[i].frequency].thursday.push(
            this.cleaningTasks[i]
          );
        }
        if (this.cleaningTasks[i].days.includes("5")) {
          this.frequencies[this.cleaningTasks[i].frequency].friday.push(
            this.cleaningTasks[i]
          );
        }
        if (this.cleaningTasks[i].days.includes("6")) {
          this.frequencies[this.cleaningTasks[i].frequency].saturday.push(
            this.cleaningTasks[i]
          );
        }
      }

      this.completionDownloadURLS = await this.cleaning.getCleaningTaskRecords(
        this.cleaningTasks
      );
      console.log(this.completionDownloadURLS);
    } catch (error) {
      console.error(error);
      this.alert.nextEmit(AlertsService.error(error));
    } finally {
    }
  }

  setSortBy(key, changeDirection = true) {
    console.log("sortuing on", key, changeDirection);
    if (this.sortBy.key === key && !changeDirection) {
    } else if (this.sortBy.key === key) {
      this.sortBy.reverse = !this.sortBy.reverse;
    } else {
      this.sortBy.key = key;
      this.sortBy.reverse = false;
    }

    this.cleaningTasks = this.cleaningTasks.sort((a, b) => {
      if (a[this.sortBy.key] === undefined || a[this.sortBy.key] === null) {
        return 1;
      }
      if (b[this.sortBy.key] === undefined || b[this.sortBy.key] === null) {
        return -1;
      }

      if (a[this.sortBy.key] < b[this.sortBy.key]) {
        return this.sortBy.reverse ? 1 : -1;
      } else if (a[this.sortBy.key] > b[this.sortBy.key]) {
        return this.sortBy.reverse ? -1 : 1;
      } else {
        return 0;
      }
    });
  }

  openItem(cleaningTask: CleaningTask) {
    //on records page you click to see the record
    if (this.page == 3) {
      return this.processRecord(cleaningTask);
    }
    const modal = this.modal.open(CleaningModalComponent, {
      size: "lg",
      backdrop: "static",
    });

    modal.componentInstance.cleaningTask = Object.assign(
      new CleaningTask(),
      cleaningTask
    );

    modal.result.then((result) => {
      if (result === "save") {
        this.getCleaningTasks();
      }
    });
  }

  hoverItem(cleaningTask: CleaningTask) {
    this.selected = cleaningTask;
  }

  async processRecord(cleaningTask: CleaningTask) {
    if (this.viewRecords == cleaningTask) {
      this.viewRecords = null;
      return;
    }
    this.viewRecords = cleaningTask;
    var completionRecord = this.completionDownloadURLS.find(
      (a) => a.key == cleaningTask.ref
    );
    console.log(completionRecord);

    if (!completionRecord) {
      return;
    }

    this.loading.show();

    for (let item of completionRecord.items) {
      item.staff = await this.cleaning.getStaffWorking(
        this.bi.bar,
        new Date(item.shiftDate),
        new Date(item.date)
      );
    }

    this.viewRecords.records = completionRecord.items;
    console.log(completionRecord);
    this.loading.hide();
  }

  async openPhoto(ref, key) {
    this.loading.show();
    var downloadURL = await this.cleaning.getCleaningTaskDownloadURL(key, ref);
    window.open(downloadURL, "_blank");
    this.loading.hide();
  }
}
