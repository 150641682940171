import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ejournal-modal',
  templateUrl: './ejournal-modal.component.html',
  styleUrls: ['./ejournal-modal.component.css']
})
export class EJournalModalComponent implements OnInit {

  @Input('receipt') receipt:  string;
  @Input('receiptTitle') receiptTitle: string;

  formattedReceipt: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.formatReceipt();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['receipt']) {
      this.formatReceipt();
    }
  }

  formatReceipt() {

  }

  close(){
    this.modal.close();
  }

  download(){
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.formattedReceipt));
    element.setAttribute('download', this.receiptTitle +  '.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
