export class InvoiceCompany {
  id: string = "";
  name: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  zip: string = "";
  phone: string = "";
  email: string = "";
  kvk: string = "";
  iban: string = "";
  btw: string = "";
  logo: string = ""; //image as string
}
