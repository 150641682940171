import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { Auth } from "src/app/auth/auth";
import { Firebase } from "src/app/Services/firebase";

import { LoadingService } from "src/app/Services/loading.service";


@Component({
  selector: "app-customer-app",
  templateUrl: "./customer-app.component.html",
  styleUrls: ["./customer-app.component.css"],
})
export class CustomerAppComponent implements OnInit {
  messages: string = '';
  constructor(public db: Firebase, private loading: LoadingService, public auth: Auth) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.db.user.subscribe(val => {
        console.log(val);

      })
    }, 50)



  }

  async login(email, password) {
    console.log(email)
    this.messages = await this.db.login(email, password).then(a => '').catch(e => e + '');
  }

  async forgot(email) {
    this.messages = await this.db.forgotPassword(email).then(a => '').catch(e => e + '');
  }

  async signOut() {

    this.db.logout()
  }
}
