export class CleaningTask {
  bar: string;
  complete: boolean;
  days: string;
  end: number;
  endShift: boolean;
  frequency: number;
  lastAttempted: number;
  lastComplete: number;
  lastReviewed: number;
  level: number;
  message: string;
  ref: string;
  start: number;
  title: string;
  records: any[] = [];

  constructor() {}
}
