import { Component, OnInit, Inject } from "@angular/core";

import { MatSnackBar } from "@angular/material/snack-bar";

import * as XLSX from "xlsx";
import { Router } from "@angular/router";
import { NgIf } from "@angular/common";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "src/app/auth/auth";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-staffhours",
  templateUrl: "./staffhours.component.html",
  styleUrls: ["./staffhours.component.css"],
})
export class StaffhoursComponent implements OnInit {
  startDate = "";
  endDate = "";

  start: Date = null;
  end: Date = null;
  bar = "";
  bars = [];
  hours = [];
  staff = [];
  reviews = [];
  review: any;

  table: any[][] = null;
  days: Date[] = [];
  cleaning = false;

  subs: Subscription[] = [];

  addingShift = false;
  reviewingWeek = false;
  selectedStaff;
  dbstaff = [];
  sDate;
  sStart;
  sEnd;

  shift = null;
  filteredStaff = [];
  filterOn: string = "location";
  reviewMonth = false;
  constructor(
    public database: StaffAppDatabase,
    private _snackBar: MatSnackBar,
    private router: Router,
    private bi: BarInfo,
    private alert: AlertsService,
    private modal: NgbModal,
    private auth: Auth,
    private loading: LoadingService
  ) {
    this.table = [];
    //console.log(this.router.url);
    if (this.router.url === "/personeel/cleaning") {
      this.cleaning = true;
    } else {
      this.cleaning = false;
    }
    this.subs.push(
      this.bi.barObserver.subscribe((val) => {
        this.bar = val;
      })
    );

    this.getData();

    this.subs.push(
      this.database.hoursObserver.subscribe((hours) => {
        this.table = [];
        if (hours != null) {
          console.log("got hours", hours);
          this.fixTable(hours);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }

  async getData() {
    this.dbstaff = [];
    this.dbstaff = await this.database
      .getStaff()
      .pipe(take(1))
      .toPromise()
      .then((a) => {
        const list = a.filter(
          (s: any) =>
            s.firstName &&
            s.lastName &&
            s.lastName != "na" &&
            s.access >= 1 &&
            s.bar != "Pertempto" &&
            s.bar != "Old Staff"
        );
        return list.sort((b: any, c: any) => {
          if (b.bar == this.bar && c.bar != this.bar) {
            return -1;
          } else if (b.bar != this.bar && c.bar == this.bar) {
            return 1;
          }

          if (b.bar != c.bar) {
            return b.bar.localeCompare(c.bar);
          }
          return b.firstName
            .toLowerCase()
            .localeCompare(c.firstName.toLowerCase(), "en");
        });
      })
      .catch((e) => []);
    this.doFilterStaff();
  }

  doFilterStaff() {
    if (this.filterOn == "") {
      this.filteredStaff = this.dbstaff;
    } else if (this.filterOn == "location") {
      this.filteredStaff = this.dbstaff.filter((a) => {
        return a.bar == this.bar;
      });
    } else {
      this.filteredStaff = this.dbstaff.filter((a) => {
        return (
          a.firstName.toLowerCase().includes(this.filterOn.toLowerCase()) ||
          a.lastName.toLowerCase().includes(this.filterOn.toLowerCase())
        );
      });
    }
  }

  ngOnInit(): void {}

  fixTable(hours) {
    this.staff = [];
    hours.forEach((h) => {
      if (this.staff.indexOf(h.name) == -1) {
        this.staff.push(h.name);
      }
    });
    this.staff.sort();

    //make array of correct length
    if (this.reviewMonth) {
      this.table = new Array(this.days.length + 3);
    } else {
      this.table = new Array(this.days.length + 2);
    }

    for (let i = 0; i < this.table.length; i++) {
      this.table[i] = new Array(this.staff.length + 2);
      for (let j = 0; j < this.staff.length + 2; j++) {
        this.table[i][j] = 0;
      }
    }

    this.table[0][0] = this.bar;

    for (let i = 0; i < this.staff.length; i++) {
      this.table[0][i + 1] = this.staff[i].substring(0, 15);
    }

    for (let i = 0; i < this.days.length; i++) {
      this.table[i + 1][0] = this.days[i].toLocaleDateString().split(" ");
    }

    hours.forEach((h) => {
      if (
        this.days
          .map(Number)
          .indexOf(new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()) ==
        -1
      ) {
        //possible error
      } else if (this.staff.indexOf(h.name) == -1) {
        //possible error
      } else {
        if (this.cleaning && h.cleaning) {
          let num = Number(
            this.table[
              1 +
                this.days
                  .map(Number)
                  .indexOf(
                    new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                  )
            ][this.staff.indexOf(h.name) + 1]
          );
          num += Number(h.total);
          //console.log(h.total, num);
          this.table[
            1 +
              this.days
                .map(Number)
                .indexOf(
                  new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                )
          ][this.staff.indexOf(h.name) + 1] = (
            Math.round(num * 100) / 100
          ).toFixed(2);
          //console.log(this.table);
        } else if (!this.cleaning && !h.cleaning) {
          let num = Number(
            this.table[
              1 +
                this.days
                  .map(Number)
                  .indexOf(
                    new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                  )
            ][this.staff.indexOf(h.name) + 1]
          );
          num += Number(h.total);
          //console.log(h.total, num);
          this.table[
            1 +
              this.days
                .map(Number)
                .indexOf(
                  new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                )
          ][this.staff.indexOf(h.name) + 1] = (
            Math.round(num * 100) / 100
          ).toFixed(2);
          //console.log(this.table);
        }
      }
    });

    if (this.reviewMonth) {
      //staff totals
      this.table[this.table.length - 2][0] = "Total";
      for (let i = 1; i < this.staff.length + 1; i++) {
        let sum = 0;
        for (let j = 1; j < this.days.length + 1; j++) {
          //console.log(sum);
          sum += Number(this.table[j][i]);
        }
        this.table[this.table.length - 2][i] = (
          Math.round(sum * 100) / 100
        ).toFixed(2);
      }

      this.table[this.table.length - 1][0] = "Total / 7";
      for (let i = 1; i < this.staff.length + 1; i++) {
        let sum = 0;
        for (let j = 1; j < this.days.length + 1; j++) {
          //console.log(sum);
          sum += Number(this.table[j][i]);
        }
        this.table[this.table.length - 1][i] = Math.round(sum / 7).toFixed(0);
      }
    } else {
      //staff totals
      this.table[this.table.length - 1][0] = "Total";
      for (let i = 1; i < this.staff.length + 1; i++) {
        let sum = 0;
        for (let j = 1; j < this.days.length + 1; j++) {
          //console.log(sum);
          sum += Number(this.table[j][i]);
        }
        this.table[this.table.length - 1][i] = (
          Math.round(sum * 100) / 100
        ).toFixed(2);
      }
    }

    //day totals
    this.table[0][this.table[0].length - 1] = "Total";
    for (let i = 0; i <= this.days.length; i++) {
      let sum = 0;
      for (let j = 1; j <= this.staff.length; j++) {
        sum += Number(this.table[i + 1][j]);
      }
      this.table[i + 1][this.staff.length + 1] = (
        Math.round(sum * 100) / 100
      ).toFixed(2);
    }
    if (this.reviewMonth) {
      console.log(
        this.days.length,
        this.staff.length + 1,
        this.table[this.days.length - 1][this.staff.length + 1]
      );
      this.table[this.days.length + 2][this.staff.length + 1] = Number(
        this.table[this.days.length + 1][this.staff.length + 1] / 7
      ).toFixed(2);
    }

    console.log(this.table);
  }

  getHours() {
    //console.log("Getting Hours");
    if (
      this.startDate == "" ||
      this.endDate == "" ||
      this.bar == "" ||
      !this.bar
    ) {
      this.alert.nextEmit(
        AlertsService.error(
          "Missing Values",
          "Missing StartDate, EndDate or Bar"
        )
      );
      this.loading.nextEmit(null);
      return;
    }
    let start = new Date(this.startDate);

    let end = new Date(this.endDate);

    if (start >= end) {
      this.alert.nextEmit(
        AlertsService.error(
          "Dates in invalid order",
          "Start Date is after End Date"
        )
      );
      this.endDate = this.startDate;
      this.loading.nextEmit(null);
      return;
    }
    start.setHours(10);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(10);
    end.setMinutes(0);
    end.setSeconds(0);
    end.setMilliseconds(0);

    this.start = start;
    this.end = end;

    this.getDates(start, end);

    let startSplits = this.startDate
      .split("T")[0]
      .split("-")
      .map((x) => Number(x));
    let endSplits = this.endDate
      .split("T")[0]
      .split("-")
      .map((x) => Number(x));
    console.log(
      this.bar,
      startSplits[0],
      startSplits[1] - 1,
      startSplits[2],
      endSplits[0],
      endSplits[1] - 1,
      endSplits[2]
    );
    this.database.getHours(
      this.bar,
      startSplits[0],
      startSplits[1] - 1,
      startSplits[2],
      endSplits[0],
      endSplits[1] - 1,
      endSplits[2],
      this.cleaning
    );
    this.loading.nextEmit(null);
  }

  getDates(startDate: Date, stopDate: Date) {
    stopDate.setHours(23, 59, 59, 0);
    let temp = [];

    while (startDate <= stopDate) {
      temp.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
    this.days = temp;
  }

  filename = "";
  makeExcel() {
    if (this.cleaning) {
      this.filename =
        this.bar +
        "_cleaning_" +
        this.start.toISOString() +
        "-" +
        this.end.toISOString() +
        ".xlsx";
    } else {
      this.filename =
        this.bar +
        "_" +
        this.start.toISOString() +
        "-" +
        this.end.toISOString() +
        ".xlsx";
    }

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("table"),
      { raw: true }
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, this.filename);
  }

  cancelShift() {
    //console.log(this.selectedStaff, this.sDate, this.sStart, this.sEnd);
    this.selectedStaff = null;
    this.sDate = null;
    this.sStart = null;
    this.sEnd = null;
    this.addingShift = false;
  }

  resetShift() {
    this.selectedStaff = null;
    this.sDate = null;
    this.sStart = null;
    this.sEnd = null;
  }

  deleteShift(content: any) {
    if (!this.selectedStaff) {
      this.alert.nextEmit(AlertsService.error("Unknown Staff"));
      return;
    }

    if (!this.sDate) {
      this.alert.nextEmit(AlertsService.error("Unknown Date"));
      return;
    }

    var date = new Date(this.sDate);
    //console.log;
    var shift: any = {};
    shift.cleaning = this.cleaning;
    shift.day = date.getDate();
    shift.month = date.getMonth();
    shift.year = date.getFullYear();
    console.log(this.selectedStaff);
    console.log(this.dbstaff.filter((a) => a.uid === this.selectedStaff.uid));
    try {
      shift.name = this.dbstaff.filter(
        (a) => a.uid === this.selectedStaff.uid
      )[0]!.firstName;
    } catch (e) {
      try {
        shift.name = this.dbstaff.filter(
          (a) => a.uid === this.selectedStaff.uid
        )[0]!.displayName;
      } catch (e) {
        console.log(e);
        shift.name = "na";
      }
    }

    shift.key = this.selectedStaff.uid;

    this.shift = Object.assign({}, shift);

    this.modal.open(content).result.then(
      (a) => {
        //delete

        this.database
          .deleteShift(this.bar, date, shift, this.cleaning)
          .then((a) => {
            this.table = null;
            this.alert.nextEmit(
              AlertsService.success(
                "Shift Deleted",
                shift.name + " on: " + this.sDate
              )
            );
            this.shift = null;
            this.getHours();
          })
          .catch((e) => {
            console.error(e);
            this.alert.nextEmit(
              AlertsService.error("Failed to delete shift", e)
            );
          });

        try {
          this.database.changedRecordOld(
            this.bi.bar,
            this.auth.user.uid,
            shift,
            "hours",
            date.getTime() + "_" + shift.key,
            "deleting"
          );
        } catch (e) {}
      },
      (b) => {}
    );
  }

  confirmShift() {
    console.log(this.selectedStaff, this.sDate, this.sStart, this.sEnd);
    var date = new Date(this.sDate);
    //console.log;
    var shift: any = {};
    shift.cleaning = this.cleaning;
    shift.day = date.getDate();
    shift.month = date.getMonth();
    shift.year = date.getFullYear();

    if (!this.selectedStaff) {
      this.alert.nextEmit(AlertsService.error("Unknown Staff"));
      return;
    }

    if (!this.sDate) {
      this.alert.nextEmit(AlertsService.error("Unknown Date"));
      return;
    }
    if (!this.sStart || !this.sEnd) {
      this.alert.nextEmit(AlertsService.error("Unknown Start/End Times"));
      return;
    }

    shift.name = this.dbstaff.filter(
      (a) => a.uid === this.selectedStaff.uid
    )[0].firstName;
    shift.key = this.selectedStaff.uid;

    const start = this.sStart.split(":");
    const end = this.sEnd.split(":");
    var total = 0;
    if (Number(start[0]) > Number(end[0])) {
      total += Number(end[0]) + (24 - Number(start[0]));
    } else {
      total += Number(end[0]) - Number(start[0]);
    }

    var mins = -Number(start[1]) + Number(end[1]);

    while (mins >= 60) {
      total += 1;
      mins -= 60;
    }

    total += mins / 60;
    shift.total = total;
    //console.log(start.join(""), end);
    shift.startTime = Number(start.join(""));
    shift.endTime = Number(end.join(""));

    if (
      !shift.day ||
      shift.endTime == null ||
      shift.startTime == null ||
      !shift.name ||
      !shift.key
    ) {
      alert("missing info?" + JSON.stringify(shift));

      return;
    }

    this.database
      .setShift(this.bar, date, shift, this.cleaning)
      .then((a) => {
        this.alert.nextEmit(
          AlertsService.success(
            "Shift addded",
            shift.name + " worked: " + shift.startTime + " - " + shift.endTime
          )
        );

        try {
          this.database.changedRecordOld(
            this.bi.bar,
            this.auth.user.uid,
            shift,
            "hours",
            date.getTime() + "_" + shift.key,
            "shift set"
          );
        } catch (e) {}
        this.selectedStaff = null;
        this.getHours();
      })
      .catch((e) => {
        this.alert.nextEmit(AlertsService.error("Failed to adds shift", e));
      });
  }

  //reviews

  async approve(approved = true) {
    this.loading.nextEmit("on");

    if (this.bar == "Daga Beheer" && this.auth.accessUser.access <= 4) {
      this.alert.nextEmit(
        AlertsService.error(
          "Invalid Acccess",
          "You do not have access to approve Daga Beheer hours, Sorry Jan"
        )
      );
      this.loading.nextEmit(null);
    } else {
      this.reviewingWeek = false;
      this.review.reviewed = approved;
      await this.database.setHourReview(this.bar, this.review);
      this.seeReviews();
    }
  }

  async seeMonthReviews() {
    var date = this.startDate ? new Date(this.startDate) : new Date();
    if (date.getDate() >= 21) {
      this.startDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        21,
        12,
        0,
        0
      ).toISOString();
      this.endDate = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        20,
        12,
        0,
        0
      ).toISOString();
    } else {
      this.startDate = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        21,
        12,
        0,
        0
      ).toISOString();
      this.endDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        20,
        12,
        0,
        0
      ).toISOString();
    }
    this.getHours();
  }

  async seeReviews() {
    this.loading.nextEmit("on");
    this.reviews = [];
    this.review = {};

    this.reviews = await this.database.getHourReviews(this.bar);
    this.reviews = this.reviews.reverse();

    console.log(this.reviews);

    var date = new Date();
    date.setDate(date.getDate() - 1);
    while (date.getDay() < 6) {
      date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1,
        10,
        0,
        0,
        0
      );
    }

    console.log(
      date,
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 6,
        10,
        0,
        0
      )
    );

    console.log(this.auth.user, this.auth.accessUser, this.auth.firebaseUser);
    this.review = {
      start: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 6,
        10,
        0,
        0
      ).getTime(),
      end: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        10,
        0,
        0
      ).getTime(),
      bar: this.bar,
      timeOfReview: new Date().getTime(),
      reviewed: null,
      reviewedBy:
        (this.auth.accessUser.firstName || "na").substring(0, 10) +
          " " +
          this.auth.accessUser.lastName[0] || "na",
      uid: this.auth.accessUser.uid,
    };

    this.startDate = new Date(this.review.start).toISOString().split("T")[0];
    this.endDate = new Date(this.review.end).toISOString().split("T")[0];

    const possibles = this.reviews.filter((a) => a.start === this.review.start);
    if (possibles.length) {
      this.review = possibles[0];
      this.reviews = this.reviews.filter((a) => a.start !== this.review.start);
    }

    console.log(this.review);
    this.getHours();
    this.reviewingWeek = true;
  }
}
