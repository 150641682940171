import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'songKeySplitter'
})
export class SongKeySplitterPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string[] {
    const splits = value.split("_");
    var bettersplits = splits;
    while (bettersplits.length > 2) {
      const newSplits = [bettersplits[0] + "_" + bettersplits[1], ...bettersplits.slice(2)]
      bettersplits = newSplits;
    }
    return [bettersplits[0], bettersplits[1]]
  }

}
