import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { isThisSecond } from 'date-fns';
import { Observable } from 'rxjs';
import { Auth } from 'src/app/auth/auth';
import { Giftable } from 'src/app/Classes/giftable';

import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-staff-orders',
  templateUrl: './staff-orders.component.html',
  styleUrls: ['./staff-orders.component.css']
})
export class StaffOrdersComponent implements OnInit {
  staff;
  staffObserver;
  subs = [];
  drinks: any;
  you;
  gift = false;
  nextGift: Giftable = new Giftable();
  constructor(private bi: BarInfo, private db: StaffAppDatabase, private auth: Auth) {

  }

  ngOnInit(): void {
    console.log(this.auth.accessUser.access)
    this.subs.push(this.bi.barObserver.subscribe(values => {

      if (values) {
        this.staffObserver = this.db.getStaffAtBar(values);
      }

    }));
    this.subs.push(this.db.getUser(this.auth.accessUser.uid).subscribe(values => {
      this.you = values;
    }))
  }

  drinksFor(s, bar) {
    this.subs.push(this.db.getStaffDrinksForUser(s.uid).subscribe(snapshots => {
      this.drinks = snapshots.map((a: any) => {
        var res: any = a.payload.val(); res.time = a.key;
        if (res.drinks) {
          let list: any = Object.values(res.drinks);
          let keys = Object.keys(res.drinks);
          for (let i = 0; i < list.length; i++) {
            list[i].key = keys[i];
          }
          res.drinks = list;
        }

        return res
      }).reverse();
      console.log(this.drinks);
      console.log("Total for: " + s.firstName + " " + s.lastName + " is: " + this.drinks.reduce((a, b) => {

        if (b.type && b.type.toLowerCase() == 'debit') {
          return a + b.total;
        }
        return a;
      }, 0));

      console.log("Credit Total for: " + s.firstName + " " + s.lastName + " is: " + this.drinks.reduce((a, b) => {

        if (b.type && b.type.toLowerCase() == 'credit') {
          return a + b.total;
        }
        return a;
      }, 0));
    }));

  }

  ngOnDestroy() {
    this.subs.forEach(a => a.unsubscribe());
  }

  convertNumber(currency) {
    const regex = /[^0-9.-]/g;
    let str = currency.srcElement.value.replaceAll(regex, '');

    try {
      let num = Number(str);
      if (num === undefined || Number.isNaN(num) || !num) {
        console.log(num);
        this.nextGift.total = 0;
      } else {
        this.nextGift.total = num;
      }

    } catch (error) {
      console.log(error);
      this.nextGift.total = 0;
    }
  }

  giftTo() {
    console.log(this.staff.uid, this.you.firstName, this.nextGift);
    if (!Number(this.nextGift.total)) {
      alert('invalid total!');
      this.nextGift.total = 0;
      return;
    }
    this.nextGift.total = Number(this.nextGift.total);

    if (!this.nextGift.reason || this.nextGift.reason.trim() === "") {
      alert('invalid reason!');
      this.nextGift.reason = '';
      return;
    }

    if (!this.you.currentAllowance || this.you.currentAllowance <= 0 || this.you.currentAllowance < this.nextGift.total) {
      alert('You cannot afford this gift');
      return;
    }
    this.nextGift.reason = "Gift from: " + (this.you.firstName ? this.you.firstName : this.you.displayName) + ". With reason: " + this.nextGift.reason;
    alert('Gifting!');
    this.nextGift.from = this.you.uid;
    this.nextGift.to = this.staff.uid;
    this.db.giftStaff(this.nextGift);
    this.cancelGift();
  }

  cancelGift() {
    this.nextGift = new Giftable();
    this.gift = false;
  }



}
