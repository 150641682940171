import { Component, OnInit } from '@angular/core';
import { NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { OverviewService } from 'src/app/Packages/overview.service';

@Component({
  selector: 'app-inkomsten',
  templateUrl: './inkomsten.component.html',
  styleUrls: ['./inkomsten.component.css']
})

export class InkomstenComponent implements OnInit {

  subs: Subscription[] = [];
  bar = "";
  viewBox = '';

  modalContent = {
    title: "",
    list: [],
  };

  constructor(
    private bI: BarInfo,
    private modal: NgbModal,
    public overview: OverviewService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.bI.barObserver.subscribe((val) => {
        this.bar = val;
        this.overview.getRange(this.bar);
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }


  openModel(content, title, list) {
    this.modalContent.title = title;
    this.modalContent.list = list;
    this.modal
      .open(content)
      .result.then(
        (a) => {
          this.modalContent = { title: "", list: [] };
        },
        (e) => {
          this.modalContent = { title: "", list: [] };
        }
      )
      .catch((e) => {
        this.modalContent = { title: "", list: [] };
      });
  }

}
