import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { isNumber } from 'lodash';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { NDHOStock } from '../../Instellingen/ndostock-hq/ndostock-hq.component';
import { resolve } from 'dns';


@Component({
  selector: 'app-ndorders',
  templateUrl: './ndorders.component.html',
  styleUrls: ['./ndorders.component.css']
})
export class NDOrdersComponent implements OnInit {

  orders = [];
  order;
  name;
  hqStock:NDHOStock[] = [];
  showInvoice = false;
  invoice: any = {};
  companyInfo = { address: '', number: '', zip: '', city: '', country: 'Netherlands', email: '', iban: '', btw: '', kvk: '', name: '' };
  headOffice = { address: '', number: '', zip: '', city: '', country: 'Netherlands', email: '', iban: '', btw: '', kvk: '', name: '' };

  constructor(private db: StaffAppDatabase, private barInfo: BarInfo, private load: LoadingService, private http: HttpClient) { }

  ngOnInit(): void {
    this.db.ndOrdersObserver.subscribe(values => {
      this.orders = values;
    })
    this.barInfo.barObserver.subscribe(values => {
      this.name = values;
      this.db.getNDOrder(values);
      this.getCompanyInfo();
    })

    this.getData();

  }

  async getData(){
    this.hqStock = await this.db.getNDHOStock();
  }

  async saveAndMarkCompleted(){
    this.order.status = 'Archived';
    var  [val, error] = await this.updateHQQuantities(this.order);

    if(error) {
      alert('Error updating HQ Stock');
    }

    [val, error] = await this.saveOrder();
    if(error) {
      alert('Error saving order');
      return;
    }
    this.makeInvoice(this.order)
  }

  async updateHQQuantities(order) {
    console.log(order.items, this.hqStock);
    var promises =[];
    try {

      var errors = [];
      console.log(order);
      this.load.show();
      var list: any[] = order.items;
      for(var item of list) {
        var p = new Promise(async (resolve, reject) => {
          var hqStock = this.hqStock.find((a) => a.bars[this.name] && a.bars[this.name].id === item.key);
          if(hqStock) {
            hqStock.quantity -= item.arrived;
            await this.db.saveNDHOStockItemOrder(hqStock);
            resolve(null);
          } else {
            errors.push(item.name);
            resolve(null);
          }
        });
        promises.push(p);

      }




    } catch(e) {
      alert('Error updating HQ Stock');
      this.load.hide();
      return [false, e];
    }

    await Promise.all(promises);
    if(errors.length > 0) {
      alert('Couldnt find HQ Stock for: ' + errors.join('\n'));
      this.load.hide();
      return [false, 'Couldnt find HQ Stock for: ' + errors.join('\n')];
    }


    this.load.hide();
    return [true, null];
  }

  async getCompanyInfo() {
    const comp = await this.db.getCompanyInfo(this.name).then(a => a).catch(e => { });
    const head = await this.db.getCompanyInfo('Daga Beheer').then(a => a).catch(e => { });

    this.companyInfo = Object.assign({ address: '', number: '', zip: '', city: '', country: 'Netherlands', email: '', iban: '', btw: '', kvk: '', name: '' }, comp);
    this.headOffice = Object.assign({ address: '', number: '', zip: '', city: '', country: 'Netherlands', email: '', iban: '', btw: '', kvk: '', name: '' }, head);
  }

  openOrder(order) {
    this.order = order;
  }

  async deleteOrder(order) {
    await this.db.deleteNDOrder(this.name, order);
    this.db.getNDOrder(this.name)
  }

  async saveOrder() {
    try {
      this.db.saveNDOrder(this.name, this.order);
      this.db.getNDOrder(this.name);
      return [true, null];
    } catch(e) {
      alert('Error saving order');
      return [false, e];
    }

  }

  cancel() {
    this.order = null;
  }



  async makeInvoice(order) {
    const date = new Date(Number(order.dateTime));
    const due = new Date(date);
    due.setDate(due.getDate() + 14);
    this.showInvoice = true;


    let data = {
      image:
        // The logo on top of your invoice
        "assets/dba.jpg",


      // sender and client info (address, iban, etc..)
      sender: this.headOffice,
      client: this.companyInfo,
      information: {
        number: order.key,
        key: order.key,
        date: date.toLocaleDateString(),
        "due-date": due.toLocaleDateString(),
        datetime: date.getTime()
      },

      // The products you would like to see on your invoice
      "products": order.items.filter((a: any) => a.arrived > 0).map((a: any) => {
        return {
          "name": a.name,
          "quantity": a.arrived,
          category: a.invoiceCategory || ('not-drink'),
          "description": a.description || "desc",
          "tax-rate": a.vat ? a.vat : 0,
          "price": a.price ? a.price : 0,
          "total": a.arrived * a.price,
          embellage: 0,
        }


      }),

      //info including subtotal, map of vat amount - total, map of embellage amount - total, total including vat en embellage
      total: {
        subtotal: 0,
        vats: {},
        categories: [],
        total: 0
      }
    };

    let vat: any[] = [];
    const tempCat = {};
    data.products.forEach((prod: any) => {
      data.total.subtotal += prod.total;
      if (!data.total.vats[prod['tax-rate'] + '']) {
        data.total.vats[prod['tax-rate'] + ''] = prod.total * prod['tax-rate'] / 100;
      } else {
        data.total.vats[prod['tax-rate'] + ''] += prod.total * prod['tax-rate'] / 100;
      }

      if (!tempCat[prod.category]) {
        tempCat[prod.category] = { name: prod.category, total: (prod.price * prod.quantity) }
      } else {
        tempCat[prod.category].total += (prod.price * prod.quantity)
      }

      data.total.total += prod.total + (prod.total * prod['tax-rate'] / 100)
    })


    data.total.categories = Object.entries(tempCat).map(([key, val]) => val);
    this.invoice = data;
    await this.db.saveDrinkOrderFinal(this.name, this.invoice, 'ndo');
  }

  downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = this.name + ".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }



  async orderOrder() {
    this.load.nextEmit('on');
    this.order.items.forEach(item => {
      item.ordered = item.defaultQuantity - item.stockQuantity + Math.max(0, item.modifier);
    })
    this.order.status = "Requested";
    console.log(this.order)

    this.db.saveNDOrder(this.name, this.order);
    this.load.nextEmit();
  }


}

