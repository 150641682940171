import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "days",
})
export class DaysPipe implements PipeTransform {
  transform(value: string, full: boolean = false): unknown {
    if (!value) return "n/a";
    // string to array of numbers, 0 sunday, 6 saturday
    const days = value
      .split("")
      .map(Number)
      .sort((a, b) => a - b);

    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    if (full) {
      return days.map((day) => dayNames[day]).join(", ");
    }
    let result = "";
    let rangeStart = null;

    for (let i = 0; i < days.length; i++) {
      if (rangeStart === null) {
        rangeStart = days[i];
      }

      if (i === days.length - 1 || days[i] + 1 !== days[i + 1]) {
        if (rangeStart === days[i]) {
          result += dayNames[days[i]] + " ";
        } else {
          result += dayNames[rangeStart] + "-" + dayNames[days[i]] + " ";
        }
        rangeStart = null;
      }
    }

    return result.trim();
  }
}
