import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pin-cash-internet-chart',
  templateUrl: './pin-cash-internet-chart.component.html',
  styleUrls: ['./pin-cash-internet-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PinCashInternetChartComponent implements OnInit {
  @Input('data') set Data(data:any) {

    this.multi =[];
    data.forEach(day => {
      try {

        var d = {"name":this.toDay((new Date(day.date).getDay()+6)%7) ,"quantity" :1,"key":(new Date(day.date).getDay()+6)%7, "series": [
          {
            "name":"Pin",
            "total":day.data.pin,
            "value":day.data.pin
          },
          {
            "name":"Cash",
            "total":day.data.cash,
            "value":day.data.cash,

          },
          {
            "name":"Internet",
            "total":day.data.internet,
            "value":day.data.internet,

          },
        ]};

        var fil = this.multi.findIndex(a => a.name === d.name);
        if(fil >= 0) {
          this.multi[fil].quantity += 1;
          this.multi[fil].series[0].total += d.series[0].value;
          this.multi[fil].series[1].total += d.series[1].value;
          this.multi[fil].series[2].total += d.series[2].value;

          this.multi[fil].series[0].value = this.multi[fil].series[0].total / this.multi[fil].quantity;
          this.multi[fil].series[1].value =  this.multi[fil].series[1].total / this.multi[fil].quantity;
          this.multi[fil].series[2].value =  this.multi[fil].series[2].total / this.multi[fil].quantity;

        } else {
          this.multi.push(d);
        }
      } catch(e) {
        console.error(e)
      }

    })
    this.multi = this.multi.sort((a,b) => a.key - b.key)

  }

  @Input('viewType') viewType = 0;
  multi: any[]= [
    {
      "name": "Germany",
      "series": [
        {
          "name": "2010",
          "value": 7300000
        },
        {
          "name": "2011",
          "value": 8940000
        }
      ]
    },

    {
      "name": "USA",
      "series": [
        {
          "name": "2010",
          "value": 7870000
        },
        {
          "name": "2011",
          "value": 8270000
        }
      ]
    },

    {
      "name": "France",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    }
  ];
  view: any[] = [520, 360];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Week Day';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Average Income';
  animations: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };

  constructor() {
    Object.assign(this, { multi : this.multi});
  }
  ngOnInit(): void {

  }

  toDay(number) {
    switch(number) {
      case 0: return 'Mo';
      case 1: return 'Tu';
      case 2: return 'We';
      case 3: return 'Th';
      case 4: return 'Fr';
      case 5: return 'Sa';
      case 6: return 'Su';

    }
    return '';
  }

  onSelect(event) {

  }

}
