import { PageData } from "./page-data";

export class Page {
  type: string = '';
  data: PageData = new PageData();
  showBorder: boolean = false;
  name: string = '';
  description: string = '';
  length: number = 5000;
  calendarInfo: {location: string, openHours: string, closeHours: string}  = {location: '', openHours: '', closeHours: ''};
  constructor(type: string) {
    this.type = type;

  }
}
