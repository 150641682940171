import { Component, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { VipInformation } from "src/app/Classes/vip-information";
import { TillInformation } from "src/app/Classes/till-information";
import { Calendar } from "calendar-base";
import { CalendarAPIConfig } from "src/app/Classes/calendar-apiconfig";
import { environment } from "src/environments/environment";
import {
  DuncansCalendarLibraryService,
  DuncansCalendarLibraryServiceV3,
} from "duncans-calendar-library";

@Component({
  selector: "app-bar",
  templateUrl: "./bar.component.html",
  styleUrls: ["./bar.component.css"],
})
export class BarComponent implements OnInit {
  subs = [];
  sub;
  barSub;
  name;
  staff;
  manager;
  info: any = {};
  pinged = false;

  calendarAPI: CalendarAPIConfig;
  tillInformation: TillInformation;
  vipInformation: VipInformation;

  constructor(
    private barInfo: BarInfo,
    private db: StaffAppDatabase,
    public lib: DuncansCalendarLibraryService
  ) {}

  async getInfo() {
    const inf = await this.db
      .getCompanyInfo(this.name)
      .then((a) => a)
      .catch((e) => {
        return {};
      });

    this.info = Object.assign(
      {
        address: "",
        number: "",
        zip: "",
        city: "",
        country: "Netherlands",
        email: "",
        iban: "",
        btw: "",
        kvk: "",
        name: "",
      },
      inf
    );
  }

  async saveInfo() {
    await this.db.setCompanyInfo(this.name, this.info);
    this.getInfo();
  }

  cancelInfo() {
    this.getInfo();
  }

  saveManager() {
    console.log(this.name, this.manager);
    if (this.name && this.manager) {
      this.db.addManager(this.name, this.manager);
    }
  }

  managerChanged(event) {
    console.log(event.value);
    this.manager = event.value;
  }

  ngOnInit(): void {
    this.subs.push(
      this.db.staffInfoObserver.subscribe((values) => {
        this.staff = values;
        this.db.getManager(this.name);
      })
    );

    this.subs.push(
      this.barInfo.barObserver.subscribe((values) => {
        this.name = values;
        this.subs.push(
          this.db
            .getTillInformation(this.name)
            .subscribe((values: TillInformation) => {
              this.tillInformation = values;
            })
        );
        this.getTill();
        this.getVIP();
        this.db.getStaffInfo(values);
        this.getInfo();
        this.getCalendar();
      })
    );
    this.subs.push(
      this.db.managerObserver.subscribe((managerKey) => {
        if (managerKey) {
          console.log("found a manager", managerKey);
          this.managerChanged({ value: managerKey });
        } else {
          console.log("no manager");
        }
      })
    );
  }

  getTill() {
    this.subs.push(
      this.db
        .getTillInformation(this.name)
        .subscribe((values: TillInformation) => {
          this.tillInformation = values;
        })
    );
  }

  async getCalendar() {
    this.calendarAPI =
      (await this.db.getCalendarAPIConfig(this.name)) ||
      new CalendarAPIConfig();

    if (this.calendarAPI.functions) {
      //ping
      this.lib.setServer(this.calendarAPI.functions);
      this.ping();
    }
  }

  async ping() {
    this.pinged = await this.lib
      .ping()
      .then((a) => {
        console.log(a);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }

  getVIP() {
    this.subs.push(
      this.db
        .getVipInformation(this.name)
        .subscribe((values: VipInformation) => {
          this.vipInformation = values;
        })
    );
  }

  saveTill() {
    this.db.saveTill(this.name, this.tillInformation).then((v) => {
      this.getTill();
    });
  }

  saveVIP() {
    this.db.saveVip(this.name, this.vipInformation).then((v) => {
      this.getVIP();
    });
  }

  saveCalendar() {
    this.db.saveCalendarAPIConfig(this.name, this.calendarAPI).then((v) => {
      this.getCalendar();
    });
  }

  cancelCalendar() {
    this.getTill();
  }

  cancelTill() {
    this.getTill();
  }

  cancelVIP() {
    this.getVIP();
  }

  log(event) {
    console.log(event);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
