
export class MyLogger {
    pagename: string;
    constructor(pagename){
        this.pagename = pagename;
    }

    log(log){
        //////console.log(this.pagename,log);
    }



    logF(functionname: string,log: string){
        //////console.log(this.pagename + "-" + functionname,log);
    }

    logL(level,log){
        switch(level){
            case 0:
                //////console.log(("%c"+this.pagename) ,'color: #FF2D00',log);
                break;
            case 1:
                //////console.log(("%c"+this.pagename),'color: #FFF300', log)
                break;
            case 2:
                //////console.log((this.pagename),log);
                break;
            case 3:
                //////console.log(("%c"+this.pagename),'color: #0080FF', log);
                break;
            case 4:
                //////console.log(("%c"+this.pagename),'color:#00FF08',log);
                break;
        }
    }

    logFL(functionname,level,log){
        switch(level){
            case 0:
                //////console.log(("%c"+this.pagename + '-' + functionname) ,'color: #FF2D00',log);
                break;
            case 1:
                //////console.log(("%c"+this.pagename + '-' + functionname),'color: #FFF300', log)
                break;
            case 2:
                //////console.log(this.pagename + '-' + functionname,log);
                break;
            case 3:
                //////console.log(("%c"+this.pagename + '-' + functionname),'color: #0080FF', log);
                break;
            case 4:
                //////console.log(("%c"+this.pagename + '-' + functionname),'color:#00FF08',log);
                break;
        }
    }










}
