<select [ngModel]="selected" (ngModelChange)="staffSelected($event)">
    <option *ngFor="let s of staff" [value]="s.uid">{{s | name}} </option>
</select>


<h3 *ngIf="selected">{{selected | name}}</h3>
<div *ngIf="selected" style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-evenly; width: 100%">  
    <div>
        <h2>Payslips</h2>
        
        <div *ngFor="let c of payslips"  (click)="open(c)">
            {{c.date | date: 'EEE dd LLL yyyy'}}
        </div>
    </div>

    <div>
        <h2>Contracts</h2>
      
        <div *ngFor="let c of contracts" (click)="open(c)">
            {{c.date | date: 'EEE dd LLL yyyy'}}
        </div>

    </div>

</div>