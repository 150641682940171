import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "tipType",
})
export class TipTypePipe implements PipeTransform {
  transform(value: unknown, ...args: any[]): unknown {
    var type = "absolute";
    if (args.length > 0) {
      type = args[0];
    }

    switch (type) {
      case "percentage":
        return value + "%";
      case "round":
        return "to nearest €" + value;
      case "absolute":
        return "€" + value;
    }
    return "na";
  }
}
