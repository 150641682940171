<button
  class="action-button selected"
  *ngIf="
    auth.accessUser && auth.accessUser.access >= 2 && bar.bar != 'Daga Beheer'
  "
  routerLink="/controle/counting"
  routerLinkActive="true"
>
  Counting
</button>

<button
  class="action-button selected"
  *ngIf="
    auth.accessUser && auth.accessUser.access >= 2 && bar.bar == 'Daga Beheer'
  "
  routerLink="/controle/countinghq"
  routerLinkActive="true"
>
  Counting HQ
</button>

<button
  class="action-button selected"
  *ngIf="
    auth.accessUser && auth.accessUser.access >= 2 && bar.bar == 'Daga Beheer'
  "
  routerLink="/controle/ndcountinghq"
  routerLinkActive="true"
>
  ND Counting HQ
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 2"
  routerLink="/controle/till"
  routerLinkActive="true"
>
  Till
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 3"
  routerLink="/controle/dashboard"
  routerLinkActive="true"
>
  Dashboard
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 4"
  routerLink="/controle/telling"
  routerLinkActive="true"
>
  Telling
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 3"
  routerLink="/controle/tables"
  routerLinkActive="true"
>
  Tables
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 3"
  routerLink="/controle/shiftcheck"
  routerLinkActive="true"
>
  Shift Check
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 3"
  routerLink="/controle/cleaning"
  routerLinkActive="true"
>
  Cleaning
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 3"
  routerLink="/controle/tips"
  routerLinkActive="true"
>
  Tips
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 3"
  routerLink="/controle/payments"
  routerLinkActive="true"
>
  Payments
</button>
