import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { JSONCompareComponent } from "src/app/Packages/jsoncompare/jsoncompare.component";
import { LoadingService } from "src/app/Services/loading.service";
import {
  ChangeRecord,
  StaffAppDatabase,
} from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-change-records",
  templateUrl: "./change-records.component.html",
  styleUrls: ["./change-records.component.css"],
})
export class ChangeRecordsComponent implements OnInit {
  types = ["schedule", "staffinfo", "catalogue", "hours", "onderhoud","HQStock","NDHQStock"];
  type: string = null;
  records: ChangeRecord[] = [];
  activatedPanel: string = null;
  now = new Date();
  checked: any = {};
  dateStart: Date = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
  dateEnd: Date = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);
  bar: string = "";
  useDateRange: boolean = false;
  constructor(
    public db: StaffAppDatabase,
    public alert: AlertsService,
    public loading: LoadingService,
    public bi: BarInfo,
    public modal: NgbModal
  ) {}

  toggle(id: any) {
    if (this.checked[id] == null) {
      this.checked[id] = true;
    } else {
      this.checked[id] = !this.checked[id];
    }
  }

  compare() {
    const modal = this.modal.open(JSONCompareComponent, {
      size: "xl",
      centered: true,
      scrollable: true,
    });
    modal.componentInstance.records = this.records
      .filter((x) => !!this.checked[x.date])
      .sort((a, b) => (a.date > b.date ? 1 : -1));
  }

  hasChecked() {
    return Object.values(this.checked).filter((a) => !!a).length;
  }
  setDate(event, type: string) {
    console.log(new Date(event), type);
    if (type == "start") {
      this.dateStart = new Date(event);
    } else {
      this.dateEnd = new Date(event);
    }
  }

  ngOnInit(): void {}

  async selectType(type: string) {
    if (this.useDateRange && this.dateStart > this.dateEnd) {
      alert("Start date cannot be after end date");
      return;
    }

    this.loading.show();
    this.records = (
      await this.db.getChangedRecords(
        this.bar,
        type,
        this.useDateRange ? this.dateStart : null,
        this.useDateRange ? this.dateEnd : null
      )
    ).reverse();
    this.type = type;
    console.log(this.records);
    this.loading.hide();
  }
}
