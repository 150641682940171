<div class="flex-rows">
  <div class="flex-columns">
    <h2>Staff</h2>
    <div
      *ngFor="let s of staffObserver | async"
      (click)="staff = s; drinksFor(s)"
    >
      {{ !!s.firstName ? s.firstName[0] + ". " + s.lastName : s.displayName }}
      {{ s.balance | currency : "EUR" }}
    </div>
  </div>
  <div *ngIf="you" class="flex-columns">
    <h2>
      {{
        !!you.firstName ? you.firstName + " " + you.lastName : you.displayName
      }}
    </h2>
    Giftable: {{ you.currentAllowance | currency : "EUR" }}
  </div>
  <div *ngIf="staff" class="flex-columns">
    <h2>
      Drinks for
      {{
        !!staff.firstName
          ? staff.firstName + " " + staff.lastName
          : staff.displayName
      }}
    </h2>

    <div style="max-height: 600px !important; overflow: scroll">
      <div
        *ngFor="let order of drinks"
        style="border: 1px red solid; padding: 2px; margin: 2px"
        [ngbTooltip]="order.drinks | json"
      >
        {{ order.time | date : "yyyy/MM/dd - HH:mm" }} {{ order.bar }} <br />
        {{ order.type }} : {{ order.total | currency : "EUR" }}
        {{ order.reason }}
      </div>
    </div>

    <button (click)="staff = null">Close</button>
    <button (click)="gift = true">Gift</button>

    <div *ngIf="gift">
      <h3>
        Gifting to
        {{
          !!staff.firstName
            ? staff.firstName + " " + staff.lastName
            : staff.displayName
        }}
      </h3>
      <app-input
        type="text"
        (change)="convertNumber($event)"
        [model]="nextGift.total | currency : 'EUR'"
        name="Amount"
      ></app-input>
      <app-input
        type="text"
        (changed)="nextGift.reason = $event"
        [model]="nextGift.reason"
        name="Reason"
      ></app-input>
      <button (click)="giftTo()">Gift</button>
      <button (click)="cancelGift()">Cancel</button>
    </div>
  </div>
</div>
