import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Firebase } from "src/app/Services/firebase";

import { LoadingService } from "src/app/Services/loading.service";
import { AlertsService } from "../alerts/alerts.service";
import { clone, cloneDeep } from "lodash";
import { take } from "rxjs/operators";
import { Observable, Observer } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ConfirmationDialogService } from "../confirmation-dialog/confirmation-dialog.service";
import { Song } from "src/app/Classes/song";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { Auth } from "src/app/auth/auth";
@Component({
  selector: "app-move-song",
  templateUrl: "./move-song.component.html",
  styleUrls: ["./move-song.component.css"],
})
export class MoveSongComponent implements OnInit {
  artistForm: FormGroup;
  genres: string[] = [];
  data: Song;
  downloadUrl;
  selectedImage: string;
  @Input("song") song;
  catalogue: any[];
  filt: any[] = [];
  artistName: "";
  selectedArtist: any;

  constructor(
    public auth: Auth,
    private loading: LoadingService,
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    public api: Firebase,
    private confirmation: ConfirmationDialogService,
    private alert: AlertsService,
    private http: HttpClient,
    public db: StaffAppDatabase,
    public bi: BarInfo
  ) {
    this.getData();
  }

  async getData() {
    this.loading.nextEmit("on");
    this.catalogue = await this.api
      .getArtistsSync()
      .then((a) => a as any[])
      .catch((e) => {
        console.error(e);
        return [];
      });
    this.loading.nextEmit("");
    this.artistName = this.song.artist;
    this.search();
  }

  ngOnInit(): void {}

  dismiss(reason: string) {
    this.modal.dismiss(reason);
  }

  search() {
    this.filt = [];
    const temp = [];
    for (var artist of this.catalogue) {
      if (artist.name.includes(this.artistName)) {
        artist.realLength = artist.songs.filter((a) => a).length;
        temp.push(artist);
      }
    }
    console.log(temp);
    if (temp.length < 10) {
      this.filt = temp;
    }
  }

  async save() {
    this.loading.nextEmit("on");
    console.log(this.selectedArtist);
    console.log(
      "moving: " +
        this.song.name +
        " from " +
        this.song.key +
        " to " +
        this.selectedArtist.key
    );
    const song = cloneDeep(this.song);

    var nextIndex = this.selectedArtist.songs.length;
    console.log(
      "inserting at " + nextIndex + "/" + this.selectedArtist.songs.length
    );

    //get new key
    const newKey = this.selectedArtist.key + "_" + nextIndex;
    song.key = newKey;

    //add song to other artist

    var res = await this.api
      .addSong(song)
      .then((a) => null)
      .catch((e) => {
        alert(e);
        return e;
      });
    if (res) {
      this.loading.nextEmit("");
      console.log(res);
      return;
    }

    try {
      this.db.changedRecord(
        this.bi.bar,
        this.auth.user.uid,
        song,
        "catalogue",
        song.key,
        "editing",
        "theendadmin.nl/CustomerApp/Catalogue -> Move Song",
        "description: " + song.name + " moved from " + this.song.key + " to " + song.key,
        {}
      );
    } catch (e) {}

    var res3 = await this.api
      .moveSongPhoto("songs/" + this.song.key, "songs/" + song.key)
      .then((a) => null)
      .catch((e) => e);
    if (res3) {
      console.error(res3);
      alert("photo move failed, please manually add photo");
    }

    //mark old song for deleting
    var res2 = await this.api
      .markForDelete(this.song)
      .then((a) => null)
      .catch((e) => {
        alert(e);
        console.error(e);
      });

    if (res2) {
      this.loading.nextEmit("");
      return;
    }

    this.loading.nextEmit("");
    this.dismiss("done");
  }
}
