<div class="staff-info-title">
    <div class="action-side"></div>
    <h3 style="margin: 5px 0px 2px 0px">Edit Slideshow</h3>
    <button class="action-side close-button red">
  <i class="fas fa-times"></i>
</button>
</div>

<div class="actions staff-info-header">
    <button (click)="headerPage = 0" [class]="
  headerPage == 0 ? 'green reactive-button-title' : 'reactive-button-title'
">
Info
</button>
    <button (click)="openPages()" [class]="
headerPage == 1 ? 'green reactive-button-title' : 'reactive-button-title'
">
Pages
</button>

    <button (click)="headerPage = 2" [class]="
headerPage == 2 ? 'green reactive-button-title' : 'reactive-button-title'
">
Order
</button>

    <button (click)="headerPage = 3" [class]="
headerPage == 3 ? 'green reactive-button-title' : 'reactive-button-title'
">
Preview
</button>

</div>

<!--Editing-->
<div class="staff-info-content" *ngIf="book && headerPage == 0">
    <!--Info  -->
    <app-input [model]="book.name" (changed)="book.name = $event" placeholder="Name Of Slideshow" name="Name:"></app-input>
</div>

<div class="staff-info-content" *ngIf="book && headerPage == 1">
    <!--Pages  -->

    <button (click)="createPage()">Create Page</button>
    <div style="display: flex; flex-direction: row;">
        <button [disabled]="currentPage <= 0" (click)="previousPage()"><i class="fas fa-chevron-left"></i></button>
        <div>Page: {{currentPage + 1}} / {{book.pages.length}}</div>
        <button [disabled]="currentPage >= book.pages.length - 1" (click)="nextPage()"><i class="fas fa-chevron-right"></i></button>
    </div>

    <app-input *ngIf="book.pages[currentPage]" [model]="book.pages[currentPage].name" (changed)="book.pages[currentPage].name = $event" placeholder="Name Of Page" name="Name:"></app-input>
    <app-input type="select" [listHideAll]="true" *ngIf="book.pages[currentPage]" [list]="types" [model]="book.pages[currentPage].type" (changed)="book.pages[currentPage].type= $event" placeholder="Type Of Page" name="Type:"></app-input>

    <div class="image-input" *ngIf="book.pages[currentPage]?.type == 'photo'">
        <div>Image 1:</div>
        <input type="file" (change)="onFileSelected(0,$event)">
        <button (click)="uploadFile(0)">Upload</button>
        <button (click)="deleteFile(0)">Delete</button>
        <div *ngIf="book.pages[currentPage].data.images[0].dataUrl">
            <h3>Preview:</h3>
            <img *ngIf="book.pages[currentPage].data.images[0].isImage" [src]="book.pages[currentPage].data.images[0].dataUrl" alt="Preview" width="300">
            <video *ngIf="!book.pages[currentPage].data.images[0].isImage" [src]="book.pages[currentPage].data.images[0].dataUrl" controls width="300"></video>
        </div>

        <div>Image 2:</div>
        <input type="file" (change)="onFileSelected(1,$event)">
        <button (click)="uploadFile(1)">Upload</button>
        <button (click)="deleteFile(1)">Delete</button>
        <div *ngIf="book.pages[currentPage].data?.images[1]?.dataUrl">
            <h3>Preview:</h3>
            <img *ngIf="book.pages[currentPage].data.images[1].isImage" [src]="book.pages[currentPage].data.images[1].dataUrl" alt="Preview" width="300">
            <video *ngIf="!book.pages[currentPage].data.images[1].isImage" [src]="book.pages[currentPage].data.images[1].dataUrl" controls width="300"></video>
        </div>
    </div>

    <ng-container *ngIf="book.pages[currentPage]?.type  == 'calendar'">
        <app-input *ngIf="book.pages[currentPage]" [model]="book.pages[currentPage].calendarInfo.location" (changed)="book.pages[currentPage].calendarInfo.location =  $event" placeholder="ID Of Private Room" name="Room ID:"></app-input>
        <app-input *ngIf="book.pages[currentPage]" [model]="book.pages[currentPage].calendarInfo.startTime" (changed)="book.pages[currentPage].calendarInfo.startTime =  $event" placeholder="2000, 2030 etc..." name="Start (2000):"></app-input>
        <app-input *ngIf="book.pages[currentPage]" [model]="book.pages[currentPage].calendarInfo.endTime" (changed)="book.pages[currentPage].calendarInfo.endTime =  $event" placeholder="0200 0220 etc..." name="End (0400):"></app-input>

    </ng-container>


</div>

<div class="staff-info-content" *ngIf="book && headerPage == 2">
    <!--Reorder  -->
</div>

<div class="staff-info-content" *ngIf="book && headerPage == 3">
    <!-- Preview -->
</div>

<div class="actions staff-info-header">
    <button color="accent" class="reactive-button-title green" (click)="save()">
    Save
    </button>


    <button color="warn" class="reactive-button-title orange" (click)="cancel()">
      Cancel
    </button>

</div>