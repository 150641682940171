import { Component, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-instelligen",
  templateUrl: "./instelligen.component.html",
  styleUrls: ["./instelligen.component.css"],
})
export class InstelligenComponent implements OnInit {
  pagesOG: any[] = [
    {
      barOnly: false,
      name: "Bar Settings",
      access: 4,
      routerLink: "/instellingen/bar",
    },
    {
      barOnly: true,
      name: "Stock",
      access: 3,
      routerLink: "/instellingen/stock",
    },
    {
      barOnly: true,
      name: "VIP Rooms / Extra Fridges",
      access: 3,
      routerLink: "/instellingen/vipRooms",
    },
    {
      barOnly: true,
      name: "Shift Messages",
      access: 2,
      routerLink: "/instellingen/messages",
    },
    {
      barOnly: true,
      name: "Lost and Found",
      access: 2,
      routerLink: "/instellingen/lostandfound",
    },
    {
      barOnly: true,
      name: "Shifts / Count Attempts",
      access: 3,
      routerLink: "/instellingen/shifts",
    },
    { name: "Expenses", access: 4, routerLink: "/instellingen/expenses" },
    { name: "Reviews", access: 3, routerLink: "/instellingen/reviews" },
    {
      barOnly: true,
      name: "Tips",
      access: 5,
      routerLink: "/instellingen/tips",
    },
    { name: "Devices", access: 3, routerLink: "/instellingen/devices" },
    { name: "Tests", access: 5, routerLink: "/instellingen/tests" },
    { name: "Access Levels", access: 5, routerLink: "/instellingen/access" },
    {
      barOnly: false,
      dagaOnly: true,
      name: "HQ Stock (Inkoop Kosten)",
      access: 5,
      routerLink: "/instellingen/InkoopKosten",
    },
    {
      barOnly: false,
      dagaOnly: true,
      name: "HQ NDStock",
      access: 5,
      routerLink: "/instellingen/NDOHQStock",
    },
    {
      barOnly: false,
      dagaOnly: true,
      name: "Invoice Splitter",
      access: 5,
      routerLink: "/instellingen/invoicesplitter",
    },
    {
      barOnly: false,
      dagaOnly: false,
      name: "Competition",
      access: 4,
      routerLink: "/instellingen/competition",
    },
  ];

  pages: any[] = [];
  constructor(public auth: Auth, public bI: BarInfo) {}

  ngOnInit(): void {
    this.auth.getAccess().then(() => {
      this.pages = this.pagesOG.filter((value) => {
        console.log(value);
        console.log(this.bI.bar);
        console.log(!!this.bI.onlyDuncan.get(this.bI.bar));
        console.log(
          value.barOnly &&
            !this.bI.onlyDuncan.get(this.bI.bar) &&
            this.bI.bar !== "Daga Beheer"
        );

        if (value.barOnly) {
          return (
            this.auth.accessUser.access >= value.access &&
            this.bI.bar != "Daga Beheer"
          );
        } else if (value.dagaOnly) {
          return (
            this.bI.bar === "Daga Beheer" &&
            this.auth.accessUser.access >= value.access
          );
        } else {
          return this.auth.accessUser.access >= value.access;
        }
      });
    });
  }
}
