/**
 * API
 * # Introduction  Welcome to the TPAPI-POS API! unTill Server provides a REST interface for 3rd party applications which may be used for remotely working with POS: reading configuration, making orders, etc.  You can view code samples in the dark area to the right.  In case you might have any questions about our API, please create a new support [**ticket**](http://integrations.untill.com/support/tickets/new). If you don't have an account yet but already signed an [**NDA**](https://untill.com/nda), please send an email to [**systemintegration@untill.com**](mailto:systemintegration@untill.com). Please do not use this email address for support questions.  # User permissions  Each API function should be invoked with appropriate user permission. Please see function description. The following permissions can be assigned to a user in unTill Backoffice - Backoffice view - Backoffice modify - Ordering - Ordering: override price - Apply discounts - Payment - Client management - Reporting - Reservations - Misc
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CancelledItem } from '../model/cancelledItem';
import { DepositItem } from '../model/depositItem';
import { InOutCashItem } from '../model/inOutCashItem';
import { SmartCardDepositItem } from '../model/smartCardDepositItem';
import { TimeAttendanceRecord } from '../model/timeAttendanceRecord';
import { TurnoverBill } from '../model/turnoverBill';
import { TurnoverTransaction } from '../model/turnoverTransaction';
import { UserBreakRecord } from '../model/userBreakRecord';
import { VoucherPaymentItem } from '../model/voucherPaymentItem';

import { BASE_PATH } from 'src/variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReportingService {

    protected basePath = 'http://82.73.157.201:{port}/api/v1/{dbname}';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * List cancelled articles
     * List cancelled articles, which have been added to an order and then removed by &#x60;Cancel&#x60; or &#x60;Void&#x60; button. Required user permission: Reporting
     * @param from Search records from date/time
     * @param till Search records till date/time
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelledItemGet(from: Date, till: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<CancelledItem>>;
    public cancelledItemGet(from: Date, till: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CancelledItem>>>;
    public cancelledItemGet(from: Date, till: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CancelledItem>>>;
    public cancelledItemGet(from: Date, till: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling cancelledItemGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling cancelledItemGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CancelledItem>>('get',`${this.basePath}/cancelled-item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List deposits
     * List deposits per time period. Required user permission: Reporting
     * @param from Search records from date/time
     * @param till Search records till date/time
     * @param salesAreaId Search records by specified sales area ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public depositItemGet(from: Date, till: Date, salesAreaId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DepositItem>>;
    public depositItemGet(from: Date, till: Date, salesAreaId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DepositItem>>>;
    public depositItemGet(from: Date, till: Date, salesAreaId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DepositItem>>>;
    public depositItemGet(from: Date, till: Date, salesAreaId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling depositItemGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling depositItemGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }
        if (salesAreaId !== undefined && salesAreaId !== null) {
            queryParameters = queryParameters.set('salesAreaId', <any>salesAreaId);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DepositItem>>('get',`${this.basePath}/deposit-item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List embedded report data
     * List embedded report data. Required user permission: Reporting ### Available report names - &#x60;sales-per-sales-area&#x60; - &#x60;sales-per-group&#x60; - &#x60;sales-per-category&#x60; - &#x60;sales-per-dept&#x60; - &#x60;sales-per-article-per-dept&#x60; - &#x60;bills&#x60; - &#x60;payments-per-mode&#x60; - &#x60;open-tables&#x60; - &#x60;discounts&#x60; - &#x60;payments-per-user&#x60; - &#x60;sales-per-article-grouped&#x60; - &#x60;voids&#x60; - &#x60;sales-hourly&#x60; - &#x60;open-tables-per-waiter&#x60; - &#x60;ta-details&#x60; - &#x60;ta-summary&#x60; - &#x60;ta-current&#x60; - &#x60;covers-overall&#x60; - &#x60;covers-by-category&#x60; - &#x60;covers-by-department&#x60; - &#x60;covers-by-course&#x60; - &#x60;covers-by-group&#x60; - &#x60;invoices&#x60; - &#x60;stock-invoices&#x60; - &#x60;cash-ops&#x60;
     * @param name Specify to get report by name
     * @param from Specify to get report from date/time
     * @param till Specify to get report till date/time
     * @param userId User ID the report is requested by
     * @param forUserId User ID the report is requested for. All users if ID is empty
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public embeddedReportNameGet(name: string, from: Date, till: Date, userId?: number, forUserId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public embeddedReportNameGet(name: string, from: Date, till: Date, userId?: number, forUserId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public embeddedReportNameGet(name: string, from: Date, till: Date, userId?: number, forUserId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public embeddedReportNameGet(name: string, from: Date, till: Date, userId?: number, forUserId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling embeddedReportNameGet.');
        }

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling embeddedReportNameGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling embeddedReportNameGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (forUserId !== undefined && forUserId !== null) {
            queryParameters = queryParameters.set('forUserId', <any>forUserId);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/embedded-report/${encodeURIComponent(String(name))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List in/out cash items
     * List in/out cash items per time period. Required user permission: Reporting
     * @param from Search records from date/time
     * @param till Search records till date/time
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inOutCashItemGet(from: Date, till: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<InOutCashItem>>;
    public inOutCashItemGet(from: Date, till: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InOutCashItem>>>;
    public inOutCashItemGet(from: Date, till: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InOutCashItem>>>;
    public inOutCashItemGet(from: Date, till: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling inOutCashItemGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling inOutCashItemGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<InOutCashItem>>('get',`${this.basePath}/in-out-cash-item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List smart card deposit items
     * List smart card deposit items per time period. Required user permission: Reporting
     * @param from Search records from date/time
     * @param till Search records till date/time
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public smartCardDepositItemGet(from: Date, till: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<SmartCardDepositItem>>;
    public smartCardDepositItemGet(from: Date, till: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SmartCardDepositItem>>>;
    public smartCardDepositItemGet(from: Date, till: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SmartCardDepositItem>>>;
    public smartCardDepositItemGet(from: Date, till: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling smartCardDepositItemGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling smartCardDepositItemGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SmartCardDepositItem>>('get',`${this.basePath}/smart-card-deposit-item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List time attendance records
     * List time attendance records. Required user permission: Reporting
     * @param from Search records from date/time
     * @param till Search records till date/time
     * @param userId Search records for user with specific ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public timeAttendanceRecordGet(from: Date, till: Date, userId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TimeAttendanceRecord>>;
    public timeAttendanceRecordGet(from: Date, till: Date, userId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TimeAttendanceRecord>>>;
    public timeAttendanceRecordGet(from: Date, till: Date, userId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TimeAttendanceRecord>>>;
    public timeAttendanceRecordGet(from: Date, till: Date, userId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling timeAttendanceRecordGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling timeAttendanceRecordGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TimeAttendanceRecord>>('get',`${this.basePath}/time-attendance-record`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List turnover bills
     * List turnover bills. Required user permission: Reporting
     * @param from Search turnover bills from date/time
     * @param till Search turnover bills till date/time
     * @param userId Search turnover bills for certain user only
     * @param salesAreaId Search turnover bills for certain sales area only
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turnoverBillGet(from: Date, till: Date, userId?: number, salesAreaId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TurnoverBill>>;
    public turnoverBillGet(from: Date, till: Date, userId?: number, salesAreaId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TurnoverBill>>>;
    public turnoverBillGet(from: Date, till: Date, userId?: number, salesAreaId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TurnoverBill>>>;
    public turnoverBillGet(from: Date, till: Date, userId?: number, salesAreaId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling turnoverBillGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling turnoverBillGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (salesAreaId !== undefined && salesAreaId !== null) {
            queryParameters = queryParameters.set('salesAreaId', <any>salesAreaId);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TurnoverBill>>('get',`${this.basePath}/turnover-bill`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List turnover transactions
     * List turnover transactions. Required user permission: Reporting
     * @param from Search turnover transactions from date/time
     * @param till Search turnover transactions till date/time
     * @param salesAreaId Specify search turnover transactions of a certain sales area only
     * @param paidOnly When true, it includes only order items which have been paid during this period. By default this property is false
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turnoverTransactionGet(from: Date, till: Date, salesAreaId?: number, paidOnly?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<TurnoverTransaction>>;
    public turnoverTransactionGet(from: Date, till: Date, salesAreaId?: number, paidOnly?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TurnoverTransaction>>>;
    public turnoverTransactionGet(from: Date, till: Date, salesAreaId?: number, paidOnly?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TurnoverTransaction>>>;
    public turnoverTransactionGet(from: Date, till: Date, salesAreaId?: number, paidOnly?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling turnoverTransactionGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling turnoverTransactionGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }
        if (salesAreaId !== undefined && salesAreaId !== null) {
            queryParameters = queryParameters.set('salesAreaId', <any>salesAreaId);
        }
        if (paidOnly !== undefined && paidOnly !== null) {
            queryParameters = queryParameters.set('paidOnly', <any>paidOnly);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TurnoverTransaction>>('get',`${this.basePath}/turnover-transaction`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find turnover transaction by ID
     * Find turnover transaction by ID. Required user permission: Reporting
     * @param id Specify to get turnover transaction by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turnoverTransactionIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<TurnoverTransaction>;
    public turnoverTransactionIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TurnoverTransaction>>;
    public turnoverTransactionIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TurnoverTransaction>>;
    public turnoverTransactionIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling turnoverTransactionIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TurnoverTransaction>('get',`${this.basePath}/turnover-transaction/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List user break records
     * List user break records. Required user permission: Reporting
     * @param from Search records from date/time
     * @param till Search records till date/time
     * @param userId Search records for user with specific ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userBreakRecordGet(from: Date, till: Date, userId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<UserBreakRecord>>;
    public userBreakRecordGet(from: Date, till: Date, userId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserBreakRecord>>>;
    public userBreakRecordGet(from: Date, till: Date, userId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserBreakRecord>>>;
    public userBreakRecordGet(from: Date, till: Date, userId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling userBreakRecordGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling userBreakRecordGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserBreakRecord>>('get',`${this.basePath}/user-break-record`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List voucher payments
     * List voucher payments per time period. Required user permission: Reporting
     * @param from Search records from date/time
     * @param till Search records till date/time
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voucherPaymentItemGet(from: Date, till: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<VoucherPaymentItem>>;
    public voucherPaymentItemGet(from: Date, till: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VoucherPaymentItem>>>;
    public voucherPaymentItemGet(from: Date, till: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VoucherPaymentItem>>>;
    public voucherPaymentItemGet(from: Date, till: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling voucherPaymentItemGet.');
        }

        if (till === null || till === undefined) {
            throw new Error('Required parameter till was null or undefined when calling voucherPaymentItemGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (till !== undefined && till !== null) {
            queryParameters = queryParameters.set('till', <any>till.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VoucherPaymentItem>>('get',`${this.basePath}/voucher-payment-item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
