import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Tip } from "src/app/Classes/tip";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-calculate-tip",
  templateUrl: "./calculate-tip.component.html",
  styleUrls: ["./calculate-tip.component.css"],
})
export class CalculateTipComponent implements OnInit {
  @Input("startDate") startDate;
  @Input("endDate") endDate;
  @Input("tips") tips: Tip[] = [];

  hours: any[] = [];
  staff: any = {};
  tipsTotal: number = 0;
  hoursTotal: number = 0;

  constructor(
    public modal: NgbActiveModal,
    public db: StaffAppDatabase,
    public loading: LoadingService,
    public alert: AlertsService
  ) {

  }

  ngOnInit(): void {
    this.startDate.setHours(12, 0, 0, 0);
    this.endDate.setHours(11, 59, 59, 999);
    this.endDate.setDate(this.endDate.getDate() + 1);
    console.log(this.startDate, this.endDate);

    this.getData();
  }

  async getData() {
    this.loading.show();

    this.hours = await this.db.getHoursAsync(this.startDate, this.endDate);
    if(!this.hours){
      this.loading.hide();
      this.alert.nextEmit(AlertsService.error("No hours found for this date range."));
      return;
    }
    this.hours.forEach((day) => {
      console.log(day);
      var date = new Date(day.date);
      var start = new Date(date);
      start.setHours(12, 0, 0, 0);
      var end = new Date(start);
      end.setDate(end.getDate() + 1);
      end.setHours(11, 59, 59, 999);
      var hoursToday = 0;
      var tipsToday = this.tips.filter((t) => {
        return t.dateTime >= start.getTime() && t.dateTime <= end.getTime();
      }).reduce((a, b) => a + b.amount, 0);

      console.log(tipsToday,date);
      day.day.forEach((s) => {
        if (!this.staff[s.key]) {
          this.staff[s.key] = { total: 0, tips: 0, name: s.name, uid: s.key };
        }

        this.staff[s.key].total += s.total;
        hoursToday += s.total;
      });

      day.day.forEach((s) => {
        if (!this.staff[s.key]) {
          this.staff[s.key] = { total: 0, tips: 0, name: s.name, uid: s.key };
        }

        this.staff[s.key].tips += (s.total / hoursToday) * tipsToday;
      });



      this.hoursTotal += hoursToday;
    });

    this.tipsTotal = this.tips.reduce((a, b) => a + b.amount, 0);


    console.log(this.hours);
    console.log(this.staff);
    this.loading.hide();
  }

  markAndSheet() {}
}
