<div style="max-width: 350px;">
  <app-input type="date" name="Start Date" [model]="startDate" (changed)="dateChanged($event)"></app-input>
  <app-input editable="true" type="select" name="Bar" [list]="bars" [model]="bar"
             (changed)="bar = $event; getCurrentPrice()" listHideAll="true"></app-input>
  <app-input editable="true" type="checkbox" name="Use Table Open Time" [model]="useOpenTime"
             (changed)="useOpenTime = $event"></app-input>
  <app-input editable type="checkbox" name="Normalize to e/min" [model]="normalize"
             (changed)="normalize = $event"></app-input>
  <app-input editable="true" type="checkbox" name="Simulate" [model]="simulate"
             (changed)="simulate= $event"></app-input>
  <app-input editable="true" type="number" name="Simulation Speed (s)" [model]="speed"
             (changed)="speed = $event"></app-input>
  <app-input editable="false" type="number" name="Max Jump Up" [model]="maxUp" (changed)="maxUp = $event"></app-input>
  <app-input editable="false" type="number" name="Max Jump Down" [model]="maxDown"
             (changed)="maxDown = $event"></app-input>
  <app-input editable="false" type="number" name="Minimum Price" [model]="minimumPrice"
             (changed)="minimumPrice = $event"></app-input>
  <app-input editable type="number" name="Current Price" [model]="defaultPrice"></app-input>

  <app-input editable="true" type="select" name="Equation Type" [list]="equationTypes" [model]="equationType"
             (changed)="equationType=$event" listHideAll="true"></app-input>

  <div *ngIf="equationType == 'Custom Weights'">
    <app-input editable="true" type="number" name="First 5 Minutes" [model]="customWeights.first5Minutes"
               (changed)="customWeights.first5Minutes=$event"></app-input>
    <app-input editable="true" type="number" name="Middle 5 Minutes" [model]="customWeights.middle5Minutes"
               (changed)="customWeights.middle5Minutes=$event"></app-input>
    <app-input editable="true" type="number" name="Last 5 Minutes" [model]="customWeights.last5Minutes"
               (changed)="customWeights.last5Minutes=$event"></app-input>
    <app-input editable="true" type="number" name="Last 15 Minutes" [model]="customWeights.last15Minutes"
               (changed)="customWeights.last15Minutes=$event"></app-input>

    <app-input editable="true" type="number" name="Last 1 Hour" [model]="customWeights.lastHour"
               (changed)="customWeights.lastHour=$event"></app-input>
  </div>

  <div *ngIf="equationType == 'Bell Curve'">
    <app-input editable="true" type="number" name="Curve Height" [model]="bellCurveOpts.height"
               (changed)="bellCurveOpts.height=$event"></app-input>
    <app-input editable="true" type="number" name="Curve Peak" [model]="bellCurveOpts.peak"
               (changed)="bellCurveOpts.peak=$event"></app-input>
    <app-input editable="true" type="number" name="Standard Deviation" [model]="bellCurveOpts.stdev"
               (changed)="bellCurveOpts.stdev=$event"></app-input>


  </div>
  <button (click)="prepareSimulation()">Prepare Simulation</button>
</div>
<div style="display: block;">
  <canvas #baseChart baseChart width="1000" height="700" [type]="'line'" [data]="lineChartData"
          [options]="lineChartOptions" [legend]="lineChartLegend">
  </canvas>
</div>
