import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

import * as XLSX from "xlsx";
@Component({
  selector: "app-competition",
  templateUrl: "./competition.component.html",
  styleUrls: ["./competition.component.css"],
})
export class CompetitionComponent implements OnInit {
  constructor(
    public bi: BarInfo,
    public db: StaffAppDatabase,
    public alert: AlertsService,
    public loading: LoadingService
  ) {}
  bars = ["Amsterdam", "Groningen", "Tilburg"];
  currentBar = "";
  currentDate = new Date();
  firstDay = new Date(2024, 10, 1, 12, 0, 0, 0);
  lastDay = new Date(2024, 10, 30, 12, 0, 0, 0);
  dates = [];
  barData = {
    Amsterdam: {
      days: [],
      total: 0,
      stock: [],
      totalEuros: 0,
      shots: 0,
    },
    Groningen: {
      days: [],
      total: 0,
      stock: [],
      totalEuros: 0,
      shots: 0,
    },
    Tilburg: {
      days: [],
      total: 0,
      stock: [],
      totalEuros: 0,
      shots: 0,
    },
  };
  totals = {};
  liveShots = { Amsterdam: 0, Groningen: 0, Tilburg: 0 };
  currentDayData = {
    bar: "",
    date: null,
    days: [],
    total: 0,
    score: 0,
    euros: 0,
    shotPercentage: 0,
    omzetPercentage: 0,
    yesterday: 0,
  };

  yesterdayIndex = 0;
  yesterday = new Date();

  ngOnInit(): void {
    this.getData();
    this.db.getLiveShotsFunction().subscribe((a) => {
      this.getLive = a;
    });
  }

  dateChanged(event) {
    this.currentDate = new Date(event);
  }

  getLive: boolean = false;
  getLiveChanged() {
    this.getLive = !this.getLive;
    this.db.saveGetLiveShots(this.getLive);
  }

  get() {
    this.getCompetitionData(this.currentBar, this.currentDate);

    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.yesterday = new Date(yesterday);
    this.yesterdayIndex = yesterday.getDate() - 2;
  }

  async getData() {
    this.loading.nextEmit("on", 0);

    var today = new Date();
    today.setDate(today.getDate() - 1);

    var promises: any[] = [];

    var numberOfDays =
      Math.round(
        (this.lastDay.getTime() - this.firstDay.getTime()) / 86400000
      ) * 3;

    this.barData = {
      Amsterdam: {
        days: [],
        total: 0,
        stock: [],
        totalEuros: 0,
        shots: 0,
      },
      Groningen: {
        days: [],
        total: 0,
        stock: [],
        totalEuros: 0,
        shots: 0,
      },
      Tilburg: {
        days: [],
        total: 0,
        stock: [],
        totalEuros: 0,
        shots: 0,
      },
    };

    var done = 0;
    for (var bar of this.bi.bars) {
      if (bar == "All") continue;
      if (bar == "Daga Beheer") continue;
      if (bar == "Pertempto") continue;
      if (bar == "Old Staff") continue;
      if (bar == "Zwolle") continue;
      if (bar == "Arnhem") continue;

      var stock = await this.db.getStockForBar(bar);
      this.barData[bar].stock = stock.reduce((pre: any[], curr: any) => {
        return pre.concat(Object.values(curr));
      }, []);

      var day = new Date(this.firstDay);
      //await this.db.getCompetitionBeers(bar, today).toPromise();
      await this.db.getShots(bar, today).then((a: any) => {
        this.liveShots[bar] = Object.values(a).reduce(
          (a: number, b: number) => a + b,
          0
        );
      });

      while (day.getTime() <= this.lastDay.getTime()) {
        const b = bar;
        const d = new Date(day);
        promises.push(
          this.db
            .getShots(b, d)

            .then((a) => {
              done++;
              this.loading.nextEmit(
                "on",
                Math.round((done / numberOfDays) * 100)
              );

              return {
                bar: b,
                day: d,
                shotTypes: a,
                shots: Object.values(a).reduce(
                  (a: number, b: number) => a + b,
                  0
                ),
              };
            })
        );
        day.setDate(day.getDate() + 1);
      }
    }

    var data = await Promise.all(promises);
    this.loading.nextEmit(null);

    data.forEach((entry) => {
      if (!this.barData[entry.bar]) {
        this.barData[entry.bar] = { days: [], total: 0 };
      }
      this.barData[entry.bar].days.push(entry);
      this.barData[entry.bar].total += entry.shots;
      for (var k in entry.shotTypes) {
        var drink = this.barData[entry.bar].stock.filter((d) => {
          return d.serverId == k;
        })[0];
        this.barData[entry.bar].totalEuros += drink.price * entry.shotTypes[k];
      }
    });

    this.dates = [];
    var day = new Date(this.firstDay);

    while (day.getTime() <= this.lastDay.getTime()) {
      const d = new Date(day);
      this.dates.push(d);
      day.setDate(day.getDate() + 1);
    }
  }

  async save() {
    await this.db.saveShotCompetitionData(
      this.currentBar,
      this.currentDayData,
      "competitionNov24"
    );
    this.alert.nextEmit(AlertsService.good("Saved"));
    this.currentBar = "";
  }

  twoDP(num: number) {
    return Math.round(num * 100) / 100;
  }

  saveValue(type: string, value: string) {
    this.currentDayData[type] = Number(value);
  }

  async getCompetitionData(bar, day) {
    this.loading.nextEmit("on", 0);
    var data: any = await this.db.getShotCompetitionData(
      bar,
      day,
      "competitionNov24"
    );
    this.loading.nextEmit(null);

    if (data) {
      this.currentDayData.total = data.total;
      this.currentDayData.days = data.days;
      this.currentDayData.score = data.score;
      this.currentDayData.euros = data.euros;
      this.currentDayData.yesterday = data.yesterday || 0;
      this.currentDayData.shotPercentage = data.shotPercentage || 0;
      this.currentDayData.omzetPercentage = data.omzetPercentage || 0;
      this.currentDayData.days = [];
    } else {
      this.currentDayData = {
        bar: "",
        date: null,
        days: [],
        total: 0,
        score: 0,
        euros: 0,
        yesterday: 0,
        shotPercentage: 0,
        omzetPercentage: 0,
      };
    }

    this.currentDayData.bar = bar;
    this.currentDayData.date = day;
    this.currentDayData.days = this.barData[bar].days;
    return data;
  }
  @ViewChild("resultsTable") table: ElementRef;
  toExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    var fmt = "@";
    wb.Sheets["Sheet1"]["F"] = fmt;

    /* save to file */
    XLSX.writeFile(wb, "July2024BeerCompetition.xlsx");
  }
}
