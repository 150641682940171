import { PrivateBookingInfo } from "./private-booking-info";

export class TillInformation {
  password = "";
  url = "";
  username = "";
  token = "";
  path = "";
  database = "";
  salesAreaId = "";
  cashReference: number = 0;
  internetReference: number = 0;
  pinReference: number = 0;
  staffReference: number = 0;
  vipHalf: number = 0;
  vipHour: number = 0;

  internetQRReference: number = 0;
  missingReference: number = 0;
  postCode: string = "";
  privateBookings: { [key: string]: PrivateBookingInfo } = {};
  staffDiscountReason: number = 0;

  constructor() {}
  verify() {
    return true;
  }

  fixVariables() {
    // inputs are strings, cast to number if needed
    // this.number = Number(this.number);

    this.cashReference = Number(this.cashReference);
    this.internetReference = Number(this.internetReference);
    this.pinReference = Number(this.pinReference);
    this.staffReference = Number(this.staffReference);
    this.vipHalf = Number(this.vipHalf);
    this.vipHour = Number(this.vipHour);
    this.internetQRReference = Number(this.internetQRReference);
    this.missingReference = Number(this.missingReference);
    this.staffDiscountReason = Number(this.staffDiscountReason);
  }
}
