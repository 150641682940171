<div class="flex-columns">
  <h1>Tasks</h1>
  <div class="button-row">
    <button (click)="viewScheduled = false" color="primary" mat-raised-button>
      New
    </button>
    <button (click)="viewScheduled = true" color="primary" mat-raised-button>
      Scheduled
    </button>
  </div>
  <div class="column scrollable-column" *ngIf="!viewScheduled">
    <button *ngFor="let task of tasks" style="
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border-radius: 5px;
        margin-bottom: 5px;
      " [style.backgroundColor]="switchStatusColor(task.status)" (click)=" openTask(task)">
      <div class="button-row" style="margin-top: 10px; overflow: hidden; white-space: normal">
        {{ task.title }} {{ task.date | date : "yyyy/MM/dd" }}
      </div>
      <div>{{ switchStatus(task.status) }} {{ getUserName(task.user) }}</div>
      <div *ngIf="task.completedOn && task.completedOn > 0">
        Completed on {{ task.completedOn | date : "yyyy/MM/dd" }}
      </div>
    </button>
  </div>
  <div class="column scrollable-column" style="min-height: 200px;" *ngIf="viewScheduled">
    <br>
    <button style="
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 5px;" (click)="createScheduledTask()">Create</button>
    <button style="
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 5px;" *ngFor="let task of scheduledTasks" (click)="scheduledTaskClicked(task)">
      <div class="button-row" style="margin-top: 10px; overflow: hidden; white-space: normal">
        {{ task.title }}
      </div>
      <div class="row" style="margin: 0">
        <div *ngFor="let bar of task.bars">{{bar}}, &nbsp;</div>
      </div>
    </button>
  </div>
</div>

<ng-container *ngIf="task">
  <div class="flex-columns" style="width: 400px; align-items: stretch" *ngIf="task">
    <div class="button-row">
      <h1>Task</h1>
      <button class="datchev" (click)="showChat = !showChat">
        {{ showChat ? " Photos" : "Chat" }}
      </button>
    </div>

    <div class="edit-row">
      <div class="edit-desc">Title</div>
      <div class="edit">{{ task.title }}</div>
    </div>
    <div class="edit-row">
      <div class="edit-desc">Description</div>
      <div class="edit" style="
          word-wrap: break-word !important;
          white-space: normal !important;
          overflow: auto;
          height: auto !important;
        ">
        {{ task.description }}
      </div>
    </div>
    <div class="edit-row">
      <div class="edit-desc">User</div>
      <div class="edit">{{ getUserName(task.user) }}</div>
    </div>
    <div class="edit-row">
      <div class="edit-desc">Status</div>
      <div class="edit">{{ switchStatus(task.status) }}</div>
    </div>

    <div class="edit-row">
      <div class="edit-desc">Created By</div>
      <div class="edit">{{ task.creatorName || task.creator || "n/a" }}</div>
    </div>

    <div class="edit-row">
      <div class="edit-desc">Assigned</div>
      <select #user [(value)]="task.user">
        <option value="management">Management</option>
        <option value="manager">Manager</option>
      </select>
    </div>

    <div class="edit-row">
      <div class="edit-desc">Task Status</div>
      <select #status [(value)]="task.status">
        <option value="requested">Requested</option>
        <option value="progress">In Progress</option>
        <option value="archived">Completed</option>
        <option value="removed">Archive</option>
      </select>
    </div>

    <div *ngIf="task.status == 'archived' || task.status == 'removed'">
      <div class="edit-row">
        <div class="edit-desc">Completed On</div>
        <div class="edit">{{ task.completedOn | date : "yyyy/MM/dd" }}</div>
      </div>

      <p style="margin: 5px 0px 0px 0px">Show on Staff App Until:</p>
      <input type="date" [ngModel]="task.showUntil | date:'YYYY-MM-dd'" (ngModelChange)="showUntilChanged($event)">
    </div>

    <div class="button-row">
      <button (click)="save()" color="primary" mat-raised-button>Save</button>
      <button (click)="cancel()" color="accent" mat-raised-button>Cancel</button>
    </div>
  </div>
  <div class="flex-columns" style="max-width: 400px" *ngIf="task && !showChat">
    <h1>Pictures</h1>
    <div class="button-row" style="margin-bottom: 5px">
      <button (click)="selectedImage = 0" [class]="'datchev ' + (selectedImage == 0 ? 'green' : '')">
        1
      </button>
      <button (click)="selectedImage = 1" [class]="'datchev ' + (selectedImage == 1 ? 'green' : '')">
        2
      </button>
      <button (click)="selectedImage = 2" [class]="'datchev ' + (selectedImage == 2 ? 'green' : '')">
        3
      </button>
    </div>
    <div class="picture-box">
      <div class="tasks" style="top: 20px !important; z-index: 2; bottom: auto !important">
        <h3 style="color: 'white'">Photo {{ selectedImage }}</h3>
      </div>

      <div (click)="download(pictures[selectedImage])" class="picture">
        <img [src]="pictures[selectedImage]" />
      </div>
    </div>
  </div>

  <div class="flex-columns" style="width: 400px; max-height: calc(100% - 20px)" *ngIf="task && showChat">
    <h1>Chat</h1>
    <div class="edit-row" style="
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
      ">
      <input type="text" [(ngModel)]="nextMessage" />
      <button class="datchev" (click)="sendMessage()">Send</button>
    </div>
    <div class="column scrollable-column" style="
        width: 100%;
        max-height: calc(100% - 150px);
        flex-direction: column-reverse;
      ">
      <div class="edit-row" *ngFor="let message of task.messages | keyvalue" style="
          max-width: 380px;
          border-radius: 5px;
          color: white;
          padding: 5px;
          margin-bottom: 2px;
          overflow: auto;
          word-wrap: break-word;
          max-height: 200px;
        " [style.backgroundColor]="message.value.displayName | toColor">
        <div class="edit-desc">
          {{ message.value.displayName }}
        </div>
        <div class="edit"
             style="align-self: flex-end; max-height: 200px; word-wrap: break-word; overflow: auto; max-width: 100%">
          {{ message.value.message }}
        </div>
        <div class="edit-desc" style="align-self: flex-end">
          {{ message.value.date | date : "yyyy-MM-dd HH:mm" }}
        </div>
      </div>
    </div>
  </div>



</ng-container>
<ng-container *ngIf="scheduledTask">
  <div class="flex-columns" style="width: 400px; align-items: stretch">
    <div class="button-row">
      <h1>Scheduled Task</h1>
    </div>

    <app-input type="text" name="Title" [model]="scheduledTask.title" (changed)="scheduledTask.title = $event"
               [editable]="isEdit"></app-input>
    <app-input type="text" name="Description" [model]="scheduledTask.description"
               (changed)="scheduledTask.description = $event" [editable]="isEdit"></app-input>

    <app-input type="select" [editable]="isEdit" name="Frequency" (changed)="frequencyChange($event)"
               [model]="scheduledTask.frequencyType" [list]="frequencySimplified" listHideAll=true></app-input>

    <app-input type="multiselect" [editable]="isEdit" name="Bars" (changed)="barsChange($event)"
               [model]="scheduledTask.bars" [list]="bars" listHideAll=true></app-input>

    <app-input type="checkbox" [editable]="isEdit" name="Force Photo" (changed)="scheduledTask.takePhoto = $event"
               [model]="scheduledTask.takePhoto"></app-input>


    <div class="button-row">
      <button (click)="saveScheduledTask()" color="primary" mat-raised-button>{{isEdit ? 'Save' : 'Edit'}}</button>
      <button (click)="cancel()" color="accent" mat-raised-button>Cancel</button>
      <button (click)="deleteScheduledTask()" *ngIf="!isEdit" color="accent" mat-raised-button>Delete</button>
    </div>

    <div class="divider"></div>

    <ng-container *ngIf="scheduledTask.lastCompletedOn">
      <h2>Last Completed Info</h2>
      {{scheduledTask.lastCompletedOn | json }}
    </ng-container>
  </div>

  <div class="flex-columns" style="max-width: 400px" *ngIf="!showChat">
    <h1>Pictures</h1>
    <div class="button-row" style="justify-content: flex-start; margin-bottom: 5px; max-width: 95%; overflow: auto;
    overflow-y: hidden; ">
      <button style="margin-left: 5px" *ngFor="let bar of bars; let i = index" (click)="selectedImage = i"
              [class]="'datchev ' + (selectedImage == i ? 'green' : '')">{{bar.name}}</button>

    </div>
    <div class="picture-box">
      <div class="tasks" style="top: 20px !important; z-index: 2; bottom: auto !important">
        <h3 style="color: 'white'">Photo {{ selectedImage }}</h3>
      </div>

      <div (click)="download(extraPictures[selectedImage])" class="picture">
        <img [src]="extraPictures[selectedImage]" />
      </div>
    </div>
  </div>




</ng-container>
