import { Component, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-working",
  templateUrl: "./working.component.html",
  styleUrls: ["./working.component.css"],
})
export class WorkingComponent implements OnInit {
  date = new Date();
  constructor(
    private db: StaffAppDatabase,
    private loading: LoadingService,
    private alert: AlertsService,
    private bi: BarInfo
  ) {}

  ngOnInit(): void {
    this.bi.barObserver.subscribe(async (bar) => {
      if (bar) {
        this.date = new Date();
        this.getData();
      }
    });
  }

  async getData() {
    const working = await this.db
      .getWorking(this.bi.bar, this.date)
      .then((a) => a)
      .catch((e) => {
        console.error(e);
        return [];
      });
    console.log(working);
    for (var uid of working) {
      const worked = await this.db
        .getWorked(this.bi.bar, this.date, uid)
        .then((a) => a)
        .catch((e) => {
          console.error(e);
          return [];
        });
      console.log(worked);
    }
  }
}
