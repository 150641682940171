import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateth'
})
export class DatethPipe implements PipeTransform {

  transform(value: Date,args: string): string {
    value = new Date(value)
    console.log(value,args);
    if (!value) {
      return '';
    }
   let months= ["January","February","March","April","May","June","July",
           "August","September","October","November","December"]
           let days = ["Sunday","Monday", "Tuesday","Wednesday","Thursday","Friday","Saturday"]

           if(args && args=='time') {
            return `${days[value.getDay()]} ${value.getDate()}${this.nth(value.getDate)} ${months[value.getMonth()]} ${value.getFullYear()} - ${value.getHours()}:${value.getMinutes()}`;
           }
           return `${days[value.getDay()]} ${value.getDate()}${this.nth(value.getDate)} ${months[value.getMonth()]} ${value.getFullYear()}`;

  }

 nth(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

}
