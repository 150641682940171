import { Component, Input, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-worked-records",
  templateUrl: "./worked-records.component.html",
  styleUrls: ["./worked-records.component.css"],
})
export class WorkedRecordsComponent implements OnInit {
  @Input() records: any[] = [];
  staffnames = {};
  constructor(private db: StaffAppDatabase, private bi: BarInfo) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    const staff = await this.db.getStaffNamesAsync(this.bi.bar);
    staff.forEach((a: any) => {
      this.staffnames[a.uid] = a.firstName + " " + a.lastName;
    });

    this.records.forEach(async (record) => {
      record.name = this.staffnames[record.uid];
      record.values = Object.values(record.updates);
    });
  }
  async sortData() {}
}
