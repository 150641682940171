import { NgZone, Pipe, PipeTransform } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { end } from "@popperjs/core";
import { stringify } from "querystring";
import { CalendarManager } from "src/app/Classes/my-calendar/calendar-manager";

import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
@Pipe({ name: "datePipe" })
export class DatePipe implements PipeTransform {
  transform(val) {
    let d = new Date(val);
    return d.toISOString().slice(0, 16);
  }
}

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.css"],
})
export class MessagesComponent implements OnInit {
  barSub = null;
  sub = null;
  calSub = null;
  staffSub = null;
  messages = [];
  barName = null;
  selectedEvents = [];
  adding = false;
  selectedMessage = null;
  constructor(
    private db: StaffAppDatabase,
    private barInfo: BarInfo,
    private loading: LoadingService,
    private alert: AlertsService,
    private zone: NgZone
  ) {
    this.barSub = this.barInfo.barObserver.subscribe((values) => {
      this.barName = values;
      this.getData();
    });
  }

  ngOnInit(): void {}

  async getData() {
    this.loading.nextEmit("on");
    this.messages = await this.db.getMessagesAsync(this.barName);
    console.log(this.messages);
    this.loading.nextEmit(null);
  }

  save() {
    this.loading.nextEmit("on");
    if (this.adding) {
      this.saveNew();
      return;
    }
    try {
      this.db.updateMessage(this.barName, this.selectedMessage);
      this.alert.nextEmit(AlertsService.good("Saved Message"));
    } catch (e) {
      this.alert.nextEmit(
        AlertsService.error("Error Saving Message", e.message)
      );
    }

    this.getData();
  }

  delete() {
    this.loading.nextEmit("on");
    try {
      this.db.deleteMessage(this.barName, this.selectedMessage);
      this.alert.nextEmit(AlertsService.good("Deleted Message"));
    } catch (e) {
      this.alert.nextEmit(
        AlertsService.error("Error Deleting Message", e.message)
      );
    }

    this.getData();
  }

  saveNew() {
    this.loading.nextEmit("on");
    this.adding = false;
    try {
      this.db.addMessage(this.barName, this.selectedMessage);
      this.alert.nextEmit(AlertsService.good("Saved New Message"));
    } catch (e) {
      this.alert.nextEmit(
        AlertsService.error("Error Saving Message", e.message)
      );
    }

    this.getData();
  }

  cancel() {
    this.selectedMessage = null;
  }

  messageSelected(m) {
    console.log("secled messsage = ", m);
    this.selectedMessage = m;
  }

  radioClicked() {
    console.log("hoi");
    this.selectedMessage.endShift = !this.selectedMessage.endShift;
  }

  ngOnDestroy() {
    if (this.barSub) {
      this.barSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.calSub) {
      this.calSub.unsubscribe();
    }
    if (this.staffSub) {
      this.staffSub.unsubscribe();
    }
  }

  removeDay(i) {
    console.log("removing:" + i, this.selectedMessage);
    if (this.selectedMessage.days.includes(i)) {
      this.selectedMessage.days = this.selectedMessage.days.split(i).join("");
    } else {
      this.selectedMessage.days += i;
    }
    console.log("removing:" + i, this.selectedMessage);
  }

  changeDate(start, date) {
    console.log(start, date);
    if (date) {
      if (start) {
        this.selectedMessage.start = new Date(date).getTime();
      } else {
        this.selectedMessage.end = new Date(date).getTime();
      }
    }
    console.log(this.selectedMessage);
  }

  add() {
    this.adding = true;
    this.selectedMessage = {
      title: "",
      message: "",
      days: "",
      start: new Date(),
      end: new Date(),
      endShift: false,
    };
  }
}
