import { Injectable } from "@angular/core";
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { tr } from "date-fns/locale";
import {
  cloneDeep,
  mergeWith,
  keyBy,
  isNumber,
  isBoolean,
  merge,
  concat,
  add,
} from "lodash";
import { map, take } from "rxjs/operators";
import { Omzet } from "../Classes/omzet";
import { Overview } from "../Classes/Overview";
import { Verbruik } from "../Classes/verbruik";

import { MyDatePipe } from "../Pipes/my-date.pipe";
import { LoadingService } from "../Services/loading.service";
import { StaffAppDatabase } from "../Services/staffappdatabase";
import { AlertsService } from "./alerts/alerts.service";
import { GenericMessage } from "../Classes/generic-message";
import { GenericMessageGroup } from "../Classes/generic-message-group";

@Injectable({
  providedIn: "root",
})
export class OverviewService {
  public stock: any = [];
  private staff: any = [];
  private bar: string;
  private year: any = {};
  private pipe = new MyDatePipe();
  public date: Date = new Date();
  public viewType: number = 0;
  public viewScale: number = 0;
  public selection: any = new Overview(true);
  public charts = [];
  public endDate = new Date();

  public start: Date = new Date();
  public end: Date = new Date();

  public missingDates: any[] = [];

  shiftMessages: GenericMessageGroup;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  dateVisible = false;

  constructor(
    private db: StaffAppDatabase,
    private alert: AlertsService,
    private loading: LoadingService,
    calendar: NgbCalendar
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
  }

  async getRange(bar, build = true) {
    this.reset(bar != this.bar, false);
    this.bar = bar;
    this.shiftMessages = null;
    await this.getStock2();
    if (build) await this.buildSelection();

    return this.selection;
  }

  setBar(bar) {
    this.bar = bar;
  }
  reset(barReset, dateReset = true) {
    if (barReset) {
      this.stock = [];
      this.staff = [];
    }
    if (dateReset) {
      this.date = new Date();
    }

    this.year = {};
    this.viewType = 0;
    this.viewScale = 0;
    this.selection = new Overview(true);
    this.charts = [];
  }

  async getStock2() {
    if (!this.stock.length) {
      this.stock = await this.getStock();
    }

    if (!this.staff.length) {
      this.staff = await this.db
        .getStaffAtBar(this.bar)
        .pipe(take(1))
        .toPromise();
    }
  }
  async getData(date) {
    //make sure year object is valid
    if (this.year && this.year[this.pipe.transform(date)]) {
      return;
    }
    //make sure has all variables while not ruining
    this.year[this.pipe.transform(date)] = new Overview(false);

    await this.getWorked(date);
    await this.getCleaningHours(date);
    await this.getVerbruik(date);
    await this.getRecurringCosts(date);
    await this.getOneOffCosts(date);
    await this.getOrders(date);
    await this.getInOut(date);

    var kosten = {
      vasteLasten:
        this.year[this.pipe.transform(date)].recurring.reduce(
          (part, a) => part + a.price,
          0
        ) / this.getDaysInMonth(date.getMonth(), date.getFullYear()),
      oneoff: this.year[this.pipe.transform(date)].oneoff.reduce(
        (part, a) => part + a.price,
        0
      ),
      inout: this.year[this.pipe.transform(date)].inout.reduce(
        (part, a) => part - a.amount,
        0
      ),
      orders: this.year[this.pipe.transform(date)].orders.reduce(
        (part, a) => part + a.total,
        0
      ),
      total: 0,
    };

    kosten.total =
      kosten.vasteLasten + kosten.oneoff + kosten.inout + kosten.orders;
    this.year[this.pipe.transform(date)].totalKosten +=
      kosten.total +
      this.year[this.pipe.transform(date)].verbruik.total +
      this.year[this.pipe.transform(date)].personel.totalCost;
    this.year[this.pipe.transform(date)].kosten = kosten;

    this.loading.nextEmit("on");

    //omzet at this point should still be all 0's
    //Get Omzet and Set It
    const data = await this.db
      .getFinalTelling(this.bar, date)
      .then((a: any) => {
        return a;
      })
      .catch((e) => {
        this.alert.nextEmit(AlertsService.error("Couldnt get Data", e));
      });

    if (!data) {
      this.alert.nextEmit(AlertsService.info("No omzet data yet", "", 3));
    }

    this.formatData(data, this.pipe.transform(date));
    console.log(
      "after formatting: " + date.toISOString(),
      JSON.stringify(this.year[this.pipe.transform(date)].omzet, null, 2)
    );
  }

  formatData(data, date) {
    if (!data) {
      data = { end: null };
    }
    if (!data.end) {
      data.end = null;
    }
    if (data && data.final) {
      this.year[date].omzet = mergeWith(
        this.year[date].omzet,
        data.final,
        (obj, src) => {
          if (src == null || src == undefined) {
            return obj;
          }
          return src;
        }
      );
    } else if (data) {
      //not final
      var tempOmzet = {
        pin: data.expectedPin,
        cash: data.expectedCash,
        internet: data.internet,
        flesjes: 0,
        teller: 0,
      };
      this.year[date].omzet = merge(this.year[date].omzet, tempOmzet);
    }

    this.year[date].omzet.total =
      this.year[date].omzet.pin +
      this.year[date].omzet.cash +
      this.year[date].omzet.internet +
      this.year[date].omzet.flesjes +
      this.year[date].omzet.teller;
    this.year[date].omzet.differences.total =
      this.year[date].omzet.differences.pin +
      this.year[date].omzet.differences.cash +
      this.year[date].omzet.differences.internet +
      this.year[date].omzet.differences.flesjes +
      this.year[date].omzet.differences.teller;
  }

  async getMessages() {
    this.shiftMessages = await this.db.getGenericMessage(
      this.bar,
      this.start,
      "telling"
    );
  }

  async toggleMessageActive() {
    this.shiftMessages.active =
      this.shiftMessages.active === "true" ? "false" : "true";
    await this.db.updateGenericMessageActive(this.shiftMessages);
    await this.getMessages();
  }
  async buildSelection(start = null, end = null) {
    this.selection = new Overview(true);
    this.charts = [];

    switch (this.viewType) {
      case 0:
        //its just today
        start = new Date(this.date);
        end = new Date(this.date);
        break;
      case 1:
        //everthing in this week
        start = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate() - ((this.date.getDay() + 6) % 7),
          12,
          0,
          0
        );
        end = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate() + ((7 - this.date.getDay()) % 7),
          0,
          13,
          0,
          0
        );
        break;
      case 2:
        //everything in month
        start = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          1,
          12,
          0,
          0
        );
        end = new Date(
          this.date.getFullYear(),
          this.date.getMonth() + 1,
          0,
          13,
          0,
          0
        );
        break;
      case 3:
        //year
        start = new Date(this.date.getFullYear(), 0, 1, 12, 0, 0);
        end = new Date(this.date.getFullYear(), 12, 0, 13, 0, 0);
        break;
      case 4:
        start = new Date(this.start);
        end = new Date(this.end);
        break;
    }

    if (this.viewType == 0) {
      this.getMessages();
    }

    if (this.viewType >= 2 && this.viewType != 4) {
      console.log("buiding months");
      await this.buildMonths(start, end);
    } else {
      console.log("building days");
      await this.buildDays(start, end);
    }
    this.finalBonus();
    this.charts = [].concat(this.charts);

    this.loading.nextEmit("");
  }

  async buildMonths(start, end) {
    this.selection = new Overview();
    const now = new Date();
    if (this.viewType === 2) {
      return await this.buildMonth(start);
    }

    var date = new Date(start);
    while (date <= end && date <= now) {
      await this.buildMonth(date);
      date.setMonth(date.getMonth() + 1);
    }
  }

  async buildMonth(start) {
    const now = new Date();
    var lastFinished: Date;
    if (now.getDate() >= 15) {
      lastFinished = new Date(now.getFullYear(), now.getMonth(), 0);
    } else {
      lastFinished = new Date(now.getFullYear(), now.getMonth() - 1, 0);
    }
    //get everyday of month
    const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
    await this.buildDays(start, end);
  }

  async finishedMonth(date) {
    if (!this.year || !this.year[date.getFullYear() + "-" + date.getMonth()]) {
      const data: any = await this.db
        .getFinishedMonth(this.bar, date)
        .then((a) => a)
        .catch((e) => {
          return null;
        });

      var sel = {
        inout: data.inout,
        recurring:
          data.kosten && data.kosten.recurring ? data.kosten.recurring : [],

        oneoff: data.kosten && data.kosten.oneoff ? data.kosten.oneoff : [],

        verbruik: data.verbruik,
        worked: data.worked,
        orders: data.kosten && data.kosten.orders ? data.kosten.orders : [],
        omzet: data.omzet,
      };

      this.year[date.getFullYear() + "-" + date.getMonth()] = sel;
    }
    var data = this.year[date.getFullYear() + "-" + date.getMonth()];

    this.selection.inout = concat(this.selection.inout, data.inout);

    this.selection.worked = Object.values(
      mergeWith(
        keyBy(this.selection.worked, "key"),
        keyBy(data.worked, "key"),
        (obj, src, key, obj2) => {
          if (key === "total") {
            return src + (Number(obj) || 0);
          }
        }
      )
    );

    this.selection.recurring = Object.values(
      merge(
        keyBy(this.selection.recurring, "key"),
        keyBy(data.recurring, "key")
      )
    );
    this.selection.oneoff = concat(this.selection.oneoff, data.oneoff);
    this.selection.orders = concat(this.selection.orders, data.orders);

    this.selection.verbruik.merge(this.selection.verbruik, data.verbruik);
    this.charts.push({ date: date.toISOString(), data: data.omzet });

    this.selection.omzet = mergeWith(
      this.selection.omzet,
      data.omzet,
      (obj, val) => {
        if (String(val) === "true" || String(val) === "false") {
          return obj && val;
          //(String(obj) === 'false' ? false : true);
        }
        const result = add(obj, val);

        // ignore NaN values and return undefined instead
        if (isNaN(result)) {
          return;
        }

        //only combine values that can be combined
        return result;
      }
    );

    this.selection.workedTotal = this.selection.worked.reduce(
      (part, a) => part + a.price * a.total,
      0
    );

    var kosten = {
      vasteLasten: data.recurring.reduce((part, a) => part + a.price, 0),
      oneoff: data.oneoff.reduce((part, a) => part + a.price, 0),
      inout: data.inout.reduce((part, a) => part - a.amount, 0),
      orders: data.orders.reduce((part, a) => part + a.total, 0),
      total: 0,
    };
    kosten.total =
      kosten.vasteLasten + kosten.oneoff + kosten.inout + kosten.orders;
    this.year[date.getFullYear() + "-" + date.getMonth()].kosten = kosten;
    data.kosten = kosten;
    this.selection.kosten = mergeWith(
      this.selection.kosten,
      data.kosten,
      (obj, src) => {
        if (isNumber(obj)) {
          return obj + src;
        }
        if (isBoolean(obj)) {
          return obj && src;
        }
      }
    );
  }

  getBonus(data) {
    this.selection.personel.totalHours += data.personel.totalHours;
    this.selection.personel.totalCost += data.personel.totalCost;
    this.selection.totalKosten =
      this.selection.totalKosten + (data.totalKosten || 0);
  }

  recalculateBonus() {
    var totalPercent = 0;
    this.selection.worked.forEach((hour) => {
      totalPercent += hour.percent;
    });

    var total = this.selection.bonus.total;
    if (this.selection.bonus.changedTotal) {
      total = this.selection.bonus.changedTotal;
    }

    this.selection.bonus.afterPercent = total * (1 - totalPercent / 100);
    this.selection.bonus.perHour =
      this.selection.bonus.afterPercent /
      Math.max(1, this.selection.personel.totalHours);

    this.selection.worked.forEach((hour) => {
      hour.percentTotal = total * (hour.percent / 100);
      hour.ratioTotal = hour.total * this.selection.bonus.perHour;
      hour.bonusTotal = hour.percentTotal + hour.ratioTotal;
    });
  }

  finalBonus() {
    var totalPercent = 0;
    this.selection.worked.forEach((hour) => {
      if (!hour.price) {
        hour.price = 10;
      }

      this.selection.personel.totalHours += hour.total;
      this.selection.personel.totalCost +=
        hour.actualCost > 0 ? hour.actualCost : (hour.price || 10) * hour.total;
      var staff = this.staff.filter((a) => a.uid === hour.key)[0];
      if (staff) {
        hour.percent = staff.percent || 0;
        hour.ratio = staff.ratio || 1;
      } else {
        hour.percent = 0;
        hour.ratio = 1;
      }

      totalPercent += hour.percent;
    });

    this.selection.bonus.net =
      -this.selection.totalKosten -
      this.selection.personel.totalCost +
      this.selection.omzet.total;
    var min = 500;
    switch (this.viewType) {
      case 0:
        //its just today
        break;
      case 1:
        //everthing in this week
        min *= 7;
        break;
      case 2:
        //everything in month
        min *
          this.getDaysInMonth(this.date.getMonth(), this.date.getFullYear());
        break;
      case 3:
        //year
        min *
          (365 +
            (this.getDaysInMonth(1, this.date.getFullYear()) == 29 ? 1 : 0));
      case 4:
        min *= this.getDaysInRange(this.start, this.end);
    }

    if (this.selection.bonus.net > min) {
      this.selection.bonus.total = Number(
        this.selection.bonus.net * 0.05
      ).toFixed(2);
    } else {
      this.selection.bonus.total = 0;
    }

    var total = this.selection.bonus.total;
    if (this.selection.bonus.changedTotal) {
      total = this.selection.bonus.changedTotal;
    }

    this.selection.bonus.afterPercent = total * (1 - totalPercent / 100);
    this.selection.bonus.perHour =
      this.selection.bonus.afterPercent /
      Math.max(1, this.selection.personel.totalHours);

    this.selection.worked.forEach((hour) => {
      hour.percentTotal = total * (hour.percent / 100);
      hour.ratioTotal = hour.total * this.selection.bonus.perHour;
      hour.bonusTotal = hour.percentTotal + hour.ratioTotal;
    });
  }

  async mergeDays(date: Date) {
    if (!this.year[this.pipe.transform(date)]) {
      await this.getData(date);
    }
    //build data into selection
    this.year[this.pipe.transform(date)].date = new Date(date).getTime();

    return this.year[this.pipe.transform(date)];
  }

  async buildDays(start: Date, end: Date) {
    //make sure all data is there
    var date = new Date(start);
    date.setHours(12);
    var now = new Date();
    now.setHours(23);
    var promises = [];

    end.setHours(22);

    while (date <= end && date <= now) {
      promises.push(this.mergeDays(new Date(date)));
      date.setDate(date.getDate() + 1);
    }

    const results = await Promise.all(promises)
      .then((a) => {
        return a;
      })
      .catch((e) => {
        console.error("promises error", e);
        return [];
      });

    results.forEach((data) => {
      var date2 = new Date(data.date);
      this.selection.inout = concat(this.selection.inout, data.inout);
      this.selection.worked = Object.values(
        mergeWith(
          keyBy(this.selection.worked, "key"),
          keyBy(data.worked, "key"),
          (obj, src, key, obj2) => {
            if (key === "total") {
              return src + (Number(obj) || 0);
            }
          }
        )
      );

      this.selection.workedRecords = concat(
        this.selection.workedRecords,
        data.workedRecords
      ).filter((a) => a);

      this.selection.multipleClockIns =
        this.selection.workedRecords.filter(
          (a) => Object.values(a.updates).length > 2
        ).length > 0;

      this.selection.workedTotal = this.selection.worked.reduce(
        (part, a) => part + (a.price || 0) * a.total,
        0
      );

      this.selection.recurring = Object.values(
        merge(
          keyBy(this.selection.recurring, "key"),
          keyBy(data.recurring, "key")
        )
      );

      this.selection.oneoff = concat(this.selection.oneoff, data.oneoff);
      this.selection.orders = concat(this.selection.orders, data.orders);
      this.selection.kosten = mergeWith(
        this.selection.kosten,
        data.kosten,
        (obj, src) => {
          if (isNumber(obj)) {
            return obj + src;
          }
          if (isBoolean(obj)) {
            return obj && src;
          }
        }
      );

      this.selection.verbruik.merge(this.selection.verbruik, data.verbruik);
      this.selection.omzet = mergeWith(
        this.selection.omzet,
        data.omzet,
        (obj, val) => {
          if (String(val) === "true" || String(val) === "false") {
            return Boolean(obj) && Boolean(val);
            //(String(obj) === 'false' ? false : true);
          }
          if (isNumber(val) || isNumber(obj)) {
            return (val || 0) + (obj || 0);
          }
        }
      );

      this.getBonus(data);
      this.charts.push({ date: date2.toISOString(), data: data.omzet });
    });

    /*
    var data = this.year[this.pipe.transform(date)]


     */
  }

  // ------------------ GETTERS --------------------------//

  async getWorked(date: Date) {
    this.year[this.pipe.transform(date)].worked = await this.db
      .getHoursForDate2(this.bar, date)
      .then((a) => a)
      .catch((e) => {
        console.error(e);
        return [];
      });

    this.year[this.pipe.transform(date)].workedRecords = await this.db
      .getWorkedAll(this.bar, date)
      .then((a) => {
        return a;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  async getCleaningHours(date: Date) {
    this.year[this.pipe.transform(date)].worked = this.year[
      this.pipe.transform(date)
    ].worked.concat(
      await this.db
        .getCleaningHoursForDate2(this.bar, date)
        .then((a) => a)
        .catch((e) => {
          console.error(e);
          return [];
        })
    );
  }

  async getVerbruik(date) {
    this.year[this.pipe.transform(date)].verbruik = new Verbruik();
    const data = await this.db
      .getDiscounts(this.bar, date)
      .then()
      .catch((e) => {
        console.error(e);
        return [];
      });
    console.log("verbruik data", data);
    this.year[this.pipe.transform(date)].verbruik.setValues(data);
    this.year[this.pipe.transform(date)].verbruik.calculateColors(
      this.year[this.pipe.transform(date)].worked,
      this.stock
    );
  }

  async testDiscounts() {
    this.loading.nextEmit("on");
    const end = new Date(this.start);
    end.setDate(end.getDate() + 1);
    end.setHours(0);
    end.setMinutes(0);
    end.setSeconds(0);
    end.setMilliseconds(0);

    const start = new Date(this.start);

    start.setHours(6);
    start.setMinutes(59);
    start.setSeconds(59);
    start.setMilliseconds(999);

    return await this.db
      .testDiscounts(this.bar, start.getTime(), end.getTime())
      .then((a) => {
        this.reset(false, false);
        this.getRange(this.bar);
        this.alert.nextEmit(AlertsService.good("Verbruik Got"));
        this.loading.nextEmit("");
      })
      .catch((e) => {
        this.loading.nextEmit("");
      });
  }
  async getStock() {
    const stock: any[] = await this.db
      .getAllStock(this.bar)
      .pipe(
        take(1),
        map((a: any) => a.map((a) => a.payload.val()))
      )
      .toPromise()
      .then((a: any) => {
        return a
          .map((b: any) => {
            return Object.values(b);
          })
          .flat();

        return [];
      })
      .catch((e) => {
        console.error(e);
        return [];
      });

    return stock;
  }

  async getRecurringCosts(date) {
    this.year[this.pipe.transform(date)].recurring = await this.db
      .getRecurringExpenses(this.bar)
      .then((a) => {
        const time = date.getTime();
        return a.filter((b: any) => {
          if (b.startDate < time && b.endDate > time) {
            return true;
          }
          return false;
        });
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  async getOneOffCosts(date) {
    this.year[this.pipe.transform(date)].oneoff = await this.db
      .getOneOffExpenses(this.bar, date)
      .then((a) => {
        return a.filter((b) =>
          this.sameDate(date, this.barDate(new Date(b.date)))
        );
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  async getOrders(date) {
    this.year[this.pipe.transform(date)].orders = await this.db
      .getOrderExpenses(this.bar, date)
      .then((a) => a)
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  async getInOut(date) {
    this.year[this.pipe.transform(date)].inout = await this.db
      .getInOut(this.bar, date)
      .then((a) => {
        return a.filter((b: any) => this.sameDate(date, new Date(b.dateTime)));
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  // ---------------------------- HELPERS / UTILS ----------------------

  getDaysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  sameDate(date1: Date, date2: Date) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  barDate(date: Date) {
    if (date.getHours() < 12) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1,
        18,
        0,
        0
      );
    } else {
      return date;
    }
  }

  setDateRange() {
    switch (this.viewType) {
      case 0:
        //its just today
        this.start = new Date(this.date);
        this.end = new Date(this.date);
        break;
      case 1:
        //everthing in this week
        this.start = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate() - ((this.date.getDay() + 6) % 7)
        );
        this.end = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate() + ((7 - this.date.getDay()) % 7)
        );
        break;
      case 2:
        //everything in month
        this.start = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
        this.end = new Date(
          this.date.getFullYear(),
          this.date.getMonth() + 1,
          0
        );
        break;
      case 3:
        //year
        this.start = new Date(this.date.getFullYear(), 0, 1);
        this.end = new Date(this.date.getFullYear(), 12, 0);
    }

    this.fromDate = new NgbDate(
      this.start.getFullYear(),
      this.start.getMonth() + 1,
      this.start.getDate()
    );
    this.toDate = new NgbDate(
      this.end.getFullYear(),
      this.end.getMonth() + 1,
      this.end.getDate()
    );
  }
  async setDate(date: Date) {
    this.date = new Date(date);
    this.setDateRange();
  }

  async addDate(num: number) {
    switch (this.viewType) {
      case 0:
        this.date.setDate(this.date.getDate() + num);
        break;
      case 1:
        this.date.setDate(this.date.getDate() + num * 7);
        break;
      case 2:
        this.date.setMonth(this.date.getMonth() + num);
        break;
      case 3:
        this.date.setFullYear(this.date.getFullYear() + num);
        break;
      case 4:
        return;
    }

    this.date = new Date(this.date);
    this.setDateRange();
    await this.buildSelection();
  }

  customRange(date) {
    console.error("FUnction not implemented - Custom Range: " + date);
  }

  onDateSelection(date: NgbDate, andGet = false) {
    this.selection = new Overview(true);
    if (this.viewType == 0) {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.viewType === 4) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        this.toDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
    }

    if (this.viewType === 1) {
      //week
      var sta = new Date(date.year, date.month - 1, date.day);

      this.fromDate = new NgbDate(
        sta.getFullYear(),
        sta.getMonth() + 1,
        sta.getDate() - ((sta.getDay() + 6) % 7)
      );
      this.toDate = new NgbDate(
        sta.getFullYear(),
        sta.getMonth() + 1,
        sta.getDate() + ((7 - sta.getDay()) % 7)
      );
    }

    if (this.viewType === 2) {
      var sta = new Date(date.year, date.month - 1, 1);
      var en = new Date(sta.getFullYear(), sta.getMonth() + 1, 0);
      this.fromDate = new NgbDate(sta.getFullYear(), sta.getMonth() + 1, 1);
      this.toDate = new NgbDate(
        en.getFullYear(),
        en.getMonth() + 1,
        en.getDate()
      );
    }

    if (this.viewType === 3) {
      var sta = new Date(date.year, 0, 1);
      var en = new Date(sta.getFullYear() + 1, 0, 0);
      this.fromDate = new NgbDate(sta.getFullYear(), sta.getMonth() + 1, 1);
      this.toDate = new NgbDate(
        en.getFullYear(),
        en.getMonth() + 1,
        en.getDate()
      );
    }

    if (this.viewType != 0 && this.toDate) {
      this.end = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day
      );
    }
    this.start = new Date(
      this.fromDate.year,
      this.fromDate.month - 1,
      this.fromDate.day
    );
    this.date = new Date(this.start);

    this.date.setHours(22);

    if (andGet) {
      this.buildSelection(this.start, this.end);
    }
  }

  //checks if hovering (only for 4)
  isHovered(date: NgbDate) {
    if (this.viewType != 4) {
      return false;
    }
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  //checks if date is inside
  isInside(date: NgbDate) {
    if (this.viewType != 0) {
      return (
        this.toDate && date.after(this.fromDate) && date.before(this.toDate)
      );
    } else {
      return false;
    }
  }

  //highlights a selected range
  isRange(date: NgbDate) {
    if (this.viewType == 0) {
      return date.equals(this.fromDate);
    }
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  getDaysInRange(start: Date, end: Date) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr.length;
  }

  async refreshTill() {
    return new Promise((resolve, reject) => {
      this.loading.nextEmit("on");

      this.db
        .getTillForDate(new Date(this.date).toLocaleString(), this.bar)
        .then((a) => {
          console.log("got till", a);
          this.alert.nextEmit(AlertsService.good("Getting Till", "Success"));
          this.loading.nextEmit(null);
          this.reset(false, false);
          this.getRange(this.bar);
          return resolve(true);
        })
        .catch((e) => {
          console.error(e);
          this.alert.nextEmit(
            AlertsService.error(
              "Error Getting Till",
              JSON.stringify(e, null, 2)
            )
          );
          this.loading.nextEmit();
          return reject(false);
        });
    });
  }

  async getMissingDates(year: number) {
    var startDate = new Date(year, 0, 1);
    var endDate = new Date(year, 11, 31);
    console.log("getting missing dates", startDate, endDate);
    this.missingDates = [];
    var promises = [];
    var totalDays = 365;
    this.loading.nextEmit("on", 0, "Getting Missing Dates");
    const now = new Date();
    const date = new Date(startDate);
    for (var i = 0; i < totalDays; i++) {
      if (date > now || date > endDate) {
        break;
      }

      const finalDate = new Date(date);
      console.log("final date", finalDate);
      promises.push(
        this.db
          .getFinalTellingChecks(this.bar, finalDate)
          .then((a) => a)
          .catch((e) => {
            this.alert.nextEmit(
              AlertsService.error("Error Getting Missing Dates", e)
            );
            return null;
          })
      );
      date.setDate(date.getDate() + 1);
    }

    console.log("promises", promises.length);
    await Promise.all(promises).then((data) => {
      for (var i = 0; i < data.length; i++) {
        console.log(data[i]);
        if (!data[i]) {
          //console.log('missing date', data[i]);
          continue;
        }

        if (!data[i].value) {
          this.missingDates.push({
            date: new Date(data[i].date),
            missing: true,
            count: 0,
          });
          continue;
        }
        var count = 10;
        for (var [string, boolean] of Object.entries(data[i].value)) {
          if (!boolean) {
            count--;
          }
        }
        if (count < 10) {
          this.missingDates.push({
            date: new Date(data[i].date),
            missing: true,
            count: count,
          });
        }
      }
      this.loading.nextEmit();
    });

    return this.missingDates;
  }

  removeMissing(date: Date) {
    this.missingDates = this.missingDates.filter(
      (a) => !this.sameDate(new Date(a.date), date)
    );
  }
}
