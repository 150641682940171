import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Tip } from "src/app/Classes/tip";
import { TipModalComponent } from "src/app/Controle/tip-modal/tip-modal.component";
import { CalculateTipComponent } from "src/app/Pacakages/calculate-tip/calculate-tip.component";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-tips",
  templateUrl: "./tips.component.html",
  styleUrls: ["./tips.component.css"],
})
export class TipsComponent implements OnInit {
  startDate: Date = new Date();
  endDate: Date = new Date();
  tips: Tip[] = [];
  hasTips: boolean = false;
  constructor(
    public db: StaffAppDatabase,
    public alert: AlertsService,
    public modal: NgbModal,
    public loading: LoadingService
  ) {
    //startDate go to previous 21st day
    if (this.startDate.getDate() < 21) {
      if (this.startDate.getMonth() == 0) {
        this.startDate.setMonth(11);
        this.startDate.setFullYear(this.startDate.getFullYear() - 1);
      } else {
        this.startDate.setMonth(this.startDate.getMonth() - 1);
      }
    }
    this.startDate.setDate(21);
    this.startDate.setHours(12, 0, 0, 0);

    this.endDate = new Date(this.startDate.getTime());
    this.endDate.setMonth(this.endDate.getMonth() + 1);
    this.endDate.setHours(11, 59, 59, 999);


    this.go();
  }

  async go() {
    this.loading.show();
    console.log(this.startDate.getTime(), this.endDate.getTime());
    this.tips = await this.db.getTips(
      this.startDate.getTime(),
      this.endDate.getTime() + 24 * 60 * 60 * 1000
    );
    this.tips.forEach((tip) => {
      var indexOfQrCode = tip.receiptId.indexOf("QR Code: ");
      //remove everything after
      if (indexOfQrCode != -1) {
        tip.receiptId = tip.receiptId.substring(0, indexOfQrCode);
      }
    });
    console.log(this.tips);
    this.hasTips = this.tips.length > 0;
    this.loading.hide();
  }

  dateChanged(type: string, event: any) {
    this.hasTips = false;
    console.log(type, event);
    if (type == "start") {
      this.startDate = new Date(event);
    } else {
      this.endDate = new Date(event);
    }
  }
  ngOnInit(): void {}

  openTip(tip: Tip) {
    var modal = this.modal.open(TipModalComponent, {
      size: "lg",
      centered: true,
    });
    modal.componentInstance.tip = tip;
  }
  calculatePayment() {
    //get staff hours in the date range
    var modal = this.modal.open(CalculateTipComponent, {
      size: "lg",
      centered: true,
      backdrop: "static",
    });
    modal.componentInstance.startDate = this.startDate;
    modal.componentInstance.endDate = this.endDate;
    modal.componentInstance.tips = this.tips;

    modal.result.then((result) => {
      this.go();
    });
  }
}
