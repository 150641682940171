<form class="row row-cols-sm-auto">
  <div class="col-12">
    <div class="input-group">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="date"
        ngbDatepicker
        #d="ngbDatepicker"
      />
      <button
        class="btn btn-outline-secondary bi bi-calendar3 fas fa-calendar"
        (click)="d.toggle()"
        type="button"
      ></button>
      <button
        class="btn btn-outline-secondary bi bi-calendar3"
        (click)="go()"
        type="button"
      >
        Get
      </button>
      <button
        class="btn btn-outline-secondary bi bi-calendar3"
        (click)="getTill()"
        type="button"
      >
        Refresh Till
      </button>
    </div>
  </div>
</form>

<div class="tables-table">
  <div *ngFor="let table of tables" class="table">
    <h3>{{ table.name }}</h3>
    <div>
      <label>First Used:</label>{{ table.opened | date : "yyyy-MM-dd - HH:mm" }}
    </div>
    <div>
      <label>Last Used:</label>{{ table.closed | date : "yyyy-MM-dd - HH:mm" }}
    </div>
    <div>Quantity of Items: {{ table.orders }}</div>
    <div>Total: {{ table.total | currency : "EUR" }}</div>
  </div>
</div>
