import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {
  
  constructor() { }
  timeout: any = null;
  interval: number = 2;

  ngOnInit(): void {
    const scroll = document.getElementsByClassName('text')[0];
    scroll.addEventListener('mouseover',() => {
      this.timeout = setInterval(() => {
        scroll.scrollLeft = this.interval;
        this.interval+=2;
      },40)
    })

    scroll.addEventListener('mouseout', () => {
      clearInterval(this.timeout);
      scroll.scrollLeft = 0;
      this.interval = 2;
    })
  }

}
