import { Component, OnInit, ViewChild } from "@angular/core";
import { Firebase } from "src/app/Services/firebase";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { LoadingService } from "src/app/Services/loading.service";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { WeekdayPipe } from "src/app/Pipes/weekday.pipe";
import { EquationModel } from "src/app/Classes/equation-model";
import { CurrentPrice } from "src/app/Classes/current-price";
import { ChartConfiguration, ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { PriceStatistics } from "src/app/Classes/price-statistics";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SongPricingModelComponent } from "src/app/Packages/song-pricing-model/song-pricing-model.component";

@Component({
  selector: "app-songpricing",
  templateUrl: "./songpricing.component.html",
  styleUrls: ["./songpricing.component.css"],
})
export class SongpricingComponent implements OnInit {
  subs = [];
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  showOld = false;
  weekdays = [
    { index: 1, euros: 1, credits: 1 },
    { index: 2, euros: 1, credits: 1 },
    { index: 3, euros: 1, credits: 1 },
    { index: 4, euros: 1, credits: 1 },
    { index: 5, euros: 1, credits: 1 },
    { index: 6, euros: 1, credits: 1 },
    { index: 0, euros: 1, credits: 1 },
  ];
  multi: any[] = [
    {
      name: "Credit Price",
      series: [],
    },
  ];
  view: any[] = [700, 300];
  bar: string;
  edited = false;

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "Euros";
  yAxisLabel: string = "Credits";
  timeline: boolean = false;

  model: CurrentPrice = new CurrentPrice();

  today: Date = new Date();


  date: number = 0;
  isSimulation = false;
  getLive = true;


  colorScheme = {
    domain: ["#5AA454", "#E44D25", "#CFC0BB", "#7aa3e5", "#a8385d", "#aae3f5"],
  };

   lables = [

  ];
  public lineChartData: ChartConfiguration<"line">["data"] = {
    labels: this.lables,

    datasets: [
      {
        data: [],
        label: "Simulation Price",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(250,250,250,0.8)",
      },
      {
        data: [],
        label: "euros Drinks Sold (last 15 minutes) ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(255,0,0,0.5)",

      },

      {
        data: [],
        label: "euros Drinks Sold (last hour)",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(0,255,0,0.5)",
      },
      {
        data: [],
        label: "euros Drinks Sold (last 5 minutes) ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(0,0,255,0.3)",
        hidden: true,
      },
      {
        data: [],
        label: "euros Drinks Sold (middle 5 minutes) ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(0,0,120,0.3)",
        hidden: true,
      },

      {
        data: [],
        label: "euros Drinks Sold (start 5 minutes) ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(0,120,0,0.3)",
        hidden: true,
      },
      {
        data: [],
        label: "Current Price ",
        fill: true,
        tension: 0.5,
        borderColor: "black",
        backgroundColor: "rgba(53,53,53,0.2)",
      },
    ],
  };

  public lineChartOptions: ChartOptions<"line"> = {
    responsive: false,
  };
  public lineChartLegend = true;
  statistics: PriceStatistics[];
  constructor(
    private db: Firebase,
    private bI: BarInfo,
    private load: LoadingService,
    private alert: AlertsService,
    private modalService: NgbModal
  ) {

    if(this.today.getHours() < 20) {
      this.today.setDate(this.today.getDate() - 1);
    }
    this.today.setMinutes(0);
    this.today.setHours(14);
    this.today.setSeconds(0);
    this.date = this.today.getTime();

    for (var i = 0; i < 80; i++) {
      this.multi[0].series.push({ name: i, value: this.creditF(i) });
    }
  }

  ngOnDestroy(){
    this.subs.forEach(element => {
      try {
        element.unsubscribe();
      } catch (e) {

      }
    });
  }

  changeDate($event) {
    var d = new Date($event);
    d.setHours(14);
    d.setMinutes(0);
    d.setSeconds(0);

    this.date = d.getTime();

    this.getStatistics();
  }

  creditF(bill) {
    try {
      if (bill >= 50) {
        return (
          Math.max(
            Number((1.4 * this.baselog(10, bill - 50 + 1) + 50).toFixed(1)),
            2
          ) * 10
        );
      } else {
        return Math.max(bill.toFixed(1), 2) * 10;
      }
    } catch (e) {
      return 0;
    }

  }

  baselog(x, y) {
    return Math.log(y) / Math.log(x);
  }
  ngOnInit(): void {
    this.subs.push(this.bI.barObserver.subscribe((val) => {
      this.bar = val;
      if (val) {
        this.getCost(val);
        console.log('following live statisitcs',val)
        this.getStatistics();
      }

    }));


  }

  statisticsSubscriber = null;
  async getStatistics(){
    try {
      this.statisticsSubscriber.unsubscribe();
    } catch (e) {
      //console.error(e)
    }

    if(this.getLive) {
      this.statisticsSubscriber = this.db.followLiveCreditStatistics(this.bar,this.date,this.date +  1000 * 60 * 60 * 15).subscribe(a => {
        console.log('got live statistics',a)
        this.followStatistics(a);
      })

      this.subs.push(this.statisticsSubscriber);
    } else {
      this.followStatistics(await this.db.liveCreditStatistics(this.bar,this.date,this.date +  1000 * 60 * 60 * 15));
    }



  }

  getLiveChanged($event){
    this.getLive = new Boolean($event).valueOf();
    this.getStatistics();
  }

  async getCost(bar: string) {
    this.load.nextEmit("on");
    for (var i = 0; i < this.weekdays.length; i++) {
      const priceVal = await this.db.getCreditCost(bar, this.weekdays[i].index);
      var price = 0;
      if (!isNaN(Number(priceVal))) {
        price = Number(priceVal);
      }
      this.weekdays[i].euros = price;


    }

    var livePricing:CurrentPrice = await this.db.liveCreditPrices(bar);


    console.log(livePricing)





    this.model = livePricing
    this.load.nextEmit("");
  }

  followStatistics(statistics: PriceStatistics[]){
    this.lineChartData.datasets.forEach((element) => {
      element.data = [];
    });
    this.lables = [];
    this.statistics = statistics;

    for(var i = 0; i < statistics.length; i++){
      var date = new Date(statistics[i].change.lastUpdate);
      var hour = date.getHours();
      var minute = date.getMinutes();

      var label = hour + ":" + minute;

      this.lables.push(label);

      var nearest = statistics[i];

      this.lineChartData.datasets[0].data.push(nearest.change.currentPrice);
      this.lineChartData.datasets[1].data.push(nearest.last15Minutes);
    this.lineChartData.datasets[2].data.push(nearest.lastHour);
    this.lineChartData.datasets[3].data.push(nearest.last5Minutes);
    this.lineChartData.datasets[4].data.push(nearest.middle5Minutes);
    this.lineChartData.datasets[5].data.push(nearest.first5Minutes);
    this.lineChartData.datasets[6].data.push(this.model.model.minimumPrice);

    }

    this.lineChartData.labels = this.lables;



    this.chart.update();
  }



  async saveCosts(bar: string) {
    this.load.nextEmit("on");
    var success = true;
    for (var i = 0; i < this.weekdays.length; i++) {
      try {
        const priceVal = await this.db.setCreditCost(
          bar,
          this.weekdays[i].index,
          this.weekdays[i].euros,
          this.creditF(this.weekdays[i].euros)
        ).then(a => null).catch(e => e);
        if (priceVal) {
          success = false;
          this.alert.nextEmit(AlertsService.warning('Couldnt set price of ' + new WeekdayPipe().transform(this.weekdays[i].index, 15), priceVal))
        }
      } catch (e) {
        success = false;
        this.alert.nextEmit(AlertsService.warning('Couldnt set price of ' + new WeekdayPipe().transform(this.weekdays[i].index, 15), e))
      }


    }

    if (success) {
      this.alert.nextEmit(AlertsService.good('All Saved', "All prices updated!"))
    }


    this.getCost(bar);
  }

  simulateModel: CurrentPrice;

  simulate(){
    if(this.getLive) {
      this.alert.nextEmit(AlertsService.warning('Cant simulate while live updates are on'))
      return;
    }
    var m = this.modalService.open(SongPricingModelComponent, { size: 'lg' });

    console.log(this.model)
    m.componentInstance.model = this.simulateModel || CurrentPrice.clone(this.model);
    m.componentInstance.canSave = false;

    m.result.then(a => {
      this.simulateModel = a;
      this.runSimulation();
    }).catch(e => {
      console.error(e)
    })
  }

  async runSimulation(){
    this.isSimulation = true;
    var statistics = this.statistics;
    console.log('running simulation')
    this.lineChartData.datasets.forEach((element) => {
      element.data = [];
    });
    this.lables = [];
    var oldPrice = this.model.model.minimumPrice;
    for(var i = 0; i < statistics.length; i++){
      var date = new Date(statistics[i].change.lastUpdate);
      var hour = date.getHours();
      var minute = date.getMinutes();

      var label = hour + ":" + minute;

      this.lables.push(label);

      var nearest = statistics[i];

      var nextPrice = this.calculateNextPrice(nearest, this.simulateModel, oldPrice);
      oldPrice = nextPrice;
      this.lineChartData.datasets[0].data.push(nextPrice);
      this.lineChartData.datasets[1].data.push(nearest.last15Minutes);
    this.lineChartData.datasets[2].data.push(nearest.lastHour);
    this.lineChartData.datasets[3].data.push(nearest.last5Minutes);
    this.lineChartData.datasets[4].data.push(nearest.middle5Minutes);
    this.lineChartData.datasets[5].data.push(nearest.first5Minutes);
    this.lineChartData.datasets[6].data.push(this.model.model.minimumPrice);
      await this.timeout(250);
      this.lineChartData.labels = this.lables;



      this.chart.update();
    }


  }

  calculateNextPrice(stat: PriceStatistics, sett: CurrentPrice, previousPrice = 0) {
    console.log('stat',sett,stat)
    var last15 = stat.last15Minutes;
    var lastHour = stat.lastHour;
    var last5 = stat.last5Minutes;
    var middle5 = stat.middle5Minutes;
    var first5 = stat.first5Minutes;



    var model = sett.model;

    var last15Weight = model.customWeights.last15Minutes;
    var lastHourWeight = model.customWeights.lastHour;
    var last5Weight = model.customWeights.last5Minutes;
    var middle5Weight = model.customWeights.middle5Minutes;
    var first5Weight = model.customWeights.first5Minutes;

    var totalWeight =
      last15Weight +
      lastHourWeight +
      last5Weight +
      middle5Weight +
      first5Weight;

    var last15P = last15 * last15Weight;
    var lastHourP = lastHour * lastHourWeight;
    var last5P = last5 * last5Weight;
    var middle5P = middle5 * middle5Weight;
    var first5P = first5 * first5Weight;

    var totalP = last15P + lastHourP + last5P + middle5P + first5P;

    console.log('total price',totalP)
    console.log('total weight',totalWeight)

    var newPrice = totalP / totalWeight;

    console.log('new price before fixes',newPrice)

    if (newPrice > model.maxUp + previousPrice) {
      newPrice = previousPrice + model.maxUp;
    }

    if (newPrice < previousPrice - model.maxDown) {
      newPrice = previousPrice - model.maxDown;
    }

    newPrice = Math.max(newPrice, model.minimumPrice);
    console.log('new price',newPrice)
    return newPrice;
  }

  async timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  editModel(){
    var m = this.modalService.open(SongPricingModelComponent, { size: 'lg' });
    m.componentInstance.bar = this.bar;
    m.componentInstance.model = this.model;
    m.componentInstance.canSave = true;

    m.result.then(a => {
      this.getCost(this.bar);
    }).catch(e => {
      console.error(e)
    })
  }
}


