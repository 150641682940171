import { Router } from "@angular/router";

import { Inject, Injectable } from "@angular/core";

import { MyUser } from "../Classes/user";
import { MyLogger } from "../Helpers/functions/MyLogger";

import { BarInfo } from "../Helpers/functions/BarInfo";
import { AvatarServiceService } from "../Packages/Avatar/avatar-service.service";
import { StaffAppDatabase } from "../Services/staffappdatabase";

import { AlertsService } from "../Packages/alerts/alerts.service";
import { Subscription, firstValueFrom } from "rxjs";

import { AngularFireAuth } from "@angular/fire/compat/auth";
@Injectable({
  providedIn: "root",
})
export class Auth {
  user;
  accessUser: MyUser = { uid: "", access: 0 };
  sub: Subscription;
  previous = -1;
  firebaseUser: any;
  username = "";
  log = new MyLogger("AuthService");
  oldAccess = -1;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public firebase: StaffAppDatabase,
    private barInfo: BarInfo,
    private avatarService: AvatarServiceService,
    private alert: AlertsService
  ) {
    this.afAuth.onAuthStateChanged((user) => {
      if (user && user.uid) {
        this.avatarService.getConfig(user.uid);
        localStorage.setItem("auth", JSON.stringify(user));
        this.log.logFL("constructor", 4, "got user: " + user.uid);
        this.user = user;
        //console.log(this.router.url);
        this.accessUser.access = 0;

        if (this.router.url === "/login") {
          this.router.navigate([""]);
        }
        this.getAccess();
        this.getUsername();
      } else {
        this.avatarService.forget();
        this.log.logFL("constructor", 1, "no user");
        this.user = null;
        this.previous = -1;
        this.accessUser = {};
        this.firebaseUser = null;
        if (this.sub) {
          this.sub.unsubscribe();
        }
        if (!this.router.url.includes("walletpassthrough")) {
          this.router.navigate(["login"]);
        }
      }
    });
  }

  async login(email: string, password: string) {
    this.log.logF("login", "attempting: " + email);

    return this.afAuth.signInWithEmailAndPassword(
      email.trim(),
      password.trim()
    );
  }

  isLoggedIn() {
    return this.accessUser.access > 0;
  }

  logout() {
    this.alert.nextEmit({ title: "Logged Out", level: 0 });
    this.router.navigate(["login"]);
  }

  signOut() {
    //console.log('here');
    this.log.log("Signing Out");
    localStorage.removeItem("auth");
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
      this.previous = -1;
      this.firebaseUser = null;
    }
    this.barInfo.remove();
    this.avatarService.forget();
    this.afAuth.signOut();
  }

  async getAccess() {
    this.log.logF("getAccess", "starting");

    if (this.user) {
      this.accessUser.uid = this.user.uid;
      return new Promise((res, rej) => {
        const sub2 = this.firebase
          .getUser(this.accessUser.uid)
          .subscribe((val: any) => {
            if (!val) {
              this.router.navigate(["login"]);
            }

            if (val.bar && !this.barInfo.hasBar()) {
              this.barInfo.setBar(val.bar);
            }

            if (!val.access) {
              val.access = 1;
            }
            this.accessUser = val;
            return res(val.access);
          });
      });
    }
  }

  getUsername() {
    if (this.user) {
      this.firebase.getUsername(this.accessUser.uid).subscribe((value: any) => {
        this.username = value;
      });
    }
  }

  async getFullNameAsync() {
    if (this.user) {
      return await firstValueFrom(
        this.firebase.getUsername(this.accessUser.uid)
      );
    }
  }

  reset(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async getDbUser() {
    const uid = await this.afAuth.currentUser.then((value) => {
      return value.uid;
    });
    console.log("got uid: " + uid);
    return await firstValueFrom(this.firebase.getUser(uid));
  }
}
