<div style="height: 2rem; width: 100%" class="flex-even">
    <button (click)="overview.dateVisible = true">
    <i class="fas fa-calendar"></i>
  </button>
    <ngb-datepicker *ngIf="overview.dateVisible" style="z-index: 10; background-color: whitesmoke; color: black" #d="ngbDatepicker" name="dp" [footerTemplate]="footerTemplate" (dateSelect)="overview.onDateSelection($event); getData()" [displayMonths]="1"
        [dayTemplate]="t" [autoClose]="'outside'">
    </ngb-datepicker>

    <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="overview.focused" [class.range]="overview.isRange(date)" [class.faded]="overview.isHovered(date) || overview.isInside(date)" (mouseenter)="overview.hoveredDate = date" (mouseleave)="overview.hoveredDate = null">
      {{ date.day }}
    </span>
    </ng-template>

    <ng-template #footerTemplate>
        <hr class="my-0" />
        <button class="btn btn-secondary btn-sm m-2 float-end" (click)="overview.customRange(); overview.dateVisible = false; getData()">
      Close
    </button>
    </ng-template>

    {{ overview.start | date : "EE dd MMM `yy" }}
    <button (click)="missingDates()">Missing Dates</button>
    <button (click)="addDate(overview.missingDates[0].date)" *ngIf="overview.missingDates.length">First Missing Date {{overview.missingDates[0].date | date: 'dd/MM/yyyy'}}</button>
    <div style="flex-grow: 1"></div>
    <div style="font-size: xx-large; color: black">
        {{ data.till && data.till.final && data.till.final.checks && data.till.final.checks.total ? "Checked" : "Preview" }}
    </div>
</div>
<div style="width: 100%; margin-bottom: 5px" class="flex-even">
    <div style="flex-grow: 1"></div>
    <button (click)="refreshTill()" class="datchev" style="margin-right: 10px !important">
    Refresh Till
  </button>
    <button (click)="addDate(null, -1)" class="datchev" style="margin-right: 10px !important">
    <i class="fas fa-chevron-left"></i>
  </button>
    <button (click)="addDate(null, 1)" class="datchev">
    <i class="fas fa-chevron-right"></i>
  </button>
</div>

<div class="page-overview" style="flex-wrap: nowrap;">
    <div class="page-box-sub" style="height: 75%; width: calc(100% - 10px)">
        <div class="box-row box-header" *ngIf="data.till && data.till.final">
            <div class="normal-box">Omzet</div>
            <div class="normal-box">Till</div>
            <div class="normal-box">Final</div>

            <div class="normal-box">Staff</div>
            <div class="normal-box">+/-</div>

            <div class="small-box">✔</div>
            <div class="small-box circle"></div>
        </div>
        <div class="box-row" *ngIf="data.till && data.till.final">
            <div class="normal-box">Total</div>
            <div class="normal-box">
                {{ data.till.expectedCash + data.till.expectedPin + data.till.internet | currency : "EUR" }}
            </div>
            <div class="normal-box">
                {{ data.till.final.total | currency : "EUR" }}
            </div>

            <div class="normal-box">
                {{ data.till.end.realCash + data.till.end.realPin | currency : "EUR" }}
            </div>
            <div class="normal-box">
                {{ data.differences.total | currency : "EUR" }}
            </div>

            <div class="small-box circle orange">
                {{ data.till.final.checks.total ? "✔" : "" }}
            </div>

            <div class="small-box circle" [ngClass]="{
          green: data.differencesAbs.total <= 2,
          yellow:
            data.differencesAbs.total < 10 && data.differencesAbs.total > 2,
          red: data.differencesAbs.total >= 10
        }"></div>
        </div>

        <div class="box-row" *ngIf="data.till && data.till.final">
            <div class="normal-box">Pin</div>
            <div class="normal-box" (click)="
          data.till.final.pin = data.till.expectedPin;
          data.till.final.checks.pin = false;
          calculateDifferences()
        ">
                {{ data.till.expectedPin | currency : "EUR" }}
            </div>
            <div class="normal-box">
                <input class="input-box" type="number" step="0.01" [(ngModel)]="data.till.final.pin" (ngModelChange)="
            data.till.final.checks.pin = false; calculateDifferences()
          " step="1" />
            </div>

            <div class="normal-box" (click)="
          data.till.final.pin = data.till.end.realPin;
          data.till.final.checks.pin = false;
          calculateDifferences()
        ">
                {{ data.till.end.realPin | currency : "EUR" }}
            </div>
            <div class="normal-box">
                {{ data.differences.pin | currency : "EUR" }}
            </div>

            <div class="small-box circle orange" (click)="check('pin', !data.till.final.checks.pin)">
                {{ data.till.final.checks.pin ? "✔" : "" }}
            </div>

            <div class="small-box circle" [ngClass]="{
          green: data.differencesAbs.pin <= 2,
          yellow: data.differencesAbs.pin < 10 && data.differencesAbs.pin > 2,
          red: data.differencesAbs.pin >= 10
        }"></div>
        </div>

        <div class="box-row" *ngIf="data.till && data.till.final">
            <div class="normal-box">Cash</div>
            <div class="normal-box" (click)="
          data.till.final.cash = data.till.expectedCash;
          data.till.final.checks.cash = false;
          calculateDifferences()
        ">
                {{ data.till.expectedCash | currency : "EUR" }}
            </div>
            <div class="normal-box">
                <input class="input-box" type="number" step="0.01" [(ngModel)]="data.till.final.cash" (ngModelChange)="
            data.till.final.checks.cash = false; calculateDifferences()
          " step="1" />
            </div>

            <div class="normal-box" (click)="
          data.till.final.cash = data.till.end.realCash;
          data.till.final.checks.cash = false;
          calculateDifferences()
        ">
                {{ data.till.end.realCash | currency : "EUR" }}
            </div>
            <div class="normal-box">
                {{ data.differences.cash | currency : "EUR" }}
            </div>

            <div class="small-box circle orange" (click)="check('cash', !data.till.final.checks.cash)">
                {{ data.till.final.checks.cash ? "✔" : "" }}
            </div>

            <div class="small-box circle" [ngClass]="{
          green: data.differencesAbs.cash <= 2,
          yellow: data.differencesAbs.cash < 10 && data.differencesAbs.cash > 2,
          red: data.differencesAbs.cash >= 10
        }"></div>
        </div>

        <div class="box-row" *ngIf="data.till && data.till.final">
            <div class="normal-box">Internet</div>
            <div class="normal-box">
                <input class="input-box" type="number" step="0.01" [ngModel]="data.till.internet | number : '1.2-2'" (ngModelChange)="
            setInternet($event);
            data.till.final.checks.pin = false;
            calculateDifferences()
          " step="1" />
            </div>
            <div class="normal-box">
                <input class="input-box" type="number" step="0.01" [(ngModel)]="data.till.final.internet" (ngModelChange)="
            data.till.final.checks.internet = false; calculateDifferences()
          " step="1" />
            </div>

            <div class="normal-box"></div>
            <div class="normal-box">
                {{ data.differences.internet | currency : "EUR" }}
            </div>

            <div class="small-box circle orange" (click)="check('internet', !data.till.final.checks.internet)">
                {{ data.till.final.checks.internet ? "✔" : "" }}
            </div>

            <div class="small-box circle" [ngClass]="{
          green: data.differencesAbs.internet <= 2,
          yellow:
            data.differencesAbs.internet < 10 &&
            data.differencesAbs.internet > 2,
          red: data.differencesAbs.internet >= 10
        }"></div>
        </div>

        <div class="box-row" *ngIf="data.till && data.till.final">
            <div class="normal-box">Flesjes</div>
            <div class="normal-box"></div>
            <div class="normal-box">
                <input class="input-box" type="number" step="0.01" [(ngModel)]="data.till.final.flesjes" (ngModelChange)="
            data.till.final.checks.flesjes = false; calculateDifferences()
          " step="1" />
            </div>

            <div class="normal-box" (click)="
          data.till.final.flesjes = data.flesjes;
          data.till.final.checks.flesjes = false;
          calculateDifferences()
        ">
                {{ data.flesjes | currency : "EUR" }}
            </div>
            <div class="normal-box">
                {{ data.differences.flesjes | currency : "EUR" }}
            </div>

            <div class="small-box circle orange" (click)="check('flesjes', !data.till.final.checks.flesjes)">
                {{ data.till.final.checks.flesjes ? "✔" : "" }}
            </div>

            <div class="small-box circle" [ngClass]="{
          green: data.differencesAbs.flesjes <= 2,
          yellow:
            data.differencesAbs.flesjes < 10 && data.differencesAbs.flesjes > 2,
          red: data.differencesAbs.flesjes >= 10
        }"></div>
        </div>

        <div class="box-row" *ngIf="data.till && data.till.final">
            <div class="normal-box">Teller</div>
            <div class="normal-box"></div>
            <div class="normal-box">
                <input class="input-box" type="number" step="0.01" [(ngModel)]="data.till.final.teller" (ngModelChange)="
            data.till.final.checks.teller = false; calculateDifferences()
          " step="1" />
            </div>

            <div class="normal-box"></div>
            <div class="normal-box">
                {{ data.differences.teller | currency : "EUR" }}
            </div>

            <div class="small-box circle orange" (click)="check('teller', !data.till.final.checks.teller)">
                {{ data.till.final.checks.teller ? "✔" : "" }}
            </div>

            <div class="small-box circle" [ngClass]="{
          green: data.differencesAbs.teller <= 2,
          yellow:
            data.differencesAbs.teller < 10 && data.differencesAbs.teller > 2,
          red: data.differencesAbs.teller >= 10
        }"></div>
        </div>

        <div class="box-row" *ngIf="data.till && data.till.final" style="border: none">
            <div class="normal-box">Kosten</div>
            <div class="normal-box">Till / Staff</div>
            <div class="normal-box">Total (inc vat)</div>

            <div class="normal-box">VAT (total)</div>
            <div class="normal-box">+/-</div>

            <div class="small-box">✔</div>
            <div class="small-box circle"></div>
        </div>

        <div class="box-row" *ngIf="data.bonnen">
            <div class="normal-box">Bonnen</div>
            <div class="normal-box">
                {{ data.bonnen.till | currency : "EUR" }}
            </div>
            <div class="normal-box">
                <input class="input-box" type="number" step="0.01" [(ngModel)]="data.till.final.bonnen" (ngModelChange)="
            data.till.final.checks.bonnen = false; calculateDifferences()
          " step="1" />
            </div>

            <div class="normal-box">
                <input class="input-box" type="number" step="0.01" [(ngModel)]="data.till.final.bonnenVAT" (ngModelChange)="
            data.till.final.checks.bonnen = false; calculateDifferences()
          " step="1" />
            </div>
            <div class="normal-box">
                {{ data.differences.bonnen | currency : "EUR" }}
            </div>

            <div class="small-box circle orange" (click)="check('bonnen', !data.till.final.checks.bonnen)">
                {{ data.till.final.checks.bonnen ? "✔" : "" }}
            </div>

            <div class="small-box circle" [ngClass]="{
          green: data.differencesAbs.bonnen <= 2,
          yellow:
            data.differencesAbs.bonnen < 10 && data.differencesAbs.bonnen > 2,
          red: data.differencesAbs.bonnen >= 10
        }"></div>
        </div>
    </div>

    <div class="page-box-out" style="flex-direction: row; flex-grow: 1; width: 100%; flex-wrap: wrap">
        <div class="page-box-sub" style="
        height: calc(100% - 10px);
        width: auto !important;
        flex-grow: 1;
        min-width: 300px;
      ">
            <div class="box-row box-header">
                <div class="normal-box">Personeel</div>
                <button style="display: flex; align-items: center; justify-content: center; background-color: transparent; border-radius: 50%; height: 2rem; width: 2rem" (click)="openWorkedRecords()"><i [style.color]="overview.selection.multipleClockIns ? 'red' : 'black'"
             class="fas fa-stamp"></i></button>
                <div class="normal-box" style="height: 45px">
                    <div class="small-box circle" style="border-radius: 20px" [class]="0 | colorRange"></div>
                </div>

                <div style="flex-grow: 1"></div>
                <div class="small-box input-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.workedTotal | currency : "EUR" }}
                </div>
            </div>
            <app-who-is-working [worked]="overview.selection.worked" style="width: 100%; height: 100px"></app-who-is-working>
        </div>
        <div class="page-box-sub" style="
        height: calc(100% - 10px);
        width: auto !important;
        max-width: 50%;
        flex-grow: 1;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      ">
            <div class="button-row">
                <button (click)="sendMessage()"><i class="fas fa-plus"></i></button>
                <span>Messages</span>
                <button (click)="overview.getMessages()"><i class="fas fa-refresh"></i></button>
                <button (click)="overview.toggleMessageActive()" [disabled]="auth.user.access < 5"><i
             [style.color]="overview?.shiftMessages?.active == 'true' ? 'gold' :'black'"
             class="fas fa-star"></i></button>
            </div>





            <div class="message-list" style="max-height: 300px;">
                <app-generic-message *ngFor="let message of overview?.shiftMessages?.messages.reverse()" [message]="message"></app-generic-message>
            </div>
        </div>
    </div>
</div>