<div>
  <h1>Message for {{message.page}} on {{message.threadDate | date:'YYYY-MM-dd'}}</h1>
</div>

<div>
  <app-input [model]="message.message" (changed)="message.message = $event" name="Message"
             placeholder="Enter a message"></app-input>
</div>

<div class="button-row" style="margin: 20px 0px;">
  <button (click)="dismiss()">Cancel</button>
  <button (click)="close()">Send</button>
</div>


<div class="divider"></div>
<h3>Past Messages</h3>
<div class="list">
  <app-generic-message *ngFor="let past of messages?.messages" [message]="past"></app-generic-message>
</div>
