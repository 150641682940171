<div mat-dialog-content style="width: 250px; min-height: 150px;">
    <h1>
        Select Bar
    </h1>
    <select #sel (change)="data.selected = sel.value">
            <option *ngFor="let b of data.bars" [value]="b" [attr.selected]="data.selected == b ? true : null">{{b}}</option>
    </select>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [mat-dialog-close]="data.selected" cdkFocusInitial>Select</button>
    </mat-dialog-actions>

</div>