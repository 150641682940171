import { Pipe, PipeTransform } from '@angular/core';
import { isNumber, isString, reduce } from 'lodash';

@Pipe({
  name: 'colorRange'
})
export class ColorRangePipe implements PipeTransform {

  transform(value:any, args: any): unknown {
    var scale = 1;
    if(isNumber(args)) {
      scale = Number(args);
    }

    if(isString(args)) {
      switch(args) {
        case 'inverse':
          return this.inverse(Number(value), scale);
          break;
          case 'aboveZero':
          return this.aboveZero(Number(value), scale);
          break;
      }
    }

    if(isNaN(Number(value))) {
      return 'NaN';
    }

    const num = Number(value);

    if(Math.abs(num) < 2 * scale) {
      return 'green'
    }

    if(Math.abs(num) < 10 * scale) {
      return 'yellow'
    }

    return 'red';
  }

  inverse(numb: number, scale: number) {

  }

  aboveZero(numb: number, scale: number) {
    if(numb > 0) {
      return 'green';
    }

    if(numb > -100) {
      return 'yellow';
    }

    return 'red'
  }

}
