<div class="flex-columns">
    <h2>Payslips</h2>
    <div class="list-item" *ngFor="let payslip of payslips" (click)="open(payslip, 'payslip',payslip.date)">
        {{payslip.date | date:'MMMM yyyy'}}
    </div>
</div>

<div class="flex-columns">
    <h2>Contracts</h2>
    <div class="list-item" *ngFor="let payslip of contracts" (click)="open(payslip,'contract',payslip.date)">
        {{payslip.date | date:'MMMM yyyy'}}
    </div>
</div>