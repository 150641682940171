import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { LafmodalComponent } from './lafmodal/lafmodal.component';

@Component({
  selector: 'app-lostandfound',
  templateUrl: './lostandfound.component.html',
  styleUrls: ['./lostandfound.component.css']
})
export class LostandfoundComponent implements OnInit {
  bar;
  subs =[];
  data = [];

  constructor(private bI: BarInfo, public db: StaffAppDatabase, private loadingService: LoadingService, private modal: NgbModal) { }

  ngOnInit(): void {
    this.subs.push(this.bI.barObserver.subscribe(a => {
      this.bar = a;
      this.getData();
    }));

  }

  async getData(){
    this.loadingService.nextEmit('on');
    this.data = [];
    this.db.getLostAndFound(this.bar).then(a => {
      this.data = a.map((b:any) => {

        b.date = new Date(b.key.replace('_','T')).getTime()
        return b;
      }).sort((a,b) => b.date - a.date)
      this.loadingService.nextEmit();
    }).catch(e => {
      console.error(e)
      this.loadingService.nextEmit();
    });
  }



  async deleteItem(item) {
    if(!confirm('Are you sure this item should be deleted?')) return;
    await this.db.removeLostAndFound(this.bar,item);
    this.getData();
  }



  async openItem(item) {

    var modal = this.modal.open(LafmodalComponent, {size: 'lg'});
    modal.componentInstance.item = item;

    modal.result.then((result) => {
      if(result && result == 'delete') {
        this.deleteItem(item);
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  ngOnDestroy() {
    this.subs.forEach(a => {
      try {
        a.unsubscribe();
      } catch (e) {
       // i dont care
      }
    });
  }

}
