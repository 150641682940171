<h1 style="padding: 0px 10px 0px 10px">Review Invoices For Email</h1>
<div class="list-item">
    <label style="width: 50%;">File:</label>
    <label style="width: 20%;">Company</label>
    <label style="width: 20%;">Email</label>
    <label style="width: 10%;">Status</label></div>
<div *ngFor="let file of files" class="list-item">
    <p style="width: 50%;">{{file.pdfData.name}}</p>
    <p style="width: 20%;">{{file.assignedBar}}</p>
    <p style="width: 20%;">{{file.email}}</p>

    <div class="circle" [class]="{

      green: file.status == 'success',
      orange: file.status == 'sending',
      gray: !file.status && file.email && file.email != 'n/a',
      red: file.status == 'error' || !file.email || file.email == 'n/a'
    }"></div>


</div>



<div class="actions actions-header">
    <button class="reactive-button-title orange" (click)="close()">Cancel</button>
    <button class="reactive-button-title green" (click)="email()">Confirm and Email</button>

</div>