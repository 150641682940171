export class PageData {
  images: ImageData[] = [new ImageData()];


}

export class ImageData {
  dataUrl: string = '';
  serverPath: string = '';
  isImage: boolean = true;

}
