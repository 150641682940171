import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekday'
})
export class WeekdayPipe implements PipeTransform {
  days = ["Sunday","Monday", "Tuesday","Wednesday","Thursday","Friday","Saturday"]
  transform(value: number | string, characters = 2): string {

    try {
      return this.days[Number(value)].substring(0,characters);
    } catch(e) {
      console.log(e);
      return 'NaN'
    }


  }

}
