import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-up-down',
  templateUrl: './up-down.component.html',
  styleUrls: ['./up-down.component.css']
})
export class UpDownComponent implements OnInit {

  //give sup and down buttons
  @Output('moved') moved = new EventEmitter<number>();
  @Input('length') length: number = 0;
  @Input('index') index: number = 0;
  constructor() { }

  ngOnInit(): void {
  }

  onMoved(direction: number) {
    this.moved.emit(direction);
  }

}
