<div class="flex-columns">
  <div class="text-field">
    <label>
      Name:
    </label> {{auth.accessUser | name:'full'}}
  </div>
  <div class="text-field"> <label>
      Email:
    </label> {{auth.accessUser.email}}</div>
  <div class="text-field"> <label>
      UID:
    </label> {{auth.accessUser.uid}}</div>

  <button class="action-button selected" (click)="auth.signOut()">Log Out</button>
</div>

<div class="flex-columns">
  <button class="action-button selected" routerLink="/account/documents" routerLinkActive="true">
    Documents
  </button>
  <button class="action-button selected" routerLink="/account/hours" routerLinkActive="true">
    Hours
  </button>
  <button class="action-button selected" routerLink="/account/schedule" routerLinkActive="true">
    Schedule
  </button>

</div>
