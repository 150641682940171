<div class="generic-modal-header">
    <div class="generic-modal-title">Calculate Tip Payment</div>
    <button class="generic-modal-close-button" (click)="modal.dismiss()">
    <i class="fas fa-times"></i>
  </button>
</div>

<div class="generic-modal-content">
    <app-input name="Start Date" [model]="startDate | date : 'yyyy-MM-dd'" [editable]="false">
    </app-input>
    <app-input name="End Date (not inclusive)" [model]="endDate | date : 'yyyy-MM-dd'" [editable]="false">
    </app-input>

    <app-input name="Total Tips" [editable]="false" [model]="tipsTotal | currency : 'EUR'"></app-input>
    <app-input name="Total Hours" [editable]="false" [model]="hoursTotal"></app-input>

    <div class="generic-table-header">
        <div class="generic-table-header-item long">Name</div>

        <div class="generic-table-header-item short">Hours</div>
        <div class="generic-table-header-item short">Amount</div>
    </div>
    <div class="generic-table">
        <div class="generic-table-row" [ngbTooltip]="s.uid" *ngFor="let s of staff | keyvalue">
            <div class="generic-table-item long">
                {{ s.value.name }}
            </div>

            <div class="generic-table-item short">
                {{ s.value.total }}
            </div>
            <div class="generic-table-item short">
                {{ s.value.tips | currency : "EUR" }}
            </div>
        </div>
    </div>


</div>
<div class="generic-modal-footer">
    <button class="generic-modal-button" (click)="modal.dismiss()">Cancel</button>
    <button class="generic-modal-button" (click)="markAndSheet()">
    Mark as Paid and Get Spreadsheet
  </button>
</div>
