import { Component, OnInit } from '@angular/core';
import { Auth } from '../../../auth/auth';
import { AvatarServiceService } from '../../../Packages/Avatar/avatar-service.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  constructor(public auth: Auth) {

  }

  ngOnInit(): void {
  }



}
