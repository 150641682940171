<button
  class="action-button selected"
  routerLink="invoicemaker"
  routerLinkActive="true"
  *ngIf="auth.accessUser && auth.accessUser.access >= 4"
>
  Invoice Maker
</button>
<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 1"
  routerLink="/tools/tutorials"
  routerLinkActive="true"
>
  Tutorials
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 5"
  routerLink="/tools/ChangeRecords"
  routerLinkActive="true"
>
  Change Records
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 5"
  routerLink="/tools/createBar"
  routerLinkActive="true"
>
  Create Bar
</button>
