import { InvoiceCompany } from "./invoice-company";
import { InvoiceProduct } from "./invoice-product";

export class InvoiceSave {
    company: InvoiceCompany;
    client: InvoiceCompany;
    logo: string; //path to logo

    key: string;
    date: number;
    dueDate: number;

    products: InvoiceProduct[];

    total = 0;
    total9 = 0;
    total21 = 0;
    totalBefore = 0;

    constructor(){
        this.company = new InvoiceCompany();
        this.client = new InvoiceCompany();
        this.logo = "";
        this.key = "";
        this.date = Date.now();
        this.dueDate = Date.now() + 1000 * 60 * 60 * 24 * 30;
        this.products = [];
    }

    calculateTotal(){
        this.total = 0;
        this.total9 = 0;
        this.total21 = 0;
        this.totalBefore = 0;
        this.products.forEach(product => {
            this.total += product.total;
            this.totalBefore += product.amount;
            if(product.vatType == 9){
                this.total9 += product.total;
            } else if(product.vatType == 21){
                this.total21 += product.total;
            }
        });
    }
}
