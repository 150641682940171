import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expenses-pop-over',
  templateUrl: './expenses-pop-over.component.html',
  styleUrls: ['./expenses-pop-over.component.css']
})
export class ExpensesPopOverComponent implements OnInit {
  @Input('items') items: any[] = [];



  constructor() { }

  ngOnInit(): void {
  }



}
