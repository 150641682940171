<div class="flex-columns" style="flex-shrink: 0">
    <h2>Change Records</h2>

    <app-input type="checkbox" [model]="useDateRange" (changed)="useDateRange = $event" name="Use Date Range"></app-input>
    <app-input *ngIf="useDateRange" name="Start Date" type="date" [model]="dateStart | date : 'yyyy-MM-dd'" (changed)="setDate($event, 'start')"></app-input>
    <app-input *ngIf="useDateRange" name="End Date" type="date" [model]="dateEnd | date : 'yyyy-MM-dd'" (changed)="setDate($event, 'end')"></app-input>
    <app-input type="select" [list]="types" (changed)="type = $event" [listHideAll]="true" [model]="type" name="Which data type?"></app-input>

    <button (click)="selectType(type)">Get</button>
</div>

<div class="flex-columns" style="flex-grow: 1; width: 100%; max-height: 100%" *ngIf="records.length">
    <div class="row" style="
      width: 80%;
      margin-left: 15px;
      align-items: center;
      margin-bottom: 12px;
    ">
        <h2 style="margin: 0; padding-top: 2px">Records for {{ type }}</h2>
        <button style="margin-left: 25px; height: 1.8rem" *ngIf="hasChecked() == 2" (click)="compare()">
      Compare
    </button>
    </div>

    <ngb-accordion [closeOthers]="true">
        <ngb-panel *ngFor="let panel of records" [id]="panel.date">
            <ng-template ngbPanelHeader let-opened="opened">
                <button ngbPanelToggle class="accordion-button custom-header" style="
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          " [ngbTooltip]="'edits: '+ (panel.edits | json)" [class.collapsed]="!opened">
          <input
            style="margin: 0px 5px"
            type="checkbox"
            (click)="$event.stopPropagation(); toggle(panel.date)"
            [checked]="checked[panel.date]"
          />
          <p class="m-0">
            {{
              (panel.action || "Edited") +
                " on: " +
                panel.dateString +
                " by " +
                panel.userName +
                " from " +
                panel.bar + " at " + panel.location
            }}
          </p>
        </button>
            </ng-template>
            <ng-template ngbPanelContent>
                <p ngbTooltip="If it has one, most dont">Description: {{panel.description}}</p>
                <ngx-json-viewer [expanded]="false" style="width: 100%; height: 100%; display: grid" [json]="panel.record"></ngx-json-viewer>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
