import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AngularFireStorage } from "@angular/fire/compat/storage";

import { Auth } from "src/app/auth/auth";
import { isSameMonth } from "date-fns";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { PDFViewerComponent } from "duncans-pdfviewer";
import { StaffInfoPopupComponent } from "../staff-info-popup/staff-info-popup.component";
import { MyUser } from "src/app/Classes/user";
import { LoadingService } from "src/app/Services/loading.service";

@Component({
  selector: "app-staff-info",
  templateUrl: "./staff-info.component.html",
  styleUrls: ["./staff-info.component.css"],
})
export class StaffInfoComponent implements OnInit {
  constructor(
    public auth: Auth,
    private db: StaffAppDatabase,
    private storage: AngularFireStorage,

    private barInfo: BarInfo,
    private modal: NgbModal,
    private alert: AlertsService,
    private loading: LoadingService,
    public bis: BarInfo
  ) {}
  staff: MyUser[] = [];
  bar;
  // values
  sortDirection: number = 1;
  sortingOn: string = "name";
  dateTime = Date.now();
  ngOnInit(): void {
    this.bar = this.bis.bar;
    this.getData();
  }

  async getData() {
    this.loading.nextEmit("on");
    this.staff = await this.db
      .getStaffAtBarAsync(this.bis.bar)
      .then((a) => {
        return a.map((b: any) => {
          b.firstName =
            b.firstName.charAt(0).toUpperCase() + b.firstName.slice(1);
          b.lastName = b.lastName.charAt(0).toUpperCase() + b.lastName.slice(1);
          return b as MyUser;
        });
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
    this.sortBy("name");
    console.log(this.staff);
    this.loading.nextEmit(null);
  }

  sortBy(str) {
    if (this.sortingOn == str) {
      this.sortDirection = this.sortDirection * -1;
    }
    this.sortingOn = str;
    switch (str) {
      case "name":
        this.staff = this.staff.sort((a, b) => {
          return (a.firstName + " " + a.lastName).localeCompare(
            b.firstName + " " + b.lastName
          );
        });
        break;
      case "email":
        this.staff = this.staff.sort((a, b) => {
          return a.email.localeCompare(b.email);
        });
        break;

      case "telephone":
        this.staff = this.staff.sort((a, b) => {
          return a.telephone.localeCompare(b.telephone);
        });
        break;

      case "eoc":
        this.staff = this.staff.sort((a, b) => {
          return a.eoc - b.eoc;
        });
        break;
    }

    if (this.sortDirection == -1) {
      this.staff = this.staff.reverse();
    }
  }

  staffSelected(s: MyUser) {
    var modal = this.modal.open(StaffInfoPopupComponent, {
      size: "lg",
      backdrop: "static",
    });

    var person = s;
    modal.componentInstance.bar = this.bar;
    modal.componentInstance.person = Object.assign({}, person) as MyUser;

    modal.result.then(
      (closed) => {},
      (dismissed) => {}
    );
  }
}

@Component({
  selector: "delete-staff-dialog",
  templateUrl: "delete-dialog-staff.html",
})
export class DeleteStaffDialog {
  constructor(public activeModal: NgbActiveModal) {}
  close(val) {
    this.activeModal.close(val);
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
