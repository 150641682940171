<div style="
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  ">
    <button class="up-down-up" [disabled]="index <= 0" (click)="onMoved(0)">
    <i class="fas fa-chevron-up"></i>
  </button>
    <button class="up-down-down" [disabled]="index >= length - 1" (click)="onMoved(1)">
    <i class="fas fa-chevron-down"></i>
  </button>
</div>
