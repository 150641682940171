import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "src/app/auth/auth";
import { OutingBudget } from "src/app/Classes/outing-budget";
import { OutingRecord } from "src/app/Classes/outing-record";
import { StringEntryModalComponent } from "src/app/Modals/string-entry-modal/string-entry-modal.component";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-outing-budget-modal",
  templateUrl: "./outing-budget-modal.component.html",
  styleUrls: ["./outing-budget-modal.component.css"],
})
export class OutingBudgetModalComponent implements OnInit {
  @Input("budget") budget: OutingBudget;
  editing: boolean = false;
  editingRecord: OutingRecord;
  constructor(
    public loading: LoadingService,
    public activeModal: NgbActiveModal,
    public alert: AlertsService,
    private db: StaffAppDatabase,
    public auth: Auth,
    public modal: NgbModal
  ) {}

  ngOnInit(): void {}

  cancel() {
    console.log("cancel");
    this.editingRecord = null;
    this.editing = false;
  }

  save() {
    console.log("save");

    //check the budget
    //this is definitely an update not a new budget
    if (this.budget.bar == "") {
      this.alert.nextEmit(AlertsService.warning("Bar is required"));
      return;
    }

    //calculate the new balance
    this.budget.currentBalance = 0;
    this.budget.records.forEach((r) => {
      if (r.type == "credit") {
        this.budget.currentBalance += r.amount;
      } else {
        this.budget.currentBalance -= r.amount;
      }
    });

    this.loading.show();
    this.db.updateOutingBudget(this.budget).then(() => {
      this.loading.hide();
      this.cancel();
    });
  }
  addComment() {
    console.log("add comment");
    var modal = this.modal.open(StringEntryModalComponent, {
      centered: true,
      size: "sm",
      backdrop: "static",
    });
    modal.componentInstance.title = "Add Comment";
    modal.componentInstance.placeholder = "Enter Comment";
    modal.result.then((res) => {
      if (res) {
        this.budget.comments += res + "\n";
      }
    });
  }

  addRecord() {
    console.log("add record");
    this.editingRecord = new OutingRecord();
  }

  saveRecord() {
    //check the editingRecord
    console.log("save record", this.editingRecord);

    //it needs a location, an amount that is positive and a type
    if (!this.editingRecord.location) {
      this.alert.nextEmit(AlertsService.warning("Location is required"));
      return;
    }

    if (!this.editingRecord.amount || this.editingRecord.amount <= 0) {
      this.alert.nextEmit(AlertsService.warning("Amount must be positive"));
      return;
    }

    if (!this.editingRecord.type) {
      this.alert.nextEmit(AlertsService.warning("Type is required"));
      return;
    }

    if (
      this.editingRecord.type != "credit" &&
      this.editingRecord.type != "debit"
    ) {
      this.alert.nextEmit(
        AlertsService.warning("Type must be credit or debit")
      );
      return;
    }

    //if the record is new, add it to the records
    if (this.budget.records.findIndex((r) => r == this.editingRecord) == -1) {
      this.budget.records.push(this.editingRecord);
    } else {
      var index = this.budget.records.findIndex((r) => r == this.editingRecord);
      this.budget.records[index] = this.editingRecord;
    }

    //calculate the new balance
    this.budget.currentBalance = 0;
    this.budget.records.forEach((r) => {
      if (r.type == "credit") {
        this.budget.currentBalance += r.amount;
      } else {
        this.budget.currentBalance -= r.amount;
      }
    });

    this.editingRecord = null;
  }

  cancelRecord() {
    console.log("cancel record");
    this.editingRecord = null;
  }

  dateChanged(event) {
    console.log("date changed", event);
    this.editingRecord.date = new Date(event).getTime();
  }
}
