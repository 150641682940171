<link href="https://fonts.googleapis.com/icon?family=Material+Icons&display=block" rel="stylesheet" />

<app-alerts style="position: absolute; bottom: 0; z-index: 50"></app-alerts>
<div class="header" *ngIf="router.url != '/login' && router.url != '/dynamicvoucher'">
    <button class="chevron-button" (click)="hidden = !hidden">
    <i class="fas fa-bars"></i>
  </button>
    <div class="bar">{{ bar }}</div>
    <div style="text-transform: capitalize">
        {{ router.url.split("/").pop() }}
    </div>
    <div style="flex-grow: 1"></div>
    <div style="margin-right: 15px">
        <i [class]="
        'fas fa-' +
        (auth.accessUser.access >= accessRequired ? 'unlock' : 'lock')
      "></i> {{ auth.accessUser.access }}/{{ accessRequired }}
    </div>
    <div style="margin-right: 15px" (click)="makeBugReport()">
        <i class="fas fa-bug"></i>
    </div>
    <div routerLinkActive="true" routerLink="account">
        {{ auth.accessUser | name }}
    </div>
</div>

<div class="page">
    <div class="action-bar" style="flex-shrink: 0" *ngIf="
      !hidden && router.url != '/dynamicvoucher' && auth.accessUser.access > 0
    ">
        <button class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="" [class.selected]="router.url == '/'">
      Locatie
    </button>
        <button *ngIf="auth.accessUser.access >= 2" class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="controle" [class.selected]="router.url.includes('controle')">
      Controle
    </button>
        <button *ngIf="auth.accessUser.access >= 3" class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="inkomsten" [class.selected]="router.url.includes('inkomsten')">
      Inkomsten
    </button>
        <button *ngIf="auth.accessUser.access >= 2" class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="personeel" [class.selected]="router.url.includes('personeel')">
      Personeel
    </button>
        <button *ngIf="auth.accessUser.access >= 3" class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="bestellingen" [class.selected]="router.url.includes('bestellingen')">
      Bestellingen
    </button>

        <button *ngIf="auth.accessUser.access >= 2" class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="onderhoud" [class.selected]="router.url.includes('onderhoud')">
      Onderhoud
    </button>
        <button *ngIf="auth.accessUser.access >= 2" class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="instellingen" [class.selected]="router.url.includes('instellingen')">
      Instellingen
    </button>
        <button *ngIf="auth.accessUser.access >= 1" class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="tools" [class.selected]="router.url.includes('tools')">
      Tools
    </button>
        <button class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="customerapp" [class.selected]="router.url.includes('customerapp')">
      Customer App
    </button>
        <button class="action-button" (click)="hideView(true)" *ngIf="auth.accessUser.access >= 6" routerLinkActive="true" routerLink="testing" [class.selected]="router.url.includes('testing')">
      Testing
    </button>

        <button class="action-button" (click)="hideView(true)" *ngIf="auth.accessUser.access >= 3" routerLinkActive="true" routerLink="dynamicvoucher" [class.selected]="router.url.includes('dynamicvoucher')">
      Dynamic Voucher
    </button>
        <button class="action-button" (click)="hideView(true)" routerLinkActive="true" routerLink="account" [class.selected]="router.url.includes('account')">
      Account
    </button>
    </div>
    <div class="page-content">
        <router-outlet></router-outlet>
    </div>
</div>

<!-- class="sidenav-container" hasBackdrop="false">
      <div color="warn" #sidenav (mouseenter)="stationary = false || !canExpand" (mouseleave)="stationary = true" class="mat-elevation-z8 sidenav stationary" style="z-index: 5; position: absolute;">
        <mat-nav-list>
            <a mat-list-item class="menu-a" routerLink="" routerLinkActive="active">
                <mat-icon class="icon" aria-hidden="true">home</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Home</div>
                </div>
                <div class="menu-action"></div>
            </a>
            <a mat-list-item class="menu-a" routerLink="customerapp" routerLinkActive="active">
                <mat-icon class="icon" aria-hidden="true">mic</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Customer App</div>
                </div>
            </a>
            <a mat-list-item class="menu-a" routerLink="staffapp" routerLinkActive="active">
                <mat-icon class="icon" aria-hidden="true">local_bar</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Staff App</div>
                </div>
            </a>
            <a mat-list-item class="menu-a" routerLink="admin" routerLinkActive="active">
                <mat-icon class="icon" aria-hidden="true">admin_panel_settings</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Admin</div>
                </div>
            </a>
            <a mat-list-item class="menu-a" routerLink="account" routerLinkActive="active">
                <mat-icon class="icon" aria-hidden="true">account_circle</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Account</div>
                </div>
            </a>
            <a mat-list-item class="menu-a" routerLink="help" routerLinkActive="active">
                <mat-icon class="icon" aria-hidden="true">help_outline</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Help</div>
                </div>
            </a>
            <a mat-list-item class="menu-a" (click)="auth.logout()">
                <mat-icon aria-hidden="true">exit_to_app</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Logout</div>
                </div>
            </a>




        </mat-nav-list>

        <mat-nav-list style="position: absolute; bottom: 0; left: 0;">
            <a *ngIf="!dark" mat-list-item style="bottom: 0;" class="menu-a" (click)="toggleNightMode(true)">
                <mat-icon aria-hidden="true">brightness_low</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Light Mode</div>
                </div>
            </a>
            <a *ngIf="dark" mat-list-item style="bottom: 0" class="menu-a" (click)="toggleNightMode(false)">
                <mat-icon aria-hidden="true">brightness_high</mat-icon>
                <div class="list-text" *ngIf="!stationary">
                    <div class="child">Dark Mode</div>
                </div>
            </a>
        </mat-nav-list>
    </div>







    <div #content style="display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch; width: 100% !important;">
        <mat-toolbar *ngIf="opened" color="primary" class="header">
            <div style="width: 5%;"></div>
            <div class="account-background" style="width: 45%;">
                <h2 class="title-text"> The End Admin Portal</h2>
            </div>

            <div style=" width: 25%; border-left: 1px solid rgba(0,0,0,.12); padding-left: 5px;" class="bar-info">
                <mat-icon style="margin-right: 10px;" (click)="showBarSelector()">explore</mat-icon>
                <h2 class="title-text">{{bar}}</h2>

            </div>
            <div class="account-background" [routerLink]="['account']" style=" width: 25%; border-left: 1px solid rgba(0,0,0,.12);">
                <app-avatar-viewer style="min-width: 40px !important;width: 40px !important; margin-left: 20px"></app-avatar-viewer>
                <h2 *ngIf="auth.username" class="title-text">{{auth.username}}</h2>
                <div *ngIf="!auth.user" class="child"></div>

        </mat-toolbar>

        <div *ngIf="profileExpanded" class="profile">
            <div class="profile-content">
                <mat-nav-list>
                    <a mat-list-item style="color: black;">Account</a>
                    <a mat-list-item style="color: black;">Logout</a>
                </mat-nav-list>
            </div>

        </div>
        <div class="page-content">

            <div style="position: absolute; width: 100%; height: 100%;display: flex; pointer-events: none; z-index: -1;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: center;
align-content: center;">
                <img style="width: 50vw; height: auto; opacity: 0.3; " src="../../assets//logo.png"></div>
            <ngb-alert style="margin-top: 2px;" *ngIf="error" type="success" (closed)="error = null" (click)="error = null">{{error}}</ngb-alert>
            <app-loading style="position: absolute; width: 100%; height: 100%; z-index: -50;"></app-loading>
            <router-outlet></router-outlet>
        </div>


        <div *ngIf="opened && isChat" class="chat">
            <div *ngIf="isChat" class="chat-main">
                <div class="chat-bar" style="bottom: 0 !important;">
                    <mat-icon>insert_photo</mat-icon>
                    <mat-icon>gif</mat-icon>
                    <mat-icon>article</mat-icon>
                </div>
                <app-chat class="conversation">

                </app-chat>




            </div>
            <div [ngClass]="{'openChat' : isChat,
        'closedChat': !isChat}" class="chat-bar">
                <mat-icon>quickreply</mat-icon>
                <div style=" text-align: center;">Support</div>
                <mat-icon *ngIf="isChat" (click)="isChat = !isChat">close_fullscreen</mat-icon>
                <mat-icon *ngIf="!isChat" (click)="isChat = !isChat">open_in_full</mat-icon>
            </div>
        </div>

    </div>
</div>
-->