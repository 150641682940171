import { Component, Input, OnInit } from "@angular/core";
import { CCVPaymentStatus } from "../payments/payments.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReceiptModalComponent } from "../receipt-modal/receipt-modal.component";
import { EJournalModalComponent } from "../ejournal-modal/ejournal-modal.component";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";

@Component({
  selector: "app-payment-modal",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.css"],
})
export class PaymentModalComponent implements OnInit {
  @Input("payment") payment: CCVPaymentStatus;
  paymentInfo: {
    cardLabelName: string;
    applicationIdentifier: string;
    issuerLabelName: string;
    cardNumber: string;
    cardSequenceNumber: string;
    expirationDate: string;
    serviceLabelName: string;
  } = {
    cardLabelName: "",
    applicationIdentifier: "",
    issuerLabelName: "",
    cardNumber: "",
    cardSequenceNumber: "",
    expirationDate: "",
    serviceLabelName: "",
  };
  constructor(
    public modal: NgbModal,
    public activeModal: NgbActiveModal,
    public alert: AlertsService
  ) {}

  ngOnInit(): void {
    console.log(this.payment);

    //parse this.payment.ejournal which is an xml string, any child nodes should be put into paymentInfo where applicable and capitalising the first letter for the xml
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(this.payment.ejournal, "text/xml");
    console.log(xmlDoc);
    console.log(xmlDoc.getElementsByTagName("CardLabelName"));
    this.paymentInfo.cardLabelName =
      xmlDoc.getElementsByTagName("CardLabelName")[0].textContent;
    this.paymentInfo.applicationIdentifier = xmlDoc.getElementsByTagName(
      "ApplicationIdentifier"
    )[0].textContent;
    this.paymentInfo.issuerLabelName =
      xmlDoc.getElementsByTagName("IssuerLabelName")[0].textContent;
    this.paymentInfo.cardNumber =
      xmlDoc.getElementsByTagName("CardNumber")[0].textContent;
    this.paymentInfo.cardSequenceNumber =
      xmlDoc.getElementsByTagName("CardSequenceNumber")[0].textContent;
    this.paymentInfo.expirationDate =
      xmlDoc.getElementsByTagName("ExpirationDate")[0].textContent;
    this.paymentInfo.serviceLabelName =
      xmlDoc.getElementsByTagName("ServiceLabelName")[0].textContent;

    console.log(this.paymentInfo);
  }

  openReceipt(isMerchant: boolean, receiptTitle: String) {
    var receiptModal = this.modal.open(ReceiptModalComponent, {
      size: "lg",
      centered: true,
    });
    receiptModal.componentInstance.status = this.payment;
    receiptModal.componentInstance.receiptTitle = receiptTitle;
    receiptModal.componentInstance.isMerchant = isMerchant;
  }

  openEJournal(receipt: String, receiptTitle: String) {
    var receiptModal = this.modal.open(EJournalModalComponent, {
      size: "lg",
      centered: true,
    });
    receiptModal.componentInstance.receipt = receipt;
    receiptModal.componentInstance.receiptTitle = receiptTitle;
  }

  close() {
    this.activeModal.close();
  }

  download() {
    // so downlaod the page (this modal) as pdf but also remove the buttons
    // html is #paymentModalContent
    var div = document.getElementById("paymentModalContent");

    if (!div) {
      this.alert.nextEmit(
        AlertsService.error("Error", "Could not find the content to download")
      );
      return;
    }

    //make a copy of div so dont modify the original
    var divCopy = div.cloneNode(true) as HTMLElement;
    //remove the buttons
    var buttons = divCopy.getElementsByTagName("button");
    while (buttons.length > 0) {
      buttons[0].remove();
    }

    //print the div
    var printWindow = window.open("", "", "height=400,width=800");

    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        // If the stylesheet is an external link
        if (styleSheet.href) {
          return `<link rel="stylesheet" type="text/css" href="${styleSheet.href}">`;
        } else if (styleSheet.cssRules) {
          // If the stylesheet is internal or inline, copy the styles
          let styleContent = "";
          for (let rule of Array.from(styleSheet.cssRules)) {
            styleContent += rule.cssText;
          }
          return `<style>${styleContent}</style>`;
        }
        return "";
      })
      .join("");

    // Add the styles to the print window
    printWindow.document.write(styles);
    printWindow.document.write("<html><head><title>Payment</title>");
    printWindow.document.write("</head><body >");
    printWindow.document.write(divCopy.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  }
}
