import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "src/app/auth/auth";
import { OutingBudget } from "src/app/Classes/outing-budget";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { OutingBudgetModalComponent } from "../outing-budget-modal/outing-budget-modal.component";

@Component({
  selector: "app-outing-budget",
  templateUrl: "./outing-budget.component.html",
  styleUrls: ["./outing-budget.component.css"],
})
export class OutingBudgetComponent implements OnInit {
  budgets: OutingBudget[] = [];
  date: Date = new Date();
  constructor(
    public db: StaffAppDatabase,
    public loading: LoadingService,
    public alert: AlertsService,
    public bi: BarInfo,
    public auth: Auth,
    public modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData(loadThisYear: boolean = false) {
    this.loading.show();
    console.log("getting budgets");
    this.budgets = await this.db.getAllOutingBudgets(this.bi.bar);
    if (loadThisYear) {
      var year = new Date().getFullYear();
      var budget = this.budgets.find((b) => b.year == year);
      if (budget) {
        this.selectBudget(budget);
      }
    }

    this.loading.hide();
  }

  createBudget(year: number | null = null) {
    //check if the budget for this year already exists
    if (!year) {
      year = new Date().getFullYear();
    }
    console.log(year);
    var budget = this.budgets.find((b) => b.year == year);
    if (budget) {
      this.alert.nextEmit(
        AlertsService.error("Error", "Budget for this year already exists")
      );
      return;
    }

    this.createOutingBudget(year);
  }
  async createOutingBudget(year: number) {
    this.loading.show();

    var budget = new OutingBudget();
    budget.year = year;
    budget.bar = this.bi.bar;
    budget.createdOn = new Date().getTime();
    budget.currentBalance = 0;

    try {
      await this.db.createOutingBudget(this.bi.bar, year, budget);
    } catch (e) {
      this.alert.nextEmit(AlertsService.error("Error", e));
    }

    this.loading.hide();
    this.getData(true);
  }

  selectBudget(budget: OutingBudget) {
    const modal = this.modal.open(OutingBudgetModalComponent, {
      centered: true,
      size: "lg",
      backdrop: "static",
    });

    modal.componentInstance.budget = budget;
  }
}
