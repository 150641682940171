<div class="staff-row-header">
    <div class="staff-name" (click)="sortBy('name') ">
        Name

        <i [style.display]="sortingOn == 'name' ? 'flex':'none'" [class]="'fas fa-chevron-' + (sortDirection == 1 ? 'up' : 'down')"></i>
    </div>

    <div class="staff-email" (click)="sortBy('email')">
        Email

        <i [style.display]="sortingOn == 'email' ? 'flex':'none'" [class]="'fas fa-chevron-' + (sortDirection == 1 ? 'up' : 'down')"></i>
    </div>

    <div class="staff-telephone" (click)="sortBy('telephone')">
        Telephone

        <i [style.display]="sortingOn == 'telephone' ? 'flex':'none'" [class]="'fas fa-chevron-' + (sortDirection == 1 ? 'up' : 'down')"></i>
    </div>

    <div class="staff-eoc" (click)="sortBy('eoc')">End of contract

        <i [style.display]="sortingOn == 'eoc' ? 'flex':'none'" [class]="'fas fa-chevron-' + (sortDirection == 1 ? 'up' : 'down')"></i></div>


</div>


<div class="staff-table">
    <div *ngFor="let s of staff" class="staff-row" (click)="staffSelected(s)" [ngClass]="{'red': (s.eoc  <= dateTime ),'orange': (s.eoc  > dateTime && s.eoc < dateTime +  2*2629800000)}">
        <div class="staff-name">
            {{ s.firstName ?? [0] + ". " }} {{ s.lastName }}
        </div>

        <div class="staff-email">
            {{ s.email }}
        </div>

        <div class="staff-telephone">
            {{ s.telephone }}
        </div>

        <div class="staff-eoc">{{s.eoc | date:'yyyy-MM-dd'}}</div>
    </div>
</div>
