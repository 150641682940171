<div class="flex-rows">
  <div class="flex-columns">
    <h1>
      Default Calculations
    </h1>

    <app-input class="edit-row" type="number" name="Rate per hour" (changed)="def.rate = $event"
               [model]="def.rate"></app-input>
    <app-input class="edit-row" type="number" name="Minimum Hours" min="5" (changed)="def.targetHours= $event"
               [model]="def.targetHours"></app-input>
    <app-input class="edit-row" type="number" name="Initial Gift Per Minimum Hours" (changed)="def.targetRate= $event"
               [model]="def.targetRate"></app-input>
    <app-input class="edit-row" type="number" name="Initial amount for new staff" (changed)="def.newStaff= $event"
               [model]="def.newStaff"></app-input>


    <br>
    <div class="button-row">
      <button (click)="clearDefault()">Cancel</button>
      <button (click)="saveDefault()">Save</button>
    </div>

    <div class="divider"></div>
    <h1>
      {{barname}} Rates
    </h1>

    <app-input class="edit-row" type="number" name="Rate per hour" (changed)="barDef.rate = $event"
               [model]="barDef.rate"></app-input>
    <app-input class="edit-row" type="number" name="Minimum Hours" min="5" (changed)="barDef.targetHours= $event"
               [model]="barDef.targetHours"></app-input>
    <app-input class="edit-row" type="number" name="Initial Gift Per Minimum Hours"
               (changed)="barDef.targetRate= $event" [model]="barDef.targetRate"></app-input>
    <app-input class="edit-row" type="number" name="Initial amount for new staff" (changed)="barDef.newStaff= $event"
               [model]="barDef.newStaff"></app-input>

    <br>
    <div class="button-row">
      <button (click)="clearBarDefault()">Cancel</button>
      <button (click)="saveBarDefault()">Save {{barname}}</button>
    </div>

  </div>


  <div class="flex-columns">
    <h1>
      Gifting Allowance
    </h1>
    <button (click)="clearManagers()">Reset</button>
    <div
         style="display: flex; flex-direction:column; align-items: center; justify-content: flex-start; max-height: 800px; overflow: auto">
      <div *ngFor="let man of managers; let i = index">
        {{!!man.firstName ? man.firstName + ' ' + man.lastName : man.displayName}} {{man.access}}
        <app-input class="edit-row" type="number" name="Allowance" (changed)="managers[i].allowance = $event"
                   [model]="managers[i].allowance"></app-input>
        <app-input class="edit-row" type="number" name="Current Allowance"
                   (changed)="managers[i].currentAllowance = $event" [model]="managers[i].currentAllowance"></app-input>
        <button (click)="saveManager(man)">Save</button>
      </div>
    </div>

  </div>




</div>
