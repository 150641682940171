import { Component, Input, OnInit } from '@angular/core';
import { SurveyQuestion } from 'src/app/Components/Instellingen/reviews/SurveyQuestion';

@Component({
  selector: 'app-review-answer',
  templateUrl: './review-answer.component.html',
  styleUrls: ['./review-answer.component.css']
})
export class ReviewAnswerComponent implements OnInit {
  @Input() question: SurveyQuestion;
  @Input() value: any = null;
  @Input() staffName: string = "";
  @Input() staffNames: any[] = [];
  levels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  agreements = [{ index: 0, title: 'Strongly Disagree' }, { index: 1, title: 'Disagree' }, { index: 2, title: 'Neutral' }, { index: 3, title: 'Agree' }, { index: 4, title: 'Strongly Agree' },]
  yesno = [{ index: 0, title: 'No' }, { index: 1, title: 'Unsure or n/a' }, { index: 2, title: 'Yes' }]
  timely = [{ index: 0, title: 'Daily or more' }, { index: 1, title: 'Once a week' }, { index: 2, title: 'Once a month' }, { index: 3, title: 'Every 2 months' }, { index: 4, title: '6 months or less' },]

  constructor() { }

  ngOnInit(): void {
    console.log(this.value, this.question)
  }

}
