<div class="flex-rows">
    <div class="flex-columns">
        <div *ngFor="let vip of vipRooms" (click)="this.selectedRoom = vip">
            {{vip.key}} {{vip.name}}
        </div>
    </div>

    <div class="flex-columns" *ngIf="selectedRoom">
        <button *ngIf="this.changed" (click)="saveChanges()">
            Save Changes
        </button>
        <table style="height: 80%;">
            <thead>
                <tr>
                    <th>Name and VIP Room Quantity</th>
                </tr>
            </thead>

            <tbody style="overflow: auto;;">
                <tr *ngFor="let drink of crates">

                    <th>
                        <app-input [name]="drink.name" type="number" (changed)="drink.vipRooms[selectedRoom.key].defaultQuantity = toNumber($event);this.changed = true" [model]="drink.vipRooms[selectedRoom.key].defaultQuantity"></app-input>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</div>