import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Book } from 'src/app/Classes/book';
import { Page } from 'src/app/Classes/page';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { BookService } from 'src/app/Services/book.service';
import { LoadingService } from 'src/app/Services/loading.service';

@Component({
  selector: 'app-the-end-tvpopup',
  templateUrl: './the-end-tvpopup.component.html',
  styleUrls: ['./the-end-tvpopup.component.css']
})
export class TheEndTVPopupComponent implements OnInit {
  @Input() book: Book;
  headerPage = 0;
  currentPage = 0;
  file: File = null;

  types = ['photo','calendar']
  constructor(public activeModal: NgbActiveModal, public alert: AlertsService, public loading: LoadingService, public bookService: BookService) { }

  ngOnInit(): void {
    console.log(this.book);

  }

  async loadImage(){
    for(var image of this.book.pages[this.currentPage].data.images){
      if(image.serverPath){
        var url = await this.bookService.downloadImage(image.serverPath);
        image.dataUrl = url;
      }
    }
  }
  createPage(){
    this.book.pages.push(new Page('photo'));
    console.log(this.book);
  }

  nextPage(){
    this.currentPage++;
    this.loadImage();
  }

  previousPage(){
    this.currentPage--;
    this.loadImage();
  }

  openPages(){
    this.headerPage = 1;
    this.currentPage = 0;
    if(this.book.pages.length == 0){
      this.createPage();
    }

    this.loadImage();

  }

  async save(){
    var res = await this.bookService.saveBook(this.book);
    if(res) {
      this.alert.nextEmit(AlertsService.error('Error saving book',res));
      return;
    }

    this.activeModal.close(this.book);
    this.alert.nextEmit(AlertsService.good('Saved book successfully',res));
  }

  cancel(){
    this.activeModal.dismiss('cancel');
  }

  onFileSelected(page, event){
    //make sure image object is ready
    if(!this.book.pages[this.currentPage].data.images[page]){
      this.book.pages[this.currentPage].data.images[page] = {
        dataUrl: '',
        serverPath: '',
        isImage: true
      }
    }
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.file = input.files[0];
      const mimeType = this.file.type;
      if (mimeType.match(/image\/*/)) {
        this.book.pages[this.currentPage].data.images[page].isImage = true;

      } else if (mimeType.match(/video\/*/)) {
        this.book.pages[this.currentPage].data.images[page].isImage = false;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.book.pages[this.currentPage].data.images[page].dataUrl = reader.result as string;
      };
      reader.readAsDataURL(this.file);
    }
  }

  async uploadFile(page) {
    await this.bookService.uploadImage(this.book, this.currentPage, page, this.file);
    this.book.pages[this.currentPage].data.images[page].serverPath = `theendtv/${this.book.id}/page-${this.currentPage}/image-${page}`;
  }

  deleteFile(page) {

  }

}
