<div class="page-overview">
    <div class="page-box-out">
        <div class="page-box-sub">
            Recurring
            <button (click)="newRecurring()">Add</button>
            <div class="list">
                <div *ngFor="let exp of recurring" (click)="recurringClicked(exp)">
                    {{exp.name}} {{exp.price}}
                </div>

            </div>


        </div>

        <div class="page-box-sub" *ngIf="rExpense">
            New Recurring

            <div>
                <label for="rKey"> Key: </label> {{rExpense.key}} </div>

            <div>
                <label for="rName"> Name: </label>
                <input id="rName" type="text" [(ngModel)]="rExpense.name" />
            </div>

            <div>
                <label for="rPrice"> Price (per Month): </label>
                <input id="rPrice" type="number" [(ngModel)]="rExpense.price" />
            </div>

            <div>
                <label for="rName"> Start Date: </label>
                <input id="rName" type="date" [ngModel]="rExpense.startDate | date:'yyyy-MM-dd'" (ngModelChange)="setExpenseDate(true,true,$event)" />
            </div>

            <div>
                <label for="rName"> End Date: </label>
                <input id="rName" type="date" [ngModel]="rExpense.endDate | date:'yyyy-MM-dd'" (ngModelChange)="setExpenseDate(true,false,$event)" />
            </div>

            <div class="flex-even" style="width: 100%;">
                <button (click)="this.rExpense = null">Cancel</button>
                <button (click)="deleteRecurringExpense(rExpense)">Delete</button>
                <button (click)="setRecurringExpense(rExpense)">Save</button>
            </div>


        </div>
    </div>

    <div class="page-box-out">
        <div class="page-box-sub">
            One Off
            <button (click)="newOneOff()">Add</button>
            <div class="list">
                <div *ngFor="let exp of oneOff" (click)="oneOffClicked(exp)">
                    {{exp.name}} {{exp.price}}
                </div>

            </div>

        </div>

        <div class="page-box-sub" *ngIf="oExpense">New OneOff

            <div>
                <label for="rKey"> Key: </label> {{oExpense.key}} </div>

            <div>
                <label for="rName"> Name: </label>
                <input id="rName" type="text" [(ngModel)]="oExpense.name" />
            </div>

            <div>
                <label for="rPrice"> Price: </label>
                <input id="rPrice" type="number" [(ngModel)]="oExpense.price" />
            </div>

            <div>
                <label for="rName"> Date: </label>
                <input id="rName" type="datetime-local" [ngModel]="oExpense.date | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="setExpenseDate(false,false,$event)" />
            </div>



            <div class="flex-even" style="width: 100%;">
                <button (click)="this.oExpense = null">Cancel</button>
                <button (click)="deleteOneOffExpense(oExpense)">Delete</button>
                <button (click)="setOneOffExpense(oExpense)">Save</button>
            </div>

        </div>
    </div>
</div>
