<button (click)="dismiss('dismiss')">Cancel</button>
<div *ngIf="song">
    <h2>Moving Song: {{song.name}} </h2>
    <div>Search Artist Name</div>
    <input [(ngModel)]="artistName" (ngModelChange)="search()">



    <div class="list" *ngIf="!selectedArtist">
        <div *ngFor="let art of filt" class="list-item">
            {{art.name}} - {{art.realLength}}
            <button (click)="selectedArtist = art">Move Here</button>
        </div>
    </div>

    <div *ngIf="selectedArtist">
        <h2>Confirm!</h2>
        <button (click)="selectedArtist = null">Cancel</button>
        <button (click)="save()">Save And Move</button>


        <h3>Selected Artist</h3>
        <div>
            {{selectedArtist.name}}
        </div>
        <h3>Songs</h3>

        <div style="max-height: 400px; overflow: auto;">

            <div *ngFor="let so of selectedArtist.songs">
                <div *ngIf="so">
                    {{so.name}}
                </div>

            </div>
        </div>



    </div>

</div>