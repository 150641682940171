<div class="actions">
  <button
    class="reactive-button-title orange"
    [disabled]="auth.accessUser.access < 4"
    (click)="openTillStock()"
  >
    Till Stock
  </button>
  <button class="reactive-button-title green" (click)="reorderStock()">
    Reorder Stock
  </button>
  <button
    class="reactive-button-title red"
    [disabled]="auth.accessUser.access < 5"
    (click)="resetOffset()"
  >
    Reset Offsets
  </button>
</div>

<div class="stock-table-header">
  <div class="stock-item-large" (click)="setSortBy('name')">
    <div>Name</div>
    <i
      [style.display]="sortBy.key == 'name' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="stock-item-imp" (click)="setSortBy('category')">
    <div>Category</div>
    <i
      [style.display]="sortBy.key == 'category' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="stock-item-imp" (click)="setSortBy('serverId')">
    <div>Server ID</div>
    <i
      [style.display]="sortBy.key == 'serverId' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="stock-item-sml" (click)="setSortBy('price')">
    <div>Price</div>
    <i
      [style.display]="sortBy.key == 'price' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="stock-item-sml" (click)="setSortBy('offset')">
    <div>Offset</div>
    <i
      [style.display]="sortBy.key == 'offset' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div
    class="stock-item-sml"
    (click)="setSortBy('pricePerUnit')"
    *ngIf="auth.accessUser.access >= 5"
  >
    <div>PPU</div>
    <i
      [style.display]="sortBy.key == 'pricePerUnit' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>

  <div
    class="stock-item-sml"
    (click)="setSortBy('resalePerUnit')"
    *ngIf="auth.accessUser.access >= 5"
  >
    <div>PPU (R)</div>
    <i
      [style.display]="sortBy.key == 'resalePerUnit' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>

  <div
    class="stock-item-sml"
    (click)="setSortBy('sellingRatio')"
    *ngIf="auth.accessUser.access >= 5"
  >
    <div>Selling Ratio</div>
    <i
      [style.display]="sortBy.key == 'sellingRatio' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
</div>

<div class="stock-table">
  <div *ngFor="let s of allStock" class="stock-item" (click)="openItem(s)">
    <div class="stock-item-large">{{ s.name }}</div>
    <div class="stock-item-imp">{{ s.category }}</div>
    <div class="stock-item-imp">{{ s.serverId }}</div>
    <div class="stock-item-sml">{{ s.price | currency : "EUR" }}</div>
    <div class="stock-item-sml">{{ s.offset }}</div>
    <div class="stock-item-sml" *ngIf="auth.accessUser.access >= 5">
      {{ s.pricePerUnit | currency : "EUR" }}
    </div>
    <div class="stock-item-sml" *ngIf="auth.accessUser.access >= 5">
      {{ s.resalePerUnit | currency : "EUR" }}
    </div>
    <div class="stock-item-sml" *ngIf="auth.accessUser.access >= 5">
      {{ s.sellingRatio }}
    </div>
  </div>
</div>
