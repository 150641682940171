import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expandable-box',
  templateUrl: './expandable-box.component.html',
  styleUrls: ['./expandable-box.component.css']
})
export class ExpandableBoxComponent implements OnInit {

  @Input('title') title: string;
  @Input('subtitle') subtitle: string = '';
  @Input('isAbsolute') isAbsolute: boolean = false;
  expanded = false;
  constructor() { }

  ngOnInit(): void {
  }

}
