<app-input style="width: 100px;" type="checkbox" [model]="filterBars" (changed)="filterBars = $event; getData()" name="Filter Bars"></app-input>

<div class="list-item">

    <button class="list-item-child wider" (click)="sortBy = 'name';staff = sort(staff)">
        <label>Filter On:</label>
        <div>Name</div>
    </button>

    <button class="list-item-child" (click)="sortBy = 'bar';staff = sort(staff)">
        <label></label>
        <div>Bar</div>
    </button>

    <button class="list-item-child" (click)="sortBy = 'access';staff = sort(staff)">
        <label></label>
        <div>Access</div>
    </button>

    <button class="list-item-child" (click)="sortDirection = sortDirection * -1; staff = sort(staff)">
        <i [class]="'fas fa-chevron-' + (sortDirection > 0 ? 'up' : 'down')"></i>



    </button>
</div>

<div class="list">
    <div *ngFor="let user of staff" class="list-item">

        <div class="list-item-child wider">
            <label>Name</label>
            <div style="text-align: start;">
                {{user | name}}
            </div>
        </div>


        <div class="list-item-child">
            <label>Bar</label>
            <select [(ngModel)]="user.bar">
              <option *ngFor="let bar of bars" [value]="bar">
                {{bar}}
              </option>
          </select>
        </div>

        <div class="list-item-child">
            <label>Access</label>
            <select [(ngModel)]="user.access">
              <option *ngFor="let level of accessLevels" [value]="level.value">{{level.name}}</option>

          </select>
        </div>

        <div class="list-item-child">
            <label>Actions</label>
            <div style="display: flex;
            flex-direction: row; align-items: center; justify-content: flex-start; flex-wrap: nowrap;">

                <button (click)="getData()">Cancel</button>
                <button (click)="saveUser(user)">Save</button>
            </div>


        </div>


    </div>
</div>