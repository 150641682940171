import { Component, Input, OnInit, Output ,EventEmitter} from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.css']
})
export class SortByComponent implements OnInit {

  @Output() clicked = new EventEmitter<SortByDirection>();
  @Input()name: string = '';
  @Input()activated: number = 0; //0 neutral, 1 up, 2 down
  @Input('currentSort') currentSort: SortByDirection
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.currentSort.name === this.name){
      this.activated = this.currentSort.direction;
    } else {
      this.activated = 0;
    }

  }

  up(){
    if(this.activated === 1){
      this.activated = 0;
      this.clicked.emit(new SortByDirection(this.name, 0));
      return;
    }
    this.clicked.emit(new SortByDirection(this.name, 1));
  }

  down(){
    if(this.activated === 2){
      this.activated = 0;
      this.clicked.emit(new SortByDirection(this.name, 0));
      return;
    }
    this.clicked.emit(new SortByDirection(this.name, 2));
  }


}

export class SortByDirection {
  name: string = '';
  direction: number = 0;

  constructor(name: string, direction: number) {
    this.name = name;
    this.direction = direction;
  }

  toString() {
    return this.name + '__' + this.direction;
  }

}
