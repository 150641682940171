<div class="flex-rows">
    <div class="flex-columns">
        <h1>
            Contacts
        </h1>
        <div class="contact-container">
            <div class="contact" *ngFor="let con of contacts" (click)="contactSelected(con)">
                <div class="name">
                    {{con.name}}
                </div>
                <div class="level">
                    {{access.printLevel(con.access)}}
                </div>

                <div class="icon" style="width: 50px !important; height: 50px !important;">
                    <app-avatar-viewer [config]="{'theme': con.avatar  && con.avatar.theme ? con.avatar.theme : 'B' ,'part':con.avatar && con.avatar.part ? con.avatar.part : '01'}"></app-avatar-viewer>
                </div>
                <div *ngIf="con.notifications != 0">
                    {{con.notifications}}
                </div>


            </div>

        </div>
    </div>

    <div *ngIf="contact" class="flex-columns">
        <h1>
            {{contact.name}}
        </h1>

        <div class="edit-row">

            <input [(ngModel)]="message" (keyup.enter)="sendMessage()" placeholder="enter a message">
        </div>



        <br>

        <div style="width: 100%; max-height: 600px;display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: stretch;
        align-content: stretch; overflow: hidden;">
            <div style="overflow: auto;">
                <div *ngFor="let m of messages.reverse()" [ngClass]="{'sentMessage': m.messages.sender == uid , 'recievedMessage' : m.messages.sender != uid}">
                    <div class="message-content">
                        {{m.messages.message}}
                    </div>

                    <div class="message-sender" *ngIf="m.messages.sender != uid">
                        {{contact.name}}
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>