import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { StorageInstances } from '@angular/fire/storage';
import { BehaviorSubject } from 'rxjs';
import { Firebase } from 'src/app/Services/firebase';

@Injectable({
  providedIn: 'root'
})

export class CustomerAppStorageUploader {
  ref;
  uid;
  private imageSubject = new BehaviorSubject<any>(null);
  imageObserver = this.imageSubject.asObservable();
  storage;
  constructor(private storages: StorageInstances, private storage2: AngularFireStorage, private db: Firebase, private auth : AngularFireAuth){
    this.storage = this.storages[1];
    this.auth.authState.subscribe(values => {
      if(values && values.uid){
        this.uid = values.uid;
      }
      console.log("Checking auth state", values);
    })
  }
  upload(event,song){
    if(!song.key){
      console.log("no key")
      return;
    }
    console.log(event,song);
    const file = event.target.files[0];
    console.log(file);
    const filePath = "/songs/" + song.key;
    const ref = this.storage.ref(filePath);
    let task : AngularFireUploadTask = this.storage.upload(filePath,file);
    task.percentageChanges().subscribe(values => {
      console.log(values);
      if(values == 100){
       // this.db.updateSongPhoto(this.db.getArtistKey(song.key),song.key);
        this.getImg(song);
      }
    }, (error) => {
      console.log(error);
    })


  }

  //any should be a song object
  getImg(song: any){
    console.log("getting ref: " + song.key);
    let ref = this.storage.ref("/songs").child(song.key)
    ref.getDownloadURL().subscribe(values => {
      let song = {'song': values,'errors':''};
      this.imageSubject.next(song);
      }, (error) => {
        console.log(error);
        let song = {'song': '','errors':JSON.stringify(error)};
      this.imageSubject.next(song);

      });

  }

  uploading  = new BehaviorSubject<string>("");
  watchUploading = this.uploading.asObservable();
  getBugReportImage(bug: number) {

    let ref = this.storage2.ref("/bugReports").child(bug + "")
    return ref.getDownloadURL();
  }

  uploadBugReportImage(event,key){

    const file = event.target.files[0];
    console.log(file);
    const filePath = "/bugReports/" + key;
    const ref = this.storage.ref(filePath);
    let task : AngularFireUploadTask = this.storage2.upload(filePath,file);
    task.percentageChanges().subscribe(values => {
      console.log(values);
      if(values == 100){
        this.uploading.next("done");
      }
    }, (error) => {
      console.log(error);
    })
  }


}

