import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'firebase/auth';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { AlertsService } from '../alerts/alerts.service';
import { Bug } from '../bug';

@Component({
  selector: 'app-bug-report',
  templateUrl: './bug-report.component.html',
  styleUrls: ['./bug-report.component.css']
})
export class BugReportComponent implements OnInit {
  @Input('user') user: any;
  @Input('bar') bar: string;
  bug: Bug = new Bug();
  constructor(private router: Router, private modal: NgbActiveModal, private db: StaffAppDatabase, private loading: LoadingService, private alert: AlertsService) {

  }

  ngOnInit(): void {

    this.bug.location = 'theendadmin.nl' + this.router.url;
    this.bug.date = new Date().getTime();

  }

  async save() {
    this.bug.user.bar = this.bar;
    this.bug.user.key = this.user.uid;
    this.bug.user.name = this.user.firstName + " " + this.user.lastName;
    console.log(this.bug);
    this.loading.nextEmit('on');
    await this.db.addBugReport(this.bug).then(a => this.alert.nextEmit(AlertsService.good("Bug Added", "Thanks!"))).catch(e => {
      AlertsService.error("Couldn't Add Bug", e.toString());
    });
    this.loading.nextEmit('');
    this.modal.close();
  }

  cancel() {
    this.modal.dismiss();
  }

}
