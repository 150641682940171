import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceCapitals'
})
export class SpaceCapitalsPipe implements PipeTransform {

  transform(input: string, ...args: unknown[]): string {


    let output = "" // Initialize the output string with the first character

    // Iterate through the remaining characters of the input string
    input.split(" ").forEach(each => {
      if (input == input.toUpperCase()) {
        output += ' ' + input;
        return;
      }
      for (let i = 0; i < each.length; i++) {
        const currentChar = each[i];

        // Insert a space before each uppercase letter (except for the first letter)
        if (currentChar === currentChar.toUpperCase()) {
          output += ' ';
        }

        output += currentChar; // Add the current character to the output string
      }
    })


    return output;
  }

}
