export class TipType {
  type: string = "absolute"; // e.g. percentage, round, absolute
  amount: number = 1; // 5 for 5%, 1 for round to nearest $1, 5 for round to nearest $5, 0.5 for 50 cents
  id: string =
    Math.random().toString(32).substring(3, 8) +
    Date.now().toString().substring(8);
  position: number = Number.MAX_SAFE_INTEGER;
  constructor(type: string = "absolute", amount: number = 1) {
    this.type = type;
    this.amount = amount;
  }
}
