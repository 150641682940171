import { BehaviorSubject } from 'rxjs';

export class CalendarManager {
    constructor(){

    }

    daySubject = new BehaviorSubject<Date>(null);
    daysObserver = this.daySubject.asObservable();

    destroy(){
      this.daySubject = new BehaviorSubject<Date>(null);
      this.daysObserver = this.daySubject.asObservable();

      this.eventSubject = new BehaviorSubject<any>(null);
      this.eventObserver = this.eventSubject.asObservable();

      this.nameClickedSubject = new BehaviorSubject<string>(null);
    this.nameClickedObserver = this.nameClickedSubject.asObservable();

    this.deleteEventSubject = new BehaviorSubject<boolean>(false);
    this.deleteEventObserver = this.deleteEventSubject.asObservable();
    }

    nextDay(date){
        this.daySubject.next(date);
    }

    eventSubject = new BehaviorSubject<any>(null);
    eventObserver = this.eventSubject.asObservable();

    nextEvent(event){
        this.eventSubject.next(event);
    }

    deleteEventSubject = new BehaviorSubject<boolean>(false);
    deleteEventObserver = this.deleteEventSubject.asObservable();

    deleteEvent(should){
      this.deleteEventSubject.next(should);
    }

    clearEvents(){
      this.eventSubject.next('clear');
      this.nameClickedSubject.next(null);
    }


    private nameClickedSubject = new BehaviorSubject<string>(null);
    nameClickedObserver = this.nameClickedSubject.asObservable();

    nextNameClick(name){
      this.nameClickedSubject.next(name);
    }

    colors: any = {
        red: {
          primary: '#ad2121',
          secondary: '#FAE3E3',
        },
        blue: {
          primary: '#1e90ff',
          secondary: '#D1E8FF',
        },
        yellow: {
          primary: '#e3bc08',
          secondary: '#FDF1BA',
        },
      };

      hashCode(str) { // java String#hashCode
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
           hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
      }

      intToRGB(i){
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();

        return "#00000".substring(0, 7 - c.length) + c;
      }
}
