<div style="width: 20vw; min-width: 100px; min-height: 50px;">
  <h1 style="color: black;">Delete staff?</h1>
  <div mat-dialog-actions style="width: 100%; display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: stretch;
	align-content: stretch;">
    <button mat-raised-button (click)="dismiss()">No Thanks</button>
    <button mat-raised-button (click)="close(true)" cdkFocusInitial>Ok</button>
  </div>

</div>
