<div class="staff-row-header">
  <div class="staff-name" (click)="sortBy('name')">
    Name

    <i
      [style.display]="sortingOn == 'name' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortDirection == 1 ? 'up' : 'down')"
    ></i>
  </div>

  <div class="staff-email" (click)="sortBy('date')">
    Date

    <i
      [style.display]="sortingOn == 'date' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortDirection == 1 ? 'up' : 'down')"
    ></i>
  </div>

  <div class="staff-telephone" (click)="sortBy('reason')">
    Reason

    <i
      [style.display]="sortingOn == 'reason' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortDirection == 1 ? 'up' : 'down')"
    ></i>
  </div>

  <div class="staff-eoc">
    No: Flags

    <i
      [style.display]="sortingOn == 'eoc' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortDirection == 1 ? 'up' : 'down')"
    ></i>
  </div>
</div>

<div class="staff-table">
  <div
    class="staff-container"
    *ngFor="let s of userFlags"
    (click)="userSelected(s)"
  >
    <div class="staff-row">
      <div class="staff-name">
        {{ s.user.userName }}
      </div>

      <div class="staff-email">
        {{ s.lastFlagObj.date | date : "yyyy-MM-dd - HH:mm" }}
      </div>

      <div class="staff-telephone">
        {{ s.lastFlagObj.flagType }}
      </div>

      <div class="staff-eoc">{{ s.flagLength() }}</div>
    </div>

    <div *ngIf="selectedUser == s.key" class="staff-extra">
      <div class="staff-extra-row">
        <div class="staff-extra-desc">Flags:</div>
        <div class="staff-extra-value">
          <div *ngFor="let f of s.flags | keyvalue">
            {{ f.value.date | date : "yyyy-MM-dd - HH:mm" }} -
            {{ f.value.flagType }} {{ f.value.description }}
            {{ f.value.staffId }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
