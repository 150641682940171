import { Component, OnInit } from '@angular/core';
import { audit, Subscribable, Subscription } from 'rxjs';

import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { Firebase } from 'src/app/Services/firebase';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.css']
})
export class UsageComponent implements OnInit {
  bar: string;
  subs: Subscription[] = [];
  dates: any = {};
  day: [string,any];
  constructor(private db: Firebase, private bI: BarInfo) { }

  ngOnInit(): void {
    this.subs.push(this.bI.barObserver.subscribe(b => {
      this.bar = b;
      if(b) {
        this.getData();
      }
    }));
  }
  setDay(day) {
    this.day = day;
  }
  ngOnDestroy(){
    this.subs.forEach(a => a.unsubscribe());
  }

  async getData(){
    const scanned = await this.db.getQrScanned(this.bar)
    this.dates = {};
    const temp: any = {};
    scanned.forEach((scan: any) => {
      console.log(new Date(scan.date))
      const date = new Date(scan.date);
      const ref = this.getBarDate(date);
      if(!temp[ref]) {
        temp[ref] = {scans:[], newUsers: 0, android: 0,ios: 0, total:0};
      }
      temp[ref].scans.push(scan);
    })
    console.log(temp)

    Object.entries(temp).forEach(([key,val]:[string,any]) => {
      const tempUsers: any = {};
      for(const scan of val.scans) {
        if(scan.newUser) {
          val.newUsers++;
        }

        if(scan.isIos) {
          val.ios++;
        } else {
          val.android++;
        }

        val.total++;
        if(!val.users) {
          val.users = {};
        }
        if(!tempUsers[scan.uid]) {
          tempUsers[scan.uid] = {uid: scan.uid, newUser: scan.newUser, quantity: 1 };
        } else {
          tempUsers[scan.uid].newUser = tempUsers[scan.uid].newUser || scan.newUser;
          tempUsers[scan.uid].quantity++;
        }
      }
      val.users = Object.values(tempUsers).sort((a: any,b: any) => {
        if(a.newUser == b.newUser) {
            return -a.quantity + b.quantity;
        } else {
          if(a.newUser) {
            return 1;
          } else {
            return -1;
          }
        }
      })
    })

    this.dates = temp;




  }

  isSameDay(a: Date, b:Date) {
    return a.getDate() == b.getDate() && a.getMonth() == b.getMonth() && a.getFullYear() == b.getFullYear();
  }

  getBarDate(a:Date):string {
    if(a.getHours() < 10) {
      a.setDate(a.getDate()-1);
    }
    return a.getFullYear() + "-" + (a.getMonth()+1) + "-" + a.getDate();
  }

}
