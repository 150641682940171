export class WaitlistSong {
  public songUid: string;
  public isLocked?: string;
  public isDemand?: string;
  public song?: any;

  public timeRequested?: number;
  public songLoaded?: Promise<boolean>;
  public timeAdded?: number;

  private loadingSong = false;
}
