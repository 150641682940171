import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  HostListener,
  Inject,
} from "@angular/core";
import { Auth } from "./auth/auth";
import { AppRoutingModule } from "./Services/app-routing.module";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MatSidenav } from "@angular/material/sidenav";
import { BnNgIdleService } from "bn-ng-idle";

import { interval, Observable } from "rxjs";

import { MyLogger } from "./Helpers/functions/MyLogger";
import { MyUnorg } from "./Helpers/functions/MyUnorg";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColorSchemeService } from "./Helpers/functions/ColorSchemeService";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from "@angular/animations";
import { StaffAppDatabase } from "./Services/staffappdatabase";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BarInfo } from "./Helpers/functions/BarInfo";

import { AvatarServiceService } from "./Packages/Avatar/avatar-service.service";
import { Firebase } from "./Services/firebase";
import { LoadingService } from "./Services/loading.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BugReportComponent } from "./Packages/bug-report/bug-report.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  host: {
    "(window:resize)": "onResize($event)",
  },
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          overflow: "hidden",
          height: "*",
          width: "300px",
        })
      ),
      state(
        "out",
        style({
          opacity: "0",
          overflow: "hidden",
          height: "0px",
          width: "0px",
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out")),
    ]),
  ],
})
export class AppComponent {
  lastUpdate = "05/05/2020:1741h";

  bars = [];
  title = "admin-website";
  opened = true;
  @ViewChild("content") content: any;
  readonly TIME_SIZE = 6000;
  idle = false;
  myLog = new MyLogger("app.component");
  stationary = true;
  dark = false;
  canExpand = true;
  isChat = false;

  profileExpanded = false;
  profileSrc = "../assets/defaultprofile.svg";

  bar = "Groningen";

  error;
  accessRequired = 0;
  isMobile = false;
  hidden = false;

  constructor(
    public auth: Auth,
    public router: Router,
    private render: Renderer2,
    private bnIdle: BnNgIdleService,
    private firebase: Firebase,
    public functions: MyUnorg,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private staffDatabase: StaffAppDatabase,
    private barInfo: BarInfo,
    private colorSchemeService: ColorSchemeService,
    private loading: LoadingService,
    private modal: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    console.log(window.location.href);
    localStorage.setItem("fullurl", window.location.href);
    setTimeout(() => {
      localStorage.removeItem("fullurl");
    },10000)
    this.myLog.logFL("constructor", 3, "starting");
    if (window.visualViewport.width < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
      this.hidden = false;
    }
    window.visualViewport.addEventListener("resize", (a) => {
      if (window.visualViewport.width < 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.hidden = false;
      }
    });

    //Logs if session is idle or not
    this.bnIdle.startWatching(300).subscribe((idle) => {
      if (idle) {
        this.myLog.logFL("constructor-bnIdl", 1, "Session Idle");
        this.idle = idle;
      }
    });

    //Follows the url path
    this.router.events.subscribe((val) => {
      this.idle = false;
      this.opened = this.router.url !== "/login";

      if (val instanceof NavigationEnd) {
        var route = this.activatedRoute.root;
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.accessRequired = Number(route.snapshot.data.roleLevel) || -1;
      }
    });

    this.colorSchemeService.load();
    this.dark = this.colorSchemeService.currentActive() === "dark";

    this.barInfo.errorObserver.subscribe((values) => {
      if (values) {
        this.error = values;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    });

    this.barInfo.barObserver.subscribe((val) => {
      console.log(val);
      this.staffDatabase.bar = val;
    });
  }

  hideView(var2) {
    if (this.isMobile) {
      this.hidden = var2;
    } else {
      this.hidden = false;
    }
  }

  toggleNightMode(isDark: boolean) {
    this.colorSchemeService.update(isDark ? "dark" : "light");
    this.dark = isDark;
  }
  openSnackBar(message, result, time) {
    this._snackBar.open(message, result, {
      duration: time,
    });
  }

  ngOnInit() {
    //repeates and interval of this.TIME_SIZE (ms)
    interval(this.TIME_SIZE).subscribe(() => {
      this.stillActive();
    });

    this.barInfo.getBars();
    this.barInfo.barsObserver.subscribe((values) => {
      this.bars = values;
    });
    this.barInfo.barObserver.subscribe((value) => {
      this.bar = value;
    });
    // css changes
    if (window.innerWidth < 736) {
      this.canExpand = false;
    }

  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (window.innerWidth < 736) {
      this.canExpand = false;
    }
  }

  //updates active session time
  stillActive() {
    //if logged in and not idle
    if (!this.idle && this.auth && this.auth.user) {
      //this.firebase.addSession(this.auth.user.uid,this.TIME_SIZE);
    }
  }

  showBarSelector() {
    if (this.auth.accessUser.access > 3) {
      //console.log(('selecting bar'));
      //console.log(this.bars);
      const ref = this.dialog.open(SelectBarDialog, {
        data: {
          selected: this.bar,
          bars: this.bars,
        },
      });
      ref.afterClosed().subscribe((result) => {
        //console.log(result);
        if (result) {
          this.barInfo.setBar(result);
        }
      });
    }
  }

  makeBugReport() {
    const ref = this.modal.open(BugReportComponent, {
      size: "lg",
    });
    ref.componentInstance.user = this.auth.accessUser;
    ref.componentInstance.bar = this.bar;
  }
}

export interface DialogData {
  selected;
  bars;
}
@Component({
  selector: "dialog-data-example-dialog",
  templateUrl: "select-bar-dialog.html",
})
export class SelectBarDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log(data);
  }
}
