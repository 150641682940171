import { Component, OnInit } from '@angular/core';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { NdOrderItem } from './ndorder-item';


@Component({
  selector: 'app-ndorder-items',
  templateUrl: './ndorder-items.component.html',
  styleUrls: ['./ndorder-items.component.css']
})
export class NDOrderItemsComponent implements OnInit {
  selectedDrink: any;
  invoiceCategories = ["frisdrank", "bieren", "bieren alcoholvrij", "gedestilleerd", "wijnen", "schoonmaak", "restaurant artikelen", "glaswerk", "vel"];

  drinks: NdOrderItem[] = [];
  bar;
  isNewDrink = false;
  constructor(private db: StaffAppDatabase, private barInfo: BarInfo, private loading: LoadingService, private alert: AlertsService) { }

  ngOnInit(): void {
    this.loading.nextEmit('on')
    this.barInfo.barObserver.subscribe(value => {
      this.bar = value;
      this.db.getNDItems(value);
    })

    this.db.ndObserver.subscribe(async (values) => {
      console.log(values);
      this.loading.nextEmit('on')
      if (values) {

        var positionChanged = false;
        this.drinks = values.sort((a, b) => a.position - b.position).map((a, index) => {
          if (a.position != index) {
            positionChanged = true;
          }
          a.position = index;
          return a;
        })

        if(positionChanged) {
          this.alert.nextEmit(AlertsService.warning('Ordering Mismatch', 'Server and Local ordering mismatch, please wait.'));
          for(var drink of this.drinks) {
            await this.db.updateNDItem(this.bar, drink);
          }
        }

      }
      this.loading.nextEmit('')

    })
  }

  getDrinks() {
    this.loading.nextEmit('on')
    this.db.getNDItems(this.bar);
  }

  drinkSelected(i) {
    this.isNewDrink = false;
    this.selectedDrink = i;
  }

  saveDrink(drink) {
    if(!drink) {
      return this.alert.nextEmit(AlertsService.error('Failed to Save None Drink', 'Please make sure all variables are filled in (with  0 if unknown value) '));
    }
    drink.price = Number(drink.price);
    drink.vat = Number(drink.vat);
    try {
      if (this.isNewDrink) {
        drink.position = this.drinks.reduce((a, b) => a.position > b.position ? a : b).position + 1;
        this.db.addNDItem(this.bar, drink);
      } else {
        this.db.updateNDItem(this.bar, drink);
      }
      this.alert.nextEmit(AlertsService.good('Saved None Drink', 'Saved None Drink'));
      this.cancel();
    } catch (e) {
      console.error(e);
      this.alert.nextEmit(AlertsService.error('Failed to Save None Drink', 'Please make sure all variables are filled in (with  0 if unknown value) ' + e.message));
    }


  }



  newDrink() {
    this.isNewDrink = true;
    this.selectedDrink = {
      name: '',
      defaultQuantity: ''
    }
  }

  cancel() {
    this.selectedDrink = null;
    this.isNewDrink = false;
    this.db.getNDItems(this.bar);
  }

  deleteDrink() {
    this.isNewDrink = false;
    this.db.deleteNDOrderItem(this.bar, this.selectedDrink);
    this.cancel();
  }

  sortUp(item: NdOrderItem) {
    const swapItem = this.drinks[item.position - 1];
    const temp = item.position;
    item.position = swapItem.position;
    swapItem.position = temp;
    this.saveBoth(swapItem, item);
    console.log(swapItem, item);
  }

  sortDown(item: NdOrderItem) {
    const swapItem = this.drinks[item.position + 1];
    console.log(swapItem, item);
    const temp = item.position;
    item.position = swapItem.position;
    swapItem.position = temp;
    this.saveBoth(swapItem, item);
  }

  async saveBoth(itemA, itemB) {
    this.loading.nextEmit('on')
    this.db.updateNDItem(this.bar, itemA);
    this.db.updateNDItem(this.bar, itemB);
    this.getDrinks();
  }

}
