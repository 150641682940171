<h1 style="padding: 0px 10px 0px 10px">{{stock.name}}</h1>
<span>Id: {{editingStock.id}}</span>
<br>

<app-input name="Stock Item Name:" type="text" placeholder="Stock Name" [model]="editingStock.name" (changed)="editingStock.name = $event"></app-input>
<br>
<div *ngFor="let bar of bars">
    <div>{{bar}}</div>
    <app-input [name]="'Choose stock for: ' + bar" type="select" listHideAll="true" [model]="editingStock.bars[bar]?.name" (changed)="barChanged(bar,$event)" [list]="barStockNameStrings.get(bar)"></app-input>
</div>
<br>
<app-input name="Price Per Unit (PPU):" type="number" placeholder="Price for the bottle/crate/key" [model]="editingStock.ppu" (changed)="editingStock.ppu = $event"></app-input>
<app-input name="Resale Percentage (RP) (0-100):" max="100" min="0" step="1" type="number" placeholder="Percentage added to PPU to pake PPRU" [model]="editingStock.percentage" (changed)=" percentageChanged($event)"></app-input>
<app-input name="Price Per Resale Unit (PPRU):" type="number" placeholder="Price for the bottle/crate/key selling to bar!" [model]="editingStock.resalePerUnit" (changed)="editingStock.resalePerUnit = $event;  unitPriceChanged($event, editingStock.units)"></app-input>
<app-input name="Singles Per Unit (SPU)" type="number" placeholder="How many singles Per Unit" [model]="editingStock.units" (changed)="editingStock.units = $event; unitPriceChanged(editingStock.resalePerUnit,$event)"></app-input>
<app-input name="Price Per Single (PPS):" type="number" placeholder="Price for a single drink" [model]="editingStock.singlePrice" [editable]="false"></app-input>
<app-input name="Quantity:" type="number" placeholder="Quantity of drinks in the HO (individual bottles)" [model]="editingStock.quantity" (changed)="quantityChanged($event)"></app-input>

<div class="actions actions-header">
    <button class="reactive-button-title orange" (click)="cancel()">Cancel</button>
    <button class="reactive-button-title green" (click)="saveStock()">Save</button>
    <button class="reactive-button-title red" (click)="deleteStock()">Delete</button>
</div>
