import { Component, Inject, OnInit } from "@angular/core";
import { AngularFireDatabase, QueryFn } from "@angular/fire/compat/database";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { Firebase } from "src/app/Services/firebase";
import { WaitlistSong } from "./WaitlistSong";
import { take } from "rxjs";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  catalogue: any = {};
  songs: any[];
  users: any[];
  constructor(
    public db: Firebase,
    public bi: BarInfo,
    @Inject("firebaseProject1") private db2: AngularFireDatabase
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    var cat = await this.db.getArtistsSync();
    const query: QueryFn = (ref) =>
      ref.orderByChild("lastAcceptedTime").startAfter(1);
    this.users = await this.db
      .getUsersFiltered(query)
      .pipe(take(1))
      .toPromise();
    this.songs = Object.values(cat).reduce(
      (acc, val) => acc.concat(val.songs),
      []
    );
    console.log(this.users);
    console.log(this.songs);
  }

  async randomSongDemand() {
    //get random user and random song
    var user = this.users[Math.floor(Math.random() * this.users.length)];
    var song = this.songs[Math.floor(Math.random() * this.songs.length)];
    var waitlist = new WaitlistSong();
    waitlist.songUid = song.key;
    waitlist.timeAdded = new Date().getTime();
    waitlist.isDemand = "true";
    waitlist.isLocked = "false";
    console.log(user, waitlist);
    this.songDemand(user.key ? user.key : user.uid, waitlist);
  }

  songDemand(uid: string, waitlist: WaitlistSong, price: number = 30) {
    let songUid = waitlist.songUid;

    if (!this.bi.bar) {
      alert("We cannot determine your location please scan your receipt!");
      return;
    }

    waitlist.isDemand = "true";
    const d = new Date();
    var newDate = d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds();
    if (newDate < 12 * 3600) {
      newDate += 24 * 3600;
    }

    this.db2
      .list("bars/" + this.bi.bar + "/songDemands/" + uid + "/" + songUid)
      .set("timeRequested", newDate);
    let setObject = {
      isDemand: waitlist.isDemand,
      isLocked: waitlist.isLocked,
      price: 50,
      songUid: waitlist.songUid,
      timeAdded: waitlist.timeAdded,
    };
    this.db2.list("users/" + uid + "/waitingList/").set(songUid, setObject);
  }
}
