import { EventEmitter, Output } from "@angular/core";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.css"],
})
export class InputComponent implements OnInit {
  @Input("type") type: String;
  @Input("name") name: String;
  @Input("model") model: String;
  @Output("changed") change = new EventEmitter<any>();
  @Input("editable") editable: boolean = true;
  @Input("placeholder") placeholder: string = "";
  @Input("titleClass") titleClass: string = "edit-desc";
  @Input("list") list: any[] = [];
  @Input("hint") hint: string = "Select None For All to be Reviewed";
  @Input("listHideAll") listHideAll = false;
  constructor() {}

  ngOnInit(): void {}

  onChange($ev, useModel = true) {
    if(!useModel) return this.change.emit($ev);
    console.log($ev,this.model)
    this.change.emit(this.model);
  }

  onChangeList($ev) {
    this.change.emit($ev);
  }
}
