<!--

<div class="flex-rows">
  <div class="flex-columns">
    <div>
      <button *ngIf="resorted" (click)="saveOrder()">Save Order</button>
    </div>
    <app-expandable-box title="Crates">
      <button *ngIf="!drink" (click)="resetOffset()">Reset Offsets to 0</button>
      <button (click)="fixOrdering()">Fix Ordering</button>
      <div *ngFor="let d of crates" (click)="this.drink = d; this.category = 'crates'" class="crates">
        <div style="align-self: center; width: 150px">
          {{ d.name }}
        </div>
        <div class="arrows">
          <div *ngIf="d.id != 0" class="sort-arrow" (click)="sortUp(crates,d); $event.stopPropagation()">
            <i class="fas fa-sort-up" style="margin-top: 16px"></i>
          </div>

          <div *ngIf="d.id < crates.length - 1" class="sort-arrow"
               (click)="sortDown(crates,d); $event.stopPropagation()">
            <i class="fas fa-sort-down" style="margin-bottom: 16px"></i>
          </div>
        </div>
      </div>
    </app-expandable-box>

    <app-expandable-box title="Wine">
      <div *ngFor="let d of wine" (click)="this.drink = d; this.category = 'wine'">
        {{ d.name }}
      </div>
    </app-expandable-box>

    <app-expandable-box title="liqueur">
      <div *ngFor="let d of liqueur" (click)="this.drink = d; this.category = 'liqueur'" class="crates">
        <div style="align-self: center; width: 150px">
          {{ d.name }}
        </div>
        <div class="arrows">
          <div *ngIf="d.id != 0" class="sort-arrow" (click)="sortUp(liqueur, d); $event.stopPropagation()">
            <i class="fas fa-sort-up" style="margin-top: 16px"></i>
          </div>

          <div *ngIf="d.id < liqueur.length - 1" class="sort-arrow"
               (click)="sortDown(liqueur, d); $event.stopPropagation()">
            <i class="fas fa-sort-down" style="margin-bottom: 16px"></i>
          </div>
        </div>
      </div>
    </app-expandable-box>

    <app-expandable-box title="Glasses">
      <div *ngFor="let d of glasses" (click)="this.drink = d; this.category = 'glasses'" class="crates">
        <div style="align-self: center; width: 150px">
          {{ d.name }}
        </div>
        <div class="arrows">
          <div *ngIf="d.id != 0" class="sort-arrow" (click)="sortUp(glasses, d); $event.stopPropagation()">
            <i class="fas fa-sort-up" style="margin-top: 16px"></i>
          </div>

          <div *ngIf="d.id < glasses.length - 1" class="sort-arrow"
               (click)="sortDown(glasses, d); $event.stopPropagation()">
            <i class="fas fa-sort-down" style="margin-bottom: 16px"></i>
          </div>
        </div>
      </div>
    </app-expandable-box>
    <app-expandable-box title="Kegs">
      <div *ngFor="let d of kegs" (click)="this.drink = d; this.category = 'kegs'">
        {{ d.name }}
      </div>
    </app-expandable-box>
    <app-expandable-box title="Other">
      <div *ngFor="let d of other" (click)="this.drink = d; this.category = 'other'">
        {{ d.name }}
      </div>
    </app-expandable-box>
  </div>

  <div class="flex-columns">
    <button *ngIf="barName === 'Daga Beheer'" (click)="this.drink = {}">
      Add Drink
    </button>
    <button (click)="mergePrices()" *ngIf="tillDrinks.length" style="width: 300px; height: 50px">
      Update All Prices From Till
    </button>
    <button (click)="addDrinkByName(null)" style="width: 300px; height: 50px">
      Get Drinks
    </button>
    <h3>Till Drinks</h3>
    <div class="edit-row">
      <div class="edit-desc">Search:</div>
      <input class="edit" [(ngModel)]="search" (keyup)="filteredDrinks(search)" name="search" />
    </div>
    <div style="max-height: 700px; overflow: auto">
      <div *ngFor="let d of filteredTillDrinks" (click)="this.drink = d; this.category = ''">
        {{ d.name }}
      </div>
    </div>
  </div>


<div class="flex-columns" *ngIf="drink">
    <h2>General Information</h2>
    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'name',
            name: 'Drink Name',
            drink: drink
          })
        ">
            Drink Name:
        </div>
        <input class="edit" [(ngModel)]="drink.name" name="name" />
    </div>

    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'description',
            name: 'Description',
            drink: drink
          })
        ">
            Description:
        </div>
        <input class="edit" [(ngModel)]="drink.description" name="name" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">Category:</div>
        <select *ngIf="!category" [(ngModel)]="category">
        <option *ngFor="let c of categories" [ngValue]="c">{{ c }}</option>
      </select>
        <p>{{ category }}</p>
    </div>

    <div class="edit-row">
        <div class="edit-desc">Server Id:</div>
        <div class="edit" *ngIf="barName !== 'Daga Beheer'">
            {{ drink.serverId ? drink.serverId : drink.id }}
        </div>
        <input *ngIf="barName === 'Daga Beheer'" class="edit" [(ngModel)]="drink.serverId" />
    </div>

    <h2>Bar Information</h2>

    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'price',
            name: 'Price (€) - Customers',
            drink: drink,
            type: 'number'
          })
        ">
            Price (€)
        </div>

        <input *ngIf="drink.price" class="edit" [(ngModel)]="drink.price" name="price" />
        <input *ngIf="drink.prices" class="edit" type="number" [(ngModel)]="drink.prices[0].amount" name="price" />
        <input *ngIf="barName === 'Daga Beheer'" class="edit" type="number" [(ngModel)]="drink.price" name="price" />
    </div>

    <div class="edit-row" *ngIf="barName === 'Daga Beheer'">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'tillQuantity',
            name: 'Hoofdkantoor Quant',
            drink: drink,
            type: 'number'
          })
        ">
            Hoofdkantoor Quantity
        </div>

        <input class="edit" type="number" type="number" [(ngModel)]="drink.tillQuantity" name="price" />
    </div>

    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'crateSize',
            name: 'Crate Size',
            drink: drink,
            type: 'number'
          })
        ">
            Crate Size
        </div>
        <input class="edit" type="number" [(ngModel)]="drink.crateSize" name="defaultCrateSize" />
    </div>

    <div class="divider"></div>

    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'defaultFridgeStock',
            name: 'Default Fridge Stock',
            drink: drink,
            type: 'number'
          })
        ">
            Default Fridge Stock
        </div>
        <input class="edit" type="number" [(ngModel)]="drink.defaultFridgeStock" name="defaultFridgeStock" />
    </div>

    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'defaultQuantity',
            name: 'Default Quantity',
            drink: drink,
            type: 'number'
          })
        ">
            Default Quantity
        </div>
        <input class="edit" type="number" [(ngModel)]="drink.defaultQuantity" name="defaultQuantity" />
    </div>

    <div class="edit-row" *ngIf="category == 'crates'">
        <div class="edit-desc">Offset</div>
        <input class="edit" [disabled]="auth.accessUser.access < 5" type="number" [(ngModel)]="drink.offset" name="Offset" />
        <button *ngIf="auth.accessUser.access >= 5" (click)="saveOffset()">Save</button>
    </div>

    <div class="divider"></div>
    <h2>Invoice Information</h2>
    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'embellage',
            name: 'Embellage',
            drink: drink,
            type: 'number'
          })
        ">
            Emballage
        </div>
        <input class="edit" type="number" [(ngModel)]="drink.embellage" name="embellage" />
    </div>

    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'pricePerUnit',
            name: 'Delivery Price (per CrateSize) (€)',
            drink: drink,
            type: 'number'
          })
        ">
            Delivery Price (per CrateSize) (€)
        </div>

        <input class="edit" [(ngModel)]="drink.pricePerUnit" name="price2" />
    </div>

    <div class="edit-row">
        <div class="edit-desc" (click)="
          editVariable(category, {
            key: 'singlePrice',
            name: 'Inkoop per serving (0 for none) (€):',
            drink: drink,
            type: 'number'
          })
        ">
            Inkoop per unit (0 for none) (€):
        </div>

        <input class="edit" [(ngModel)]="drink.singlePrice" name="price2" />
    </div>

    <div class="edit-row">
        <div class="edit-desc" *ngIf="!drink.prices" (click)="
          editVariable(category, {
            key: 'vat',
            name: 'VAT',
            drink: drink,
            type: 'number'
          })
        ">
            VAT
        </div>
        <input class="edit" *ngIf="!drink.prices" [(ngModel)]="drink.vat" name="vat" />
        <input *ngIf="drink.prices" class="edit" type="number" [(ngModel)]="drink.prices[0].vat" name="vat" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">Emballage Type:</div>
        <select [(ngModel)]="drink.emballageType">
        <option *ngFor="let c of emballageTypes" [ngValue]="c">{{ c }}</option>
      </select>
    </div>

    <div class="edit-row">
        <div class="edit-desc">Invoice Category:</div>
        <select [(ngModel)]="drink.invoiceCategory">
        <option *ngFor="let c of invoiceCategories" [ngValue]="c">
          {{ c }}
        </option>
      </select>
    </div>

    <div class="edit-row">
        <div class="edit-desc">Till Category:</div>

        <ng-select [items]="tillCategories" bindLabel="tillCategory" ngDefaultControl placeholder="Select Category(ies)" [multiple]="true">
            <ngx-bootstrap-multiselect [options]="tillCategories" [(ngModel)]="drink.tillCategory" (ngModelChange)="tillCategoryChanged($event)"></ngx-bootstrap-multiselect>
        </ng-select>
    </div>

    <div class="divider"></div>

    <button (click)="delete(drink, category)">Delete</button>
    <button (click)="save(drink, category)">Save/Update</button>
    <button (click)="drink = null; category = null">Cancel</button>

    <p>
        Delete will only remove a drink from the Database not the till, i.e. if a drink is no longer available or has incorrect serverID you delete it, and can reimport it from the till drinks
    </p>
    <p>
        Save will update, change category, correctly modify the order, only saves to the Database not the till!
    </p>
</div>

<div class="flex-columns" *ngIf="variable">
    <button (click)="previous()">previous</button>
    <button (click)="next()">Next</button>

    <div class="edit-row">
        <div class="edit-desc">Drink</div>
        <div class="edit">{{ variable.drink.name }}</div>
    </div>

    <div class="edit-row">
        <div class="edit-desc">
            {{ variable.name }}
        </div>
        <input class="edit" [(ngModel)]="variable.drink[variable.key]" name="name" />
    </div>

    <button (click)="saveVariable(category, variable)">Save</button>
    <button (click)="drink = null; variable = null; category = null">
      Exit
    </button>
</div>
</div>


-->

<div class="actions">
    <button class="reactive-button-title orange" [disabled]="auth.accessUser.access < 4" (click)="openTillStock()">Till Stock</button>
    <button class="reactive-button-title green" (click)="reorderStock()">Reorder Stock</button>
    <button class="reactive-button-title red" [disabled]="auth.accessUser.access < 5" (click)="resetOffset()">Reset Offsets</button>
</div>

<div class="stock-table-header">
    <div class="stock-item-large" (click)="setSortBy('name')">
        <div>Name</div> <i [style.display]="sortBy.key == 'name' ? 'flex': 'none'" [class]="'fas fa-chevron-'+ (sortBy.reverse ? 'down' : 'up')"></i></div>
    <div class="stock-item-imp" (click)="setSortBy('category')">
        <div>Category</div> <i [style.display]="sortBy.key == 'category' ? 'flex': 'none'" [class]="'fas fa-chevron-'+ (sortBy.reverse ? 'down' : 'up')"></i></div>
    <div class="stock-item-imp" (click)="setSortBy('serverId')">
        <div>Server ID</div> <i [style.display]="sortBy.key == 'serverId' ? 'flex': 'none'" [class]="'fas fa-chevron-'+ (sortBy.reverse ? 'down' : 'up')"></i></div>
    <div class="stock-item-sml" (click)="setSortBy('price')">
        <div>Price</div> <i [style.display]="sortBy.key == 'price' ? 'flex': 'none'" [class]="'fas fa-chevron-'+ (sortBy.reverse ? 'down' : 'up')"></i></div>
    <div class="stock-item-sml" (click)="setSortBy('offset')">
        <div>Offset</div> <i [style.display]="sortBy.key == 'offset' ? 'flex': 'none'" [class]="'fas fa-chevron-'+ (sortBy.reverse ? 'down' : 'up')"></i></div>
    <div class="stock-item-sml" (click)="setSortBy('pricePerUnit')" *ngIf="auth.accessUser.access >= 5">
        <div>PPU</div> <i [style.display]="sortBy.key == 'pricePerUnit' ? 'flex': 'none'" [class]="'fas fa-chevron-'+ (sortBy.reverse ? 'down' : 'up')"></i></div>

    <div class="stock-item-sml" (click)="setSortBy('resalePerUnit')" *ngIf="auth.accessUser.access >= 5">
        <div>PPU (R)</div> <i [style.display]="sortBy.key == 'resalePerUnit' ? 'flex': 'none'" [class]="'fas fa-chevron-'+ (sortBy.reverse ? 'down' : 'up')"></i></div>

    <div class="stock-item-sml" (click)="setSortBy('sellingRatio')" *ngIf="auth.accessUser.access >= 5">
        <div>Selling Ratio</div> <i [style.display]="sortBy.key == 'sellingRatio' ? 'flex': 'none'" [class]="'fas fa-chevron-'+ (sortBy.reverse ? 'down' : 'up')"></i></div>
</div>

<div class="stock-table">
    <div *ngFor="let s of allStock" class="stock-item" (click)="openItem(s)">
        <div class="stock-item-large">{{s.name}}</div>
        <div class="stock-item-imp">{{s.category}}</div>
        <div class="stock-item-imp">{{s.serverId}}</div>
        <div class="stock-item-sml">{{s.price | currency:'EUR'}}</div>
        <div class="stock-item-sml">{{s.offset}}</div>
        <div class="stock-item-sml" *ngIf="auth.accessUser.access >= 5">{{s.pricePerUnit | currency:'EUR'}}</div>
        <div class="stock-item-sml" *ngIf="auth.accessUser.access >= 5">{{s.resalePerUnit | currency:'EUR'}}</div>
        <div class="stock-item-sml" *ngIf="auth.accessUser.access >= 5">{{s.sellingRatio}}</div>
    </div>
</div>