<div class="staff-info-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">Staff Info</h3>
  <button class="action-side close-button red" (click)="activeModal.dismiss()">
    <i class="fas fa-times"></i>
  </button>
</div>

<div class="actions staff-info-header">
  <button
    (click)="photos = -1"
    [class]="
      photos == -1 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    Info
  </button>
  <button
    *ngIf="this.auth.accessUser.access > 3"
    (click)="photos = 0"
    [class]="
      photos == 0 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    General
  </button>
  <button
    *ngIf="this.auth.accessUser.access > 3"
    (click)="photos = 1"
    [class]="
      photos == 1 ? 'green  reactive-button-title' : 'reactive-button-title'
    "
  >
    Documents
  </button>

  <button
    *ngIf="this.auth.accessUser.access >= 3"
    (click)="photos = 3"
    [class]="
      photos == 3 ? 'green  reactive-button-title' : 'reactive-button-title'
    "
  >
    Inventory
  </button>
</div>

<!--Editing-->
<div
  class="staff-info-content"
  *ngIf="person && auth.accessUser.access >= 4 && editing && photos == -1"
>
  <div class="edit-row">
    <div class="edit-desc">UID:</div>
    <input class="edit" [ngModel]="person.uid" disabled name="uid" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">First Name:</div>
    <input class="edit" [(ngModel)]="person.firstName" name="firstName" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Last Name:</div>
    <input class="edit" [(ngModel)]="person.lastName" name="lastName" />
  </div>

  <app-input
    type="date"
    [model]="person.birthdate | date : 'yyyy-MM-dd'"
    name="Birthdate"
    (changed)="saveBirthday($event)"
  ></app-input>

  <div class="edit-row">
    <div class="edit-desc">Price Per Hour:</div>
    <input class="edit" name="price" [(ngModel)]="person.price" type="number" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Email:</div>
    <input class="edit" [(ngModel)]="person.email" name="email" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Telephone:</div>
    <input class="edit" [(ngModel)]="person.telephone" name="telephone" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Street:</div>
    <input class="edit" [(ngModel)]="person.street" name="street" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">City:</div>
    <input class="edit" [(ngModel)]="person.city" name="city" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Postcode:</div>
    <input class="edit" [(ngModel)]="person.postcode" name="postcode" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">IBAN:</div>
    <input class="edit" [(ngModel)]="person.iban" name="iban" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">BSN:</div>
    <input class="edit" [(ngModel)]="person.bsn" name="bsn" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Medewerker Nummer:</div>
    <input
      class="edit"
      [(ngModel)]="person.medewerkernumber"
      name="medewerkernumber"
    />
  </div>

  <app-input
    type="date"
    [model]="person.soc | date : 'yyyy-MM-dd'"
    name="Start Of Employment:"
    (changed)="saveSOC($event)"
  ></app-input>
  <app-input
    type="date"
    [model]="person.eoc | date : 'yyyy-MM-dd'"
    name="End Of Contract:"
    (changed)="saveEOC($event)"
  ></app-input>
</div>

<div
  class="actions staff-info-header"
  *ngIf="person && auth.accessUser.access >= 4 && editing && photos == -1"
>
  <button color="accent" class="reactive-button-title green" (click)="save()">
    Save
  </button>
  <button
    *ngIf="this.auth.accessUser.access >= 3"
    [class]="'reactive-button-title ' + (person.complete ? 'green' : 'red')"
    color="accent"
    (click)="approve()"
  >
    {{ person.complete ? "Approved" : "Not Approved" }}
  </button>
  <button
    *ngIf="this.auth.accessUser.access > 3"
    class="reactive-button-title red"
    color="warn"
    (click)="removeStaff()"
  >
    Delete
  </button>

  <button color="warn" class="reactive-button-title orange" (click)="reset()">
    Cancel
  </button>

  <button
    color="warn"
    class="reactive-button-title green"
    (click)="resetPassword(person)"
  >
    Reset Password
  </button>
</div>

<!--Info-->
<div
  class="staff-info-content"
  *ngIf="(person && auth.accessUser.access <= 3) || (!editing && photos == -1)"
>
  <div class="edit-row">
    <div class="edit-desc">First Name:</div>
    <div>{{ person.firstName }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Last Name:</div>
    <div>{{ person.lastName }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Birthdate:</div>
    <div>{{ person.birthdate | date : "yyyy-MM-dd" }}</div>
  </div>

  <div class="divider"></div>

  <div class="edit-row">
    <div class="edit-desc">Email:</div>
    <div>{{ person.email }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Telephone:</div>
    <div>{{ person.telephone }}</div>
  </div>

  <div class="divider"></div>

  <div class="edit-row">
    <div class="edit-desc">Street:</div>
    <div>{{ person.street }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">City:</div>
    <div>{{ person.city }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Postcode:</div>
    <div>{{ person.postcode }}</div>
  </div>

  <div class="divider"></div>

  <div class="edit-row">
    <div class="edit-desc">IBAN:</div>
    <div>{{ person.iban }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">End Of Contract:</div>
    <div>{{ person.eoc | date : "yyyy LLL dd" }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Medewerker Nummer:</div>
    <input
      class="edit"
      [(ngModel)]="person.medewerkernumber"
      name="medewerkernumber"
    />
    <button (click)="saveMedewerker()">Save No:</button>
  </div>
</div>
<div
  class="actions staff-info-header"
  *ngIf="(person && auth.accessUser.access <= 3) || (!editing && photos == -1)"
>
  <button color="warn" class="reactive-button-title orange" (click)="reset()">
    Cancel
  </button>
  <button
    color="warn"
    class="reactive-button-title green"
    (click)="resetPassword(person)"
  >
    Reset Password
  </button>
  <button
    color="warn"
    class="reactive-button-title green"
    [disabled]="auth.accessUser.access <= 3"
    (click)="editing = true"
  >
    Edit
  </button>
</div>
<!--General-->
<div
  class="staff-info-content scrollable"
  *ngIf="person && auth.accessUser.access >= 4 && photos == 0"
>
  <div class="picture-box">
    <div class="picture-title">ID Front</div>

    <img *ngIf="idFront" [src]="idFront" />
    <img *ngIf="!idFront" src="../../assets//defaultprofile.svg" />
  </div>

  <div class="picture-box">
    <div class="picture-title">ID Back</div>
    <img *ngIf="idBack" [src]="idBack" />
    <img *ngIf="!idBack" src="../../assets//defaultprofile.svg" />
  </div>

  <div class="picture-box">
    <div class="picture-title">Social Hygiene</div>

    <img *ngIf="sh" [src]="sh" />
    <img *ngIf="!sh" src="../../assets//defaultprofile.svg" />
  </div>

  <div class="picture-box">
    <div class="picture-title">Bankpas Front</div>

    <img *ngIf="bankFront" [src]="bankFront" />
    <img *ngIf="!bankFront" src="../../assets//defaultprofile.svg" />
  </div>
  <div class="picture-box">
    <div class="picture-title">Bankpas Back</div>

    <img *ngIf="bankBack" [src]="bankBack" />
    <img *ngIf="!bankBack" src="../../assets//defaultprofile.svg" />
  </div>
</div>

<!--Documents-->
<div class="staff-info-content" *ngIf="person && photos == 1">
  <div class="actions staff-info-header">
    <button
      class="reactive-button-title"
      (click)="documentType = 0"
      [ngClass]="documentType == 0 ? 'green' : ''"
    >
      Contract
    </button>
    <button
      class="reactive-button-title"
      (click)="documentType = 1"
      [ngClass]="documentType == 1 ? 'green' : ''"
    >
      Payslip
    </button>
    <button
      class="reactive-button-title"
      (click)="documentType = 2"
      [ngClass]="documentType == 2 ? 'green' : ''"
    >
      Loonheffing
    </button>
  </div>

  <ng-container *ngIf="documentType == 0">
    <div class="document-make">Upload New Contract:</div>
    <div class="edit-row" style="width: 350px">
      <div class="edit-desc">Start Date:</div>
      <input
        class="edit"
        [valueAsDate]="startDate"
        type="date"
        name="eoc"
        (change)="saveStartDate($event.target.valueAsDate)"
      />
    </div>

    <button
      style="margin-top: 5px"
      class="reactive-button"
      #fileClick
      (click)="fileClick9.click()"
    >
      <i class="fas fa-upload"></i> Choose File and Upload
    </button>
    <input
      style="width: 0 !important; height: 0px !important; overflow: hidden"
      type="file"
      (change)="upload($event, 'contract')"
      accept="application/pdf"
      #fileClick9
    />

    <div class="divider"></div>
    <h3>Existing Contracts</h3>

    <div
      *ngFor="let p of contracts"
      class="picture staff-row"
      (click)="openPDF(p, 'contract', p.key)"
    >
      <a *ngIf="p.data" type="application/pdf"
        >Contract {{ p.key | date : "yyyy-MM-dd" }}
      </a>
      <button (click)="$event.stopPropagation(); deleteContract(person, p)">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="documentType == 1">
    <div class="document-make">Upload New Payslip:</div>
    <div class="edit-row" style="width: 350px">
      <div class="edit-desc">Start Date:</div>
      <input
        class="edit"
        [valueAsDate]="payslipDate"
        type="date"
        name="eoc"
        (change)="savePayslipDate($event.target.valueAsDate)"
      />
    </div>

    <button
      style="margin-top: 5px"
      class="reactive-button"
      #fileClick
      (click)="fileClick10.click()"
    >
      <i class="fas fa-upload"></i> Choose File and Upload
    </button>
    <input
      style="width: 0 !important; overflow: hidden; height: 0 !important"
      type="file"
      (change)="upload($event, 'payslip')"
      #fileClick10
    />

    <div class="divider"></div>
    <h3>Existing Payslips</h3>

    <div
      *ngFor="let p of payslips"
      class="picture staff-row"
      (click)="openPDF(p, 'payslip', p.key)"
    >
      <a *ngIf="p.data" type="application/pdf"
        >Payslip {{ p.key | date : "yyyy-MM-dd" }}
      </a>

      <button (click)="$event.stopPropagation(); deletePayslip(person, p)">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="documentType == 2">
    <div class="document-make">Upload New Loonheffing:</div>
    <div class="edit-row" style="width: 350px">
      <div class="edit-desc">Start Date:</div>
      <input
        class="edit"
        [valueAsDate]="loonheffingDate"
        type="date"
        name="eoc"
        (change)="saveLoonheffingDate($event.target.valueAsDate)"
      />
    </div>

    <button
      style="margin-top: 5px"
      class="reactive-button"
      #fileClick
      (click)="fileClick11.click()"
    >
      <i class="fas fa-upload"></i> Choose File and Upload
    </button>
    <input
      style="width: 0 !important; overflow: hidden; height: 0 !important"
      type="file"
      (change)="upload($event, 'loonheffing')"
      #fileClick11
    />

    <div class="divider"></div>
    <h3>Existing Loonheffing</h3>

    <div
      *ngFor="let p of loonheffing"
      class="picture staff-row"
      (click)="openPDF(p, 'loonheffing', p.key)"
    >
      <a *ngIf="p.data" type="application/pdf"
        >Payslip {{ p.key | date : "yyyy-MM-dd" }}
      </a>

      <button (click)="$event.stopPropagation(); deleteLoonheffing(person, p)">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </ng-container>
</div>

<!--Shirts-->
<div class="staff-info-content" *ngIf="person && photos == 3">
  <app-shirts [staff]="person"></app-shirts>
</div>
