import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-testers",
  templateUrl: "./testers.component.html",
  styleUrls: ["./testers.component.css"],
})
export class TestersComponent implements OnInit {
  bar;
  subs = [];
  end = new Date();
  start = new Date(this.end);

  force = false;
  staffDrinks: any = [];
  staff = {};
  stock: any = {};
  result = {};
  discounts = [];

  constructor(
    private db: StaffAppDatabase,
    private barInfo: BarInfo,
    private loading: LoadingService
  ) {
    this.start.setDate(this.start.getDate() - 1);
    console.log(this.start, this.end);
    this.loading.nextEmit("on");
  }

  ngOnInit(): void {
    this.subs.push(
      this.barInfo.barObserver.subscribe((val) => {
        this.bar = val;
        this.getStock();
        this.getStaff();
        this.loading.nextEmit(null);
      })
    );
  }

  async getStaff() {
    this.staff = await (
      await this.db
        .getStaff()
        .pipe(take(1))
        .toPromise()
        .then((a) => a)
        .catch((e) => [])
    ).reduce((obj: any, item) => {
      return {
        ...obj,
        [item["uid"]]: item,
      };
    }, {});
  }

  async getStock() {
    this.stock = await this.db
      .getStockForBar(this.barInfo.bar)
      .then((a: any[]) => {
        return a.reduce((acc, val) => Object.assign(acc, val), {});
      })
      .catch((e) => {
        return [];
      });
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }

  setDate(isStart, date) {
    if (isStart) {
      this.start = new Date(date);
    } else {
      this.end = new Date(date);
    }
  }

  notification(type: string) {
    console.log(this.start, this.end);
    if (type === "TestDrinks") {
      this.staffDrinks = [];
      this.loading.nextEmit("on");
      this.db
        .testStaffDrinks(
          this.barInfo.bar,
          this.start.getTime(),
          this.end.getTime(),
          true,
          this.force
        )
        .then((a: any) => {
          console.log(a);
          //const obj = JSON.parse(a);
          this.staffDrinks = JSON.parse(a[0].data);
          this.loading.nextEmit("");
        })
        .catch((e) => {
          console.log(e);
          this.loading.nextEmit("");
        });
    } else {
      this.db.triggerNotification(this.bar, type);
    }
  }

  setDrinks() {
    this.staffDrinks = [];
    this.loading.nextEmit("on");
    this.db
      .testStaffDrinks(
        this.barInfo.bar,
        this.start.getTime(),
        this.end.getTime(),
        false,
        this.force
      )
      .then((a: any) => {
        const obj = JSON.parse(a);
        this.staffDrinks = obj;
        this.loading.nextEmit("");
      })
      .catch((e) => {
        console.log(e);
        this.loading.nextEmit("");
      });
  }

  testDiscounts(date1, date2) {
    this.discounts = [];
    this.loading.nextEmit("on");
    this.db
      .testDiscounts(this.barInfo.bar, date1.getTime(), date2.getTime())
      .then((a) => {
        try {
          console.log(JSON.parse(a));
          Object.values(JSON.parse(a)).forEach((type: any) => {
            console.log(type);
            this.discounts = this.discounts.concat(type);
          });
        } catch (e) {
          console.error(e, a);
        }

        this.loading.nextEmit("");
      })
      .catch((e) => {
        console.log(e);
        this.loading.nextEmit("");
      });
  }

  async testInOutCash() {
    this.loading.nextEmit("on");
    const data = await this.db
      .testInOut(this.barInfo.bar, this.start)
      .then((a) => a)
      .catch((e) => e);

    try {
      console.log(JSON.parse(data));
      this.result = JSON.parse(data);
    } catch (e) {
      console.log(data);
    }
    this.result = JSON.parse(data);
    this.loading.nextEmit("");
  }
}
