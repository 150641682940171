import { Component, OnInit } from "@angular/core";
import { bn } from "date-fns/locale";
import { isString } from "lodash";
import { exit } from "process";

import { take } from "rxjs/operators";
import { Auth } from "src/app/auth/auth";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-access-levels",
  templateUrl: "./access-levels.component.html",
  styleUrls: ["./access-levels.component.css"],
})
export class AccessLevelsComponent implements OnInit {
  accessLevels: any[] = [
    { value: 0, name: "Guest" },
    { value: 1, name: "Staff" },
    { value: 2, name: "Assistant Manager / Junior Developer" },
    { value: 3, name: "Manager" },
    { value: 4, name: "Regional Manager" },
    { value: 5, name: "Hoofdkantoor" },
    { value: 6, name: "Directors" },
    { value: 7, name: "Senior Developer" },
    { value: 8, name: "Duncan" },
  ];
  bars: string[] = [];
  staff: any[] = [];

  //sort types
  sortBy: string = 'access'; //name, bar or access
  sortDirection: number = -1; //1 or -1
  filterBars: boolean = true;
  constructor(private db: StaffAppDatabase, private alert: AlertsService, private auth: Auth) {
    if(this.db.bar == 'Pertempto') {
      this.filterBars = false;
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.bars = await this.db.getBarsAsync().then(a => a.map(b => b as string)).catch(e => {
      console.error(e);
      return [];
    })

    const tempArray: any[] = await this.db
      .getStaff()
      .pipe(take(1))
      .toPromise()
      .then((a) => {
        return a.map((b: any) => {
          if (!b.access) {
            b.access = 0;
          }
          return b;
        }).filter(b => (b.bar != 'Old Staff'|| !this.filterBars));
      })
      .catch((e) => {
        console.error("error" + e);
        return [];
      });
      this.staff = this.sort(tempArray);
  }

  sort(list: any[]) {
    const pipeName = function(b) {
      return  b.lastName ? b.lastName : b.displayName;;
    }
    switch(this.sortBy) {
      case 'bar':
        return list.sort((a: any, b: any) => {
          return (a.bar.localeCompare(b.bar)) * this.sortDirection;
        })
      case 'name':
        return list.sort((a: any, b: any) => {
          var bName: string = pipeName(b);
          var aName: string = pipeName(a)
          if(!aName) {
            console.log(a);
            return 1;
          }
          if(!bName) {
            return -1;
          }
          var compare = aName.localeCompare(bName);
          console.log(compare);

          return compare * this.sortDirection;
        })
      case 'access':
        return list.sort((a: any, b: any) => {
          return (a.access - b.access) * this.sortDirection;
        })
      default:
        return list;
    }
  }

  async saveUser(user) {

    if(isNaN(user.access)){
      user.access = 0;
    }
    user.access = Number(user.access);

    if(!isString(user.bar)) {
      user.bar = '';
    }

    const res = await this.db.saveUserLevelBar(user).then(a => null).catch(e =>e);
    if(res) {
      this.alert.nextEmit(AlertsService.error('Couldnt save user',res))
      this.getData();
    }
  }
}
