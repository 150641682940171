import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogComponent } from "src/app/Packages/confirmation-dialog/confirmation-dialog.component";

import { Firebase } from "src/app/Services/firebase";

import { AlertsService } from "../alerts/alerts.service";
import { ConfirmationDialogService } from "../confirmation-dialog/confirmation-dialog.service";
import { Artist } from "src/app/Classes/artist";

import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-edit-artist",
  templateUrl: "./edit-artist.component.html",
  styleUrls: ["./edit-artist.component.css"],
})
export class EditArtistComponent implements OnInit {
  artistForm: FormGroup;
  data: Artist;
  @Input("artist") set artist(artist: Artist) {
    this.artistForm.patchValue({ name: artist.name });
    this.data = artist;
  }

  constructor(
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    private api: Firebase,
    private confirmation: ConfirmationDialogService,
    private alert: AlertsService,
    public db: StaffAppDatabase,
    public bi: BarInfo,
    public auth: Auth
  ) {
    this.artistForm = this.fb.group({
      name: [
        "",
        [Validators.required, Validators.maxLength(200), Validators.min(1)],
      ],
    });
  }

  ngOnInit(): void {}

  dismiss(reason: string) {
    this.modal.dismiss(reason);
  }

  async close(reason: string) {
    if (reason === "save") {
      await this.save();
    } else if (reason === "delete") {
      await this.delete();
    }
  }

  async save() {
    console.log(
      "saving " + this.data.name + " to " + this.artistForm.get("name").value
    );
    var val;
    if (this.data.key) {
      val = await this.api
        .editArtist(this.data.key, this.artistForm.get("name").value)
        .then((a) => null)
        .catch((e) => e);
    } else {
      val = await this.api
        .addArtist(this.artistForm.get("name").value)
        .then((a) => null)
        .catch((e) => e);
    }
    if (val) {
      this.alert.nextEmit(AlertsService.error("Save Error", val));
      await this.api.init();
    }

    try {
      this.db.changedRecordOld(
        this.bi.bar,
        this.auth.user.uid,
        this.data,
        "catalogue",
        this.data.key
      );
    } catch (e) {}
    this.modal.dismiss();
  }

  async delete() {
    console.log("deleting " + this.data.name);
    this.confirmation
      .confirm(
        "Delete: " + this.data.name,
        "Are you sure you want to delete this artist? This will remove all songs",
        "Delete",
        "Cancel",
        "lg"
      )
      .then(
        async (ful) => {
          if (ful) {
            const val = await this.api
              .removeArtist(this.data.key)
              .then((a) => null)
              .catch((e) => e);
            if (val) {
              this.alert.nextEmit(AlertsService.error("Delete Error", val));
              await this.api.init();
            }

            this.modal.dismiss();
          }
        },
        (rej) => {}
      );
  }

  isValid(name: string) {
    return this.artistForm.get(name).valid;
  }
}
