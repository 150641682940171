import { Component, OnInit, Input } from '@angular/core';
import { MyUser } from 'src/app/Classes/user';
import { AlertsService } from '../alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { Shirts } from 'src/app/Classes/shirts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddShirtComponent } from '../add-shirt/add-shirt.component';



@Component({
  selector: 'app-shirts',
  templateUrl: './shirts.component.html',
  styleUrls: ['./shirts.component.css']
})
export class ShirtsComponent implements OnInit {

  @Input('staff') staff: MyUser;
  shirts: Shirts[] = [];
  constructor(public alert: AlertsService, public loading: LoadingService, public db: StaffAppDatabase, public modal: NgbModal) {
    this.loading.nextEmit('on');

  }

  ngOnInit(): void {
    this.getData();
  }

  async getData(){
    this.shirts = await this.db.getShirts(this.staff.uid).then((values) => {
      this.loading.nextEmit('');
      return values;
    }).catch((error) => {
      this.loading.nextEmit('');
      this.alert.nextEmit(AlertsService.error('Error', error));
      return [];
    });
    console.log(this.shirts);
  }

  editShirt(shirt: Shirts){
    const modalRef = this.modal.open(AddShirtComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.shirt = shirt;
    modalRef.componentInstance.staff = this.staff;
    modalRef.result.then((result) => {
      //needs saving
      this.saveShirt(result);

    }, (dismiss) => {
      this.getData();
    }).catch((error) => {
      console.log(error);
    });
  }

  async deleteShirt(shirt: Shirts){
    if(window.confirm('Are you sure you want to delete this shirt?')){
      this.loading.nextEmit('on');
      await this.db.removeShirt(this.staff.uid, shirt).then((value) => {
        this.loading.nextEmit('');
        this.alert.nextEmit(AlertsService.success('Success', 'Shirt deleted'));
        this.getData();
      }).catch((error) => {
        this.loading.nextEmit('');
        this.alert.nextEmit(AlertsService.error('Error', error));
      });
    }

  }

  async saveShirt(shirt: Shirts) {
    this.loading.nextEmit('on');
    await this.db.addShirt(this.staff.uid, shirt).then((value) => {
      this.loading.nextEmit('');
      this.alert.nextEmit(AlertsService.success('Success', 'Shirt saved'));
      this.getData();
    }).catch((error) => {
      this.loading.nextEmit('');
      this.alert.nextEmit(AlertsService.error('Error', error));
    });
  }

  newShirt(){
    var shirt = new Shirts('shirt', 'M');
    this.editShirt(shirt);
  }

}
