<div class="generic-modal-header">
  <h3>{{ receiptTitle }}</h3>
</div>

<div class="generic-modal-content">
  <pre [innerHTML]="formattedReceipt"></pre>
</div>

<div class="actions generic-modal-footer">
  <button class="reactive-button-title orange" (click)="close()">Close</button>
  <button class="reactive-button-title green" (click)="download()">
    Download
  </button>
</div>
