<div class="generic-modal-header">
  <h3>{{ payment.date | date : "yyyy-MM-dd HH:mm:ss" }}</h3>
  <div style="flex-grow: 1"></div>
  <h2>{{ payment.amount | currency : "EUR" }}</h2>
</div>

<div
  #paymentModalContent
  id="paymentModalContent"
  class="generic-modal-content"
  style="max-height: 80vh; overflow: auto"
>
  <div class="generic-modal-content-row">
    <div class="generic-modal-content-box">
      <div class="generic-modal-content-box-title">General</div>
      <app-input
        [editable]="false"
        name="Type"
        [model]="payment.type"
      ></app-input>
      <app-input
        [editable]="false"
        name="Date"
        [model]="payment.date | date : 'yyyy-MM-dd HH:mm:ss'"
      ></app-input>
      <app-input
        [editable]="false"
        name="Table"
        [model]="payment.table"
      ></app-input>
      <app-input
        [editable]="false"
        name="Location"
        [model]="payment.bar"
      ></app-input>
    </div>
    <div class="generic-modal-content-box">
      <div class="generic-modal-content-box-title">Amount</div>
      <app-input
        [editable]="false"
        name="Amount"
        [model]="payment.amount | currency : 'EUR'"
      ></app-input>
      <app-input
        [editable]="false"
        name="Tip"
        [model]="payment.tip.amount"
      ></app-input>
      <div class="divider"></div>
      <app-input
        [editable]="false"
        name="Pin/Cash Amount"
        [model]="payment.pinCashAmount | currency : 'EUR'"
      ></app-input>
      <app-input
        [editable]="false"
        name="QR Amount"
        [model]="payment.qrAmount | currency : 'EUR'"
      ></app-input>
    </div>
  </div>
  <div class="generic-modal-content-row">
    <div class="generic-modal-content-box">
      <div class="generic-modal-content-box-title">Identifier</div>
      <app-input
        [editable]="false"
        name="NP ID"
        [model]="payment.id"
      ></app-input>
      <app-input
        [editable]="false"
        name="CCV ID"
        [model]="payment.ccvId"
      ></app-input>

      <app-input
        [editable]="false"
        name="Untill ID"
        [model]="payment.untilId"
      ></app-input>

      <app-input
        [editable]="false"
        name="Device ID"
        [model]="payment.deviceId"
      ></app-input>
    </div>
    <div class="generic-modal-content-box">
      <div class="generic-modal-content-box-title">Journals & Receipts</div>

      <button
        [disabled]="!payment.ejournal"
        (click)="openEJournal(payment.ejournal, 'E-Journal')"
      >
        E-Journal
      </button>
      <button
        [disabled]="!payment.customerReceipt"
        (click)="openReceipt(false, 'Customer Receipt')"
      >
        Customer Receipt
      </button>
      <button
        [disabled]="!payment.merchantReceipt"
        (click)="openReceipt(true, 'Merchant Receipt')"
      >
        Merchant Receipt
      </button>
    </div>
  </div>
  <div class="generic-modal-content-row">
    <div class="generic-modal-content-box">
      <div class="generic-modal-content-box-title">Statuses</div>
      <app-input
        [editable]="false"
        name="Status"
        [model]="payment.status"
      ></app-input>
      <app-input
        [editable]="false"
        name="Till Status"
        [model]="payment.tillStatus"
      ></app-input>
      <app-input
        [editable]="false"
        name="Pin Status"
        [model]="payment.pinStatus"
      ></app-input>
      <app-input
        [editable]="false"
        name="QR Status"
        [model]="payment.qrStatus"
      ></app-input>
      <app-input
        [editable]="false"
        name="Errors"
        [model]="payment.errors"
      ></app-input>
    </div>
    <div class="generic-modal-content-box">
      <div class="generic-modal-content-box-title">Payment Info</div>

      <app-input
        [editable]="false"
        name="Payment Method"
        [model]="paymentInfo.cardLabelName"
      ></app-input>
      <app-input
        [editable]="false"
        name="Card Entry Method"
        [model]="paymentInfo.serviceLabelName"
      ></app-input>

      <app-input
        [editable]="false"
        name="Card Number"
        [model]="paymentInfo.cardNumber"
      ></app-input>
      <app-input
        [editable]="false"
        name="Expiration"
        [model]="paymentInfo.expirationDate"
      ></app-input>
    </div>
  </div>
</div>

<div class="generic-modal-footer">
  <button class="reactive-button-title orange" (click)="close()">Close</button>
  <button class="reactive-button-title green" (click)="download()">
    Download
  </button>
</div>
