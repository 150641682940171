<div style="display: flex;
flex-direction: column;
flex-wrap: nowrap;
justify-content: flex-start;
align-items: stretch;
align-content: stretch;">

    <div style="text-align: center;">
        <h3 style="margin: 2px 0px;">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>

    <div>
        <div style="display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: stretch;
        align-content: stretch;">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()" (click)="monthClicked()">
                Previous
            </div>
            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate" (click)="monthClicked()">
                Today
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()" (click)="monthClicked()">
                Next
            </div>
        </div>
    </div>


</div>
<br />
<div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>

</div>
