<ng-template #content let-modal>
    <div class="modal-header" style="color: black">
        <h4 class="modal-title" id="modal-basic-title">{{ modalContent.title }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
      ">
      <i class="fas fa-close"></i>
    </button>
    </div>
    <div class="modal-body">
        <app-verbruik-popover [items]="modalContent.list" [stock]="overview.stock"></app-verbruik-popover>
    </div>
</ng-template>

<ng-template #content2 let-modal>
    <div class="modal-header" style="color: black">
        <h4 class="modal-title" id="modal-basic-title">{{ modalContent.title }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
      ">
      <i class="fas fa-close"></i>
    </button>
    </div>
    <div class="modal-body">
        <app-expenses-pop-over [items]="modalContent.list"></app-expenses-pop-over>
    </div>
</ng-template>

<div style=" width: 100%" class="flex-even">
    <button (click)="overview.dateVisible = true">
    <i class="fas fa-calendar"></i>
  </button>
    <ngb-datepicker *ngIf="overview.dateVisible" style="z-index: 10; background-color: whitesmoke; color: black" #d="ngbDatepicker" name="dp" [footerTemplate]="footerTemplate" (dateSelect)="overview.onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"
        [autoClose]="'outside'">
    </ngb-datepicker>

    <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="overview.focused" [class.range]="overview.isRange(date)" [class.faded]="overview.isHovered(date) || overview.isInside(date)" (mouseenter)="overview.hoveredDate = date" (mouseleave)="overview.hoveredDate = null">
      {{ date.day }}
    </span>
    </ng-template>

    <ng-template #footerTemplate>
        <hr class="my-0" />
        <button class="btn btn-secondary btn-sm m-2 float-end" (click)="overview.customRange(); overview.dateVisible = false">
      Close
    </button>
        <button class="btn btn-primary btn-sm m-2 float-end" (click)="
        overview.customRange();
        overview.dateVisible = false;
        overview.buildSelection()
      ">
      Go
    </button>
    </ng-template>

    {{ overview.start | date : "EE dd MMM `yy" }}{{ overview.viewType == 0 ? "" : " t/m " + (overview.end | date : "EE dd MMM `yy") }}
    <button (click)="overview.buildSelection()">Go</button>
    <div style="flex-grow: 1"></div>
    <div style="font-size: xx-large; color: black">
        {{ overview.selection.omzet.checks && overview.selection.omzet.checks.total ? "Checked " : "Preview " }}
    </div>
</div>

<div style=" width: 100%" class="flex-even">
    <button (click)="overview.viewType = 0" class="datchev" [ngClass]="{ selected: overview.viewType === 0 }" style="margin-right: 10px !important">
    Day
  </button>
    <button (click)="overview.viewType = 1" class="datchev" [ngClass]="{ selected: overview.viewType === 1 }" style="margin-right: 10px !important">
    Week
  </button>
    <button (click)="overview.viewType = 2" class="datchev" [ngClass]="{ selected: overview.viewType === 2 }" style="margin-right: 10px !important">
    Month
  </button>
    <button (click)="overview.viewType = 3" class="datchev" [ngClass]="{ selected: overview.viewType === 3 }" style="margin-right: 10px !important">
    Year
  </button>
    <button (click)="overview.viewType = 4; overview.customRange(null)" class="datchev" [ngClass]="{ selected: overview.viewType === 4 }" style="margin-right: 10px !important">
    Custom
  </button>
    <button *ngIf="overview.viewType == 0" (click)="overview.refreshTill()" class="datchev" style="margin-right: 10px !important; width: 150px !important">
    Refresh Omzet
  </button>
    <button *ngIf="overview.viewType == 0" (click)="overview.testDiscounts()" class="datchev" style="margin-right: 10px !important; width: 150px !important">
    Refresh Verbruik
  </button>

    <div style="flex-grow: 1"></div>
    <button (click)="overview.addDate(-1)" class="datchev" style="margin-right: 10px !important">
    <i class="fas fa-chevron-left"></i>
  </button>
    <button (click)="overview.addDate(1)" class="datchev">
    <i class="fas fa-chevron-right"></i>
  </button>
</div>

<div class="page-overview" style="flex-grow: 1; margin-top: 10px;">
    <div class="page-box-out">
        <div class="page-box-sub" *ngIf="overview.selection.omzet.differences">
            <app-overview-list class="box-row box-header" [title]="'Omzet'" [list]="[
          {
            class: 'normal-box input-box header-input',
            data: overview.selection.omzet.total,
            type: 'currency'
          },
          {
            class: 'normal-box',
            data: overview.selection.omzet.differences.total,
            type: 'currency'
          },
          { class: 'grow' },
          {
            class: 'small-box circle',
            data: overview.selection.omzet.differences.total,
            type: 'color'
          }
        ]"></app-overview-list>
            <app-overview-list class="box-row" [title]="'Pin'" [list]="[
          {
            class: 'normal-box',
            data: overview.selection.omzet.pin,
            type: 'currency'
          },
          {
            class: 'normal-box',
            data: overview.selection.omzet.differences.pin,
            type: 'currency'
          },
          { class: 'grow' },
          {
            class: 'small-box circle',
            data: overview.selection.omzet.differences.pin,
            type: 'color'
          }
        ]"></app-overview-list>
            <app-overview-list class="box-row" [title]="'Cash'" [list]="[
          {
            class: 'normal-box',
            data: overview.selection.omzet.cash,
            type: 'currency'
          },
          {
            class: 'normal-box',
            data: overview.selection.omzet.differences.cash,
            type: 'currency'
          },
          { class: 'grow' },
          {
            class: 'small-box circle',
            data: overview.selection.omzet.differences.cash,
            type: 'color'
          }
        ]"></app-overview-list>
            <app-overview-list class="box-row" [title]="'Internet'" [list]="[
          {
            class: 'normal-box',
            data: overview.selection.omzet.internet,
            type: 'currency'
          },
          {
            class: 'normal-box',
            data: overview.selection.omzet.differences.internet,
            type: 'currency'
          },
          { class: 'grow' },
          {
            class: 'small-box circle',
            data: overview.selection.omzet.differences.internet,
            type: 'color'
          }
        ]"></app-overview-list>
            <app-overview-list class="box-row" [title]="'Flesjes'" [list]="[
          {
            class: 'normal-box',
            data: overview.selection.omzet.flesjes,
            type: 'currency'
          },
          {
            class: 'normal-box',
            data: overview.selection.omzet.differences.flesjes,
            type: 'currency'
          },
          { class: 'grow' },
          {
            class: 'small-box circle',
            data: overview.selection.omzet.differences.flesjes,
            type: 'color'
          }
        ]"></app-overview-list>
            <app-overview-list class="box-row" [title]="'Teller'" [list]="[
          {
            class: 'normal-box',
            data: overview.selection.omzet.teller,
            type: 'currency'
          },
          {
            class: 'normal-box',
            data: overview.selection.omzet.differences.teller,
            type: 'currency'
          },
          { class: 'grow' },
          {
            class: 'small-box circle',
            data: overview.selection.omzet.differences.teller,
            type: 'color'
          }
        ]"></app-overview-list>
        </div>

        <div class="page-box-sub" style="flex-grow: 1">
            <div class="box-row box-header">
                <div class="normal-box">Personeel</div>
                <button style="display: flex; align-items: center; justify-content: center; background-color: transparent; border-radius: 50%; height: 2rem; width: 2rem" (click)="openWorkedRecords()"><i [style.color]="overview.selection.multipleClockIns ? 'red' : 'black'"
             class="fas fa-stamp"></i></button>
                <div class="normal-box" style="height: 45px">
                    <div class="small-box circle" style="border-radius: 20px" [class]="0 | colorRange"></div>
                </div>

                <div style="flex-grow: 1"></div>
                <div class="small-box input-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.workedTotal | currency : "EUR" }}
                </div>
            </div>
            <app-who-is-working [worked]="overview.selection.worked" style="width: 100%; height: 100px"></app-who-is-working>


        </div>
    </div>

    <div class="page-box-out">
        <div class="page-box-sub">
            <div class="box-row box-header">
                <div class="normal-box">Kosten</div>
                <button class="normal-box" style="height: 45px">
          <div class="small-box circle" style="border-radius: 20px" [class]="{
              green:
                overview.selection.kosten.total <
                overview.selection.omzet.total,
              yellow:
                overview.selection.kosten.total ==
                overview.selection.omzet.total,
              red:
                overview.selection.kosten.total > overview.selection.omzet.total
            }"></div>
        </button>

                <div style="flex-grow: 1"></div>
                <div class="small-box input-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.kosten.total | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">Vaste Lasten</div>
                <button class="normal-box" style="height: 45px" (click)="
            openModel(content2, 'Vaste Lasten', overview.selection.recurring)
          ">
          {{ overview.selection.recurring.length }}
        </button>

                <div style="flex-grow: 1"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.kosten.vasteLasten | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">One Off</div>
                <button class="normal-box" style="height: 45px" (click)="openModel(content2, 'One Off', overview.selection.oneoff)">
          {{ overview.selection.oneoff.length }}
        </button>

                <div style="flex-grow: 1"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.kosten.oneoff | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">Bonnen</div>
                <button class="normal-box" style="height: 45px" (click)="openModel(content2, 'Cash In Out', overview.selection.inout)">
          {{ overview.selection.inout.length }}
        </button>

                <div style="flex-grow: 1"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.kosten.inout | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">Inkoop</div>
                <button class="normal-box" style="height: 45px" (click)="
            openModel(content2, 'Cash In Out', overview.selection.orders)
          ">
          {{ overview.selection.orders.length }}
        </button>

                <div style="flex-grow: 1"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.omzet.inkoop | currency : "EUR" }}
                </div>
            </div>
        </div>
        <div class="page-box-sub">
            <div class="box-row box-header">
                <div class="normal-box">Verbruik</div>

                <button class="normal-box" style="height: 45px" (click)="
            openModel(
              content,
              'Verbruik Total',
              overview.selection.verbruik.discounts
            )
          ">
          <div class="small-box circle" style="border-radius: 20px" [class]="overview.selection.verbruik.color"></div>
        </button>

                <div style="flex-grow: 1"></div>
                <div class="small-box input-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.verbruik.total | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">Verbruik</div>

                <button class="normal-box" style="height: 45px" (click)="
            openModel(
              content,
              'Verbruik',
              overview.selection.verbruik.verbruik.items
            )
          ">
          {{ overview.selection.verbruik.verbruik.quantity }}
        </button>

                <div class="small-box circle" style="border-radius: 20px; margin: 0" [class]="overview.selection.verbruik.verbruik.color"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.verbruik.verbruik.total | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">Spill/Breuk</div>

                <button class="normal-box" style="height: 45px" (click)="
            openModel(
              content,
              'Spill/Breuk',
              overview.selection.verbruik.spill.items
            )
          ">
          {{ overview.selection.verbruik.spill.quantity }}
        </button>

                <div class="small-box circle" style="border-radius: 20px; margin: 0" [class]="overview.selection.verbruik.spill.color"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.verbruik.spill.total | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">Wisselen fles</div>

                <button class="normal-box" style="height: 45px" (click)="
            openModel(
              content,
              'Wisselen',
              overview.selection.verbruik.wisselen.items
            )
          ">
          {{ overview.selection.verbruik.wisselen.quantity }}
        </button>
                <div class="small-box circle" style="border-radius: 20px; margin: 0" [class]="overview.selection.verbruik.wisselen.color"></div>

                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.verbruik.wisselen.total | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">QR Staff</div>

                <button class="normal-box" style="height: 45px" (click)="
            openModel(
              content,
              'QR Staff',
              overview.selection.verbruik.qr.items
            )
          ">
          {{ overview.selection?.verbruik?.qr?.quantity }}
        </button>
                <div class="small-box circle" style="border-radius: 20px; margin: 0" [class]="overview.selection.verbruik.qr.color"></div>

                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ overview.selection.verbruik.wisselen.total | currency : "EUR" }}
                </div>
            </div>
        </div>
        <div class="page-box-sub">
            <div class="box-row box-header">
                <div class="normal-box">Winst</div>
                <div class="normal-box" style="height: 45px">
                    <div class="small-box circle" style="border-radius: 20px" [class]="0 | colorRange"></div>
                </div>

                <div style="flex-grow: 1"></div>
                <div class="small-box input-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ 0 | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">Prognose</div>
                <div class="normal-box" style="height: 45px">0</div>

                <div style="flex-grow: 1"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ 0 | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">2021</div>
                <div class="normal-box" style="height: 45px">0</div>

                <div style="flex-grow: 1"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ 0 | currency : "EUR" }}
                </div>
            </div>

            <div class="box-row">
                <div class="normal-box">2020</div>
                <div class="normal-box" style="height: 45px">0</div>

                <div style="flex-grow: 1"></div>
                <div class="small-box" style="height: 45px; margin: 0 !important; border-radius: 20px">
                    {{ 0 | currency : "EUR" }}
                </div>
            </div>
        </div>
    </div>
</div>