import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert } from 'selenium-webdriver';
import { OneOffExpense } from 'src/app/Classes/one-off-expense';
import { RecurringExpense } from 'src/app/Classes/recurring-expense';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { LoadingService } from 'src/app/Services/loading.service';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css']
})
export class ExpensesComponent implements OnInit {

  bar;
  subs: Subscription[] = [];
  date = new Date();
  recurring: RecurringExpense[] = [];
  oneOff: OneOffExpense[] = [];

  rExpense: RecurringExpense;
  oExpense: any;


  constructor(private bI: BarInfo, private db: StaffAppDatabase, private alert: AlertsService, private loading: LoadingService) {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 18,0,0,0);
  }

  ngOnInit(): void {
    this.subs.push(this.bI.barObserver.subscribe(val => {
      this.bar = val
      if(val) {
        this.getData();
      }
    }))
  }

  monthChanged($event){
    this.date = new Date($event);
    this.getData();
  }

  async getData(){
    await this.getOneOffExpenses(this.date);
    await this.getRecurringExpenses();
  }


  ngOnDestroy(){
    this.subs.forEach(a => a.unsubscribe())
  }

  recurringClicked(rec){
    this.rExpense = rec;
    this.oExpense = null;
  }

  newRecurring(){
    this.rExpense = new RecurringExpense();
    this.oExpense = null;
  }

  oneOffClicked(rec){
    this.oExpense = rec;
    this.rExpense = null;
  }

  newOneOff(){
    this.oExpense = new OneOffExpense();
    this.rExpense = null;
  }

  setExpenseDate(isRecurring, isStart, event){
    console.log(isRecurring,isStart,event);
    if(isRecurring) {
      if(isStart) {
        this.rExpense.startDate = (new Date(event)).getTime();
      } else {
        this.rExpense.endDate = (new Date(event)).getTime();
      }
    } else {
      this.oExpense.date = (new Date(event)).getTime();
    }
  }

  //-------------------------

  async getOneOffExpenses(date:Date){
    this.oneOff = await  this.db.getOneOffExpenses(this.bar, date).then(a => a).catch(e => {
      console.log(e);
      return [];
    })
    console.log(this.oneOff);
  }

  async getRecurringExpenses(){
    this.recurring = await this.db.getRecurringExpenses(this.bar).then(a => a).catch(e => {
      console.log(e);
      return [];
    })
    console.log(this.recurring)
  }

  async setRecurringExpense(expense: RecurringExpense){
    if(!expense.isValid()){
      this.alert.nextEmit(AlertsService.warning('Object not valid','please fix object'));
      return;
    }
    await this.db.setRecurringExpense(this.bar, expense);
    this.getData();
  }

  async setOneOffExpense(expense: OneOffExpense){
    if(!expense.isValid()) {
      this.alert.nextEmit(AlertsService.warning('Object not valid','Please fix!'));
      return;
    }

    await this.db.setOneOffExpense(this.bar, this.date, expense);
    this.getData();
  }

  async deleteRecurringExpense(expense){
    await this.db.deleteRecurringExpense(this.bar, expense);
    this.getData();
  }

  async deleteOneOffExpense(expense){
    await this.db.deleteOneOffExpense(this.bar, this.date, expense);
    this.getData();
  }

}
