import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CCVPaymentStatus } from "../payments/payments.component";

@Component({
  selector: "app-receipt-modal",
  templateUrl: "./receipt-modal.component.html",
  styleUrls: ["./receipt-modal.component.css"],
})
export class ReceiptModalComponent implements OnInit {
  @Input("status") status: CCVPaymentStatus;
  @Input("isMerchant") isMerchant: boolean;
  @Input("receiptTitle") receiptTitle: string;
  receipt: string[];
  formattedReceipt: string;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.isMerchant) {
      this.receipt = this.status.merchantReceipt;
    } else {
      this.receipt = this.status.customerReceipt;
    }
    this.formatReceipt();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["receipt"]) {
      this.formatReceipt();
    }
  }

  formatReceipt() {
    this.formattedReceipt = "";

    for (var l = 0; l < this.receipt.length; l++) {
      var line = this.receipt[l];
      this.formattedReceipt += line + "\n";

      if (line.toLocaleLowerCase().includes("identificatie")) {
        this.formattedReceipt += this.status.customerIdentification + "\n";
        //remove following empty lines but the last one
        while (
          l + 1 < this.receipt.length &&
          this.receipt[l + 1].trim() === ""
        ) {
          l++;
        }
      }

      if (line.toLocaleLowerCase().includes("handtekening")) {
        var stringBitmap = this.status.customerSignature;
        //insert signature?
        if (stringBitmap) {
          this.formattedReceipt += `<img src="data:image/png;base64,${stringBitmap}" alt="Signature" />\n`;
        }
        while (
          l + 1 < this.receipt.length &&
          this.receipt[l + 1].trim() === ""
        ) {
          l++;
        }
      }
    }
  }

  close() {
    this.modal.close();
  }

  download() {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(this.formattedReceipt)
    );
    element.setAttribute("download", this.receiptTitle + ".txt");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
