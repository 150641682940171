<div class="staff-info-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">Outing Budget {{ budget.year }}</h3>
  <button
    [disabled]="editing"
    class="action-side close-button red"
    (click)="activeModal.dismiss()"
  >
    <i class="fas fa-times"></i>
  </button>
</div>

<!--Editing-->
<div class="staff-info-content" *ngIf="editing">
  <div class="edit-row">
    <div class="edit-desc">Year:</div>
    <div>{{ budget.year }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Bar:</div>
    <div>{{ budget.bar }}</div>
  </div>

  <!--Bar, createdOn, currentBalance, comments, records-->
  <div class="edit-row">
    <div class="edit-desc">Created On:</div>
    <div>{{ budget.createdOn | date : "MM/dd/yyyy" }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Current Balance:</div>
    <div>{{ budget.currentBalance | currency }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Comments:</div>
    <button (click)="addComment()">Add Comment</button>
    <div [ngbTooltip]="budget.comments" tooltipClass="white-space-pre-line">
      {{ budget.comments | slice : 0 : 25 }}
    </div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Records:</div>
    <button (click)="addRecord()">Add Record</button>
    <div style="max-height: 400px; overflow: auto; width: 100%">
      <div *ngIf="editingRecord" class="budget-record">
        <div class="edit-row">
          <div class="edit-desc">Date:</div>
          <input
            type="date"
            [ngModel]="editingRecord.date | date : 'yyyy-MM-dd'"
            (ngModelChanged)="dateChanged($event)"
            placeholder="Date"
          />
        </div>
        <div class="edit-row">
          <div class="edit-desc">Amount:</div>
          <input
            ngbTooltip="Always a positive number"
            type="number"
            [(ngModel)]="editingRecord.amount"
            placeholder="Amount"
          />
        </div>
        <div class="edit-row">
          <div class="edit-desc">Type:</div>
          <select [(ngModel)]="editingRecord.type">
            <option ngValue="credit" value="credit">Credit</option>
            <option ngValue="debit" value="debit">Debit</option>
          </select>
        </div>
        <div class="edit-row">
          <div
            class="edit-desc"
            ngbTooltip="Why money is added or where the money was spent"
          >
            Location / Description:
          </div>
          <input
            type="text"
            [(ngModel)]="editingRecord.location"
            placeholder="Location of Outing"
          />
        </div>

        <div class="edit-row">
          <button (click)="saveRecord()">Save</button>
          <button (click)="cancelRecord()">Cancel</button>
        </div>
      </div>
      <div
        *ngFor="let record of budget.records"
        class="budget-record"
        [ngClass]="{
          red: record.type == 'debit',
          green: record.type == 'credit'
        }"
      >
        <div class="edit-row">
          <div class="edit-desc">Date:</div>
          <div>{{ record.date | date : "MM/dd/yyyy" }}</div>
        </div>
        <div class="edit-row">
          <div class="edit-desc">Amount:</div>
          <div>{{ record.amount | currency }}</div>
        </div>
        <div class="edit-row">
          <div class="edit-desc">Location:</div>
          <div>{{ record.location }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Info-->
<div class="staff-info-content" *ngIf="!editing">
  <div class="edit-row">
    <div class="edit-desc">Year:</div>
    <div>{{ budget.year }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Bar:</div>
    <div>{{ budget.bar }}</div>
  </div>

  <!--Bar, createdOn, currentBalance, comments, records-->
  <div class="edit-row">
    <div class="edit-desc">Created On:</div>
    <div>{{ budget.createdOn | date : "MM/dd/yyyy" }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Current Balance:</div>
    <div>{{ budget.currentBalance | currency }}</div>
  </div>

  <div
    class="edit-row"
    [ngbTooltip]="budget.comments"
    tooltipClass="white-space-pre-line"
  >
    <div class="edit-desc">Comments:</div>
    <div>{{ budget.comments | slice : 0 : 25 }}</div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Records:</div>
    <div style="width: 100%; max-height: 450px; overflow: auto">
      <div
        *ngFor="let record of budget.records"
        class="budget-record"
        [ngClass]="{
          red: record.type == 'debit',
          green: record.type == 'credit'
        }"
      >
        <div class="edit-row">
          <div class="edit-desc">Date:</div>
          <div>{{ record.date | date : "MM/dd/yyyy" }}</div>
        </div>

        <div class="edit-row">
          <div class="edit-desc">Type:</div>
          <div>{{ record.type | capitalize }}</div>
        </div>
        <div class="edit-row">
          <div class="edit-desc">Amount:</div>
          <div>{{ record.amount | currency }}</div>
        </div>
        <div class="edit-row">
          <div class="edit-desc">Location:</div>
          <div>{{ record.location }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="actions staff-info-header" *ngIf="auth.user.access >= 3">
  <button
    color="accent"
    [disabled]="editing"
    class="reactive-button-title green"
    (click)="editing = true"
  >
    Edit
  </button>

  <button
    class="reactive-button-title red"
    (click)="cancel()"
    [disabled]="!editing"
  >
    Cancel
  </button>
  <button
    class="reactive-button-title green"
    (click)="save()"
    [disabled]="!editing"
  >
    Save
  </button>
</div>
