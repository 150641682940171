<div class="staff-table">
    <div *ngFor="let bar of bars" class="staff-row">
        <div class="staff-name">
            {{ bar.name}}
        </div>

        <div class="staff-email" ngbTooltip="This will take effect immediately. Use schedule rather: At 7.45pm it will turn off unless schedule keeps it on, at 1pm it will turn on">
            <app-input type="checkbox" [model]="bar.canDutch" name="Can Dutch" (changed)="toggle(bar, $event)"></app-input>
        </div>

        <div class="table-inner-row" style="width: 50px;">

            <app-input type="checkbox" *ngFor="let day of bar.schedule | keyvalue" [name]="day.value.name" [model]="day.value.canDutch" (changed)="toggleSchedule(bar, day.key)"></app-input>


        </div>


    </div>
</div>
