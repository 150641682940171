<div class="page-header">
    <div>

        <input type="date" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)">

    </div>
</div>

<div class="page-content">
    <div class="page-row">

        <app-expandable-box title="Who Worked" [isAbsolute]="true">
            <app-who-is-working [worked]="worked"></app-who-is-working>
        </app-expandable-box>

        <app-expandable-box title="Shift Open/Close" [isAbsolute]="true">
            <div *ngFor="let item of times" style="display: flex; flex-direction: row; align-items: center; justify-content: space-evenly;">
                <div>
                    {{item.value}} &nbsp;
                </div>

                <div>
                    {{item.key | date:'LLL dd HH:mm'}}
                </div>

            </div>
        </app-expandable-box>





    </div>

    <div class="page-row">
        <div class="count-box" *ngIf="attempts">
            <div>
                End Attempts
            </div>
            <div class="count-box-options">
                <div *ngFor="let item of attempts" class="count-option" [ngClass]="{'selected':attempt === item.key}" (click)="attempt = item.key">
                    <div>
                        {{item.key | date:'LLL dd HH:mm'}}
                    </div>

                </div>
            </div>

            <app-stock-count class="count-box-data" *ngIf="attempt" [count]="getAttempt()"></app-stock-count>






        </div>

        <div class="count-box" *ngIf="final.length">
            <div>
                Submitted
            </div>
            <div class="count-box-options opt2">
                <div *ngFor="let item of final" class="count-option" [ngClass]="{'selected':item.key === start}" (click)="start = item.key === 'start' ? 'start' : 'end'">
                    <div>
                        {{item.key}}
                    </div>



                </div>
            </div>



            <app-stock-count class="count-box-data" *ngIf="start =='start'" [count]="startCount"></app-stock-count>

            <app-stock-count class="count-box-data" *ngIf="start =='end'" [count]="endCount"></app-stock-count>








        </div>

    </div>
</div>