import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";

import { SortByDirection } from "src/app/Packages/sort-by/sort-by.component";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

import { Auth } from "src/app/auth/auth";
import { NDOStockEditComponent } from "../ndostock-edit/ndostock-edit.component";

@Component({
  selector: "app-ndostock-hq",
  templateUrl: "./ndostock-hq.component.html",
  styleUrls: ["./ndostock-hq.component.css"],
})
export class NDOStockHQComponent implements OnInit {
  searchText = "";

  sortBy: SortByDirection = new SortByDirection("Id", 1);
  stock: NDHOStock[] = [];
  filteredList: NDHOStock[] = [];

  barStock: Map<string, any[]> = new Map<string, any[]>();
  constructor(
    public auth: Auth,
    public alert: AlertsService,
    public loading: LoadingService,
    public db: StaffAppDatabase,
    public modal: NgbModal,
    public bi: BarInfo
  ) {}

  ngOnInit(): void {
    this.getAllStock();
  }

  async getAllStock(actuallyAll = true) {
    this.loading.nextEmit("on");
    this.stock = await this.db.getNDHOStock();

    if (!actuallyAll) {
      this.loading.nextEmit(null);
      this.search();
      return;
    }
    console.log(this.stock);
    var bars = this.bi.bars.filter((value) => {
      return (
        value != "All" &&
        value != "Daga Beheer" &&
        !this.bi.onlyDuncan.get(value)
      );
    });
    for (var value of bars) {
      await this.db.getAllNDStockAsync(value).then((values) => {
        console.log(values);
        this.barStock.set(
          value,
          values.reduce((acc: any[], val: any[]) => {
            acc = [...acc, val];
            return acc;
          }, [])
        );
      });
    }
    console.log(this.barStock);
    this.loading.nextEmit(null);
    this.search();
    console.log(this.auth.accessUser);
  }

  async importStock() {
    this.stock = await this.db.getAllNDStockAsync("Amsterdam").then((value) => {
      return value
        .reduce((acc: any[], val: any[]) => {
          acc = [...acc, val];
          return acc;
        }, [])
        .sort((a, b) => {
          return a.id - b.id;
        })
        .map((value, index) => {
          value.id = index;
          var stock = new NDHOStock(
            value.id,
            value.name,
            "ndstock",
            value.price,
            0
          );

          stock.bars["Amsterdam"] = {
            name: value.name,
            id: value.key,
            type: "ndstock",
          };
          return stock;
        })
        .filter((a) => {
          return !!a.type;
        });
    });

    this.stock.forEach((value) => {
      console.log("searching for", value.name);
      for (var bar of this.bi.bars) {
        if (!this.barStock.has(bar)) {
          continue;
        }

        var found = this.barStock.get(bar).find((v) => {
          return v.name === value.name;
        });

        if (found) {
          console.log("found", bar, value.name, found.key);
          value.bars[bar] = {
            name: value.name,
            id: found.key,
            type: "ndstock",
          };
        }
      }
    });

    console.log(this.stock);

    if (confirm("Are you sure you want to import this stock?")) {
      await this.db.saveNDHOStock(this.stock);
    }

    this.search();
  }

  search() {
    this.filteredList = this.stock
      .filter((value) => {
        if (
          this.searchText === "" ||
          this.searchText === null ||
          this.searchText === undefined
        ) {
          return true;
        }
        return value.name.toLowerCase().includes(this.searchText.toLowerCase());
      })
      .sort((a, b) => {
        switch (this.sortBy.name) {
          case "Id":
            return this.sortBy.direction === 1 ? a.id - b.id : b.id - a.id;
          case "Name":
            return this.sortBy.direction === 1
              ? a.name.localeCompare(b.name)
              : b.name.localeCompare(a.name);
          case "Type":
            return this.sortBy.direction === 1
              ? a.type.localeCompare(b.type)
              : b.type.localeCompare(a.type);
          case "Bars":
            return Object.values(a.bars).length - Object.values(b.bars).length;
          case "PPU":
            return this.sortBy.direction === 1 ? a.ppu - b.ppu : b.ppu - a.ppu;
          case "Single":
            return this.sortBy.direction === 1
              ? a.singlePrice - b.singlePrice
              : b.singlePrice - a.singlePrice;
          case "Quantity":
            return this.sortBy.direction === 1
              ? a.quantity - b.quantity
              : b.quantity - a.quantity;
          default:
            return 0;
        }
      });

    console.log(this.filteredList);
  }

  timeOut: any;
  onSearchChanged(event: any, force: boolean = false) {
    if (force) {
      this.searchText = "";
      return this.search();
    }
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.searchText = event.target.value;
      this.search();
    }, 500);
  }

  sort(event: SortByDirection) {
    this.sortBy = event;
    this.search();
  }

  editingOrder: boolean = false;
  editOrder() {
    this.editingOrder = true;
    this.onSearchChanged({ target: { value: "" } }, true);
  }

  async saveOrder() {
    this.editingOrder = false;
    this.loading.nextEmit("on");
    var res = await this.db.saveNDHOStockOrder(this.filteredList);
    console.log(res);
    if (res.error.length) {
      this.alert.nextEmit(AlertsService.error(res.error));
      this.loading.nextEmit(null);
      return;
    }
    this.alert.nextEmit(AlertsService.good("Order saved!"));
    this.loading.nextEmit(null);
  }

  moveUp(item: NDHOStock) {
    var index = this.filteredList.indexOf(item);
    if (index === 0) {
      return;
    }

    var temp = this.filteredList[index];
    this.filteredList[index] = this.filteredList[index - 1];
    this.filteredList[index - 1] = temp;

    this.filteredList[index].id = index;
    this.filteredList[index - 1].id = index - 1;
  }

  moveDown(item: NDHOStock) {
    var index = this.filteredList.indexOf(item);
    if (index === this.filteredList.length - 1) {
      return;
    }

    var temp = this.filteredList[index];
    this.filteredList[index] = this.filteredList[index + 1];
    this.filteredList[index + 1] = temp;

    this.filteredList[index].id = index;
    this.filteredList[index + 1].id = index + 1;
  }

  openItem(item) {
    var m = this.modal.open(NDOStockEditComponent, {
      size: "lg",
      centered: true,
      scrollable: true,
      backdrop: "static",
    });
    m.componentInstance.stock = item;
    m.componentInstance.barStock = this.barStock;

    m.result.then(
      (res) => {
        console.log("getting all stock");
        this.getAllStock(false);
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  create() {
    var nextId = 0;
    for (var s of this.stock) {
      if (s.id > nextId) {
        nextId = Math.max(nextId, s.id);
      }
    }
    var item = new NDHOStock(nextId + 1, "", "", 0, 0);
    this.openItem(item);
  }
}

export class NDHOStock {
  UID: string = Math.random().toString(36).substring(2);
  id: number = 0;
  name: string = "";
  type: string = "";
  ppu: number = 0;
  singlePrice: number = 0;
  resalePerUnit: number = 0;
  units: number = 0;
  quantity: number = 0;
  percentage: number = 0;
  bars: { [key: string]: { name: string; id: string; type: string } } = {};

  constructor(
    id: number,
    name: string,
    type: string,
    ppu: number,
    singlePrice: number
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.ppu = ppu;
    this.singlePrice = singlePrice;
  }
}
