import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { compareAsc } from 'date-fns';
import { firstValueFrom, Observable } from 'rxjs';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-document-manager',
  templateUrl: './document-manager.component.html',
  styleUrls: ['./document-manager.component.css']
})
export class DocumentManagerComponent implements OnInit {

  staff: any[] = [];
  documentList: any[] = [];
  selected: any;

  payslips: any[] = [];
  contracts: any[] = [];

  constructor(private api: StaffAppDatabase, private alert: AlertsService, private loading: LoadingService, private http: HttpClient) { }

  ngOnInit(): void {
    this.loading.nextEmit('on');
    this.getData();
  }

  async getData(){
    this.loading.nextEmit('on');
    this.staff = (await firstValueFrom(this.api.getStaff()) as any[]).sort(((a,b) => {
      try {
        return a.lastName - b.lastName;
      } catch(e) {
        return -1;
      }
    }));
    console.log(this.staff);
    this.loading.nextEmit(null);
  }

  

  async staffSelected(s: string) {
    this.payslips = [];
    this.contracts = [];
    this.selected = this.staff.find(a => a.uid == s);
    if(this.selected && this.selected.uid) {
      this.loading.nextEmit('on');
      const payslips = (await this.api.getDocuments(s,'payslips'))['_delegate'].items.map(a => a['_location']['path_']).map(a => {
        return {location: a, date: a.split("/").pop()}
      });
      const contracts = (await this.api.getDocuments(s,'contract'))['_delegate'].items.map(a => a['_location']['path_']).map(a => {
        return {location: a, date: a.split("/").pop()}
      });
      this.payslips = payslips;
      this.contracts = contracts;
      console.log(payslips, contracts);
      this.loading.nextEmit(null);
    }
  
  }

  async open(doc: any) {
    const url  = await this.api.getDownloadURL(doc.location);
    window.open(encodeURI('https://docs.google.com/gview?embedded=true&url=' + url),"_blank","location=no, enableViewportScale=yes,hidden=no");
 
  }

  
 

}
