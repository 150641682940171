import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import {
  clone,
  cloneDeep,
  concat,
  isBoolean,
  isNumber,
  keyBy,
  merge,
  mergeWith,
} from "lodash";
import { build } from "pdfjs-dist";
import { Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { Omzet } from "src/app/Classes/omzet";
import { OneOffExpense } from "src/app/Classes/one-off-expense";
import { RecurringExpense } from "src/app/Classes/recurring-expense";
import { Verbruik } from "src/app/Classes/verbruik";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { MyDate } from "src/app/Helpers/functions/MyDate";
import { LoadingService } from "src/app/Services/loading.service";

import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { OverviewService } from "src/app/Packages/overview.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { WorkedRecordsComponent } from "src/app/Packages/worked-records/worked-records.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  subs: Subscription[] = [];
  bar = "";

  modalContent = {
    title: "",
    list: [],
  };

  constructor(
    private bI: BarInfo,
    private modal: NgbModal,
    public overview: OverviewService
  ) {}

  openWorkedRecords() {
    const modal = this.modal.open(WorkedRecordsComponent, { size: "xl" });
    modal.componentInstance.records = this.overview.selection.workedRecords;
    modal.result.then(
      (a) => {},
      (e) => {}
    );
  }

  ngOnInit(): void {
    this.subs.push(
      this.bI.barObserver.subscribe((val) => {
        this.bar = val;
        if (val) {
          this.overview.getRange(val);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }

  openModel(content, title, list) {
    this.modalContent.title = title;
    this.modalContent.list = list;
    this.modal
      .open(content)
      .result.then(
        (a) => {
          this.modalContent = { title: "", list: [] };
        },
        (e) => {
          this.modalContent = { title: "", list: [] };
        }
      )
      .catch((e) => {
        this.modalContent = { title: "", list: [] };
      });
  }
}
